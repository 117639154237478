import React, { useState } from 'react';
import { Loginnavigation } from './Loginnavigation';
import './search.css';
import Advancesearch from './Advancesearch';
import Locationsearch from './Locationsearch';
import Communitysearch from './Communitysearch';
import Collegesearch from './Collegesearch';
import Idsearch from './Idsearch';


const Search = () => {
  const [activeSearch, setActiveSearch] = useState('Advance');
  

  const handleSearchButtonClick = (searchType) => {
    setActiveSearch(searchType);
  };

  return (
    <div>
      <Loginnavigation />

      <div className='search-filter'>
        <button
          onClick={() => handleSearchButtonClick('Advance')}
          className={activeSearch === 'Advance' ? 'active' : ''}
        >
          Advance Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Location')}
          className={activeSearch === 'Location' ? 'active' : ''}
        >
          Location Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Community')}
          className={activeSearch === 'Community' ? 'active' : ''}
        >
          Community Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('College')}
          className={activeSearch === 'College' ? 'active' : ''}
        >
          College Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('ID')}
          className={activeSearch === 'ID' ? 'active' : ''}
        >
          ID Based Search
        </button>
      </div>

      {activeSearch === 'Advance' && <Advancesearch />}
      {activeSearch === 'Location' && <Locationsearch />}
      {activeSearch === 'Community' && <Communitysearch />}
      {activeSearch === 'College' && <Collegesearch />}
      {activeSearch === 'ID' && <Idsearch />}
    </div>
  );
};

export default Search;


