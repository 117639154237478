import React, { useEffect,useRef,useState } from 'react';
import html2canvas from 'html2canvas';
import useProfile from "./utils/useProfile";
import useHandleimg from "./utils/useHandleimg";
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom';
import useHandlealbum from "./utils/useHandlealbum";
import './biodataDownload.css'
import bg1 from './images/bg-1.png'
import bg2 from './images/bg-2.png'
import bg3 from './images/bg-3.png'
import bg4 from './images/bg-4.png'
import bg5 from './images/bg-5.png'
import bg6 from './images/bg-6.png'
import bg7 from './images/bg-7.png'
import bg8 from './images/bg-8.png'
import bg9 from './images/bg-9.png'
import bg10 from './images/bg-10.png'
import bg11 from './images/bg-11.png'
import bg12 from './images/bg-12.png'
import bg13 from './images/bg-13.png'
import bg14 from './images/bg-14.png'
import bg15 from './images/bg-15.png'
import bg16 from './images/bg-16.png'
import bg17 from './images/bg-17.png'
const BiodataDownload = () => {
const pdfRef=useRef();
const [photo, setPhoto] = useState('');
useEffect(() => {
  const fetchData = () => {
    const storedData = JSON.parse(localStorage.getItem('biodataForm'));
    if (storedData && storedData.uploadedPhoto) {
      setPhoto(storedData.uploadedPhoto);
    }
  }
    fetchData();
   
        const timer = setTimeout(() => {
           downloadPDF()
        }, 1000); // 3000 milliseconds = 3 seconds

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []); // Empty dependency array ensures this runs once when the component mounts

    const downloadImage = (imgData) => {
      // Create an anchor element
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = 'biodata_image.png'; // Specify the file name for the download
  
      // Append the anchor to the body, click it, and then remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  };





const downloadPDF = () => {
   const input = pdfRef.current;
   html2canvas(input, {
       scale: 6, // Higher resolution
       useCORS: true, // For images from external origins
       logging: true, // For debugging purposes
     
       width: input.clientWidth,
       height: input.clientHeight
   }).then(canvas => {
       const imgData = canvas.toDataURL('image/png', 4.0); // Get the highest quality image
       downloadImage(imgData); // Call the function to download the image
      
       const imgWidth = canvas.width;
       const imgHeight = canvas.height;
   // Convert canvas dimensions from pixels to millimeters (mm)
   const widthInMm = imgWidth * 0.264583; // 1 px = 0.264583 mm
   const heightInMm = imgHeight * 0.264583;
      // Create a new jsPDF instance with page size exactly matching the image size
      const pdf = new jsPDF({
        orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
        unit: 'mm',
        format: [widthInMm, heightInMm]
    });
       // Fit the canvas image to the entire A4 page
    
        // Add the image to the PDF at full page size
        pdf.addImage(imgData, 'PNG', 0, 0, widthInMm, heightInMm);

       pdf.save('biodata.pdf');
   });
};

 const userId = sessionStorage.getItem("userId");
 const userInfo = useProfile(userId);
 const userAlbum = useHandlealbum(userId);
const religion=userInfo?.profile_data?.religion;
const userImg = useHandleimg(userId);
const formatTextWithLineBreaks = (text) => {
   if (!text) return "";

   // Example: Insert a line break after every 10 words
   const words = text.split(' ');
   const formattedText = words.reduce((acc, word, index) => {
       return acc + word + ((index + 1) % 8 === 0 ? '\n' : ' ');
   }, '');

   return formattedText;
};
const location = useLocation();
const { selectedId } = location.state || {};
// console.log(selectedId);

const backgroundImages = {
   1: bg1,
   2: bg2,
   3: bg3,
   4: bg4,
   5: bg5,
   6: bg6,
   7:bg7,
   8:bg8,
   9:bg9,
   10:bg10,
   11:bg11,
   12:bg12,
   13:bg13,
   14:bg14,
   15:bg15,
   16:bg16,
   17:bg17
 };

 const backgroundImage = backgroundImages[selectedId] || './images/bg-1.png';
// console.log(backgroundImage)
return (
   <>
  
   <div  className='main-div' >
 <div className="card-container"    style={{ backgroundImage: `url(${backgroundImage})` }} ref={pdfRef}>
      <div className="profile-header">
        <div className="profile-image">
         <img src={photo} alt="Profile"  />
         
        </div>
        <div style={{marginLeft:'2px'}}>
            <h1 style={{marginTop: '0', marginBottom: '4px',textAlign:'left', fontFamily: 'Redressed', fontWeight:'bold', fontSize:'30px', color:'#f6C105', lineHeight: '29.17px'}}>{userInfo?.profile_data?.full_name}</h1>
            <p className="formattedText">
    {userInfo?.profile_data?.about_me}
</p>
        </div>
    </div>

    <div style={{width:'100%', maxWidth:'900px',marginLeft:'60px'}}>
        
        <div style={{display: 'flex'}}>
      <div style={{padding:'10px',marginRight:'20px', marginLeft:'5px'}}>
        <h2 style={{fontSize:'1.4em',fontWeight:'bold' ,lineHeight:'28px', color:'#f6C105',textTransform:'none'}}>Personal Information:</h2>
      
  
        <ul style={{listStyle: 'none', paddingLeft:'0'}}>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            DOB :               {userInfo?.profile_data?.birth_date} ,  {userInfo?.profile_data?.age}
          </li>
      
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
           Highest Eduction :      {userInfo?.profile_data?.highest_education}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
           College Name :      {userInfo?.profile_data?.college_name}
          </li>
       
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Profession :      {userInfo?.profile_data?.occupation}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Religion :           {religion}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Caste :          {userInfo?.partner_pref?.caste} ,  Gotra :          {userInfo?.profile_data?.gotra}
          </li>
      
         
        </ul>
      </div>
      
        <div style={{padding:'10px',marginLeft:'8px'}}>
        <h2 style={{fontSize:'1.4em',fontWeight:'bold', lineHeight:'28px',color: '#f6C105',textTransform:'none'}}>Family Information:</h2>
       
        <ul style={{listStyle: 'none',paddingLeft:'0'}}>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Father Occupation :    {userInfo?.family_details?.father_occupation}
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Mother Occupation :    {userInfo?.family_details?.mother_occupation}
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Brother count:   {userInfo?.family_details?.brother_count}
          </li>
        
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
             Sister count :      {userInfo?.family_details?.sister_count} 
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
          Family Income   :      {userInfo?.family_details?.family_income}
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Family Details  :    {userInfo?.family_details?.family_type}
          </li>
        </ul>
        
      </div>
      </div>
      
        <div style={{marginLeft:'20px'}}>
          <h2 style={{fontSize:'1.4em', fontWeight:'bold',lineHeight:'28px' ,color:'#f6C105',textTransform:'none'}}>Expectations:</h2>
          
          <div style={{display:'flex',marginLeft:'2px'}}>
              <div style={{marginRight:'20px'}}>
                  <ul style={{listStyle: 'none',paddingLeft:'0'}}>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                         Age :      {userInfo?.partner_pref?.min_age} - {userInfo?.partner_pref?.max_age}
                      </li>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                         Weight :     {userInfo?.partner_pref?.min_weight} - {userInfo?.partner_pref?.max_weight}
                      </li>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                        Country :    {userInfo?.partner_pref?.country}
                      </li>
                    
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                        Caste :      {userInfo?.partner_pref?.caste}, Gotra :   {userInfo?.partner_pref?.gotra}
                      </li>
                      </ul>
              </div>
             <div style={{marginLeft:'30px'}}>
              <ul style={{listStyle: 'none', paddingLeft: '0'}}>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Marital Status :     {userInfo?.partner_pref?.marital_status}
                  </li>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Working Status :   {userInfo?.partner_pref?.working_status}
                  </li>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Education :   {userInfo?.partner_pref?.education}
                  </li>
                
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                     Min Income :       {userInfo?.partner_pref?.min_income}  -  {userInfo?.partner_pref?.max_income}          
                          </li>
                  </ul>
             </div>
          </div>
             <div style={{padding:'2px', marginRight:'10px', marginLeft:'2px'}}>
                <h2 style={{fontSize:'1.4em', fontWeight:'bold', lineHeight:'28px', color:'#f6C105',textTransform:'none'}}>Contact Details</h2>
             
          
                <ul style={{listStyle: 'none', paddingLeft:'0'}}>
                  <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Phone Number:       {userInfo?.profile_data?.phonenumber}
                    </li>
                  <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Residental Address:      {userInfo?.profile_data?.address}   
                  </li>
                  </ul>
             </div>

    </div>
    </div>
    </div>
    </div>
        <button className='btn' onClick={downloadPDF}>Download Pdf</button>
     </>

  );
}

export default BiodataDownload;
