import React from "react";
import "./Chart.css";
import { Link } from "react-router-dom";

const Employees = [
  {
    Benefits: "Cost",
    Free: "Free",
    Bronze: "299/-",
    Copper: "499/-",
    Silver: "649/-",
    Gold: "900/-",
    GoldPremium: "1299/-",
    GirlSpecial: "499/-",
    Platinum: "2000/-",
    Lifetime: "2500/-",
    VIP: "3000/-",
  },
 {
    Benefits: "Duration(In months)",
    Free: "36",
    Bronze: "1",
    Copper: "3",
    Silver: "4",
    Gold: "6",
    GoldPremium: "6",
    GirlSpecial: "3",
    Platinum: "12",
    Lifetime: "60",
    VIP: "60",
  },
  {
    Benefits: "Bonus",
    Free: "NA",
    Bronze: "1",
    Copper: "1",
    Silver: "3",
    Gold: "1",
    GoldPremium: "3",
    GirlSpecial: "3",
    Platinum: "",
    Lifetime: "",
    VIP: "",
  },
  {
    Benefits: "Net Duration",
    Free: "36",
    Bronze: "2",
    Copper: "4",
    Silver: "7",
    Gold: "7",
    GoldPremium: "9",
    GirlSpecial: "6",
    Platinum: "12",
    Lifetime: "60",
    VIP: "60",
  },
  {
    Benefits: "Photo Album(Max Photos)",
    Free: "1 Photo",
    Bronze: "5 Photos",
    Copper: "10 Photos",
    Silver: "10 Photos",
    Gold: "10 Photos",
    GoldPremium: "10 Photos",
    GirlSpecial: "10 Photos",
    Platinum: "20 Photos",
    Lifetime: "20 Photos",
    VIP: "20 Photos",
  },
  {
    Benefits: "Contacts",
    Free: "0",
    Bronze: "100",
    Copper: "100",
    Silver: "200",
    Gold: "250",
    GoldPremium: "500",
    GirlSpecial: "400",
    Platinum: "1000",
    Lifetime: "1000",
    VIP: "1000",
  },
  {
    Benefits: "Chat",
    Free: "❌",
    Bronze: "✅",
    Copper: "✅",
    Silver: "✅",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
  {
    Benefits: "Video Call",
    Free: "❌",
    Bronze: "❌",
    Copper: "✅",
    Silver: "✅",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
  {
    Benefits: "Verified Profiles Filter",
    Free: "❌",
    Bronze: "❌",
    Copper: "✅",
    Silver: "✅",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
  {
    Benefits: "Profile Image Filter",
    Free: "❌",
    Bronze: "❌",
    Copper: "❌",
    Silver: "❌",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
  {
    Benefits: "Horoscope Matching",
    Free: "❌",
    Bronze: "✅",
    Copper: "✅",
    Silver: "✅",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
  {
    Benefits: "Send Interest",
    Free: "✅",
    Bronze: "✅",
    Copper: "✅",
    Silver: "✅",
    Gold: "✅",
    GoldPremium: "✅",
    GirlSpecial: "✅",
    Platinum: "✅",
    Lifetime: "✅",
    VIP: "✅",
  },
];

function Row(props) {
  return (
    <tr>
      <td>{props.employee.Benefits}</td>
      <td>{props.employee.Free}</td>
      <td>{props.employee.Bronze}</td>
      <td>{props.employee.Copper}</td>
      <td>{props.employee.Silver}</td>
      <td>{props.employee.Gold}</td>
      <td>{props.employee.GoldPremium}</td>
      <td>{props.employee.GirlSpecial}</td>
      <td>{props.employee.Platinum}</td>
      <td>{props.employee.Lifetime}</td>
      <td>{props.employee.VIP}</td>
    </tr>
  );
}

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: Employees,
    };
    this.handleSelectChnage = this.handleSelectChnage.bind(this);
    this.handleInputChnage = this.handleInputChnage.bind(this);
  }

  handleSelectChnage(event) {
    if (event.target.value === "") {
      this.setState({
        employees: Employees,
      });
    } else {
      let searchData = Employees.filter((emp) => {
        return emp.department === event.target.value;
      });
      this.setState({
        employees: searchData,
      });
    }
  }

  handleInputChnage(event) {
    const searchText = event.target.value.toLowerCase();
    let searchData = Employees.filter((emp) => {
      return emp.name.toLowerCase().indexOf(searchText) > -1;
    });

    this.setState({
      employees: searchData,
    });
  }

  render() {
    const rows = [];
    this.state.employees.forEach((emp) => {
      rows.push(<Row employee={emp} />);
    });

    return (
      <div id="chart">
        <h3
          style={{
            color: "#FFB800",
            fontSize: "38px",
            paddingBottom: "30px",
            textAlign: "center",
            fontWeight: "575",
            fontFamily: "Lemon",
          }}
        >
          Membership Plans Comparison
        </h3>
        <div class="table-responsive">
          <table className="table table-striped  table-bordered table-hoved text-center">
            <thead>
              <tr className="text-center">
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Benefits
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Free
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Bronze
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Copper
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Silver
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  Gold
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                    borderRight: "1px solid black",
                  }}
                >
                  Gold Premium
                </th>
                <th
                  style={{
                    background: "#34b4eb",
                    color: "white",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Recommended<br></br> for girls
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                    borderRight: "1px solid black",
                  }}
                >
                  Platinum
                </th>
                <th
                  style={{
                    background: "#15d61f",
                    color: "white",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Special<br></br> Recommendation
                </th>
                <th
                  style={{
                    background: "white",
                    color: "black",
                    visibility: "hidden",
                    border: "1px solid rgb(244 229 174 / 72%)",
                  }}
                >
                  VIP
                </th>
              </tr>

              <tr className="text-center">
                <th
                  style={{
                    background: "white",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Benefits
                </th>
                <th
                  style={{
                    background: "#C0C0C0",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Free
                </th>
                <th
                  style={{
                    background: "#CD7F32",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Bronze
                </th>
                <th
                  style={{
                    background: "#B87333",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Copper
                </th>
                <th
                  style={{
                    background: "#C0C0C0",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Silver
                </th>
                <th
                  style={{
                    background: "#FFD700",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Gold
                </th>
                <th
                  style={{
                    background: "#FBE050",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Gold Premium
                </th>
                <th
                  style={{
                    background: "#FF5E84",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Girl's Special
                </th>
                <th
                  style={{
                    background: "#E5E4E2",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Platinum
                </th>
                <th
                  style={{
                    background: "#526EFF",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Lifetime
                </th>
                <th
                  style={{
                    background: "#FF862E",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  VIP
                </th>
              </tr>
            </thead>
            <tbody style={{ background: "white", color: "black" }}>
              {rows}
            </tbody>
          </table>
        </div>

        <button
          style={{
            border: "none",
            background: "#BF0500",
            fontSize: "28px",
            fontWeight: "575",
            color: "white",
            fontFamily: "Lemon",
            padding: "10px 20px",
            borderRadius: "10px",
            display: "block",
            margin: "auto", // Center horizontally
            marginTop: "20px", // Adjust top margin as needed
          }}
        >
          <li style={{listStyle: 'none'}}><Link style={{color: 'white'}} to="/planpage">Browse Plans</Link></li>
        </button>
      </div>
    );
  }
}

export default Chart;
