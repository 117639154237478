import React, { useState } from 'react';
import './search.css';
import LocationSearchResult from './LocationSearchResult';
import Advancesearch from './Advancesearch';
import Collegesearch from './Collegesearch';
import Idsearch from './Idsearch';
import { useLocation } from "react-router-dom";
import { Loginnavigation } from './Loginnavigation';
import Communitysearch from './Communitysearch';


const LocationSearchresultNavbar = () => {
  const location = useLocation();
  
    
  const [activeSearch, setActiveSearch] = useState('Locationsearch');
  const userinfo = location.state?.responseData || null;

  const handleSearchButtonClick = (searchType) => {
    setActiveSearch(searchType);
  };

  return (
    <div>
      <Loginnavigation/>
      <div className='search-filter'>
        <button style={{background: 'none', color: '#222222'}}
          onClick={() => handleSearchButtonClick('Locationsearch')}
          className={activeSearch === 'Locationsearch' ? 'active' : ''}
        >
          Results({userinfo.count})
        </button>
        <button
          onClick={() => handleSearchButtonClick('Advance')}
          className={activeSearch === 'Advance' ? 'active' : ''}
        >
          Advance Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Community')}
          className={activeSearch === 'Community' ? 'active' : ''}
        >
          Community Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('College')}
          className={activeSearch === 'College' ? 'active' : ''}
        >
          College Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('ID')}
          className={activeSearch === 'ID' ? 'active' : ''}
        >
          ID Based Search
        </button>
      </div>

      {activeSearch === 'Locationsearch' && <LocationSearchResult userinfo={userinfo?.users}/>}
      {activeSearch === 'Advance' && <Advancesearch/>}
      {activeSearch === 'Community' && <Communitysearch />}
      {activeSearch === 'College' && <Collegesearch />}
      {activeSearch === 'ID' && <Idsearch />}
    </div>
  );
};

export default LocationSearchresultNavbar;