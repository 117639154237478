import React from "react";
import Item from "./Item";
import "react-responsive-modal/styles.css";
import './plan.css';

function PlanPagecardsSubscribe() {
  return (
    <>
      <div
        id="plans"
        style={{
          marginTop: '50px',
          background: "#FBC913",
          paddingTop: "30px",
          paddingBottom: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "46px",
            paddingBottom: "20px",
            fontWeight: "575",
            fontFamily: "Lemon",
          }}
        >
          All Membership Plans
        </h1>
        <div
          className="App"
          style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
        >
          <div className="item-width">
            <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: 'linear-gradient(180deg, hsla(30, 61%, 50%, 1) 0%, hsla(0, 0%, 0%, 1) 100%, hsla(28, 46%, 17%, 1) 100%, hsla(30, 61%, 16%, 1) 100%, hsla(31, 61%, 19%, 1) 100%)',
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Bronze <br />
            <div className="modal_new" style={{ marginTop: '25px', fontFamily: "Lemon", fontWeight: "100" }}>
              <sup>₹</sup>299<sub></sub>
            </div>
            <div className="modal_new" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, fontFamily: "Lemon", fontWeight: "100", marginTop: '-70px' }}>
              1 Month
            </div>
          </Item>
          </div>

          <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(29, 51%, 48%, 1) 0%, hsla(29, 100%, 93%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Copper <br />
            <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon"}}><sup>₹</sup>499<sub></sub>
            </div>
            <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
               3 Months
            </div>
          </Item>
          </div>

          <div className="item-width">
          <Item className="item-width"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(240, 1%, 70%, 1) 0%, hsla(0, 0%, 0%, 1) 100%",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Silver <br/>
              <div className="modal_new" style={{fontWeight: "100",marginTop: '25px',fontFamily: "Lemon"}}>
                <sup>₹</sup>649<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                4 Months
              </div>
          </Item>
          </div>

          {/* Repeat the pattern for other cards */}
          
          
          <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            background: "linear-gradient(180deg, hsla(51, 98%, 48%, 1) 0%, hsla(0, 0%, 0%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Gold <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>900<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                6 Months
              </div>
          </Item>
          </div>

          <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(50, 98%, 46%, 1) 0%, hsla(50, 100%, 88%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Gold Premium <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>1299<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                6 Months
              </div>
          </Item>
          </div>

          <div className="item-width">
          <Item 
            style={{ 
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(346, 100%, 68%, 1) 0%, hsla(0, 0%, 0%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Girl's Special <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>499<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                3 Months
              </div>
          </Item>
          </div>

        

          <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(40, 5%, 89%, 1) 0%, hsla(0, 0%, 0%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            Platinum <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>2000<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, fontFamily: "Lemon",}}>
                12 Months
              </div>
          </Item>
          </div>

          <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(230, 100%, 66%, 1) 0%, hsla(0, 0%, 0%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
             Lifetime <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>2500<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                Unlimited
              </div>
          </Item>
          </div>
        

        <div className="item-width">
          <Item 
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "linear-gradient(180deg, hsla(266, 94%, 58%, 1) 0%, hsla(0, 0%, 0%, 1) 100%)",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              fontSize: "45px",
              fontWeight: "500",
              margin: "30px", // Add some margin between cards
              border: '7px solid white'
            }}
          >
            VIP <br />
              <div className="modal_new" style={{marginTop: '25px',fontFamily: "Lemon",fontWeight: "100"}}>
                <sup>₹</sup>3000<sub></sub>
              </div>
              <div className="modal_new" style={{ fontWeight: "100", marginTop: '-70px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1,fontFamily: "Lemon"}}>
                Unlimited
              </div>
          </Item>
          </div>

          

          </div>
      </div>
    </>
  );
}

export default PlanPagecardsSubscribe;