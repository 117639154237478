import React, {useState, useEffect} from "react";
import vs from './images/vs-new.png'
import { Link } from "react-router-dom";

export const Privacynavigation = (props) => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount and unmount

  const navClass = scrolled ? 'navbar-scrolled' : '';

  return (
    <nav id="menu" className={`navbar navbar-default navbar-fixed-top ${navClass}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img style={{ width: "200px", height: "53px", marginTop: "-20px" }} src={vs} alt="logo" />
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
          <li>
              <Link to="/" className="page-scroll">
                Home
              </Link>
            </li>
            <li>
              <Link to="#register" className="page-scroll">
                Register
              </Link>
            </li>
            <li className="dropdown hover-dropdown">
              <Link to="#" className="dropdown-toggle page-scroll" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                Help <span className="caret"></span>
              </Link>
              <ul className="dropdown-menu" style={{fontSize: '17px', fontFamily: 'Lemon', borderRadius: '10px', color: '#3e3e3ed1'}}>
                <li><Link to="#">Help Option 1</Link></li>
                <li><Link to="#">Help Option 2</Link></li>
                {/* Add more dropdown items as needed */}
              </ul>
            </li>
            <li className="dropdown hover-dropdown">
              <Link to="#" className="dropdown-toggle page-scroll" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                Browse Matches <span className="caret"></span>
              </Link>
              <ul className="dropdown-menu" style={{fontSize: '17px', fontFamily: 'Lemon', borderRadius: '10px', color: '#3e3e3ed1'}}>
                {/* <li><Link to="/planpage">PlanPage</Link></li> */}
                <li><Link to="#">Option 2</Link></li>
                {/* Add more dropdown items as needed */}
              </ul>
            </li>
            <li className="dropdown hover-dropdown">
              <Link to="/login" className="dropdown-toggle page-scroll" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style={{  color: 'white', padding: '10px 18px', border: '1px solid white' }}>
                Login <span className="caret"></span>
              </Link>
              {/* <ul className="dropdown-menu" style={{fontSize: '17px', fontFamily: 'Lemon', borderRadius: '10px', color: '#3e3e3ed1'}}>
                <li><Link to="#">Login Option 1</Link></li>
                <li><Link to="#">Login Option 2</Link></li>
              </ul> */}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};