import React, {useState} from 'react';
import './search.css'; // Import your custom CSS file
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import { cityData, districtData } from '../data/city';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './searchsliders.css'
import { useNavigate } from "react-router-dom";


const Locationsearch = () => {
  const navigate = useNavigate();
  const gender = sessionStorage.getItem("gender");
  const religion = sessionStorage.getItem("religion");


  const [gotra1, setGotra1] = useState('');
  const [otherGotra1, setOtherGotra1] = useState('');
  const [caste1, setCaste1] = useState('');
  const [otherCaste1, setOtherCaste1] = useState('');
  const [subcaste1, setSubcaste1] = useState('');
  const [otherSubCaste1, setOtherSubCaste1] = useState('');
  const [country1, setCountry1] = useState('');
  const [otherCountry1, setOtherCountry1] = useState('');
  const [state1, setState1] = useState('');
  const [otherState1, setOtherState1] = useState('');
  const [city1, setCity1] = useState('');
  const [otherCity1, setOtherCity1] = useState('');
  const [district1, setDistrict1] = useState('');
  const [otherDistrict1, setOtherDistrict1] = useState('');
  const [facecolor1, setFacecolor1] = useState('');
  const [marital1, setMarital1] = useState('');
  const [working1, setWorking1] = useState('');

  const [weightRange1, setweightRange1] = useState({ min: 0, max: 100 });
  const [ageRange1, setageRange1] = useState({ min: 18, max: 100 })


  const [country1Error, setCountry1Error] = useState("");
  const [otherCountry1Error, setOtherCountry1Error] = useState("");
  const [state1Error, setState1Error] = useState("");
  const [otherState1Error, setOtherState1Error] = useState("");
  const [district1Error, setDistrict1Error] = useState("");
  const [otherDistrict1Error, setOtherDistrict1Error] = useState("");
  const [city1Error, setCity1Error] = useState("");
  const [otherCity1Error, setOtherCity1Error] = useState("");
  

  
  const [heightRange1, setheightRange1] = useState({
    min: 5 * 12, // Default to 5 feet
    max: 6 * 12, // Default to 6 feet
  });
  const formatValue = (value) => {
    const feet = Math.floor(value / 12);
    const inches = value % 12;
    return `${feet}'${inches}''`;
  };


  const filteredDistricts = districtData.states.find(s => s.state === state1)?.districts || [];

  const filteredcities = cityData.states.find(s => s.state === state1)?.city || [];

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };


  const handleSearch = async () => {
    if (
      country1.trim() !== "" &&
      state1.trim() !== "" &&
      district1.trim() !== "" &&
      city1.trim() !== "" &&
      ((country1 === "Other (Specify)" && otherCountry1.trim() !== "") ||
      country1 !== "Other (Specify)") &&
      ((state1 === "Other (Specify)" && otherState1.trim() !== "") ||
      state1 !== "Other (Specify)") &&
      ((district1 === "Other (Specify)" && otherDistrict1.trim() !== "") ||
      district1 !== "Other (Specify)") &&
      ((city1 === "Other (Specify)" && otherCity1.trim() !== "") ||
      city1 !== "Other (Specify)") 
   
    ){
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "country": country1 === "Other (Specify)" ? capitalizeFirstLetter(otherCountry1) : country1,
            "state": state1 === "Other (Specify)" ? capitalizeFirstLetter(otherState1) : state1,
            "district": district1 === "Other (Specify)" ? capitalizeFirstLetter(otherDistrict1) : district1,
            "city": city1 === "Other (Specify)" ? capitalizeFirstLetter(otherCity1) : city1,
            "gender": gender,
            "religion": religion,
            
            locationsearch: true,
          }),
        });
  
        const data = await response.json();
        if (data !== null) {
          navigate('/locationsearchresultnavbar', { state: { responseData: data } });
        }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    }
    else {

      setCountry1Error(country1.trim() === "" ? "Please select country" : "");
      setOtherCountry1Error(
          country1 === "Other (Specify)" && otherCountry1.trim() === ""
            ? "Please specify country"
            : ""
      );

      setState1Error(state1.trim() === "" ? "Please select state" : "");
      setOtherState1Error(
          state1 === "Other (Specify)" && otherState1.trim() === ""
            ? "Please specify state"
            : ""
      );

      setDistrict1Error(district1.trim() === "" ? "Please select district" : "");
      setOtherDistrict1Error(
          district1 === "Other (Specify)" && otherDistrict1.trim() === ""
            ? "Please specify district"
            : ""
      );

      setCity1Error(city1.trim() === "" ? "Please select city" : "");
      setOtherCity1Error(
        city1 === "Other (Specify)" && otherCity1.trim() === ""
          ? "Please specify city"
          : ""
      );



    }
  }


  return (
    <>
      <div className='advance-search'>
      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="gotra1">Gotra</label>
            <select
              className="form-control"
              id="gotra1"
              value={gotra1}
              onChange={(e) => {
                  setGotra1(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherGotra1("");
                  }
                }}
            > 
              <option value="">Select</option>
              <option value="Bharadwaj">Bharadwaj</option>
              <option value="Kashyap">Kashyap</option>
              <option value="Vatsa">Vatsa</option>
              <option value="Gautam">Gautam</option>
              <option value="Shandilya">Shandilya</option>
              <option value="Atri">Atri</option>
              <option value="Vasistha">Vasistha</option>
              <option value="Kaushik">Kaushik</option>
              <option value="Garg">Garg</option>
              <option value="Parashara">Parashara</option>
              <option value="Sandilya">Sandilya</option>
              <option value="Vashishtha">Vashishtha</option>
              <option value="Jamadagni">Jamadagni</option>
              <option value="Angirasa">Angirasa</option>
              <option value="Kaundinya">Kaundinya</option>
              <option value="Suryavanshi">Suryavanshi</option>
              <option value="Chandravanshi">Chandravanshi</option>
              <option value="Agnivanshi">Agnivanshi</option>
              <option value="Nagvanshi">Nagvanshi</option>
              <option value="Kachwaha">Kachwaha</option>
              <option value="Rathore">Rathore</option>
              <option value="Tomar">Tomar</option>
              <option value="Solanki">Solanki</option>
              <option value="Chauhan">Chauhan</option>
              <option value="Parmar">Parmar</option>
              <option value="Gohil">Gohil</option>
              <option value="Pratihar">Pratihar</option>
              <option value="Parihar">Parihar</option>
              <option value="Rajput">Rajput</option>
              <option value="Rajawat">Rajawat</option>
              <option value="Agarwal">Agarwal</option>
              <option value="Garg">Garg</option>
              <option value="Bansal">Bansal</option>
              <option value="Mittal">Mittal</option>
              <option value="Singhal">Singhal</option>
              <option value="Kansal">Kansal</option>
              <option value="Mangal">Mangal</option>
              <option value="Madhukul">Madhukul</option>
              <option value="Goyal">Goyal</option>
              <option value="Jindal">Jindal</option>
              <option value="Tayal">Tayal</option>
              <option value="Kuchhal">Kuchhal</option>
              <option value="Goyal">Goyal</option>
              <option value="Mangal">Mangal</option>
              <option value="Khandelwal">Khandelwal</option>
              <option value="Gupta">Gupta</option>
              <option value="Maheshwari">Maheshwari</option>
              <option value="Oswal">Oswal</option>
              <option value="Porwal">Porwal</option>
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {gotra1 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherGotra1}
                  onChange={(e) => setOtherGotra1(e.target.value)}
                  style={{
                    marginTop: '20px'
                    
                  }}
                />
              )}
          </div>
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="caste1">Caste</label>
            <select
              className="form-control"
              id="caste1"
              value={caste1}
              onChange={(e) => {
                  setCaste1(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCaste1("");
                  }
                }}
            > 
              <option value="">Select</option>
              <option value="Brahmin">Brahmin</option>
              <option value="Kshatriya">Kshatriya</option>
              <option value="Vaishya">Vaishya</option>
              <option value="Shudra">Shudra</option>
              <option value="Scheduled Castes (SC)">Scheduled Castes (SC)</option>
              <option value="Scheduled Tribes (ST)">Scheduled Tribes (ST)</option>
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {caste1 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherCaste1}
                  onChange={(e) => setOtherCaste1(e.target.value)}
                  style={{marginTop: '20px'
                  }}
                />
              )}
          </div>
        </div>
      </div>


      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="subcaste1">Sub-Caste</label>
            <select
              className="form-control"
              id="subcaste1"
              value={subcaste1}
              onChange={(e) => {
                  setSubcaste1(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherSubCaste1("");
                  }
                }}
            > 
              <option value="">Select</option>
              <option value="Not Applicable">Not Applicable</option>
              <option value="Agarwal">Agarwal</option>
              <option value="Dalit">Dalit</option>
              <option value="Adi Dravida">Adi Dravida</option>
              <option value="Chamar">Chamar</option>
              <option value="Tribal">Tribal</option>
              <option value="Gond">Gond</option>
              <option value="Munda">Munda</option>
              <option value="Yadav">Yadav</option>
              <option value="Kurmi">Kurmi</option>
              <option value="Maurya">Maurya</option>
              <option value="Nair">Nair</option>
              <option value="Nadar">Nadar</option>
              <option value="Vanniyar">Vanniyar</option>
              <option value="Brahmin">Brahmin</option>
              <option value="Iyer">Iyer</option>
              <option value="Iyengar">Iyengar</option>
              <option value="Smarta Brahmin">Smarta Brahmin</option>
              <option value="Rajput">Rajput</option>
              <option value="Thakur">Thakur</option>
              <option value="Kshatriya Raju">Kshatriya Raju</option>
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {subcaste1 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherSubCaste1}
                  onChange={(e) => setOtherSubCaste1(e.target.value)}
                  style={{marginTop: '20px'
                  }}
                />
              )}
          </div>
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="facecolor1">Face Color</label>
            <select
              className="form-control"
              id="facecolor1"
              value={facecolor1}
              onChange={(e) => setFacecolor1(e.target.value)}
            > 
              <option value="">Select</option>
              <option value="Fair">Fair</option>
              <option value="Medium Skin">Medium Skin</option>
              <option value="Wheatish">Wheatish</option>
              <option value="Brown">Brown</option>
              <option value="Dark">Dark</option>
            </select>
          </div>
        </div>

        
      </div>

      <div className="custom-row">

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="country1">Country *</label>
            <select
              className="form-control"
              id="country1"
              value={country1}
              onChange={(e) => {
                  setCountry1(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCountry1("");
                  }

                }}
            > 
              <option value="">Select</option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Australia">Australia</option>
              <option value="India">India</option>
              <option value="Germany">Germany</option>
              <option value="France">France</option>
              <option value="Spain">Spain</option>
              <option value="Italy">Italy</option>
              <option value="Japan">Japan</option>
              <option value="China">China</option>
              <option value="Brazil">Brazil</option>
              <option value="Russia">Russia</option>
              <option value="South Africa">South Africa</option>
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {country1 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherCountry1}
                  onChange={(e) => setOtherCountry1(e.target.value)}
                  style={{marginTop: '20px',}}
                />
              )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {country1Error || otherCountry1Error}
            </div>
        </div>
        

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="state1">State *</label>
            <select
              className="form-control"
              id="state1"
              value={state1}
              onChange={(e) => {
                    setState1(e.target.value);
                    // Reset otherMotherTongue when a different option is selected
                    if (e.target.value !== "Other (Specify)") {
                      setOtherState1("");
                    }
                  }}
            > 
              <option value="">Select</option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Chattisgarh">Chattisgarh</option>
              <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
              <option value="Daman and Diu">Daman and Diu</option>
              <option value="Delhi">Delhi</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jammu and Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Ladakh">Ladakh</option>
              <option value="Madhya Pradesh">MadhyaPradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Puducherry">Puducherry</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">TamilNadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">UttarPradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {state1 === "Other (Specify)" && (
                  <input
                    type="text" 
                    className="form-control"
                    placeholder="Specify"
                    value={otherState1}
                    onChange={(e) => setOtherState1(e.target.value)}
                    style={{marginTop: '20px'
                    }}
                  />
                )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {state1Error || otherState1Error}
            </div>
        </div>

        
      </div>

      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="district1">District</label>
            <select
              className="form-control"
              id="district1"
              value={district1}
              onChange={(e) => {
                    setDistrict1(e.target.value);
                    if (e.target.value !== "Other (Specify)") {
                      setOtherDistrict1("");
                    }
              }}
            > 
              <option value="">Select</option>
              {filteredDistricts.map((district, index) => (
                <option key={index} value={district}>
                  {district}
                </option>
              ))}

              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {district1 === "Other (Specify)" && (

                  <input
                    type="text" 
                    className="form-control"
                    placeholder="Specify"
                    value={otherDistrict1}
                    onChange={(e) => setOtherDistrict1(e.target.value)}
                    style={{marginTop: '20px'
                    }}
                  />
                )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {district1Error || otherDistrict1Error}
            </div>
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="city1">City</label>
            <select
              className="form-control"
              id="city1"
              value={city1}
              onChange={(e) => {
                    setCity1(e.target.value);
                    // Reset otherMotherTongue when a different option is selected
                    if (e.target.value !== "Other (Specify)") {
                      setOtherCity1("");
                    }
                  }}
            > 
              <option value="">Select</option>
              {filteredcities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {city1 === "Other (Specify)" && (
                  <input
                    type="text" 
                    className="form-control"
                    placeholder="Specify"
                    value={otherCity1}
                    onChange={(e) => setOtherCity1(e.target.value)}
                    style={{marginTop: '20px'
                    }}
                  />
                )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {city1Error || otherCity1Error}
            </div>
        </div>

        
      </div>

      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="marital1">Marital Status</label>
            <select
              className="form-control"
              id="marital1"
              value={marital1}
              onChange={(e) => setMarital1(e.target.value)}
            > 
              <option value="">Select</option>
              <option value="Single">Single</option>
              <option value="Divorced">Divorced</option>
              <option value="Widow">Widow</option>
              <option value="Seperated">Seperated</option>
            </select>
          </div>
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="working1">Working Status</label>
            <select
              className="form-control"
              id="working1"
              value={working1}
              onChange={(e) => setWorking1(e.target.value)}
            > 
              <option value="">Select</option>
              <option value="Working">Working</option>
              <option value="Non-working">Non-working</option>
              <option value="Student">Student</option>
            </select>
          </div>
        </div>
      </div>

      <div className="custom-row">
      <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="weightRange1">Weight(in kg)</label>
            <br/><br/><br/>
            <InputRange
            minValue={0}
            maxValue={100}
            value={weightRange1}
            onChange={(value) => setweightRange1(value)}
          />
                
            
          </div>
        </div>

        <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="ageRange1">Age</label>
              <br/><br/><br/>
            <InputRange
            minValue={18}
            maxValue={100}
            value={ageRange1}
            onChange={(value) => setageRange1(value)}
          />
            </div>
          </div>
      </div>

      <div className="custom-row">
          

          <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="heightRange1">Height</label>
            <br/><br/><br/>
            <InputRange
            minValue={1}
            maxValue={10 * 12} // Assuming a maximum of 10 feet
            value={{ min: heightRange1.min, max: heightRange1.max }}
            onChange={(value) => {
              setheightRange1({
                min: value.min,
                max: value.max,
              });
            }}
            formatLabel={(value) => formatValue(value)}
          />
          </div>
        </div>
        </div>

      
      </div>

      <button className='advance-search-btn' style={{marginBottom: '50px'}} onClick={handleSearch}>Search</button>

      <Aboutdownload />
      <Footer/>

    </>
  )
}

export default Locationsearch;
