import React, { useState } from 'react';
import './search.css'; // Import your custom CSS file
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './searchsliders.css'
import { useNavigate } from "react-router-dom";

const Collegesearch = () => {
  const navigate = useNavigate();
  const gender = sessionStorage.getItem("gender");
  const religion = sessionStorage.getItem("religion");


  const [college3, setCollege3] = useState('');
  const [otherCollege3, setOtherCollege3] = useState('');
  const [facecolor3, setFacecolor3] = useState('');
  const [marital3, setMarital3] = useState('');

  const [WeightRange3, setWeightRange3] = useState({ min: 0, max: 100 });
  const [ageRange3, setageRange3] = useState({ min: 18, max: 100 })


  const [college3Error, setCollege3Error] = useState("");
  const [otherCollege3Error, setOtherCollege3Error] = useState("");
  

  
  const [heightRange3, setheightRange3] = useState({
    min: 5 * 12, // Default to 5 feet
    max: 6 * 12, // Default to 6 feet
  });
  const formatValue = (value) => {
    const feet = Math.floor(value / 12);
    const inches = value % 12;
    return `${feet}'${inches}''`;
  };

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleSearch = async () => {
    if (
      college3.trim() !== "" &&
      ((college3 === "Other (Specify)" && otherCollege3.trim() !== "") ||
      college3 !== "Other (Specify)") 
   
    ){
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "college": college3 === "Other (Specify)" ? capitalizeFirstLetter(otherCollege3) : college3,
            "gender": gender,
            "religion": religion,
            
            
            collegeSearch: true,
          }),
        });
  
        const data = await response.json();
        if (data !== null) {
          navigate('/collegesearchresultnavbar', { state: { responseData: data } });
        }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    }
    else {
      setCollege3Error(college3.trim() === "" ? "Please select college" : "");
      setOtherCollege3Error(
          college3 === "Other (Specify)" && otherCollege3.trim() === ""
            ? "Please specify college"
            : ""
      );
    }
  }



  return (
    <>
    <div className='advance-search'>
    <div className="custom-row">
      <div className="custom-col">
        <div className="form-group">
          <label htmlFor="college3">College Name *</label>
          <select
            className="form-control"
            id="college3"
            value={college3}
            onChange={(e) => {
                  setCollege3(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCollege3("");
                  }
                }}
          > 
            <option value="">Select</option>
            <option value="IIT Kharagpur">IIT Kharagpur</option>
              <option value="IIT Bombay">IIT Bombay</option>
              <option value="IIT Madras">IIT Madras</option>
              <option value="IIT Kanpur">IIT Kanpur</option>
              <option value="IIT Delhi">IIT Delhi</option>
              <option value="IIT Guwahati">IIT Guwahati</option>
              <option value="IIT Roorkee">IIT Roorkee</option>
              <option value="IIT Ropar">IIT Ropar</option>
              <option value="IIT Bhubaneswar">IIT Bhubaneswar</option>
              <option value="IIT Gandhinagar">IIT Gandhinagar</option>
              <option value="IIT Hyderabad">IIT Hyderabad</option>
              <option value="IIT Jodhpur">IIT Jodhpur</option>
              <option value="IIT Patna">IIT Patna</option>
              <option value="IIT Indore">IIT Indore</option>
              <option value="IIT Mandi">IIT Mandi</option>
              <option value="IIT Varanasi">IIT Varanasi</option>
              <option value="IIT Palakkad">IIT Palakkad</option>
              <option value="IIT Tirupati">IIT Tirupati</option>
              <option value="IIT Dhanbad">IIT Dhanbad</option>
              <option value="IIT Bhilai">IIT Bhilai</option>
              <option value="IIT Dharwad">IIT Dharwad</option>
              <option value="IIT Jammu">IIT Jammu</option>
              <option value="IIT Goa">IIT Goa</option>

              <option value="Birla Institute of Technology and Science - Pilani">Birla Institute of Technology and Science - Pilani</option>
              <option value="Birla Institute of Technology and Science - Pilani, Goa campus">Birla Institute of Technology and Science - Pilani, Goa campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Hyderabad Campus">Birla Institute of Technology and Science - Pilani, Hyderabad Campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Dubai Campus">Birla Institute of Technology and Science - Pilani, Dubai Campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Pilani Campus">Birla Institute of Technology and Science - Pilani, Pilani Campus</option>

              <option value="IIM Ahmedabad">IIM Ahmedabad</option>
              <option value="IIM Bangalore">IIM Bangalore</option>
              <option value="IIM Calcutta">IIM Calcutta</option>
              <option value="IIM Lucknow">IIM Lucknow</option>
              <option value="IIM Indore">IIM Indore</option>
              <option value="IIM Kozhikode">IIM Kozhikode</option>
              <option value="IIM Raipur">IIM Raipur</option>
              <option value="IIM Ranchi">IIM Ranchi</option>
              <option value="IIM Rohtak">IIM Rohtak</option>
              <option value="IIM Kashipur">IIM Kashipur</option>
              <option value="IIM Tiruchirappalli">IIM Tiruchirappalli</option>
              <option value="IIM Udaipur">IIM Udaipur</option>
              <option value="IIM Amritsar">IIM Amritsar</option>
              <option value="IIM Bodh Gaya">IIM Bodh Gaya</option>
              <option value="IIM Nagpur">IIM Nagpur</option>
              <option value="IIM Sambalpur">IIM Sambalpur</option>
              <option value="IIM Sirmaur">IIM Sirmaur</option>
              <option value="IIM Visakhapatnam">IIM Visakhapatnam</option>
              <option value="IIM Jammu">IIM Jammu</option>
              <option value="IIM Mumbai">IIM Mumbai</option>

              <option value="XLRI Ã¢â‚¬â€œ Xavier School of Management">XLRI Ã¢â‚¬â€œ Xavier School of Management</option>
              <option value="S.P Jain College of Management and Research">S.P Jain College of Management and Research</option>
              <option value="NIT Warangal(NITW)">NIT Warangal(NITW)</option>
              <option value="NIT Bhopal(MANIT)">NIT Bhopal(MANIT)</option>
              <option value="NIT Nagpur(VNIT)">NIT Nagpur(VNIT)</option>
              <option value="NIT Durgapur(NITDGP)">NIT Durgapur(NITDGP)</option>
              <option value="NIT Jamshedpur(NITJSR)">NIT Jamshedpur(NITJSR)</option>
              <option value="NIT Karnataka(NITK)">NIT Karnataka(NITK)</option>
              <option value="NIT Srinagar(NITSRI)">NIT Srinagar(NITSRI)</option>
              <option value="NIT Allahabad(MNNIT)">NIT Allahabad(MNNIT)</option>
              <option value="NIT Surat(SVNIT)">NIT Surat(SVNIT)</option>
              <option value="NIT Calicut(NITC)">NIT Calicut(NITC)</option>
              <option value="NIT Rourkela(NITR)">NIT Rourkela(NITR)</option>
              <option value="NIT Jaipur(MNIT)">NIT Jaipur(MNIT)</option>
              <option value="NIT Kurukshetra(NITKKR)">NIT Kurukshetra(NITKKR)</option>
              <option value="NIT Tiruchirappalli(NITT)">NIT Tiruchirappalli(NITT)</option>
              <option value="NIT Silchar(NITS)">NIT Silchar(NITS)</option>
              <option value="NIT Hamirpur(NITH)">NIT Hamirpur(NITH)</option>
              <option value="NIT Jalandhar(NITJ)">NIT Jalandhar(NITJ)</option>
              <option value="NIT Patna(NITP)">NIT Patna(NITP)</option>
              <option value="NIT Raipur(NITRR)">NIT Raipur(NITRR)</option>
              <option value="NIT Agartala(NITA)">NIT Agartala(NITA)</option>
              <option value="NIT Arunachal Pradesh(NITAP)">NIT Arunachal Pradesh(NITAP)</option>
              <option value="NIT Delhi(NITD)">NIT Delhi(NITD)</option>
              <option value="NIT Goa(NITG)">NIT Goa(NITG)</option>
              <option value="NIT Manipur(NITMN)">NIT Manipur(NITMN)</option>
              <option value="NIT Meghalaya(NITM)">NIT Meghalaya(NITM)</option>
              <option value="NIT Mizoram(NITMZ)">NIT Mizoram(NITMZ)</option>
              <option value="NIT Nagaland(NITN)">NIT Nagaland(NITN)</option>
              <option value="NIT Puducherry(NITPY)">NIT Puducherry(NITPY)</option>
              <option value="NIT Sikkim(NITSKM)">NIT Sikkim(NITSKM)</option>
              <option value="NIT Uttarakhand(NITUK)">NIT Uttarakhand(NITUK)</option>
              <option value="NIT Andhra Pradesh NITANP">NIT Andhra Pradesh NITANP</option>
              <option value="Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)">Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)</option>
              <option value="Indian Institute of Information Technology, Allahabad(IIITA)">Indian Institute of Information Technology, Allahabad(IIITA)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)">Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)">Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)</option>
              <option value="Indian Institute of Information Technology, Sri City(IIITSC)">Indian Institute of Information Technology, Sri City(IIITSC)</option>
              <option value="Indian Institute of Information Technology, Guwahati(IIITG)">Indian Institute of Information Technology, Guwahati(IIITG)</option>
              <option value="Indian Institute of Information Technology, Vadodara(IIITV)">Indian Institute of Information Technology, Vadodara(IIITV)</option>
              <option value="Indian Institute of Information Technology, Kota(IIIT Kota)">Indian Institute of Information Technology, Kota(IIIT Kota)</option>
              <option value="Indian Institute of Information Technology, Tiruchirappalli(IIITT)">Indian Institute of Information Technology, Tiruchirappalli(IIITT)</option>
              <option value="Indian Institute of Information Technology, Una(IIITU)">Indian Institute of Information Technology, Una(IIITU)</option>
              <option value="Indian Institute of Information Technology, Sonepat(IIIT Sonepat)">Indian Institute of Information Technology, Sonepat(IIIT Sonepat)</option>
              <option value="Indian Institute of Information Technology, Kalyani(IIIT Kalyani)">Indian Institute of Information Technology, Kalyani(IIIT Kalyani)</option>
              <option value="Indian Institute of Information Technology, Lucknow(IIITL)">Indian Institute of Information Technology, Lucknow(IIITL)</option>
              <option value="Indian Institute of Information Technology, Dharwad(IIITDWD">Indian Institute of Information Technology, Dharwad(IIITDWD)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)">Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)</option>
              <option value="Indian Institute of Information Technology, Kottayam(IIIT Kottayam)">Indian Institute of Information Technology, Kottayam(IIIT Kottayam)</option>
              <option value="Indian Institute of Information Technology, Manipur(IIITM)">Indian Institute of Information Technology, Manipur(IIITM)</option>
              <option value="Indian Institute of Information Technology, Nagpur(IIITN)">Indian Institute of Information Technology, Nagpur(IIITN)</option>
              <option value="Indian Institute of Information Technology, Pune(IIITP)">Indian Institute of Information Technology, Pune(IIITP)</option>
              <option value="Indian Institute of Information Technology, Ranchi(IIIT Ranchi)">Indian Institute of Information Technology, Ranchi(IIIT Ranchi)</option>
              <option value="Indian Institute of Information Technology, Surat(IIIT Surat)">Indian Institute of Information Technology, Surat(IIIT Surat)</option>
              <option value="Indian Institute of Information Technology, Bhopal(IIIT Bhopal)">Indian Institute of Information Technology, Bhopal(IIIT Bhopal)</option>
              <option value="Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)">Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)</option>
              <option value="Indian Institute of Information Technology, Agartala(IIIT Agartala)">Indian Institute of Information Technology, Agartala(IIIT Agartala)</option>
              <option value="Indian Institute of Information Technology, Raichur">Indian Institute of Information Technology, Raichur</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
          </select>

          {college3 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherCollege3}
                  onChange={(e) => setOtherCollege3(e.target.value)}
                  style={{marginTop: '20px'
                  }}
                />
              )}
        </div>
        <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {college3Error || otherCollege3Error}
            </div>
      </div>

      <div className="custom-col">
        <div className="form-group">
          <label htmlFor="facecolor3">Face Color</label>
          <select
            className="form-control"
            id="facecolor3"
            value={facecolor3}
            onChange={(e) => setFacecolor3(e.target.value)}
          > 
            <option value="">Select</option>
            <option value="Fair">Fair</option>
            <option value="Medium Skin">Medium Skin</option>
            <option value="Wheatish">Wheatish</option>
            <option value="Brown">Brown</option>
            <option value="Dark">Dark</option>

          </select>
        </div>
      </div>
    </div>


    <div className="custom-row">
      <div className="custom-col">
        <div className="form-group">
          <label htmlFor="caste">Marital Status</label>
          <select
            className="form-control"
            id="caste"
            value={marital3}
            onChange={(e) => setMarital3(e.target.value)}
          > 
            <option value="">Select</option>
            <option value="Single">Single</option>
            <option value="Divorced">Divorced</option>
            <option value="Widow">Widow</option>
            <option value="Seperated">Seperated</option>
          </select>
        </div>
      </div>

      <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="WeightRange3">Weight(in kg)</label>
            <br/><br/><br/>
            <InputRange
            minValue={0}
            maxValue={100}
            value={WeightRange3}
            onChange={(value) => setWeightRange3(value)}
          />
                
            
          </div>
        </div>

      
    </div>

    <div className="custom-row">
          <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="ageRange3">Age</label>
              <br/><br/><br/>
            <InputRange
            minValue={18}
            maxValue={100}
            value={ageRange3}
            onChange={(value) => setageRange3(value)}
          />
            </div>
          </div>

          <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="heightRange3">Height</label>
            <br/><br/><br/>
            <InputRange
            minValue={1}
            maxValue={10 * 12} // Assuming a maximum of 10 feet
            value={{ min: heightRange3.min, max: heightRange3.max }}
            onChange={(value) => {
              setheightRange3({
                min: value.min,
                max: value.max,
              });
            }}
            formatLabel={(value) => formatValue(value)}
          />
          </div>
        </div>
        </div>

    

    

    
    </div>

    <button className='advance-search-btn' style={{marginBottom: '50px'}} onClick={handleSearch}>Search</button>

    <Aboutdownload />
    <Footer/>

   </>
  )
}

export default Collegesearch
