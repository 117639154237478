import React, {useState, useRef} from "react";
import {  useLocation, useNavigate } from "react-router-dom";


export const Accountdeletion = () => {
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(true);
  const [emailforgot, setEmailforgot] = useState("");
  const [emailforgoterror, setEmailforgoterror] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const userId = sessionStorage.getItem("userId");


  const [code, setCode] = useState("");
  const [otp, setOtp] = useState("")
  const [showResetPasswordField, setShowResetPasswordField] = useState(false);
  const[otpError, setOtpError] = useState("");







  const logoutfunc = () => {
    sessionStorage.clear();
    navigate("/login");
  };





  const handleForgotEmailSubmit = async () => {
    if (emailforgot.trim() !== "") {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailforgot,
            action: "emailOtp",
          }),
        });
  
        const data = await response.json();
        setCode(data.otpdata.code);
  
        if (data.otpdata.message === "Mail has been sent successfully!") {
          window.alert("Otp is sent to your email. Please enter OTP to reset the password");
  
          // Show the reset password field
          setShowResetPasswordField(true);
        } else {
          window.alert("Please enter a valid email address");
        }
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setEmailforgoterror(emailforgot.trim() === "" ? "Please enter your email" : "");
    }
  };


  const handleResetPassword = async () => {
    if (otp.trim() !== "" && otp === code
    ) {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
            otp_verify: 1,
            is_delete: 1,
            action: "delete account",
          }),
        });
        const data = await response.json();
  
        console.log(data.reset.message)
        if (data.reset.message) {
          window.alert("Account deleted successfully");
          setShowForgotPasswordPopup(false);
          logoutfunc();
        }
          
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {

      setOtpError(
        otp.trim() === "" ? "Please enter otp" : otp !== code
          ? "OTP not verified"
          : ""
      );
    }

  }



  return (
    <div style={{height:"100vh", background: "linear-gradient(180deg,#fcd232,#e02e1b)"}}>
      

      {showForgotPasswordPopup && (
      <div className="forgot-password-popup accountancy" ref={popupRef}>
        <div className="popup-content" style={{marginTop:"20px"}}>
          <h2 style={{marginBottom:"-20px"}}>Delete Account</h2>
          <br></br><br></br>


          {showResetPasswordField ? (
    <>
    <h2 style={{fontWeight: "700", fontSize: "30px", textTransform:"none",}}>Delete Account</h2>
      <p style={{marginTop:"-10px", }}>Enter OTP to delete account</p>
      <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
        type="number"
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {otpError}
      </div>
      
      
      <div className="otp-btn-forgot" onClick={handleResetPassword}>Delete</div>
    </>
  ):(


          <>
          <p style={{marginTop:"-10px"}}>Enter your email address</p>
          <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
            type="email"
            placeholder="Enter your email"
            value={emailforgot}
            onChange={(e) => setEmailforgot(e.target.value)}
          />
          <br></br>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px', marginLeft:"12px" }}>
            {emailforgoterror}
            </div>
          <div className="otp-btn-forgot" onClick={handleForgotEmailSubmit}>GET OTP</div>
          </>
  )}
        </div>
      </div>
    )}
    </div>
    




  );
};