import React from 'react'
import founder from "./founder.jpeg";
import linkdin from "./images/linkdin.png";

const AboutTeam = () => {
  return (
    <div id='leadership'>
        <h3
        style={{
          fontSize: "42px",
          fontWeight: "700",
          color: "#FF5555",
          textAlign: "center",
          fontFamily: "Lemon",
        }}
      >
        Leadership Team
      </h3>

      <div
        className="card"
        style={{
          maxWidth: "800px",
          margin: "40px auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          border: "4px solid #ffe6b3",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <img
            src={founder}
            className="card-img-top"
            alt="Card Image"
            style={{ height: "200px", width: "200px", margin: "0 auto" }}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <a
            href="https://www.linkedin.com/in/akshit-goel-29bb6058/"
            className="fa"
            style={{ border: "none" }}
          >
            <img
              className="card-img-top"
              style={{ height: "43px", width: "45px" }}
              src={linkdin}
              alt="LinkedIn"
            />
          </a>
        </div>
        <div className="card-body">
          <h1
            className="card-title"
            style={{
              textTransform: "none",
              textAlign: "center",
              color: "#56D5DD",
              fontSize: "42px",
              fontWeight: "700",
            }}
          >
            Akshit Goel
          </h1>
          <h1
            className="card-title"
            style={{
              textTransform: "none",
              textAlign: "center",
              color: "#FF8A00",
              fontSize: "45px",
              fontWeight: "700",
              marginTop: "-25px",
            }}
          >
            Founder & CEO
          </h1>
          <p
            className="card-text"
            style={{
              textAlign: "center",
              color: "#B0B0B0",
              fontSize: "20px",
              fontWeight: "500",
              marginTop: "-15px",
            }}
          >
            B.E(Hons.) EEE & M.Sc Physics, BITS Pilani
          </p>
          <p
            className="card-text"
            style={{
              textAlign: "center",
              color: "#B0B0B0",
              fontSize: "20px",
              fontWeight: "500",
              marginTop: "-15px",
            }}
          >
            PG in AI for BFSI, IIT Roorkee
          </p>
          <p
            className="card-text"
            style={{
              textAlign: "center",
              color: "#B0B0B0",
              fontSize: "20px",
              fontWeight: "500",
              marginTop: "-15px",
            }}
          >
            Worked for Wipro, Inadev, and Infosys as a Senior Developer
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutTeam
