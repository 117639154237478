import React, {useState} from 'react'
import './search.css'; // Import your custom CSS file
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './searchsliders.css'
import { useNavigate } from "react-router-dom";


const Communitysearch = () => {

  const navigate = useNavigate();
  const gender = sessionStorage.getItem("gender");
  const religion = sessionStorage.getItem("religion");


  const [caste2, setCaste2] = useState('');
  const [otherCaste2, setOtherCaste2] = useState('');
  const [mothertongue2, setMothertongue2] = useState('');
  const [otherMotherTongue2, setOtherMotherTongue2] = useState('');
  const [facecolor2, setFacecolor2] = useState('');
  const [marital2, setMarital2] = useState('');

  const [WeightRange2, setWeightRange2] = useState({ min: 0, max: 100 });
  const [ageRange2, setageRange2] = useState({ min: 18, max: 100 })


  const [caste2Error, setCaste2Error] = useState("");
  const [otherCaste2Error, setOtherCaste2Error] = useState("");
  const [mothertongue2Error, setMothertongue2Error] = useState("");
  const [otherMothertongue2Error, setOtherMothertongue2Error] = useState("");
  const [marital2Error, setMarital2Error] = useState("");
  

  
  const [heightRange2, setheightRange2] = useState({
    min: 5 * 12, // Default to 5 feet
    max: 6 * 12, // Default to 6 feet
  });
  const formatValue = (value) => {
    const feet = Math.floor(value / 12);
    const inches = value % 12;
    return `${feet}'${inches}''`;
  };

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };


  const handleSearch = async () => {
    if (
      caste2.trim() !== "" &&
      mothertongue2.trim() !== "" &&
      marital2.trim() !== "" &&
      ((caste2 === "Other (Specify)" && otherCaste2.trim() !== "") ||
      caste2 !== "Other (Specify)") &&
      ((mothertongue2 === "Other (Specify)" && otherMotherTongue2.trim() !== "") ||
      mothertongue2 !== "Other (Specify)") 
   
    ){
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "marital_status": marital2,
            "mother_tongue": mothertongue2 === "Other (Specify)" ? capitalizeFirstLetter(otherMotherTongue2) : mothertongue2,
            "caste": caste2 === "Other (Specify)" ? capitalizeFirstLetter(otherCaste2) : caste2,
            "gender": gender,
            "religion": religion,
            
            
            communitySearch: true,
          }),
        });
  
        const data = await response.json();
        // Redirect to CommunitySearchResults component with responseData as prop
      if (data !== null) {
        navigate('/communitysearchresultnavbar', { state: { responseData: data } });
      }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    }
    else {
      setCaste2Error(caste2.trim() === "" ? "Please select caste" : "");
      setOtherCaste2Error(
          caste2 === "Other (Specify)" && otherCaste2.trim() === ""
            ? "Please specify caste"
            : ""
      );


      


      setMarital2Error(marital2.trim() === "" ? "Please select marital status" : "");

      setMothertongue2Error(mothertongue2.trim() === "" ? "Please select mother tongue" : "");
      setOtherMothertongue2Error(
        mothertongue2 === "Other (Specify)" && otherMotherTongue2.trim() === ""
          ? "Please specify mother tongue"
          : ""
      );


    }
  }





  return (
    <>
      <div className='advance-search'>
      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="caste2">Caste / Community *</label>
            <select
              className="form-control"
              id="caste2"
              value={caste2}
              onChange={(e) => {
                  setCaste2(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCaste2("");
                  }
                }}
            > 
              <option value="">Select</option>
              <option value="Brahmin">Brahmin</option>
              <option value="Kshatriya">Kshatriya</option>
              <option value="Vaishya">Vaishya</option>
              <option value="Shudra">Shudra</option>
              <option value="Scheduled Castes (SC)">Scheduled Castes (SC)</option>
              <option value="Scheduled Tribes (ST)">Scheduled Tribes (ST)</option>
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {caste2 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherCaste2}
                  onChange={(e) => setOtherCaste2(e.target.value)}
                  style={{marginTop: '20px'
                  }}
                />
              )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {caste2Error || otherCaste2Error}
            </div>
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="mothertongue2">Mother Tongue</label>
            <select
              className="form-control"
              id="mothertongue2"
              value={mothertongue2}
              onChange={(e) => {
                  setMothertongue2(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherMotherTongue2("");
                  }
                }}
            > 
              <option value="">Select</option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
              <option value="Chinese">Chinese</option>
              <option value="Arabic">Arabic</option>
              <option value="Russian">Russian</option>
              <option value="Japanese">Japanese</option>
              <option value="Portuguese">Portuguese</option>
              <option value="Bengali">Bengali</option>
              <option value="Telugu">Telugu</option>
              <option value="Tamil">Tamil</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Marathi">Marathi</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Kannada">Kannada</option>
              <option value="Odia">Odia</option>
              <option value="Malayalam">Malayalam</option>
              {/* Add other language options */}
              <option value="Other (Specify)">Other (Specify)</option>
            </select>

            {mothertongue2 === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherMotherTongue2}
                  onChange={(e) => setOtherMotherTongue2(e.target.value)}
                  style={{marginTop: '20px'
                  }}
                />
              )}
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {mothertongue2Error || otherMothertongue2Error}
            </div>
        </div>
      </div>


      <div className="custom-row">
        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="facecolor2">Face Color</label>
            <select
              className="form-control"
              id="facecolor2"
              value={facecolor2}
              onChange={(e) => setFacecolor2(e.target.value)}
            > 
              <option value="">Select</option>
              <option value="Fair">Fair</option>
              <option value="Medium Skin">Medium Skin</option>
              <option value="Wheatish">Wheatish</option>
              <option value="Brown">Brown</option>
              <option value="Dark">Dark</option>
            </select>
          </div>
          
        </div>

        <div className="custom-col">
          <div className="form-group">
            <label htmlFor="marital2">Marital Status</label>
            <select
              className="form-control"
              id="marital2"
              value={marital2}
              onChange={(e) => setMarital2(e.target.value)}
            > 
              <option value="">Select</option>
              <option value="Single">Single</option>
              <option value="Divorced">Divorced</option>
              <option value="Widow">Widow</option>
              <option value="Seperated">Seperated</option>
            </select>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {marital2Error}
            </div>
        </div>
      </div>


      <div className="custom-row">
      <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="WeightRange2">Weight(in kg)</label>
            <br/><br/><br/>
            <InputRange
            minValue={0}
            maxValue={100}
            value={WeightRange2}
            onChange={(value) => setWeightRange2(value)}
          />
                
            
          </div>
        </div>

        <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="ageRange2">Age</label>
              <br/><br/><br/>
            <InputRange
            minValue={18}
            maxValue={100}
            value={ageRange2}
            onChange={(value) => setageRange2(value)}
          />
            </div>
          </div>
      </div>

      <div className="custom-row">
          

          <div className="custom-col search-rowwise">
          <div className="form-group">
            <label htmlFor="heightRange2">Height</label>
            <br/><br/><br/>
            <InputRange
            minValue={1}
            maxValue={10 * 12} // Assuming a maximum of 10 feet
            value={{ min: heightRange2.min, max: heightRange2.max }}
            onChange={(value) => {
              setheightRange2({
                min: value.min,
                max: value.max,
              });
            }}
            formatLabel={(value) => formatValue(value)}
          />
          </div>
        </div>
        </div>

      

      
      </div>

      <button className='advance-search-btn' style={{marginBottom: '50px'}} onClick={handleSearch}>Search</button>

      <Aboutdownload />
      <Footer/>

    </>
  )
}

export default Communitysearch;
