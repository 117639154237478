import React, { useState, useEffect } from "react";
import { Header } from "./components/header";
import { Services } from "./components/services";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Chart from "./components/Chart";
import Footer from "./components/Footer";
import Plans from "./components/Plans";
import Aboutdownload from "./components/Aboutdownload";
import { Threesteps } from "./components/Threesteps";
import NewsletterModal from "./components/NewsletterModal";
import Popup from "./components/NewsletterModal";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isPopupVisible, setPopupVisibility] = useState(true);

  /* const closePopup = () => {
    setPopupVisibility(false);
  };*/

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Services data={landingPageData.Services} />

      <Threesteps />

      <div
        className="chart-container"
        style={{ padding: "30px 30px", background: "rgb(244 229 174 / 72%)" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <div>
              <Chart />
            </div>
          </div>
        </div>
      </div>

      <Plans />
      <Aboutdownload />
      <Footer />
    </div>
  );
};

export default App;
