// src/hooks/useLatLon.js
import { useEffect, useState } from 'react';

const useLatLon = (birthLocation) => {
  const [userLatLon, setUserLatLon] = useState(null);

  useEffect(() => {
    fetchData(birthLocation);
  }, [birthLocation]);

  const fetchData = async (birthLocation) => {
    try {
      const apiUrl = `https://api.geoapify.com/v1/geocode/search?text=${birthLocation},India&apiKey=${process.env.REACT_APP_API_KEY}`;
      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await data.json();
      setUserLatLon(json);
      console.log(json)
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
    }
  };

  return userLatLon;
};

export default useLatLon;



