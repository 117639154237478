import React, {useState, useEffect,} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import vs from './images/login-logo.png'
import search from './images/search.png'
import account from './images/Account.png'
import bell from './images/bell.png'
import settings from './images/settings.png'
import fingerprint from './images/fingerprint.png'
import edit from './images/edit.png'
import kyc from './images/kyc.png'
import key from './images/key.png'
import calendar from './images/calendar.png'
import shield from './images/shield.png'
import logout from './images/logout.png'
import { Link } from "react-router-dom";
import useFetchpermission from "./utils/useFetchpermission";

export const Loginnavigation = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSecurityDropdownOpen, setIsSecurityDropdownOpen] = useState(false);
  const [isSettingDropdownOpen, setIsSettingDropdownOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const userId = sessionStorage.getItem("userId");




  const permission = useFetchpermission(userId)


  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const logoutfunc = () => {
    sessionStorage.clear();
    navigate("/login");
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount and unmount

  const navClass = scrolled ? 'navbar-scrolled' : 'nav-grey';

  const toggleDropdown = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };

  const toggleSecurityDropdown = () => {
    setIsSecurityDropdownOpen((prevIsSecurityDropdownOpen) => !prevIsSecurityDropdownOpen);
  };

  const toggleSettingDropdown = () => {
    setIsSettingDropdownOpen((prevIsSettingDropdownOpen) => !prevIsSettingDropdownOpen);
  };










  return (
    <>
      <nav id="menu" style={{background: '#FF8A00'}} className={`navbar navbar-default navbar-fixed-top ${navClass}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img style={{ width: "120px", height: "90px", marginTop: "-20px" }} src={vs} alt="logo" />
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right" style={{fontSize: '18px'}} >
          <li style={{backgroundColor: location.pathname === "/myprofile" ? 'white' : ''}}>
              <NavLink
              to="/myprofile"
              className="page-scroll"
              style={{ fontSize: '15px',  color: location.pathname === "/myprofile" ? 'rgb(255, 138, 0)' : '' }}
            >
              My Profile
            </NavLink>
            </li>
            <li style={{backgroundColor: location.pathname === "/search" ? 'white' : ''}}>
              <NavLink style={{fontSize: '15px', color: location.pathname === "/search" ? 'rgb(255, 138, 0)' : '' }} to="/search" className="page-scroll">
              <img style={{  marginTop: "-10px", marginRight:'5px', }} src={search} alt="logo" />
                Search
              </NavLink>
            </li>

            <li style={{backgroundColor: location.pathname === "/mymatchesfilter" ? 'white' : ''}}>
            <NavLink
              to="/mymatchesfilter"
              className="page-scroll"
              style={{ fontSize: '15px',  color: location.pathname === "/mymatchesfilter" ? 'rgb(255, 138, 0)' : '' }}
            >
              My Matches
            </NavLink>
            </li>

            <li style={{backgroundColor: location.pathname === "/myfavoritefilter" ? 'white' : ''}}>
            <NavLink
              to="/myfavoritefilter"
              className="page-scroll"
              style={{ fontSize: '15px',  color: location.pathname === "/myfavoritefilter" ? 'rgb(255, 138, 0)' : ''  }}
            >
              Favorites
            </NavLink>
            </li>

            <li style={{backgroundColor: location.pathname === "/interestfilter" ? 'white' : ''}}>
            <NavLink
              to="/interestfilter"
              className="page-scroll"
              style={{ fontSize: '15px',  color: location.pathname === "/interestfilter" ? 'rgb(255, 138, 0)' : '' }}
            >
              Interest
            </NavLink>
            </li>

            {/* <li style={{backgroundColor: location.pathname === "/kyc" ? 'white' : ''}}>
            <NavLink
              to="/kyc"
              className="page-scroll"
              style={{ fontSize: '15px',  color: location.pathname === "/kyc" ? 'rgb(255, 138, 0)' : '' }}
            >
              Kyc
            </NavLink>
            </li> */}
            
            <li className="dropdown hover-dropdown">
              <Link style={{fontSize: '15px'}} to="#" className={`dropdown-toggle page-scroll ${isDropdownOpen ? 'open' : ''}`}
                onClick={toggleDropdown}
                role="button"
                aria-haspopup="true"
                aria-expanded={isDropdownOpen} >
                My Account 
                <img style={{  marginTop: "-10px", marginLeft:'5px' }} src={account} alt="logo" />
              </Link>
              <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} style={{fontSize: '16px', fontFamily: 'Inter', borderRadius: '10px', color: '#717171', padding: '10px'}}>
                {/* <li><Link><img style={{  marginRight:'5px' }} src={settings} alt="logo" />Settings</Link></li> */}

                <li>
                <Link className={`dropdown-toggle page-scroll ${isSettingDropdownOpen ? 'open' : ''}`}
                    onClick={toggleSettingDropdown}
                    role="button"
                    aria-haspopup="true"
                    aria-expanded={isSettingDropdownOpen}>
                <img style={{ marginRight:'5px' }} src={settings} alt="logo" />
                Settings
              </Link>
                  <ul
                    className={`dropdown-menu ${isSettingDropdownOpen ? 'show' : ''}`}
                    style={{fontSize: '16px', fontFamily: 'Inter', borderRadius: '10px', color: '#717171', padding: '10px'}}
                  >
                    <li><NavLink
                        to="/settingchangepassword"
                        className="page-scroll"
                      >
                        Change Password
                      </NavLink></li>
                    {/* Add more security-related dropdown items if needed */}
                  </ul>
                </li>

                <li>
              <Link className={`dropdown-toggle page-scroll ${isSecurityDropdownOpen ? 'open' : ''}`}
                    onClick={toggleSecurityDropdown}
                    role="button"
                    aria-haspopup="true"
                    aria-expanded={isSecurityDropdownOpen}>
                <img style={{ marginRight:'5px' }} src={fingerprint} alt="logo" />
                Account Security
              </Link>
                  <ul
                    className={`dropdown-menu ${isSecurityDropdownOpen ? 'show' : ''}`}
                    style={{fontSize: '16px', fontFamily: 'Inter', borderRadius: '10px', color: '#717171', padding: '10px'}}
                  >
                    <li>
                      <NavLink
                        to="/accountdeletion"
                        className="page-scroll"
                      >
                        Delete Account
                      </NavLink>
                    </li>
                    {/* <li><Link onClick={handleForgotPasswordClick}>Delete Account</Link></li> */}
                    {/* Add more security-related dropdown items if needed */}
                  </ul>
            </li>
            
                <li><Link ><img style={{  marginRight:'5px' }} src={edit} alt="logo" />Edit Profile</Link></li>
                {/* <li><Link ><img style={{   marginRight:'5px' }} src={kyc} alt="logo" />KYC</Link></li> */}
                <li><Link><img style={{   marginRight:'5px' }} src={key} alt="logo" />Change Password</Link></li>
                <li><Link><img style={{   marginRight:'5px' }} src={calendar} alt="logo" />Plan Validity</Link></li>
                {permission?.permissions?.contacts  && (<li><Link>{permission?.permissions?.cont_bal}/{permission?.permissions?.contacts} Contacts</Link></li>)}
                <li><Link to="/subscriptionplans"><img style={{   marginRight:'5px' }} src={shield} alt="logo" />Upgrade Plan</Link></li>
                <li style={{marginTop: '20px', marginLeft: '20px'}}><Link onClick={logoutfunc} to="/login" ><img style={{ marginRight:'5px' }} src={logout} alt="logo" />Logout</Link></li>
              </ul>
            </li>


            
            
          </ul>
        </div>
      </div>
      </nav>


   
    </>
    




  );
};
