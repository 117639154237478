import React, { useState,useEffect  } from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "react-responsive-modal/styles.css";
import "./plan.css";
import { useNavigate } from "react-router-dom";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

function PlanCardApi() {
    const navigate = useNavigate();
    const userId = sessionStorage.getItem("userId");
    const subscribe_user_status = sessionStorage.getItem("subscribe_user_status");
    const family_details_status = sessionStorage.getItem("family_details_status");
    const partner_pref_status = sessionStorage.getItem("partner_pref_status");

    const usePlanfetch = (planId) => {
        const [planInfos, setPlanInfos] = useState([]);
        const [allDataFetched, setAllDataFetched] = useState(false);
      
        useEffect(() => {
          const fetchDataForAllPlans = async () => {
            try {
              const promises = planId.map(async (planId) => {
                const data = await fetch(`/prod/api_code/subscription_api/fetchPlanData.php?plan_id=${planId}`, {
                  method: 'GET', // Specify the HTTP method (GET in this case)
                  headers: {
                    Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                    
                    // Add any other headers you need here
                  },
                 
                });
                const json = await data.json();
                return json;
              });
      
              const responses = await Promise.all(promises);
              setPlanInfos(responses);
              setAllDataFetched(true);
            } catch (error) {
              console.error("Error fetching user profiles:", error.message);
              // Handle the error (show an error message, etc.)
            }
          };
      
          if (!allDataFetched) {
            fetchDataForAllPlans();
          }
        }, [planId, allDataFetched]);
      
        return planInfos;
      };
      
    
      const planId = [1,2,3,4,5,6,7,8,9,10]
      const planInfos = usePlanfetch(planId);
      const planDataArray = planInfos.map(user => user.Plan_Details);
    //   console.log(planDataArray)




    
    

    const handleSubscribe = async (planId) => {

        if (subscribe_user_status === 'null'){
            try{
                const response = await fetch(`${process.env.REACT_APP_MAINURL}/subscriptionplans`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  "user_id":userId,
                  "plan_id": planId,
                  "mode": "create",
          
                  
                }),
              });
          
              const data = await response.json();
              if(data !== null && family_details_status === "empty"){
                window.alert("You have subscribe the Plan the successfully")
                navigate("/familydetails");
              }
              if(data !== null && family_details_status === "created" && partner_pref_status === "empty"){
                window.alert("You have subscribe the Plan the successfully")
                navigate("/partnerdetails");
              }

              if(data !== null && family_details_status === "created" && partner_pref_status === "created"){
                window.alert("You have subscribe the Plan the successfully")
                navigate("/myprofile");
              }
              
              }catch (err) {
                console.error(err);
                window.alert("Error occurred while processing the request.");
              }
        }

        if (subscribe_user_status === 'free' || subscribe_user_status === 'created'){
          
            try{
                const response = await fetch(`${process.env.REACT_APP_MAINURL}/subscriptionplans`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  "user_id":userId,
                  "plan_id": planId,
                  "mode": "update",
          
                  
                }),
              });
          
              const data = await response.json();
              if(data !== null && family_details_status === "empty"){
                window.alert("You have updated the Plan successfully")
                navigate("/familydetails");
              }
              if(data !== null && family_details_status === "created" && partner_pref_status === "empty"){
                window.alert("You have updated the Plan successfully")
                navigate("/partnerdetails");
              }

              if(data !== null && family_details_status === "created" && partner_pref_status === "created"){
                window.alert("You have updated the Plan successfully")
                navigate("/myprofile");
              }
              
              }catch (err) {
                console.error(err);
                window.alert("Error occurred while processing the request.");
              }
        }

        
        
      };



      const handleSubscribepaln = async (planid, cost) => {
        console.log(planid, cost)
        sessionStorage.setItem("planid", planid)
        sessionStorage.setItem("cost", cost)
        if (subscribe_user_status === 'null'){
  
          navigate('/subscriptionplans/about')
          try{
              const response = await fetch("http://localhost:3000/subscriptionplans/ccavResponseHandler", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
              }),
            });
  
            const data = await response.json();
            //console.log(data)
            // if(data !== null && family_details_status === "empty"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/familydetails");
            // }
            // if(data !== null && family_details_status === "created" && partner_pref_status === "empty"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/partnerdetails");
            // }
  
            // if(data !== null && family_details_status === "created" && partner_pref_status === "created"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/myprofile");
            // }
  
            }catch (err) {
              console.error(err);
              window.alert("Error occurred while processing the request.");
            }
        }
  
        if (subscribe_user_status === 'free' || subscribe_user_status === 'created'){
  
          navigate('/subscriptionplans/about')
          try{
              const response = await fetch("http://localhost:3000/subscriptionplans/ccavResponseHandler", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
  
              }),
            });
  
            const data = await response.json();
           // console.log(data)
            // if(data !== null && family_details_status === "empty"){
            //   window.alert("You have updated the Plan successfully")
            //   navigate("/familydetails");
            // }
            // if(data !== null && family_details_status === "created" && partner_pref_status === "empty"){
            //   window.alert("You have updated the Plan successfully")
            //   navigate("/partnerdetails");
            // }
  
            // if(data !== null && family_details_status === "created" && partner_pref_status === "created"){
            //   window.alert("You have updated the Plan successfully")
            //   navigate("/myprofile");
            // }
  
            }catch (err) {
              console.error(err);
              window.alert("Error occurred while processing the request.");
            }
  
        }
      }


      const handlePriceplan = (cost,planid) => {
       // console.log(cost,planid);
        sessionStorage.setItem("planid", planid)
        sessionStorage.setItem("cost", cost)
        

  
        navigate('/subscriptionplans/pricingplan')
      }


    //  const handleOthercards = () => {
    //    window.alert("Please Do the bank transfer to below details: IFSC CODE: HDFC0009630, A/C NO. 50200088844600, A/C NAME: RADHA KRISHNA TECHNOLOGY SOLUTIONS, BANK NAME: HDFC Bank. After the successful bank transfer plz send the snippet to payment@vivahsahyog.com")
    //  }
      


  return (


    <>
      <div
        id="plans"
        style={{
          paddingTop: "30px",
          paddingBottom: "20px",
        }}
      >
        <div
          className="App"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Carousel breakPoints={breakPoints}>
            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(180deg, hsla(40, 9%, 94%, 1) 0%, hsla(0, 0%, 97%, 1) 100%)",
                  
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={() => handleSubscribe(planDataArray[0]?.PlanID)}
              
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "grey",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                {planDataArray[0]?.PlanName}
                </h2>
                
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                
                {planDataArray[0]?.PhotoAlbum !== "0" && 
                    (<p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[0]?.PhotoAlbum} Photo Album
                </p>)
                }
                {planDataArray[0]?.Chat !== "0" && 
                    (<p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>)
                }
                {(planDataArray[0]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[0]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[0]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[0]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[0]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 36 Months
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "grey",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>
            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(180deg, hsla(30, 60%, 48%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[2]?.PlanID, planDataArray[2]?.Cost) }
              onClick={() =>handlePriceplan(planDataArray[1]?.Cost,planDataArray[1]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[1]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[1]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[1]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[1]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[1]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[0]?.VideoCall) !== 0 && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[1]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[1]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[1]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[1]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 2 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  100 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#C27831",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background:
                  "linear-gradient(180deg, hsla(29, 54%, 47%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[3]?.PlanID, planDataArray[3]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[2]?.Cost,planDataArray[2]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[2]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[2]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[2]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[2]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[2]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[2]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[2]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[2]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[2]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[2]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 4 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  100 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#BB793B",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(0, 0%, 71%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[4]?.PlanID, planDataArray[4]?.Cost)}.
              onClick={() => handlePriceplan(planDataArray[3]?.Cost,planDataArray[3]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[3]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[3]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[3]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[3]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[3]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[3]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[3]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[3]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[3]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[3]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 7 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                 200 Contacts 
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#B4B4B4",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(51, 98%, 48%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[5]?.PlanID, planDataArray[5]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[4]?.Cost,planDataArray[4]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[4]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[4]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[4]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[4]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[4]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[4]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[4]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[4]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[4]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[4]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 7 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  250 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#EFC903",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(51, 100%, 66%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[6]?.PlanID, planDataArray[6]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[5]?.Cost,planDataArray[5]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[5]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[5]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[5]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[5]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[5]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[5]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[5]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[5]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[5]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[5]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 9 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  500 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#FFE557",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(346, 92%, 66%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[7]?.PlanID, planDataArray[7]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[6]?.Cost,planDataArray[6]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[6]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[6]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[6]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[6]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[6]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[6]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[6]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[6]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[6]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[6]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 6 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  400 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#F45A7E",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(40, 4%, 86%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[8]?.PlanID, planDataArray[8]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[7]?.Cost,planDataArray[7]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[7]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[7]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[7]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[7]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[7]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[7]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[7]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[7]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[7]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[7]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 12 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  1000 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#DAD9D7",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(230, 90%, 64%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[9]?.PlanID, planDataArray[9]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[8]?.Cost,planDataArray[8]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[8]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[8]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[8]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[8]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[8]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[8]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[8]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[8]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[8]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[8]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 60 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  1000 Contacts
                </p>
                }
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#4E69F2",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '600',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(266, 100%, 59%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              // onClick={() => handleSubscribepaln(planDataArray[10]?.PlanID, planDataArray[10]?.Cost)}
              onClick={() => handlePriceplan(planDataArray[9]?.Cost,planDataArray[9]?.PlanID)}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[9]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[9]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[9]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[9]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[9]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[9]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[9]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[9]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[9]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[9]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Net Duration 60 Months
                </p>
                }
                {
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  1000 Contacts
                </p>
                }
              </div>
              <button className="plan-new-btn"
                style={{
                  marginTop: "20px",
                  background: "#842DF5",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default PlanCardApi;
