
import React, {useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Myprofile.css";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import telegram from './images/telegram.png'
import telegram1 from './images/telegram1.png'
import usehandleaddFavourite from "./utils/usehandleaddfavorite";
import useFavoriteuserlist from "./utils/useFavoriteuserlist";
import usehandleremoveFavourite from "./utils/usehandleremovefavorite";
import useSentrequestlist from "./utils/useSentrequestlist";
import usehandleSentinterest from "./utils/useSentinterest";
import useHandleimg from "./utils/useHandleimg";
import useHandlealbum from "./utils/useHandlealbum";
import useFetchpermission from "./utils/useFetchpermission";
import verifiedimg from "./images/verified.png"




 const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


const UserCard = ({ verified,id, full_name, religion, age, height, income, working_status, gotra, sub_caste, city, occupation, marital_status,}) => {
  const navigate = useNavigate();

  const userId = sessionStorage.getItem("userId");
  const userinfo = useFavoriteuserlist(userId)
  const userfavlist = userinfo?.favorites_list 
  const userImg = useHandleimg(id);
  const userAlbum = useHandlealbum(id);

  const sentInterestlist = useSentrequestlist(userId);
  const sentinterestid = sentInterestlist?.friend_requests_sent;


  const permission = useFetchpermission(userId)
  const [isinterestfree, setIsinterestfree] = useState(false);
  
  
  const handleUpgradePlans = () => {
    navigate("/subscriptionplans")
  }

  const handleaddFavourite = () => {
    if (userfavlist?.includes(id)) {
      // Show alert if the user is already in favorites
      usehandleremoveFavourite(id)
      alert("User removed from favorites successfully")
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleaddFavourite(id);
      alert("User added to favorites successfully")
    }

    window.location.reload();
  };



  const handSentinterest = () => {
    
    if (permission?.permissions?.interest && permission?.permissions?.interest === "1"){
      // Check if the current user ID exists in the friend_requests_sent array
    const isInterestSent = sentInterestlist?.friend_requests_sent.some(item => item.user_to === id);
  
    if (isInterestSent) {
      // Show alert if the user is already in sent
      alert("Request already sent to the user");
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleSentinterest(id);
      alert("Request Sent successfully");
    }
  
    window.location.reload();
    }
    if (permission?.permissions?.interest && permission?.permissions?.interest === "0") {
      setIsinterestfree(true);
    }
  };
  

  const handleDivClick = () => {
    // Navigate to '/mymatchescompleteprofile' and pass the 'id' prop
    navigate(`/mymatchescompleteprofile/${id}`);
  };

  
  
  return (
    // <Link to={`/mymatchescompleteprofile/${id}`} style={{ textDecoration: 'none' }}>
      <div style={{ marginTop: "0px", marginBottom:"20px" }} className="first-section mymatches-slide" >
      <Link to={`/mymatchescompleteprofile/${id}`} style={{ textDecoration: 'none' }}>
      <div className="profile-img mymatchesslider col-md-4" onClick={handleDivClick}>
      
      {userImg?.photo_url ? (
            <Slider {...sliderSettings}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    border: "1px solid #777777",
                  }}
                  src={userImg?.photo_url}
                  alt="person"
                /> 
              </div>


              {/* Album Slides */}
              {userAlbum?.image_urls.map((imageUrl, index) => (
                <div
                  key={index + 1} // Use a unique key for each additional slide
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    border: "1px solid #777777",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={imageUrl}
                    alt={`person-${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
            
          ) : (
            <div
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative", // Add position relative to the container
            }}
          >
            <img className="img-mymatches"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
                border: "1px solid #777777"
              }}
              src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
              alt="person"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <h3>Profile photo not found</h3>
            </div>
          </div>
                  )}
      
      </div>
      </Link>
    

    <div
      className="my-matches col-md-10  desktop"
      style={{
        background: "#fce6943d",
        border: "1px solid #FF8A00",
        boxShadow: "0px 4px 4px 0px #FBC91340",
        borderRadius: "5px",
      }}
    >
      
      <div style={{display:"flex"}}>
      {/* {verified === "yes" && (<img style={{ marginTop:"25px", marginLeft:"20px"}} src={verifiedimg} alt="img" className="verifiedimg"/>)} */}
      <h1 style={{ textAlign: "left" }}>
      {verified === "yes" && (<img src={verifiedimg} alt="img" className="verifiedimg"/>)}
        {full_name}  
        <Link style={{marginLeft:"10px", fontSize:"30px", color:"black"}} to={`/mymatchescompleteprofile/${id}`}>&#128065;
        </Link>
      </h1>
      

      <span
        style={{
          height: "40px",
          width: "40px",
          float: "right",
          marginTop: "10px",
          cursor: "pointer",
          ...(sentInterestlist?.friend_requests_sent.some(item => item.user_to === id)
            ? { color: "#A0A0A0", fontSize: "30px" } // Styling for ❤️
            : { color: "#A0A0A0", fontSize: "60px", marginTop: "-10px" } // Styling for ♡
          ),
        }}
        onClick={handSentinterest}
      >
        {sentInterestlist?.friend_requests_sent.some(item => item.user_to === id) ? <img className="telegram" src={telegram} alt="telegram" /> : <img className="telegram1" src={telegram1} alt="telegram1" />}
      </span>
      {isinterestfree && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'white',
            boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          <span style={{float:"right", padding:"3px 3px", cursor:"pointer"}} onClick={() => setIsinterestfree(false)}>✕</span>
          <p style={{color:"black"}}>Free Plan! Please upgrade your plan to send interest</p>
          <button onClick={handleUpgradePlans} style={{color:"white", fontSize:"15px", fontWeight:"700px", padding:"5px 10px", borderRadius:"5px",background:"#d11a1a", border:"none"}}>UpGrade</button>
        </div>
      )}

      
      
      <span
            style={{
              height: "40px",
              width: "40px",
              float: "right",
              marginTop: "10px",
              cursor: "pointer",
              ...(userfavlist?.includes(id)
                ? { color: "#A0A0A0", fontSize: "30px" } // Styling for ❤️
                : { color: "#A0A0A0", fontSize: "60px", marginTop: "-10px" } // Styling for ♡
              ),
            }}
            onClick={handleaddFavourite}
          >
            {userfavlist?.includes(id) ? "❤️" : "♡"}
          </span>
      </div>
      
      
      <div className="col-md-5 matches-row">
        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Religion: </div>
          <div className=" match-value col-md-2">
            {religion}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Age: </div>
          <div className=" match-value col-md-2">
            {age}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Height: </div>
          <div className=" match-value col-md-2">
          {(() => {
                    const heightInInches = height;
                    const feet = Math.floor(heightInInches / 12);
                    const inches = heightInInches % 12;

                    return `${feet} feet ${inches} inches`;
                })()}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Salary: </div>
          <div className=" match-value col-md-2">
            {income}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">
            Working Status:{" "}
          </div>
          <div className=" match-value col-md-2">
            {working_status}{" "}
          </div>
        </div>
      </div>

      <div className="col-md-5 matches-row">
        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Gotra: </div>
          <div className=" match-value col-md-2">
            {gotra}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Sub-caste: </div>
          <div className=" match-value col-md-2">
            {sub_caste}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">City: </div>
          <div className=" match-value col-md-2">
            {city}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Profession: </div>
          <div className=" match-value col-md-2">
            {occupation}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">
            Marital Status:{" "}
          </div>
          <div className=" match-value col-md-2">
            {marital_status}{" "}
          </div>
        </div>
      </div>
    </div>
  </div>
  // </Link>
    
  );


  };

const UserSlide = ({ userinfo, }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {userinfo.map((users, index) => (
      <UserCard key={index} {...users} />
    ))}
  </div>
);

const UserList = ({ userinfo,}) => (
  <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
    {[...Array(Math.ceil(userinfo.length / 5))].map((_, index) => (
      <UserSlide key={index} userinfo={userinfo.slice(index * 5, (index + 1) * 5)}  />
    ))}
  </Slider>
);






const CommunitySearchResult = ({userinfo}) => {
  console.log(userinfo)
  



  

  return (
    <>
            {userinfo?.length ? (
              <div>
                <UserList userinfo={userinfo} />
              </div>
            ) : (
              <div>
                <h3 style={{textAlign:"center"}}>No user found</h3>
              </div>
            )}


      <Aboutdownload/>
      <Footer/>
    </>
  );
};

export default CommunitySearchResult;
