import React, { useState,useEffect,useRef} from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "react-responsive-modal/styles.css";
import "./plan.css";
import { Link } from "react-router-dom";
import Modal from "./fullScreenmodal";
import MultiStepForm from "./MultiStepForm";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

function Plans() {
  const [showPopup, setShowPopup] = useState(false);
  const [modalShown, toggleModal] = useState(false);
  const popupRef = useRef(null);

  const calculateNetDuration = (bonus, duration) => {
    // Checking for null values and converting strings to numbers
    const bonusNumber = bonus ? parseInt(bonus) : 0; // Parsing bonus to number, defaulting to 0 if bonus is null
    const durationNumber = duration ? parseInt(duration) : 0; // Parsing duration to number, defaulting to 0 if duration is null
    
    // Calculating net duration in months
    const netDurationMonths = bonusNumber + durationNumber;
    
    return netDurationMonths;
}


    const usePlanfetch = (planId) => {
        const [planInfos, setPlanInfos] = useState([]);
        const [allDataFetched, setAllDataFetched] = useState(false);

      
        useEffect(() => {
          const fetchDataForAllPlans = async () => {
            try {
              const promises = planId.map(async (planId) => {
                const data = await fetch(`/prod/api_code/subscription_api/fetchPlanData.php?plan_id=${planId}`,{
                  method: 'GET', // Specify the HTTP method (GET in this case)
                  headers: {
                    Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                    
                    // Add any other headers you need here
                  },
                 
                });
                const json = await data.json();
                console.log(json)
                return json;
              });
      
              const responses = await Promise.all(promises);
              setPlanInfos(responses);
              console.log(responses)
              setAllDataFetched(true);
            } catch (error) {
              console.error("Error fetching user profiles:", error.message);
              // Handle the error (show an error message, etc.)
            }
          };
      
          if (!allDataFetched) {
            fetchDataForAllPlans();
          }
        }, [planId, allDataFetched]);
      
        return planInfos;
      };
      
    
      const planId = [1,2,3,4,5,6,7,8,9,10]
      const planInfos = usePlanfetch(planId);
      console.log(planInfos);
      const planDataArray = planInfos.map(user => user.Plan_Details);
     console.log(planDataArray)
console.log(planDataArray[0]?.Duration)



    

    const handleSubscribe = () => {
      setShowPopup(true);
    };
  
    const handleCloseForgotPasswordPopup = () => {
      setShowPopup(false);
    };
      


  return (


    <>
      <div
        id="plans"
        style={{
          paddingTop: "30px",
          paddingBottom: "20px",
        }}
      >
        <div
          className="App"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Carousel breakPoints={breakPoints}>
            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(180deg, hsla(40, 9%, 94%, 1) 0%, hsla(0, 0%, 97%, 1) 100%)",
                  
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
              
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "grey",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                {planDataArray[0]?.PlanName}
                </h2>
                
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >

                 
                  {(planDataArray[0]?.Duration !== "0" && planDataArray[0]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[0]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[0]?.Bonus !== "0" && planDataArray[0]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[0]?.Bonus} Months Bonus
                    </p>
                  }
                 
                  {
                   
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration  36 Months
                    </p>
                  }
                
                {planDataArray[0]?.PhotoAlbum !== "0" && 
                    (<p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[0]?.PhotoAlbum} Photo Album
                </p>)
                }
                {planDataArray[0]?.Chat !== "0" && 
                    (<p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>)
                }
                {(planDataArray[0]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[0]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[0]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[0]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[0]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
                  

              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "grey",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>
            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(180deg, hsla(30, 60%, 48%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[1]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[1]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              > 
                {(planDataArray[1]?.Duration !== "0" && planDataArray[1]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[1]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[1]?.Bonus !== "0" && planDataArray[1]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[1]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 2 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      100 Contacts
                    </p>
                  }

                {(planDataArray[1]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[1]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[1]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[0]?.VideoCall) !== 0 && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[1]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[1]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[1]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[1]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#C27831",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background:
                  "linear-gradient(180deg, hsla(29, 54%, 47%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[2]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[2]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >  

                  {(planDataArray[2]?.Duration !== "0" && planDataArray[2]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[2]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[2]?.Bonus !== "0" && planDataArray[2]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[2]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 4 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      100 Contacts
                    </p>
                  }

                {(planDataArray[2]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[2]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[2]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[2]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[2]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[2]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[2]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[2]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#BB793B",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(0, 0%, 71%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[3]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[3]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
               
               {(planDataArray[3]?.Duration !== "0" && planDataArray[3]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[3]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[3]?.Bonus !== "0" && planDataArray[3]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[3]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 7 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      200 Contacts
                    </p>
                  }


                {(planDataArray[3]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[3]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[3]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[3]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[3]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[3]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[3]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[3]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#B4B4B4",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(51, 98%, 48%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[4]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[4]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >

                  {(planDataArray[4]?.Duration !== "0" && planDataArray[4]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[4]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[4]?.Bonus !== "0" && planDataArray[4]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[4]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 7 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      250 Contacts
                    </p>
                  }


                {(planDataArray[4]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[4]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[4]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[4]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[4]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[4]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[4]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[4]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#EFC903",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(51, 100%, 66%, 1) 0%, hsla(0, 0%, 100%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[5]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[5]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              > 
                {(planDataArray[5]?.Duration !== "0" && planDataArray[5]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[5]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[5]?.Bonus !== "0" && planDataArray[5]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[5]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 9 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      500 Contacts
                    </p>
                  }

                {(planDataArray[5]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[5]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[5]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[5]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[5]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[5]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[5]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[5]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
              
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#FFE557",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(346, 92%, 66%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[6]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[6]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[6]?.Duration !== "0" && planDataArray[6]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[6]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[6]?.Bonus !== "0" && planDataArray[6]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[6]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 6 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      400 Contacts
                    </p>
                  }

                {(planDataArray[6]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[6]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[6]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[6]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[6]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[6]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[6]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[6]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#F45A7E",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(40, 4%, 86%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[7]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[7]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {(planDataArray[7]?.Duration !== "0" && planDataArray[7]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[7]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[7]?.Bonus !== "0" && planDataArray[7]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[7]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 12 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "white", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      1000 Contacts
                    </p>
                  }

                {(planDataArray[7]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[7]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[7]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[7]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[7]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[7]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[7]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[7]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#DAD9D7",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(230, 90%, 64%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[8]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[8]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              > 
                {(planDataArray[8]?.Duration !== "0" && planDataArray[8]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[8]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[8]?.Bonus !== "0" && planDataArray[8]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[8]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 60 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      1000 Contacts
                    </p>
                  }

                {(planDataArray[8]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[8]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[8]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[8]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[8]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[8]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[8]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[8]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button
                style={{
                  marginTop: "20px",
                  background: "#4E69F2",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>

            <Item classname="item-btn"
              style={{
                height: '700',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "linear-gradient(180deg, hsla(266, 100%, 59%, 1) 0%, hsla(270, 12%, 13%, 1) 100%)",
                cursor: "pointer",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                fontSize: "45px",
                fontWeight: "500",
              }}
              onClick={handleSubscribe}
            >
              <div className="modal_new" style={{ marginTop: "25px" }}>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    textTransform: "none",
                    fontSize: "45px",
                    fontWeight: "575",
                  }}
                >
                  {planDataArray[9]?.PlanName}
                </h2>
                <h2
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Lemon",
                  }}
                >
                  <span
                    style={{
                      color: "#ffcc00",
                      fontSize: "20px",
                      paddingRight: "25px",
                      textDecoration: "line-through",
                    }}
                  ></span>
                  {planDataArray[9]?.Cost}/-
                  <span
                    style={{
                      color: "#804000",
                      fontSize: "20px",
                      paddingLeft: "25px",
                    }}
                  ></span>
                </h2>
              </div>
              <div
                className="modal_new"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  flex: 1,
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              > 
                {(planDataArray[9]?.Duration !== "0" && planDataArray[9]?.Duration !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[9]?.Duration} Months Duration
                    </p>
                  }

                  {(planDataArray[9]?.Bonus !== "0" && planDataArray[9]?.Bonus !== null) && 
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      {planDataArray[9]?.Bonus} Months Bonus
                    </p>
                  }

                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      Net Duration 60 Months
                    </p>
                  }
                  {
                    <p className="para-new-btn" style={{ color: "grey", fontSize: "20px" }}>
                      {" "}
                      <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                        &#x2714;{" "}
                      </span>
                      1000 Contacts
                    </p>
                  }

                {(planDataArray[9]?.PhotoAlbum) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  {planDataArray[9]?.PhotoAlbum} Photo Album
                </p>
                }
                {(planDataArray[9]?.Chat) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Chat Access
                </p>
                }
                {(planDataArray[9]?.VideoCall) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Video Call Access
                </p>
                }
                {(planDataArray[9]?.VerifiedProfilesFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Verified Profiles Filter
                </p>
                }
                {(planDataArray[9]?.ProfileImageFilter) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Profile Image Filter
                </p>
                }
                {(planDataArray[9]?.HoroscopeMatching) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Horoscope Matching
                </p>
                }
                {(planDataArray[9]?.SendInterest) !== "0" && 
                    <p className="para-new-btn"style={{ color: "grey", fontSize: "20px" }}>
                  {" "}
                  <span style={{ color: "#00ff00", paddingRight: "10px" }}>
                    &#x2714;{" "}
                  </span>
                  Send Interest
                </p>
                }
                
              </div>
              <button className="plan-new-btn"
                style={{
                  marginTop: "20px",
                  background: "#842DF5",
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "500",
                  borderRadius: "5px",
                  border: "none",
                  padding: "8px 40px",
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Buy Now
              </button>
            </Item>
          </Carousel>

          
        </div>
      </div>


      {showPopup && (
        <div className="forgot-password-popup" ref={popupRef}>
          <div className="popup-content">
          <span style={{fontSize: '30px', float: 'right', cursor:"pointer"}}  className="close-popup" onClick={handleCloseForgotPasswordPopup}>
            &times;
          </span>
          <br></br><br></br>
          
          <p style={{fontSize:"20px", fontWeight:"500", textAlign:"center"}}>Do you have a account?</p>
          <button style={{border:"none", background:"rgb(214, 14, 8)", padding:"5px 10px", color:"white", fontSize:"20px", borderRadius:"10px", fontWeight:"575", marginRight:"20px"}}
          onClick={() => {
                        toggleModal(!modalShown);
                      }}>Register</button>
          <button style={{border:"none", background:"rgb(214, 14, 8)", padding:"5px 10px", fontSize:"20px", borderRadius:"10px", fontWeight:"575"}}><Link style={{color:"white"}} to="/login">Login</Link></button>
          </div>
        </div>
      )}

      <Modal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      >
        {/* <h1>Look! I'm inside the modal!</h1> */}
        <MultiStepForm/>
      </Modal>
    </>
  );
}

export default Plans;
