import React, { useState, useEffect } from 'react';
import './search.css';
import Nearby from './Nearby'
import Premium from './Premium'
import Popular from './Popular'
import { Loginnavigation } from './Loginnavigation';
import grid from './images/grid.png'
import list from './images/list.png'
import filter from './images/filter.png'
import Myfavorite from './Myfavorite';
import MyfavoriteGrid from './MyfavoriteGrid';
import useFavoriteuserlist from './utils/useFavoriteuserlist';
import loading1 from "./images/loading.gif"



const Myfavoritefilter = () => {
  const [activeMatches, setActiveMatches] = useState('Myfavorite');
  const [displayGrid, setDisplayGrid] = useState(false);
  const userId = sessionStorage.getItem("userId");
  

  const userinfo = useFavoriteuserlist(userId)

  

  

  const handleMatchesButtonClick = (searchType) => {
    setActiveMatches(searchType);
  };

  const toggleGridView = () => {
    setDisplayGrid(!displayGrid);
  };

  return (
    <div>
      <Loginnavigation/>
      <div className='search-filter' >
        <button style={{padding: '1px 20px', background: 'none', color: '#222222', border: 'none',}}
          onClick={() => handleMatchesButtonClick('Myfavorite')}
          className={`mymatches-btn ${activeMatches === 'Myfavorite' ? 'active' : ''}`}
        >
          My Favourite ({userinfo?.favorites_list?.length})
        </button>
        <button style={{padding: '1px 30px'}}
          onClick={() => handleMatchesButtonClick('Nearby')}
          className={`mymatches-btn ${activeMatches === 'Nearby' ? 'active' : ''}`}
        >
          Nearby
        </button>
        <button style={{padding: '1px 30px', }}
          onClick={() => handleMatchesButtonClick('Premium')}
          className={`mymatches-btn ${activeMatches === 'Premium' ? 'active' : ''}`}
        >
          Premium
        </button>
        <button style={{padding: '1px 30px'}}
          onClick={() => handleMatchesButtonClick('Popular')}
          className={`mymatches-btn ${activeMatches === 'Popular' ? 'active' : ''}`}
        >
          Popular
        </button>

        <div>
        <img style={{height: '50px', cursor: 'pointer'}} src={grid} alt="grid" onClick={toggleGridView}/>
        <img style={{height: '50px'}} src={list} alt="list"/>
        <img style={{height: '50px'}} src={filter} alt="filter"/>
        </div>

      </div>

      
      {!userinfo?.favorites_list ? (
          <div style={{ width: "100vw", textAlign: "center" }}>
          <img style={{height:"300px", width:"400px", margin:"auto"}} src={loading1}  />
          </div>
        ) : (
      <>
      {activeMatches === 'Myfavorite' && !displayGrid && <Myfavorite favuserlist = {userinfo?.favorites_list}  />}
      {activeMatches === 'Myfavorite' && displayGrid && <MyfavoriteGrid favuserlist = {userinfo?.favorites_list} />}
      {activeMatches === 'Nearby' && <Nearby/>}
      {activeMatches === 'Premium' && <Premium />}
      {activeMatches === 'Popular' && <Popular />}
      </>
        )}
    </div>
  )
}

export default Myfavoritefilter;