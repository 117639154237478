import React from 'react'
import Aboutdownload from './Aboutdownload'
import PlanPagecards from './PlanPagecards'
import Plans from './Plans'
import { Planheader } from './PlanHeader'

const PlanPage = () => {
  return (
    <div>
      <Planheader/>
      <Plans />
      <PlanPagecards /> 
      <Aboutdownload/>
    </div>
  )
}

export default PlanPage
