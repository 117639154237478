import React from "react";
import google from './google-play.jpeg'
import heart from './heart-new.jpeg'

const PageSix = () => {
  return (
    <main
      className="black-80 center form-main-register pagesix"
      style={{ maxHeight: "30%", margin: "auto", }} 
    >
      <div
        className="mv3"
        style={{
          maxWidth: "80%",
          margin: "auto",
          marginTop: "60px",
          position: "relative",
          background: "#FF4242",
          borderRadius:"10px"
        }}
      >
        <img className="heart-img"
          src={heart}
          alt="heart"
          style={{ width: "80%", height: "auto" }}
        />

        <p
          style={{
            color: 'black',
            textAlign: "center",
            fontWeight: "400",
            marginBottom: '50px'
          }}
        >
          Please download our app from
        </p>
        
        <a href="https://play.google.com/store/apps/details?id=com.app.vivahsahyog">
        <img className="google-img"
          src={google}
          alt="playstore"
          style={{ width: "50%", height: "auto", marginTop: "-40px", margin:"auto" , marginBottom:"20px"}}
        />
        </a>
        
      </div>

      
    </main>
  );
};

export default PageSix;