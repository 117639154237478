import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import svg from "./images/Vector.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Loginnavigation } from "./Loginnavigation";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import "./Myprofile.css"; // Import a CSS file for styling
import partner from "./images/partner.png";
import lifestyle from "./images/lifestyle.png";
import Kundli from "./images/Kundli.png";
import contact from "./images/contact.png";
import family from "./images/family.png";
import useProfile from "./utils/useProfile";
import name from "./images/name.png"
import loading1 from "./images/loading.gif"
import basicinfo from "./images/basicinfo.png"
import educationimg from "./images/education.png"
import career from "./images/career.png"
import edit from "./images/editicon.png"
import Select from "react-select";
import { cityData } from "../data/city";
import useHandleimg from "./utils/useHandleimg";
import axios from 'axios';
import useHandlealbum from "./utils/useHandlealbum";
import useFetchpermission from "./utils/useFetchpermission";
import { districtData } from '../data/city';

import Biodata from "./Biodata";
import { useNavigate } from 'react-router-dom';


const Myprofile = () => {

  const [formData, setFormData] = useState({
    uploadedPhoto: ''
  })
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const isupload = true;
  const isalbumupload = true;

  const userInfo = useProfile(userId);
  const userImg = useHandleimg(userId);
  const userAlbum = useHandlealbum(userId);
  
  // console.log(userInfo)

  const permission = useFetchpermission(userId)
  console.log(permission)

  
  const [file, setFile] = useState(null);
  const [album, setAlbum] = useState(null);
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    navigate('/biodata');
    // Navigate to the NewComponent when the button is clicked
    
}

 
const [uploadedPhoto, setUploadedPhoto] = useState('');
  // State variables to store initial values
  const [religion, setReligion] = useState(userInfo?.profile_data?.religion);
  const [otherReligion, setOtherReligion] = useState("");
  const [caste, setCaste] = useState(userInfo?.profile_data?.caste);
  const [otherCaste, setOtherCaste] = useState("");
  const [age, setAge] = useState(userInfo?.profile_data?.age);
  const [weight, setWeight] = useState(userInfo?.profile_data?.weight);
  const [subcaste, setSubcaste] = useState(userInfo?.profile_data?.subcaste);
  const [otherSubcaste, setOtherSubcaste] = useState("");
  const [height, setHeight] = useState("");
  const [city, setCity] = useState(userInfo?.profile_data?.city);
  const [otherCity, setOtherCity] = useState("");
  const [income, setIncome] = useState(userInfo?.profile_data?.income);
  const [state, setState] = useState(userInfo?.profile_data?.state);
  const [otherState, setOtherState] = useState("");
  const [working_status, setWorking_status] = useState(userInfo?.profile_data?.working_status);
  const [gotra, setGotra] = useState(userInfo?.profile_data?.gotra);
  const [otherGotra, setOtherGotra] = useState("");
  const [birth_date, setBirth_date] = useState(userInfo?.profile_data?.birth_date);
  const [marital_status, setMarital_status] = useState(userInfo?.profile_data?.marital_status);
  const [about, setAbout] = useState(userInfo?.profile_data?.about_me);
  const [education, setEducation] = useState(userInfo?.profile_data?.highest_education)
  const [otherEducation, setOtherEducation] = useState("");
  const [education1, setEducation1] = useState(userInfo?.profile_data?.other_education)
  const [otherEducation1, setOtherEducation1] = useState("");
  const [college, setCollege] = useState(userInfo?.profile_data?.college_name)
  const [otherCollege, setOtherCollege] = useState("");
  const [occupation, setOccupation] = useState(userInfo?.profile_data?.occupation);
  const [otherOccupation, setOtherOccupation] = useState("");
  const [employedin, setEmployedin] = useState(userInfo?.profile_data?.employed_in);
  const [otherEmployedin, setOtherEmployedin] = useState("");
  const [company,setCompany] = useState(userInfo?.profile_data?.current_company);
  const [otherCompany, setOtherCompany] = useState("");
  const [companylocation, setCompanylocation] = useState(userInfo?.profile_data?.company_location);
  const [otherCompanylocation, setOtherCompanylocation] = useState("");
  const [familytype, setFamilytype] = useState(userInfo?.family_details?.family_type);
  const [brother, setBrother] = useState(userInfo?.family_details?.brother_count);
  const [fatheroccupation, setFatheroccupation] = useState(userInfo?.family_details?.father_occupation);
  const [otherfatheroccupation, setOtherFatheroccupation] = useState("");
  const [sister, setSister] = useState(userInfo?.family_details?.sister_count);
  const [motheroccupation, setMotheroccupation] = useState(userInfo?.family_details?.mother_occupation);
  const [othermotheroccupation, setOtherMotheroccupation] = useState("");
  const [relativecontact, setRelativecontact] = useState(userInfo?.family_details?.relative_contact);
  const [familyincome, setFamilyincome] = useState(userInfo?.family_details?.family_income);
  const [emailid, setEmailid] = useState(userInfo?.profile_data?.email)
  const [mobile, setMobile] = useState(userInfo?.profile_data?.phonenumber)
  const [address, setAddress] = useState(userInfo?.profile_data?.address)
  const [manglikstatus, setManglikstatus] = useState(userInfo?.profile_data?.manglik)
  const [birthlocation, setBirthlocation] = useState(userInfo?.profile_data?.birth_location)
  const [birthtime, setBirthtime] = useState(userInfo?.profile_data?.birth_time)
  const [foodstyle, setFoodstyle] = useState(userInfo?.profile_data?.food_style)
  const [drinkinghabit, setDrinkinghabit] = useState(userInfo?.profile_data?.drinking_habit)
  const [hobbies1, setHobbies1] = useState(userInfo?.profile_data?.hobbies)

  const [partnerminage, setPartnerminage] = useState(userInfo?.partner_pref?.min_age)
  const [partnermaxage, setPartnermaxage] = useState(userInfo?.partner_pref?.max_age)
  const [partnerminweight, setPartnerminweight] = useState(userInfo?.partner_pref?.min_weight)
  const [partnermaxweight, setPartnermaxweight] = useState(userInfo?.partner_pref?.max_weight)
  const [partnerminheight, setPartnerminheight] = useState(userInfo?.partner_pref?.min_height)
  const [partnermaxheight, setPartnermaxheight] = useState(userInfo?.partner_pref?.max_height)
  const [partnercountry, setPartnercountry] = useState(userInfo?.partner_pref?.country)
  const [partnercaste, setPartnercaste] = useState(userInfo?.partner_pref?.caste)
  const [partnersubcaste, setPartnersubcaste] = useState(userInfo?.partner_pref?.sub_caste)
  const [partnermothertongue, setPartnermothertongue] = useState(userInfo?.partner_pref?.mother_tongue)
  const [partnermarital, setPartnermarital] = useState(userInfo?.partner_pref?.marital_status)
  const [partnerfacecolor, setPartnerfacecolor] = useState(userInfo?.partner_pref?.face_color)
  const [partnerworking, setPartnerworking] = useState(userInfo?.partner_pref?.working_status)
  const [partnerfoodstyle, setPartnerfoodstyle] = useState(userInfo?.partner_pref?.food_style)
  const [partnerhobbies, setPartnerhobbies] = useState(userInfo?.partner_pref?.hobbies)
  const [partnerdrinking, setPartnerdrinking] = useState(userInfo?.partner_pref?.drinking_habbit)
  const [partnereducation, setPartnereducation] = useState(userInfo?.partner_pref?.education)
  const [partnerminincome, setPartnerminincome] = useState(userInfo?.partner_pref?.min_income)
  const [partnermaxincome, setPartnermaxincome] = useState(userInfo?.partner_pref?.max_income)
  const [partnerphoto, setPartnerphoto] = useState(userInfo?.partner_pref?.photo_visible_status)
  const [partnerverifiedprofile, setPartnerverifiedprofile] = useState(userInfo?.partner_pref?.verified_profile)
  const [partnerstate, setPartnerstate] = useState(userInfo?.partner_pref?.preferred_states)
  const [partnercity, setPartnercity] = useState(userInfo?.partner_pref?.preferred_cities)
  const [partnergotra, setPartnergotra] = useState(userInfo?.partner_pref?.gotra)
  const [otherpartnergotra, setOtherpartnergotra] = useState("");








  // Add more state variables for other fields

  // State variable to track whether the edit mode is active or not
  const [editMode, setEditMode] = useState(false);
  const [editModebasicinfo, setEditModebasicinfo] = useState(false);
  const [editModeabout, setEditModeabout] = useState(false);
  const [editModeeducation, setEditModeeducation] = useState(false);
  const [editModecareer, setEditModecareer] = useState(false);
  const [editModefamily, setEditModefamily] = useState(false);
  const [editModecontact, setEditModecontact] = useState(false);
  const [editModekundali, setEditModekundali] = useState(false);
  const [editModelifestyle, setEditModelifestyle] = useState(false);
  const [editModepartnerpref, setEditModepartnerpref] = useState(false);


  const filteredcities = cityData.states.find(s => s.state === state)?.city || [];


  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const hobbiesOptions = [
    { value: "Reading", label: "Reading" },
    { value: "Traveling", label: "Traveling" },
    { value: "Gardening", label: "Gardening" },
    { value: "Cooking", label: "Cooking" },
    { value: "Dancing", label: "Dancing" },
    { value: "Singing", label: "Singing" },
    { value: "Hiking", label: "Hiking" },
    { value: "Crafts", label: "Crafts" },
    { value: "Writing", label: "Writing" },
    { value: "Fitness", label: "fitness" },
    { value: "Cinema", label: "Cinema" },
    { value: "Gaming", label: "Gaming" },
    { value: "Yoga", label: "Yoga" },
    { value: "Painting", label: "Painting" },
    { value: "Photography", label: "Photography" }
    // ... (other options)
  ];
  
  const foodstyleOptions = [
    { value: "Veg", label: "Veg" },
    { value: "Pure Veg", label: "Pure Veg" },
    { value: "Non Veg", label: "Non Veg" },
    { value: "Egg", label: "Egg" },
    { value: "Jain", label: "Jain" },
    { value: "Vegan", label: "Vegan" },
  ]

  const countryOptions = [
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
    { value: "India", label: "India" },
    { value: "Germany", label: "Germany" },
    { value: "France", label: "France" },
    { value: "Spain", label: "Spain" },
    { value: "Itlay", label: "Itlay" },
    { value: "Japan", label: "Japan" },
    { value: "China", label: "China" },
    { value: "Brazil", label: "Brazil" },
    { value: "Russia", label: "Russia" },
    { value: "South Africa", label: "South Africa" }
  ]

  const casteOptions = [
    { value: "Brahmin", label: "Brahmin" },
    { value: "Kshatriya", label: "Kshatriya" },
    { value: "Vaishya", label: "Vaishya" },
    { value: "Shudra", label: "Shudra" },
    { value: "Scheduled Castes (SC)", label: "Scheduled Castes (SC)" },
    { value: "Scheduled Tribes (ST)", label: "Scheduled Tribes (ST)" }
  ]


  const subcasteOptions = [
    { value: "Not Applicable", label: "Not Applicable" },
    { value: "Agarwal", label: "Agarwal" },
    { value: "Dalit", label: "Dalit" },
    { value: "Adi Dravida", label: "Adi Dravida" },
    { value: "Chamar", label: "Chamar" },
    { value: "Tribal", label: "Tribal" },
    { value: "Gond", label: "Gond" },
    { value: "Munda", label: "Munda" },
    { value: "Yadav", label: "Yadav" },
    { value: "Kurmi", label: "Kurmi" },
    { value: "Maurya", label: "Maurya" },
    { value: "Nair", label: "Nair" },
    { value: "Nadar", label: "Nadar" },
    { value: "Vanniyar", label: "Vanniyar" },
    { value: "Brahmin", label: "Brahmin" },
    { value: "Iyer", label: "Iyer" },
    { value: "Iyengar", label: "Iyengar" },
    { value: "Smarta Brahmin", label: "Smarta Brahmin" },
    { value: "Rajput", label: "Rajput" },
    { value: "Thakur", label: "Thakur" },
    { value: "Kshatriya Raju", label: "Kshatriya Raju" }
  ]


  const mothertongueOptions = [
    { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Spanish", label: "Spanish" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Chinese", label: "Chinese" },
  { value: "Arabic", label: "Arabic" },
  { value: "Russian", label: "Russian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Bengali", label: "Bengali" },
  { value: "Telugu", label: "Telugu" },
  { value: "Tamil", label: "Tamil" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Marathi", label: "Marathi" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Kannada", label: "Kannada" },
  { value: "Odia", label: "Odia" },
  { value: "Malayalam", label: "Malayalam" }
  ]

  const maritalstatusOptions = [
    { value: "Single", label: "Single" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widow", label: "Widow" },
  { value: "Seperated", label: "Seperated" }
  ]

  const facecolorOptions = [
    { value: "Fair", label: "Fair" },
  { value: "Medium Skin", label: "Medium Skin" },
  { value: "Wheatish", label: "Wheatish" },
  { value: "Dark", label: "Dark" },
  { value: "Brown", label: "Brown" }
  ]

  const workingstatusOptions = [
    { value: "Working", label: "Working" },
  { value: "Non Working", label: "Non Working" },
  { value: "Student", label: "Student" }
  ]

  const educationOptions = [
    { value: "12th Pass", label: "12th Pass" },
    { value: "Diploma", label: "Diploma" },
    { value: "Certification", label: "Certification" },
    { value: "Associates Degree", label: "Associates Degree" },
    { value: "Bachelors Degree", label: "Bachelors Degree" },
    { value: "B.A (Bachelor of Arts)", label: "B.A (Bachelor of Arts)" },
    { value: "B.Sc (Bachelor of Science)", label: "B.Sc (Bachelor of Science)" },
    { value: "B.Com (Bachelor of Commerce)", label: "B.Com (Bachelor of Commerce)" },
    { value: "B.Tech (Bachelor of Technology)", label: "B.Tech (Bachelor of Technology)" },
    { value: "BBA (Bachelor of Business Administration)", label: "BBA (Bachelor of Business Administration)" },
    { value: "BCA (Bachelor of Computer Applications)", label: "BCA (Bachelor of Computer Applications)" },
    { value: "LLB (Bachelor of Laws)", label: "LLB (Bachelor of Laws)" },
    { value: "MBBS (Bachelor of Medicine, Bachelor of Surgery)", label: "MBBS (Bachelor of Medicine, Bachelor of Surgery)" },
    { value: "Pharm.D (Doctor of Pharmacy)", label: "Pharm.D (Doctor of Pharmacy)" },
    { value: "BDS (Bachelor of Dental Surgery)", label: "BDS (Bachelor of Dental Surgery)" },
    { value: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)", label: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)" },
    { value: "BVSc (Bachelor of Veterinary Science)", label: "BVSc (Bachelor of Veterinary Science)" },
    { value: "BE (Bachelor of Engineering)", label: "BE (Bachelor of Engineering)" },
    { value: "Masters Degree", label: "Masters Degree" },
    { value: "M.A (Master of Arts)", label: "M.A (Master of Arts)" },
    { value: "M.Sc (Master of Science)", label: "M.Sc (Master of Science)" },
    { value: "M.Com (Master of Commerce)", label: "M.Com (Master of Commerce)" },
    { value: "M.Tech (Master of Technology)", label: "M.Tech (Master of Technology)" },
    { value: "MBA (Master of Business Administration)", label: "MBA (Master of Business Administration)" },
    { value: "MCA (Master of Computer Applications)", label: "MCA (Master of Computer Applications)" },
    { value: "MFA (Master of Fine Arts)", label: "MFA (Master of Fine Arts)" },
    { value: "MSW (Master of Social Work)", label: "MSW (Master of Social Work)" },
    { value: "LL.M (Master of Laws)", label: "LL.M (Master of Laws)" },
    { value: "MD (Doctor of Medicine)", label: "MD (Doctor of Medicine)" },
    { value: "MPharm (Master of Pharmacy)", label: "MPharm (Master of Pharmacy)" },
    { value: "MDS (Master of Dental Surgery)", label: "MDS (Master of Dental Surgery)" },
    { value: "MS (Master of Surgery)", label: "MS (Master of Surgery)" },
    { value: "MBA (Master of Business Administration)", label: "MBA (Master of Business Administration)" },
    { value: "M.Phil (Master of Philosophy)", label: "M.Phil (Master of Philosophy)" },
    { value: "Ph.D. (Doctor of Philosophy)", label: "Ph.D. (Doctor of Philosophy)" },
    { value: "Other Masters Degree (Specify)", label: "Other Masters Degree (Specify)" },
    { value: "Doctorate (Post-Doctoral)", label: "Doctorate (Post-Doctoral)" },
  ];

  const stateOptions = districtData.states.map(state => ({
    value: state.state,
    label: state.state
  }));


  const CityOptions = [];
  cityData.states.forEach(state => {
    state.city.forEach(city => {
      CityOptions.push({ value: city, label: `${city}` });
    });
  });

  


  // for updating initial value
  useEffect(() => {
    if (userInfo?.profile_data?.religion !== null) {
      setReligion(userInfo?.profile_data?.religion);
    }
  }, [userInfo?.profile_data?.religion]);

  useEffect(() => {
    if (userInfo?.profile_data?.caste !== null) {
      setCaste(userInfo?.profile_data?.caste);
    }
  }, [userInfo?.profile_data?.caste]);

  useEffect(() => {
    if (userInfo?.profile_data?.age !== null) {
      setAge(userInfo?.profile_data?.age);
    }
  }, [userInfo?.profile_data?.age]);

  useEffect(() => {
    if (userInfo?.profile_data?.weight !== null) {
      setWeight(userInfo?.profile_data?.weight);
    }
  }, [userInfo?.profile_data?.weight]);

  useEffect(() => {
    if (userInfo?.profile_data?.sub_caste !== null) {
      setSubcaste(userInfo?.profile_data?.sub_caste);
    }
  }, [userInfo?.profile_data?.sub_caste]);

  useEffect(() => {
    // Assuming userInfo is available in your component state
    const userInfoHeightInInches = userInfo?.profile_data?.height;

    if (userInfoHeightInInches !== null && userInfoHeightInInches !== undefined) {
      const feet = Math.floor(userInfoHeightInInches / 12);
      const remainingInches = userInfoHeightInInches % 12;
      setHeight(`${feet} feet ${remainingInches} inches`);
    }
  }, [userInfo]); 

  useEffect(() => {
    if (userInfo?.profile_data?.city !== null) {
      setCity(userInfo?.profile_data?.city);
    }
  }, [userInfo?.profile_data?.city]);

  useEffect(() => {
    if (userInfo?.profile_data?.income !== null) {
      setIncome(userInfo?.profile_data?.income);
    }
  }, [userInfo?.profile_data?.income]);

  useEffect(() => {
    if (userInfo?.profile_data?.state !== null) {
      setState(userInfo?.profile_data?.state);
    }
  }, [userInfo?.profile_data?.state]);

  useEffect(() => {
    if (userInfo?.profile_data?.working_status !== null) {
      setWorking_status(userInfo?.profile_data?.working_status);
    }
  }, [userInfo?.profile_data?.working_status]);

  useEffect(() => {
    if (userInfo?.profile_data?.gotra !== null) {
      setGotra(userInfo?.profile_data?.gotra);
    }
  }, [userInfo?.profile_data?.gotra]);

  useEffect(() => {
    if (userInfo?.profile_data?.birth_date !== null) {
      setBirth_date(userInfo?.profile_data?.birth_date);
    }
  }, [userInfo?.profile_data?.birth_date]);

  useEffect(() => {
    if (userInfo?.profile_data?.marital_status !== null) {
      setMarital_status(userInfo?.profile_data?.marital_status);
    }
  }, [userInfo?.profile_data?.marital_status]);

  useEffect(() => {
    if (userInfo?.profile_data?.about_me !== null) {
      setAbout(userInfo?.profile_data?.about_me);
    }
  }, [userInfo?.profile_data?.about_me]);

  useEffect(() => {
    if (userInfo?.profile_data?.highest_education !== null) {
      setEducation(userInfo?.profile_data?.highest_education);
    }
  }, [userInfo?.profile_data?.highest_education]);
  useEffect(() => {
    if (userInfo?.profile_data?.other_education !== null) {
      setEducation1(userInfo?.profile_data?.other_education);
    }
  }, [userInfo?.profile_data?.other_education]);

  useEffect(() => {
    if (userInfo?.profile_data?.college_name !== null) {
      setCollege(userInfo?.profile_data?.college_name);
    }
  }, [userInfo?.profile_data?.college_name]);
  
  useEffect(() => {
    if (userInfo?.profile_data?.occupation !== null) {
      setOccupation(userInfo?.profile_data?.occupation);
    }
  }, [userInfo?.profile_data?.occupation]);

  useEffect(() => {
    if (userInfo?.profile_data?.employed_in !== null) {
      setEmployedin(userInfo?.profile_data?.employed_in);
    }
  }, [userInfo?.profile_data?.employed_in]);

  useEffect(() => {
    if (userInfo?.profile_data?.current_company !== null) {
      setCompany(userInfo?.profile_data?.current_company);
    }
  }, [userInfo?.profile_data?.current_company]);

  useEffect(() => {
    if (userInfo?.profile_data?.company_location !== null) {
      setCompanylocation(userInfo?.profile_data?.company_location);
    }
  }, [userInfo?.profile_data?.company_location]);

  useEffect(() => {
    if (userInfo?.family_details?.family_type !== null) {
      setFamilytype(userInfo?.family_details?.family_type);
    }
  }, [userInfo?.family_details?.family_type]);

  useEffect(() => {
    if (userInfo?.family_details?.brother_count !== null) {
      setBrother(userInfo?.family_details?.brother_count);
    }
  }, [userInfo?.family_details?.brother_count]);

  useEffect(() => {
    if (userInfo?.family_details?.father_occupation !== null) {
      setFatheroccupation(userInfo?.family_details?.father_occupation);
    }
  }, [userInfo?.family_details?.father_occupation]);

  useEffect(() => {
    if (userInfo?.family_details?.sister_count !== null) {
      setSister(userInfo?.family_details?.sister_count);
    }
  }, [userInfo?.family_details?.sister_count]);

  useEffect(() => {
    if (userInfo?.family_details?.mother_occupation !== null) {
      setMotheroccupation(userInfo?.family_details?.mother_occupation);
    }
  }, [userInfo?.family_details?.mother_occupation]);

  useEffect(() => {
    if (userInfo?.family_details?.relative_contact !== null) {
      setRelativecontact(userInfo?.family_details?.relative_contact);
    }
  }, [userInfo?.family_details?.relative_contact]);
  

  useEffect(() => {
    if (userInfo?.family_details?.family_income!== null) {
      setFamilyincome(userInfo?.family_details?.family_income);
    }
  }, [userInfo?.family_details?.family_income]);

  useEffect(() => {
    if (userInfo?.profile_data?.email !== null) {
      setEmailid(userInfo?.profile_data?.email);
    }
  }, [userInfo?.profile_data?.email]);

  useEffect(() => {
    if (userInfo?.profile_data?.phonenumber !== null) {
      setMobile(userInfo?.profile_data?.phonenumber);
    }
  }, [userInfo?.profile_data?.phonenumber]);

  useEffect(() => {
    if (userInfo?.profile_data?.address !== null) {
      setAddress(userInfo?.profile_data?.address);
    }
  }, [userInfo?.profile_data?.address]);

  useEffect(() => {
    if (userInfo?.profile_data?.manglik !== null) {
      setManglikstatus(userInfo?.profile_data?.manglik);
    }
  }, [userInfo?.profile_data?.manglik]);

  useEffect(() => {
    if (userInfo?.profile_data?.birth_location !== null) {
      setBirthlocation(userInfo?.profile_data?.birth_location);
    }
  }, [userInfo?.profile_data?.birth_location]);

  useEffect(() => {
    if (userInfo?.profile_data?.birth_time !== null) {
      setBirthtime(userInfo?.profile_data?.birth_time);
    }
  }, [userInfo?.profile_data?.birth_time]);

  useEffect(() => {
    if (userInfo?.profile_data?.food_style !== null) {
      setFoodstyle(userInfo?.profile_data?.food_style);
    }
  }, [userInfo?.profile_data?.food_style]);

  useEffect(() => {
    if (userInfo?.profile_data?.drinking_habit !== null) {
      setDrinkinghabit(userInfo?.profile_data?.drinking_habit);
    }
  }, [userInfo?.profile_data?.drinking_habit]);

  useEffect(() => {
    if (userInfo?.profile_data?.hobbies !== null) {
      setHobbies1(userInfo?.profile_data?.hobbies);
    }
  }, [userInfo?.profile_data?.hobbies]);

  useEffect(() => {
    if (userInfo?.partner_pref?.min_age !== null) {
      setPartnerminage(userInfo?.partner_pref?.min_age);
    }
  }, [userInfo?.partner_pref?.min_age]);

  useEffect(() => {
    if (userInfo?.partner_pref?.max_age !== null) {
      setPartnermaxage(userInfo?.partner_pref?.max_age);
    }
  }, [userInfo?.partner_pref?.max_age]);

  useEffect(() => {
    if (userInfo?.partner_pref?.min_weight !== null) {
      setPartnerminweight(userInfo?.partner_pref?.min_weight);
    }
  }, [userInfo?.partner_pref?.min_weight]);

  useEffect(() => {
    if (userInfo?.partner_pref?.max_weight !== null) {
      setPartnermaxweight(userInfo?.partner_pref?.max_weight);
    }
  }, [userInfo?.partner_pref?.max_weight]);

  useEffect(() => {
    if (userInfo?.partner_pref?.min_height !== null) {
      setPartnerminheight(userInfo?.partner_pref?.min_height);
    }
  }, [userInfo?.partner_pref?.min_height]);

  useEffect(() => {
    if (userInfo?.partner_pref?.max_height !== null) {
      setPartnermaxheight(userInfo?.partner_pref?.max_height);
    }
  }, [userInfo?.partner_pref?.max_height]);

  useEffect(() => {
    if (userInfo?.partner_pref?.country !== null) {
      setPartnercountry(userInfo?.partner_pref?.country);
    }
  }, [userInfo?.partner_pref?.country]);

  useEffect(() => {
    if (userInfo?.partner_pref?.caste !== null) {
      setPartnercaste(userInfo?.partner_pref?.caste);
    }
  }, [userInfo?.partner_pref?.caste]);

  useEffect(() => {
    if (userInfo?.partner_pref?.sub_caste !== null) {
      setPartnersubcaste(userInfo?.partner_pref?.sub_caste);
    }
  }, [userInfo?.partner_pref?.sub_caste]);

  useEffect(() => {
    if (userInfo?.partner_pref?.mother_tongue !== null) {
      setPartnermothertongue(userInfo?.partner_pref?.mother_tongue);
    }
  }, [userInfo?.partner_pref?.mother_tongue]);

  useEffect(() => {
    if (userInfo?.partner_pref?.marital_status !== null) {
      setPartnermarital(userInfo?.partner_pref?.marital_status);
    }
  }, [userInfo?.partner_pref?.marital_status]);

  useEffect(() => {
    if (userInfo?.partner_pref?.face_color !== null) {
      setPartnerfacecolor(userInfo?.partner_pref?.face_color);
    }
  }, [userInfo?.partner_pref?.face_color]);

  useEffect(() => {
    if (userInfo?.partner_pref?.working_status !== null) {
      setPartnerworking(userInfo?.partner_pref?.working_status);
    }
  }, [userInfo?.partner_pref?.working_status]);

  useEffect(() => {
    if (userInfo?.partner_pref?.food_style !== null) {
      setPartnerfoodstyle(userInfo?.partner_pref?.food_style);
    }
  }, [userInfo?.partner_pref?.food_style]);

  useEffect(() => {
    if (userInfo?.partner_pref?.hobbies !== null) {
      setPartnerhobbies(userInfo?.partner_pref?.hobbies);
    }
  }, [userInfo?.partner_pref?.hobbies]);

  useEffect(() => {
    if (userInfo?.partner_pref?.drinking_habbit !== null) {
      setPartnerdrinking(userInfo?.partner_pref?.drinking_habbit);
    }
  }, [userInfo?.partner_pref?.drinking_habbit]);

  useEffect(() => {
    if (userInfo?.partner_pref?.education !== null) {
      setPartnereducation(userInfo?.partner_pref?.education);
    }
  }, [userInfo?.partner_pref?.education]);

  useEffect(() => {
    if (userInfo?.partner_pref?.min_income !== null) {
      setPartnerminincome(userInfo?.partner_pref?.min_income);
    }
  }, [userInfo?.partner_pref?.min_income]);

  useEffect(() => {
    if (userInfo?.partner_pref?.max_income !== null) {
      setPartnermaxincome(userInfo?.partner_pref?.max_income);
    }
  }, [userInfo?.partner_pref?.max_income]);

  useEffect(() => {
    if (userInfo?.partner_pref?.photo_visible_status !== null) {
      setPartnerphoto(userInfo?.partner_pref?.photo_visible_status);
    }
  }, [userInfo?.partner_pref?.photo_visible_status]);


  useEffect(() => {
    if (userInfo?.partner_pref?.verified_profile !== null) {
      setPartnerverifiedprofile(userInfo?.partner_pref?.verified_profile);
    }
  }, [userInfo?.partner_pref?.verified_profile]);

  useEffect(() => {
    if (userInfo?.partner_pref?.preferred_cities !== null) {
      setPartnercity(userInfo?.partner_pref?.preferred_cities);
    }
  }, [userInfo?.partner_pref?.preferred_cities]);

  useEffect(() => {
    if (userInfo?.partner_pref?.preferred_states !== null) {
      setPartnerstate(userInfo?.partner_pref?.preferred_states);
    }
  }, [userInfo?.partner_pref?.preferred_states]);
  useEffect(() => {
    if (userInfo?.partner_pref?.gotra !== null) {
      setPartnergotra(userInfo?.partner_pref?.gotra);
    }
  }, [userInfo?.partner_pref?.gotra]);



  




  

  // Function to handle the edit button click
  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleEditClickbasicinfo = () => {
    setEditModebasicinfo(true);
  };

  const handleEditClickabout = () => {
    setEditModeabout(true);
  };

  const handleEditClickeducation = () => {
    setEditModeeducation(true);
  };

  const handleEditClickcareer = () => {
    setEditModecareer(true);
  };

  const handleEditClickfamily = () => {
    setEditModefamily(true);
  };

  const handleEditClickcontact = () => {
    setEditModecontact(true);
  };

  const handleEditClickkundali = () => {
    setEditModekundali(true);
  };

  const handleEditClicklifestyle = () => { 
    setEditModelifestyle(true);
  };

  const handleEditClickpartner = () => {
    setEditModepartnerpref(true);
  };


  const handleHobbiesChange = (selectedOptions) => {
    // Extract and store only the values in the selectedHobbies state
    const selectedValues = selectedOptions.map((option) => option.value);
  setHobbies1(selectedValues.join(', '));

  };

  const handleFoodstyleChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnerfoodstyle(selectedValues.join(', '));

  };

  const handlepartnerHobbiesChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnerhobbies(selectedValues.join(', '));

  };


  const handlepartnerCountryChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnercountry(selectedValues.join(', '));

  };

  const handlepartnerCasteChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnercaste(selectedValues.join(', '));

  };

  const handlepartnerSubcasteChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnersubcaste(selectedValues.join(', '));

  };

  const handlepartnermothertongueChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnermothertongue(selectedValues.join(', '));

  };

  const handlepartnermaritalstatusChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnermarital(selectedValues.join(', '));

  };

  const handlepartnerfacecolorChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnerfacecolor(selectedValues.join(', '));

  };

  const handlepartnerworkingstatusChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnerworking(selectedValues.join(', '));

  };

  const handlepartnereducationChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnereducation(selectedValues.join(', '));

  };

  const handlepartnerstateChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnerstate(selectedValues.join(', '));

  };


  const handlepartnercityChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setPartnercity(selectedValues.join(', '));

  };





  


  // Function to handle saving changes
  const handleSaveChanges = async () => {

    // console.log(religion,caste,age,subcaste,height,city,income,state,working_status,gotra)
    // console.log(religion,otherCaste,age,otherState,height,otherCity,income,otherState,working_status,otherGotra,)
    
      try{
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": userId,
          "height": height,
          "income": income,
          "working_status": working_status,
          "age": age,
          "weight": weight,
          "city": city === "Other (Specify)" ? capitalizeFirstLetter(otherCity) : city,
        "caste": caste === "Other (Specify)" ? capitalizeFirstLetter(otherCaste) : caste,
         "sub_caste": subcaste === "Other (Specify)" ? capitalizeFirstLetter(otherSubcaste) : subcaste,
        "gotra": gotra === "Other (Specify)" ? capitalizeFirstLetter(otherGotra) : gotra,
        "state": state === "Other (Specify)" ? capitalizeFirstLetter(otherState) : state,
        "religion": religion === "Other (Specify)" ? capitalizeFirstLetter(otherReligion) : religion,

          intro_section: true,
        }),
      });

      const data = await response.json();
      if(data !== null){
        window.alert("Profile Updated successfully")
      }
      setEditMode(false);
      }catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
   
  };

  

  const handleSaveChangesbasicinfo = () => {
    setEditModebasicinfo(false);
  };

  const handleSaveChangesabout = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": userId,
        "about_me": capitalizeFirstLetter(about),
        
        about_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModeabout(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

    
  };

  const handleSaveChangeseducation = async () => {
   

    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": userId,
        "education": education === "Other (Specify)" ? capitalizeFirstLetter(otherEducation) : education,
        "other_education": education1 === "Other (Specify)" ? capitalizeFirstLetter(otherEducation1) : education1,
        "college_name": college === "Other (Specify)" ? capitalizeFirstLetter(otherCollege) : college,
        
        education_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModeeducation(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

    
  };

  const handleSaveChangescareer =async () => {

    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id": userId,
        "employed_in": employedin === "Other (Specify)" ? capitalizeFirstLetter(otherEmployedin) : employedin,
        "occupation": occupation === "Other (Specify)" ? capitalizeFirstLetter(otherOccupation) : occupation,
        "current_company": company === "Other (Specify)" ? capitalizeFirstLetter(otherCompany) : company,
        "company_location": companylocation === "Other (Specify)" ? capitalizeFirstLetter(otherCompanylocation) : companylocation,
        
        career_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModecareer(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

  };


  const handleSaveChangesfamily = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id":userId,
        "family_type": familytype,
        "family_income": familyincome,
        "father_occupation": capitalizeFirstLetter(fatheroccupation),
        "mother_occupation": capitalizeFirstLetter(motheroccupation),
        "brother_count": brother,
        "sister_count": sister,
        "relative_contact": relativecontact,
        
        family_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModefamily(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

    
  };

  const handleSaveChangescontact = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id":userId,
        "email": emailid,
        "phonenumber": mobile,
        "address": capitalizeFirstLetter(address),
        
        contact_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModecontact(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

    
  };

  const handleSaveChangeskundali = async () => {
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id":userId,
        "manglik_status": manglikstatus,
        "birth_place": capitalizeFirstLetter(birthlocation),
        "birth_time": birthtime,
        
        astro_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModekundali(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

    
  };

  const handleSaveChangeslifestyle = async () => {
    console.log(hobbies1)
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id":userId,
        "food_style": foodstyle,
        "drinking_habit": drinkinghabit,
        "hobbies": hobbies1,
        
        lifestyle_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModelifestyle(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }
    
  };

  const handleSaveChangepartner = async () => {
    console.log(partnercity,partnerstate,partnerdrinking)
    try{
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "user_id":userId,
        "min_age": partnerminage,
        "max_age": partnermaxage,
        "min_weight": partnerminweight,
        "max_weight": partnermaxweight,
        "min_height": partnerminheight,
        "max_height": partnermaxheight,
        "country": partnercountry,
        "caste": partnercaste,
        "sub_caste": partnersubcaste,
        "mother_tongue": partnermothertongue,
        "marital_status": partnermarital,
        "face_color": partnerfacecolor,
        "working_status": partnerworking,
        "food_style": partnerfoodstyle,
        "hobbies": partnerhobbies,
        "drinking_habbit": partnerdrinking,
        "education": partnereducation,
        "min_income": partnerminincome,
        "max_income": partnermaxincome,
        "photo_visible_status": partnerphoto,
        "verified_profile": partnerverifiedprofile,
        "preferred_cities":partnercity,
        "preferred_states":partnerstate,
        "gotra": partnergotra === "Other (Specify)" ? capitalizeFirstLetter(otherpartnergotra) : partnergotra,
        
        partner_section: true,

        
      }),
    });

    const data = await response.json();
    if(data !== null){
      window.alert("Profile Updated successfully")
    }
    setEditModepartnerpref(false);
    }catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }
    
  }

  



  


  // Settings for the image slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let inputClassName = "partner-value-div-basic";

  if (window.innerWidth > 865) {
    inputClassName = "partner-title-div-basic";
  }

  if (window.innerWidth <= 400) {
    inputClassName = "partner-value-div-basic";
  }



  

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setFile(selectedImage);
  };

  const handlealbumChange = (event) => {
    const selectedImage = event.target.files[0];
    setAlbum(selectedImage);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setUploadedPhoto(base64String);
      const newFormData = { ...formData, uploadedPhoto: base64String };
      setFormData(newFormData);
      localStorage.setItem('biodataForm', JSON.stringify(newFormData));
      setFile(file); // Set the file for handleUpload
      handleUpload(file); // Call handleUpload after setting the file
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };


  const handleUpload = async () => {
    if (file) {
      console.log(file.name)
      try {
        const formData = new FormData();
        formData.append('userfile', file); // Use 'userfile' as the key
        formData.append('fileName', file.name);
        formData.append('userId', userId);
        formData.append('isupload', isupload);
  
       
        const response = axios.post(`${process.env.REACT_APP_MAINURL}/myprofile`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function () {
          window.alert("image uploaded successfully");
          window.location.reload();
        })
        .catch(function () {
          console.log('FAILURE!!');
        });
  

      } catch (error) {
        console.error('Error uploading image:', error);
        // Handle errors here
      }
    } else {
      console.warn('No image selected');
      // Handle case where no image is selected
    }
  };


  const handlealbumUpload = async () => {
    if (album) {
      console.log(album.name)
      try {
        const formData = new FormData();
        formData.append('userfile', album); // Use 'userfile' as the key
        formData.append('fileName', album.name);
        formData.append('userId', userId);
        formData.append('isalbumupload', isalbumupload);
  
       
        const response = axios.post(`${process.env.REACT_APP_MAINURL}/myprofile`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function () {
          window.alert("Album images uploaded successfully");
          window.location.reload();
        })
        .catch(function () {
          console.log('FAILURE!!');
        });
  

      } catch (error) {
        console.error('Error uploading image:', error);
        // Handle errors here
      }
    } else {
      console.warn('No image selected');
      // Handle case where no image is selected
    }
  };
  
  
  
console.log(permission?.permissions?.slots_album)




  return (
    <div>
      <Loginnavigation />

      {!userInfo ? (
          <div style={{ width: "100vw", textAlign: "center", marginTop:"100px", marginBottom:"100px" }}>
            <img style={{height:"300px", width:"400px", margin:"auto"}} src={loading1}  alt="loading..."/>
          </div>
        ) : (

          <>
          <div
        className="first-section"
      >
        <div className="profile-img">
          {userImg?.photo_url ? (
            <Slider {...sliderSettings}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    border: "1px solid #ff8a00",
                  }}
                  src={userImg?.photo_url}
                  alt="person"
                />
                {userAlbum?.image_urls.length < permission?.permissions?.slots_album && (
                  <div
                    className="pop-up"
                    style={{
                      position: "absolute",
                      top: "80%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <h3>To Upload more photos</h3>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handlealbumChange}
                      style={{ cursor: "pointer", margin: "10px 0", marginLeft: "10px" }}
                    />
                    <span
                      onClick={handlealbumUpload}
                      style={{
                        border: "none",
                        color: "white",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        background: "#912524",
                        fontSize: "18px",
                        marginLeft: "10px",
                      }}
                    >
                      Upload
                    </span>
                  </div>
                )}
                
              </div>

              
                

              {/* Album Slides */}
              {userAlbum?.image_urls.map((imageUrl, index) => (
                <div
                  key={index + 1} // Use a unique key for each additional slide
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                      border: "1px solid #ff8a00",
                    }}
                    src={imageUrl}
                    alt={`person-${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
            
          ) : (
            <div
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative", // Add position relative to the container
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
              src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
              alt="person"
            />
            <div
              style={{
                position: "absolute",
                top: "80%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <h3>Profile photo not found</h3>
              <input type="file" accept="image/*" onChange={handlePhotoUpload} style={{cursor:"pointer", margin:"10px 0", marginLeft:"10px"}}/>
              <button type="file" onChange={handlePhotoUpload} style={{border:"none", color:"white", padding:"5px 20px", borderRadius:"5px", background:"#912524", fontSize:"18px", marginLeft:"10px"}} >Upload</button>
            </div>
          </div>
                  )}
        
        </div>

        <div className="basic-info">
          <div className="partner" style={{marginRight: '0', marginTop: '0px'}}>
          
          <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClick}/>
          {editMode && (
            <button onClick={handleSaveChanges}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
       
            <div className="partner-header">
            {clicked && <Biodata/>}
    <img src={name} alt="Partner" />
    <h1>{userInfo?.profile_data?.full_name}</h1>
    <button 
    style={{ 
        marginLeft: '120px', 
        width: '180px', 
        height: '65px', 
        color: '#912524', 
        fontWeight: '700',  
        fontSize: '20px', 
        backgroundColor: 'transparent', 
        borderRadius: '17px', 
        border: '2px solid #FF8A00' 
    }} 
    title="You can download biodata now!!!"  // Tooltip text
    onClick={handleClick}
>
    <img src={svg} alt="Download Icon"></img>
    Download
</button>



</div>


            {/* Responsive Grid */}
            <div className="partner-grid-container3 basic">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 11 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Religion:</div>
                        <div className="partner-value-div-basic">
                          {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={religion}
                                onChange={(e) => {
                                  setReligion(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherReligion("");
                                  }
                                }}
                              >
                                <option value={religion}>{religion}</option>
                                <option value="Christianity">Christianity</option>
                                <option value="Islam">Islam</option>
                                <option value="Buddhism">Buddhism</option>
                                <option value="Hinduism">Hinduism</option>
                                <option value="Sikhism">Judaism</option>
                                <option value="Sikhism">Sikhism</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {religion === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherReligion}
                                    onChange={(e) => setOtherReligion(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            religion
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Caste:
                        </div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={caste}
                                onChange={(e) => {
                                  setCaste(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherCaste("");
                                  }
                                }}
                              >
                                <option value={caste}>{caste}</option>
                                <option value="Brahmin">Brahmin</option>
                                <option value="Kshatriya">Kshatriya</option>
                                <option value="Vaishya">Vaishya</option>
                                <option value="Shudra">Shudra</option>
                                <option value="Scheduled Castes (SC)">Scheduled Castes (SC)</option>
                                <option value="Scheduled Tribes (ST)">Scheduled Tribes (ST)</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {caste === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherCaste}
                                    onChange={(e) => setOtherCaste(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            caste
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Age:
                        </div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                        <input  className={inputClassName}
                            type="number"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                          />
                        ) : (
                          age
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 3 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Sub-Caste:</div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={subcaste}
                                onChange={(e) => {
                                  setSubcaste(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherSubcaste("");
                                  }
                                }}
                              >
                                <option value={subcaste}>{subcaste}</option>
                                <option value="Not Applicable">Not Applicable</option>
                                <option value="Agarwal">Agarwal</option>
                                <option value="Dalit">Dalit</option>
                                <option value="Adi Dravida">Adi Dravida</option>
                                <option value="Chamar">Chamar</option>
                                <option value="Tribal">Tribal</option>
                                <option value="Gond">Gond</option>
                                <option value="Munda">Munda</option>
                                <option value="Yadav">Yadav</option>
                                <option value="Kurmi">Kurmi</option>
                                <option value="Maurya">Maurya</option>
                                <option value="Nair">Nair</option>
                                <option value="Nadar">Nadar</option>
                                <option value="Vanniyar">Vanniyar</option>
                                <option value="Brahmin">Brahmin</option>
                                <option value="Iyer">Iyer</option>
                                <option value="Iyengar">Iyengar</option>
                                <option value="Smarta Brahmin">Smarta Brahmin</option>
                                <option value="Rajput">Rajput</option>
                                <option value="Thakur">Thakur</option>
                                <option value="Kshatriya Raju">Kshatriya Raju</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {subcaste === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherSubcaste}
                                    onChange={(e) => setOtherSubcaste(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            subcaste
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 4 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Height:
                        </div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                          <select
                                className={inputClassName}
                                value={height}
                                onChange={(e) => {
                                  setHeight(e.target.value);
                                }}
                              >
                                <option value={height}>{height}</option>
                                <option value="48">4 feet (48 inches)</option>
                                <option value="49">4 feet 1 inch (49 inches)</option>
                                <option value="50">4 feet 2 inches (50 inches)</option>
                                <option value="51">4 feet 3 inches (51 inches)</option>
                                <option value="52">4 feet 4 inches (52 inches)</option>
                                <option value="53">4 feet 5 inches (53 inches)</option>
                                <option value="54">4 feet 6 inches (54 inches)</option>
                                <option value="55">4 feet 7 inches (55 inches)</option>
                                <option value="56">4 feet 8 inches (56 inches)</option>
                                <option value="57">4 feet 9 inches (57 inches)</option>
                                <option value="58">4 feet 10 inches (58 inches)</option>
                                <option value="59">4 feet 11 inches (59 inches)</option>
                                <option value="60">5 feet (60 inches)</option>
                                <option value="61">5 feet 1 inch (61 inches)</option>
                                <option value="62">5 feet 2 inches (62 inches)</option>
                                <option value="63">5 feet 3 inches (63 inches)</option>
                                <option value="64">5 feet 4 inches (64 inches)</option>
                                <option value="65">5 feet 5 inches (65 inches)</option>
                                <option value="66">5 feet 6 inches (66 inches)</option>
                                <option value="67">5 feet 7 inches (67 inches)</option>
                                <option value="68">5 feet 8 inches (68 inches)</option>
                                <option value="69">5 feet 9 inches (69 inches)</option>
                                <option value="70">5 feet 10 inches (70 inches)</option>
                                <option value="71">5 feet 11 inches (71 inches)</option>
                                <option value="72">6 feet (72 inches)</option>
                                <option value="73">6 feet 1 inch (73 inches)</option>
                                <option value="74">6 feet 2 inches (74 inches)</option>
                                <option value="75">6 feet 3 inches (75 inches)</option>
                                <option value="76">6 feet 4 inches (76 inches)</option>
                                <option value="77">6 feet 5 inches (77 inches)</option>
                                <option value="78">6 feet 6 inches (78 inches)</option>
                                <option value="79">6 feet 7 inches (79 inches)</option>
                                <option value="80">6 feet 8 inches (80 inches)</option>
                                <option value="81">6 feet 9 inches (81 inches)</option>
                                <option value="82">6 feet 10 inches (82 inches)</option>
                                <option value="83">6 feet 11 inches (83 inches)</option>
                                <option value="84">7 feet (84 inches)</option>
                                <option value="85">7 feet 1 inch (85 inches)</option>
                                <option value="86">7 feet 2 inches (86 inches)</option>
                                <option value="87">7 feet 3 inches (87 inches)</option>
                                <option value="88">7 feet 4 inches (88 inches)</option>
                                <option value="89">7 feet 5 inches (89 inches)</option>
                                <option value="above 89">Above 7.5 feet</option>
                          </select>
                        ) : (
                          height
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 5 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">City:</div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={city}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherCity("");
                                  }
                                }}
                              >
                                <option value={city}>{city}</option>
                                {filteredcities.map((city, index) => (
                                  <option key={index} value={city}>
                                    {city}
                                  </option>
                                ))}
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {city === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherCity}
                                    onChange={(e) => setOtherCity(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            city
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 6 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Salary:
                        </div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                          <select
                                className={inputClassName}
                                value={income}
                                onChange={(e) => {
                                  setIncome(e.target.value);
                                }}
                              >
                                <option value={income}>{income}</option>
                                <option value="Less than 1,00,000">Less than 1,00,000</option>
                                <option value="1,00,000 - 2,00,000">1,00,000 - 2,00,000</option>
                                <option value="2,00,000 - 3,00,000">2,00,000 - 3,00,000</option>
                                <option value="3,00,000 - 4,00,000">3,00,000 - 4,00,000</option>
                                <option value="4,00,000 - 5,00,000">4,00,000 - 5,00,000</option>
                                <option value="5,00,000 - 6,00,000">5,00,000 - 6,00,000</option>
                                <option value="6,00,000 - 7,00,000">6,00,000 - 7,00,000</option>
                                <option value="7,00,000 - 8,00,000">7,00,000 - 8,00,000</option>
                                <option value="8,00,000 - 9,00,000">8,00,000 - 9,00,000</option>
                                <option value="9,00,000 - 10,00,000">9,00,000 - 10,00,000</option>
                                <option value="10,00,000 - 12,50,000">10,00,000 - 12,50,000</option>
                                <option value="12,50,000 - 15,00,000">12,50,000 - 15,00,000</option>
                                <option value="15,00,000 - 20,00,000">15,00,000 - 20,00,000</option>
                                <option value="20,00,000 - 30,00,000">20,00,000 - 30,00,000</option>
                                <option value="30,00,000 - 40,00,000">30,00,000 - 40,00,000</option>
                                <option value="40,00,000 - 50,00,000">40,00,000 - 50,00,000</option>
                                <option value="50,00,000 - 60,00,000">50,00,000 - 60,00,000</option>
                                <option value="60,00,000 - 70,00,000">60,00,000 - 70,00,000</option>
                                <option value="70,00,000 - 80,00,000">70,00,000 - 80,00,000</option>
                                <option value="80,00,000 - 90,00,000">80,00,000 - 90,00,000</option>
                                <option value="90,00,000 - 1,00,00,000">90,00,000 - 1,00,00,000</option>
                                <option value="1,00,00,000 - 1,25,00,000">1,00,00,000 - 1,25,00,000</option>
                                <option value="1,25,00,000 - 1,50,00,000">1,25,00,000 - 1,50,00,000</option>
                                <option value="1,50,00,000 - 2,00,00,000">1,50,00,000 - 2,00,00,000</option>
                                <option value="More than 2,00,00,000">More than 2,00,00,000</option>
                                <option value="More than 10,00,00,000">More than 10,00,00,000</option>
                                <option value="Not Applicable">Not Applicable</option>
                              </select>
                        ) : (
                          income
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 7 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">State:</div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherState("");
                                  }
                                }}
                              >
                                <option value={state}>{state}</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chattisgarh">Chattisgarh</option>
                                <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                <option value="Daman and Diu">Daman and Diu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Ladakh">Ladakh</option>
                                <option value="Madhya Pradesh">MadhyaPradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">TamilNadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">UttarPradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {state === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherState}
                                    onChange={(e) => setOtherState(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            state
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 8 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Working Status:</div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                          <select
                                className={inputClassName}
                                value={working_status}
                                onChange={(e) => {
                                  setWorking_status(e.target.value);
                                }}
                              >
                                <option value={working_status}>{working_status}</option>
                                <option value="Working">Working</option>
                                <option value="Non-working">Non-working</option>
                                <option value="Student">Student</option>
                              </select>
                        ) : (
                          working_status
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 9 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Gotra:</div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={gotra}
                                onChange={(e) => {
                                  setGotra(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherGotra("");
                                  }
                                }}
                              >
                                <option value={gotra}>{gotra}</option>
                                <option value="Bharadwaj">Bharadwaj</option>
                                <option value="Kashyap">Kashyap</option>
                                <option value="Vatsa">Vatsa</option>
                                <option value="Gautam">Gautam</option>
                                <option value="Shandilya">Shandilya</option>
                                <option value="Atri">Atri</option>
                                <option value="Vasistha">Vasistha</option>
                                <option value="Kaushik">Kaushik</option>
                                <option value="Garg">Garg</option>
                                <option value="Parashara">Parashara</option>
                                <option value="Sandilya">Sandilya</option>
                                <option value="Vashishtha">Vashishtha</option>
                                <option value="Jamadagni">Jamadagni</option>
                                <option value="Angirasa">Angirasa</option>
                                <option value="Kaundinya">Kaundinya</option>
                                <option value="Suryavanshi">Suryavanshi</option>
                                <option value="Chandravanshi">Chandravanshi</option>
                                <option value="Agnivanshi">Agnivanshi</option>
                                <option value="Nagvanshi">Nagvanshi</option>
                                <option value="Kachwaha">Kachwaha</option>
                                <option value="Rathore">Rathore</option>
                                <option value="Tomar">Tomar</option>
                                <option value="Solanki">Solanki</option>
                                <option value="Chauhan">Chauhan</option>
                                <option value="Parmar">Parmar</option>
                                <option value="Gohil">Gohil</option>
                                <option value="Pratihar">Pratihar</option>
                                <option value="Parihar">Parihar</option>
                                <option value="Rajput">Rajput</option>
                                <option value="Rajawat">Rajawat</option>
                                <option value="Agarwal">Agarwal</option>
                                <option value="Garg">Garg</option>
                                <option value="Bansal">Bansal</option>
                                <option value="Mittal">Mittal</option>
                                <option value="Singhal">Singhal</option>
                                <option value="Kansal">Kansal</option>
                                <option value="Mangal">Mangal</option>
                                <option value="Madhukul">Madhukul</option>
                                <option value="Goyal">Goyal</option>
                                <option value="Jindal">Jindal</option>
                                <option value="Tayal">Tayal</option>
                                <option value="Kuchhal">Kuchhal</option>
                                <option value="Goyal">Goyal</option>
                                <option value="Mangal">Mangal</option>
                                <option value="Khandelwal">Khandelwal</option>
                                <option value="Gupta">Gupta</option>
                                <option value="Maheshwari">Maheshwari</option>
                                <option value="Oswal">Oswal</option>
                                <option value="Porwal">Porwal</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {gotra === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherGotra}
                                    onChange={(e) => setOtherGotra(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            gotra
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 10 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Weight(in kg):
                        </div>
                        <div className="partner-value-div-basic">
                        {editMode ? (
                        <input  className={inputClassName}
                            type="number"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                          />
                        ) : (
                          weight
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  
                  
                </div>
                
              ))}
              
            </div>

            <div className="partner-grid-container3 verified">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 1 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                    { userInfo?.profile_data?.verified === 'no' && (
                      <div className="verified-div" style={{ display: "flex",}}>
                        <h1 style={{ fontWeight: '500', color: '#FF8A00', margin: '0'}}>Your profile is not verified</h1>
                        <button style={{background: '#70E000', fontSize: '15px', fontWeight: '500', border: 'none', padding: '5px 10px', borderRadius:'50px', color:'white', margin: '0 10px'}}>GET VERIFIED</button>
                      </div>
                    )}

                      { userInfo?.profile_data?.verified === 'yes' && (
                        <div className="verified-div" style={{ display: "flex",}}>
                        <h1 style={{ fontWeight: '500', color: '#FF8A00', margin: '0'}}>Your profile is verified</h1>
                        <button style={{background: '#70E000', fontSize: '15px', fontWeight: '500', border: 'none', padding: '5px 10px', borderRadius:'50px', color:'white', margin: '0 10px'}}>yes</button>
                      </div>
                      )}
                    </>
                  )}
                  
                  
                </div>
              ))}
              
            </div>
          </div>

          <div className="partner" style={{marginRight: '0'}}>
          <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickbasicinfo}/>
          {editModebasicinfo && (
            <button onClick={handleSaveChangesbasicinfo}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
            <div className="partner-header">
              <img src={basicinfo} alt="Partner" />
              <h1>Basic Info</h1>
            </div>

            {/* Responsive Grid */}
            <div className="partner-grid-container3">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 2 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">DOB:</div>
                        <div className="partner-value-div-basic">
                        {editModebasicinfo ? (
                        <input  className={inputClassName}
                            type="text"
                            value={birth_date}
                            onChange={(e) => setBirth_date(e.target.value)}
                          />
                        ) : (
                          birth_date
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Marital Status:
                        </div>
                        <div className="partner-value-div-basic">
                        {editModebasicinfo ? (
                        <input  className={inputClassName}
                            type="text"
                            value={marital_status}
                            onChange={(e) => setMarital_status(e.target.value)}
                          />
                        ) : (
                          marital_status
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickabout}/>
          {editModeabout && (
            <button onClick={handleSaveChangesabout}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={name} alt="Partner" />
          <h1>About Me <span style={{color: '#B1B1B1', fontWeight: '400', fontSize:'25px'}}>(Max. 1000 char)</span></h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3 verified">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 1 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                    
                      <div className="verified-div" style={{ display: "flex", alignContent: 'left', justifyContent: 'left'}}>
                        <p style={{ fontWeight: '500', color: '#FF8A00', margin: '0',textAlign:'justify'}}>
                        {editModeabout ? (
                        <input  className="verified-div"
                            type="text"
                            value={about}
                            onChange={(e) => setAbout(e.target.value)}
                          />
                        ) : (
                          about
                        )}
                        </p>
                      </div>
                    

                    </>
                  )}
                  
                  
                </div>
              ))}
            </div>
      </div>



      <div className="partner">
        <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickeducation}/>
          {editModeeducation && (
            <button onClick={handleSaveChangeseducation}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={educationimg} alt="Partner" />
          <h1>Education</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 3 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Highest Degree:</div>
                        <div className="partner-value-div">
                          {editModeeducation ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={education}
                                onChange={(e) => {
                                  setEducation(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherEducation("");
                                  }
                                }}
                              >
                                <option value={education}>{education}</option>
                                <option value="12th Pass">12th Pass</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Certification">Certification</option>
                                <option value="Associates Degree">Associates Degree</option>
                                <option value="Bachelors Degree">Bachelors Degree</option>
                                <option value="B.A (Bachelor of Arts)">B.A (Bachelor of Arts)</option>
                                <option value="B.Sc (Bachelor of Science)">B.Sc (Bachelor of Science)</option>
                                <option value="B.Com (Bachelor of Commerce)">B.Com (Bachelor of Commerce)</option>
                                <option value="B.Tech (Bachelor of Technology)">B.Tech (Bachelor of Technology)</option>
                                <option value="BBA (Bachelor of Business Administration)">BBA (Bachelor of Business Administration)</option>
                                <option value="BCA (Bachelor of Computer Applications)">BCA (Bachelor of Computer Applications)</option>
                                <option value="LLB (Bachelor of Laws)">LLB (Bachelor of Laws)</option>
                                <option value="MBBS (Bachelor of Medicine, Bachelor of Surgery)">MBBS (Bachelor of Medicine, Bachelor of Surgery)</option>
                                <option value="Pharm.D (Doctor of Pharmacy)">Pharm.D (Doctor of Pharmacy)</option>
                                <option value="BDS (Bachelor of Dental Surgery)">BDS (Bachelor of Dental Surgery)</option>
                                <option value="BAMS (Bachelor of Ayurvedic Medicine and Surgery)">BAMS (Bachelor of Ayurvedic Medicine and Surgery)</option>
                                <option value="BVSc (Bachelor of Veterinary Science)">BVSc (Bachelor of Veterinary Science)</option>
                                <option value="BE (Bachelor of Engineering)">BE (Bachelor of Engineering)</option>
                                <option value="Masters Degree">Masters Degree</option>
                                <option value="M.A (Master of Arts)">M.A (Master of Arts)</option>
                                <option value="M.Sc (Master of Science)">M.Sc (Master of Science)</option>
                                <option value="M.Com (Master of Commerce)">M.Com (Master of Commerce)</option>
                                <option value="M.Tech (Master of Technology)">M.Tech (Master of Technology)</option>
                                <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
                                <option value="MCA (Master of Computer Applications)">MCA (Master of Computer Applications)</option>
                                <option value="MFA (Master of Fine Arts)">MFA (Master of Fine Arts)</option>
                                <option value="MSW (Master of Social Work)">MSW (Master of Social Work)</option>
                                <option value="LL.M (Master of Laws)">LL.M (Master of Laws)</option>
                                <option value="MD (Doctor of Medicine)">MD (Doctor of Medicine)</option>
                                <option value="MPharm (Master of Pharmacy)">MPharm (Master of Pharmacy)</option>
                                <option value="MDS (Master of Dental Surgery)">MDS (Master of Dental Surgery)</option>
                                <option value="MS (Master of Surgery)">MS (Master of Surgery)</option>
                                <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
                                <option value="M.Phil (Master of Philosophy)">M.Phil (Master of Philosophy)</option>
                                <option value="Ph.D. (Doctor of Philosophy)">Ph.D. (Doctor of Philosophy)</option>
                                <option value="Other Masters Degree (Specify)">Other Masters Degree (Specify)</option>
                                <option value="Doctorate (Post-Doctoral)">Doctorate (Post-Doctoral)</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {education === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherEducation}
                                    onChange={(e) => setOtherEducation(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            education
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                <div style={{ display: "flex" }}>
                        <div className="partner-title-div">College Name:</div>
                        <div className="partner-value-div">
                          {editModeeducation ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={college}
                                onChange={(e) => {
                                  setCollege(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherCollege("");
                                  }
                                }}
                              >
                                <option value={college}>{college}</option>
                                <option value="IIT Kharagpur">IIT Kharagpur</option>
                                <option value="IIT Bombay">IIT Bombay</option>
                                <option value="IIT Madras">IIT Madras</option>
                                <option value="IIT Kanpur">IIT Kanpur</option>
                                <option value="IIT Delhi">IIT Delhi</option>
                                <option value="IIT Guwahati">IIT Guwahati</option>
                                <option value="IIT Roorkee">IIT Roorkee</option>
                                <option value="IIT Ropar">IIT Ropar</option>
                                <option value="IIT Bhubaneswar">IIT Bhubaneswar</option>
                                <option value="IIT Gandhinagar">IIT Gandhinagar</option>
                                <option value="IIT Hyderabad">IIT Hyderabad</option>
                                <option value="IIT Jodhpur">IIT Jodhpur</option>
                                <option value="IIT Patna">IIT Patna</option>
                                <option value="IIT Indore">IIT Indore</option>
                                <option value="IIT Mandi">IIT Mandi</option>
                                <option value="IIT Varanasi">IIT Varanasi</option>
                                <option value="IIT Palakkad">IIT Palakkad</option>
                                <option value="IIT Tirupati">IIT Tirupati</option>
                                <option value="IIT Dhanbad">IIT Dhanbad</option>
                                <option value="IIT Bhilai">IIT Bhilai</option>
                                <option value="IIT Dharwad">IIT Dharwad</option>
                                <option value="IIT Jammu">IIT Jammu</option>
                                <option value="IIT Goa">IIT Goa</option>

                                <option value="Birla Institute of Technology and Science - Pilani">Birla Institute of Technology and Science - Pilani</option>
                                <option value="Birla Institute of Technology and Science - Pilani, Goa campus">Birla Institute of Technology and Science - Pilani, Goa campus</option>
                                <option value="Birla Institute of Technology and Science - Pilani, Hyderabad Campus">Birla Institute of Technology and Science - Pilani, Hyderabad Campus</option>
                                <option value="Birla Institute of Technology and Science - Pilani, Dubai Campus">Birla Institute of Technology and Science - Pilani, Dubai Campus</option>
                                <option value="Birla Institute of Technology and Science - Pilani, Pilani Campus">Birla Institute of Technology and Science - Pilani, Pilani Campus</option>

                                <option value="IIM Ahmedabad">IIM Ahmedabad</option>
                                <option value="IIM Bangalore">IIM Bangalore</option>
                                <option value="IIM Calcutta">IIM Calcutta</option>
                                <option value="IIM Lucknow">IIM Lucknow</option>
                                <option value="IIM Indore">IIM Indore</option>
                                <option value="IIM Kozhikode">IIM Kozhikode</option>
                                <option value="IIM Raipur">IIM Raipur</option>
                                <option value="IIM Ranchi">IIM Ranchi</option>
                                <option value="IIM Rohtak">IIM Rohtak</option>
                                <option value="IIM Kashipur">IIM Kashipur</option>
                                <option value="IIM Tiruchirappalli">IIM Tiruchirappalli</option>
                                <option value="IIM Udaipur">IIM Udaipur</option>
                                <option value="IIM Amritsar">IIM Amritsar</option>
                                <option value="IIM Bodh Gaya">IIM Bodh Gaya</option>
                                <option value="IIM Nagpur">IIM Nagpur</option>
                                <option value="IIM Sambalpur">IIM Sambalpur</option>
                                <option value="IIM Sirmaur">IIM Sirmaur</option>
                                <option value="IIM Visakhapatnam">IIM Visakhapatnam</option>
                                <option value="IIM Jammu">IIM Jammu</option>
                                <option value="IIM Mumbai">IIM Mumbai</option>

                                <option value="XLRI Ã¢â‚¬â€œ Xavier School of Management">XLRI Ã¢â‚¬â€œ Xavier School of Management</option>
                                <option value="S.P Jain College of Management and Research">S.P Jain College of Management and Research</option>
                                <option value="NIT Warangal(NITW)">NIT Warangal(NITW)</option>
                                <option value="NIT Bhopal(MANIT)">NIT Bhopal(MANIT)</option>
                                <option value="NIT Nagpur(VNIT)">NIT Nagpur(VNIT)</option>
                                <option value="NIT Durgapur(NITDGP)">NIT Durgapur(NITDGP)</option>
                                <option value="NIT Jamshedpur(NITJSR)">NIT Jamshedpur(NITJSR)</option>
                                <option value="NIT Karnataka(NITK)">NIT Karnataka(NITK)</option>
                                <option value="NIT Srinagar(NITSRI)">NIT Srinagar(NITSRI)</option>
                                <option value="NIT Allahabad(MNNIT)">NIT Allahabad(MNNIT)</option>
                                <option value="NIT Surat(SVNIT)">NIT Surat(SVNIT)</option>
                                <option value="NIT Calicut(NITC)">NIT Calicut(NITC)</option>
                                <option value="NIT Rourkela(NITR)">NIT Rourkela(NITR)</option>
                                <option value="NIT Jaipur(MNIT)">NIT Jaipur(MNIT)</option>
                                <option value="NIT Kurukshetra(NITKKR)">NIT Kurukshetra(NITKKR)</option>
                                <option value="NIT Tiruchirappalli(NITT)">NIT Tiruchirappalli(NITT)</option>
                                <option value="NIT Silchar(NITS)">NIT Silchar(NITS)</option>
                                <option value="NIT Hamirpur(NITH)">NIT Hamirpur(NITH)</option>
                                <option value="NIT Jalandhar(NITJ)">NIT Jalandhar(NITJ)</option>
                                <option value="NIT Patna(NITP)">NIT Patna(NITP)</option>
                                <option value="NIT Raipur(NITRR)">NIT Raipur(NITRR)</option>
                                <option value="NIT Agartala(NITA)">NIT Agartala(NITA)</option>
                                <option value="NIT Arunachal Pradesh(NITAP)">NIT Arunachal Pradesh(NITAP)</option>
                                <option value="NIT Delhi(NITD)">NIT Delhi(NITD)</option>
                                <option value="NIT Goa(NITG)">NIT Goa(NITG)</option>
                                <option value="NIT Manipur(NITMN)">NIT Manipur(NITMN)</option>
                                <option value="NIT Meghalaya(NITM)">NIT Meghalaya(NITM)</option>
                                <option value="NIT Mizoram(NITMZ)">NIT Mizoram(NITMZ)</option>
                                <option value="NIT Nagaland(NITN)">NIT Nagaland(NITN)</option>
                                <option value="NIT Puducherry(NITPY)">NIT Puducherry(NITPY)</option>
                                <option value="NIT Sikkim(NITSKM)">NIT Sikkim(NITSKM)</option>
                                <option value="NIT Uttarakhand(NITUK)">NIT Uttarakhand(NITUK)</option>
                                <option value="NIT Andhra Pradesh NITANP">NIT Andhra Pradesh NITANP</option>
                                <option value="Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)">Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)</option>
                                <option value="Indian Institute of Information Technology, Allahabad(IIITA)">Indian Institute of Information Technology, Allahabad(IIITA)</option>
                                <option value="Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)">Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)</option>
                                <option value="Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)">Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)</option>
                                <option value="Indian Institute of Information Technology, Sri City(IIITSC)">Indian Institute of Information Technology, Sri City(IIITSC)</option>
                                <option value="Indian Institute of Information Technology, Guwahati(IIITG)">Indian Institute of Information Technology, Guwahati(IIITG)</option>
                                <option value="Indian Institute of Information Technology, Vadodara(IIITV)">Indian Institute of Information Technology, Vadodara(IIITV)</option>
                                <option value="Indian Institute of Information Technology, Kota(IIIT Kota)">Indian Institute of Information Technology, Kota(IIIT Kota)</option>
                                <option value="Indian Institute of Information Technology, Tiruchirappalli(IIITT)">Indian Institute of Information Technology, Tiruchirappalli(IIITT)</option>
                                <option value="Indian Institute of Information Technology, Una(IIITU)">Indian Institute of Information Technology, Una(IIITU)</option>
                                <option value="Indian Institute of Information Technology, Sonepat(IIIT Sonepat)">Indian Institute of Information Technology, Sonepat(IIIT Sonepat)</option>
                                <option value="Indian Institute of Information Technology, Kalyani(IIIT Kalyani)">Indian Institute of Information Technology, Kalyani(IIIT Kalyani)</option>
                                <option value="Indian Institute of Information Technology, Lucknow(IIITL)">Indian Institute of Information Technology, Lucknow(IIITL)</option>
                                <option value="Indian Institute of Information Technology, Dharwad(IIITDWD">Indian Institute of Information Technology, Dharwad(IIITDWD)</option>
                                <option value="Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)">Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)</option>
                                <option value="Indian Institute of Information Technology, Kottayam(IIIT Kottayam)">Indian Institute of Information Technology, Kottayam(IIIT Kottayam)</option>
                                <option value="Indian Institute of Information Technology, Manipur(IIITM)">Indian Institute of Information Technology, Manipur(IIITM)</option>
                                <option value="Indian Institute of Information Technology, Nagpur(IIITN)">Indian Institute of Information Technology, Nagpur(IIITN)</option>
                                <option value="Indian Institute of Information Technology, Pune(IIITP)">Indian Institute of Information Technology, Pune(IIITP)</option>
                                <option value="Indian Institute of Information Technology, Ranchi(IIIT Ranchi)">Indian Institute of Information Technology, Ranchi(IIIT Ranchi)</option>
                                <option value="Indian Institute of Information Technology, Surat(IIIT Surat)">Indian Institute of Information Technology, Surat(IIIT Surat)</option>
                                <option value="Indian Institute of Information Technology, Bhopal(IIIT Bhopal)">Indian Institute of Information Technology, Bhopal(IIIT Bhopal)</option>
                                <option value="Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)">Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)</option>
                                <option value="Indian Institute of Information Technology, Agartala(IIIT Agartala)">Indian Institute of Information Technology, Agartala(IIIT Agartala)</option>
                                <option value="Indian Institute of Information Technology, Raichur">Indian Institute of Information Technology, Raichur</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {college === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="specify"
                                    value={otherCollege}
                                    onChange={(e) => setOtherCollege(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            college
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Other Education:</div>
                        <div className="partner-value-div">
                          {editModeeducation ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={education1}
                                onChange={(e) => {
                                  setEducation1(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherEducation1("");
                                  }
                                }}
                              >
                                <option value={education1}>{education1}</option>
                                <option value="12th Pass">12th Pass</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Certification">Certification</option>
                                <option value="Associates Degree">Associates Degree</option>
                                <option value="Bachelors Degree">Bachelors Degree</option>
                                <option value="B.A (Bachelor of Arts)">B.A (Bachelor of Arts)</option>
                                <option value="B.Sc (Bachelor of Science)">B.Sc (Bachelor of Science)</option>
                                <option value="B.Com (Bachelor of Commerce)">B.Com (Bachelor of Commerce)</option>
                                <option value="B.Tech (Bachelor of Technology)">B.Tech (Bachelor of Technology)</option>
                                <option value="BBA (Bachelor of Business Administration)">BBA (Bachelor of Business Administration)</option>
                                <option value="BCA (Bachelor of Computer Applications)">BCA (Bachelor of Computer Applications)</option>
                                <option value="LLB (Bachelor of Laws)">LLB (Bachelor of Laws)</option>
                                <option value="MBBS (Bachelor of Medicine, Bachelor of Surgery)">MBBS (Bachelor of Medicine, Bachelor of Surgery)</option>
                                <option value="Pharm.D (Doctor of Pharmacy)">Pharm.D (Doctor of Pharmacy)</option>
                                <option value="BDS (Bachelor of Dental Surgery)">BDS (Bachelor of Dental Surgery)</option>
                                <option value="BAMS (Bachelor of Ayurvedic Medicine and Surgery)">BAMS (Bachelor of Ayurvedic Medicine and Surgery)</option>
                                <option value="BVSc (Bachelor of Veterinary Science)">BVSc (Bachelor of Veterinary Science)</option>
                                <option value="BE (Bachelor of Engineering)">BE (Bachelor of Engineering)</option>
                                <option value="Masters Degree">Masters Degree</option>
                                <option value="M.A (Master of Arts)">M.A (Master of Arts)</option>
                                <option value="M.Sc (Master of Science)">M.Sc (Master of Science)</option>
                                <option value="M.Com (Master of Commerce)">M.Com (Master of Commerce)</option>
                                <option value="M.Tech (Master of Technology)">M.Tech (Master of Technology)</option>
                                <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
                                <option value="MCA (Master of Computer Applications)">MCA (Master of Computer Applications)</option>
                                <option value="MFA (Master of Fine Arts)">MFA (Master of Fine Arts)</option>
                                <option value="MSW (Master of Social Work)">MSW (Master of Social Work)</option>
                                <option value="LL.M (Master of Laws)">LL.M (Master of Laws)</option>
                                <option value="MD (Doctor of Medicine)">MD (Doctor of Medicine)</option>
                                <option value="MPharm (Master of Pharmacy)">MPharm (Master of Pharmacy)</option>
                                <option value="MDS (Master of Dental Surgery)">MDS (Master of Dental Surgery)</option>
                                <option value="MS (Master of Surgery)">MS (Master of Surgery)</option>
                                <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
                                <option value="M.Phil (Master of Philosophy)">M.Phil (Master of Philosophy)</option>
                                <option value="Ph.D. (Doctor of Philosophy)">Ph.D. (Doctor of Philosophy)</option>
                                <option value="Other Masters Degree (Specify)">Other Masters Degree (Specify)</option>
                                <option value="Doctorate (Post-Doctoral)">Doctorate (Post-Doctoral)</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {education1 === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherEducation1}
                                    onChange={(e) => setOtherEducation1(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            education1
                          )}
                        </div>
                  </div>
                </>
              )}
              
              
            </div>
          ))}
        </div>
      </div>

      <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickcareer}/>
          {editModecareer && (
            <button onClick={handleSaveChangescareer}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={career} alt="Partner" />
          <h1>Career</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Profession:</div>
                        <div className="partner-value-div">
                          {editModecareer ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={occupation}
                                onChange={(e) => {
                                  setOccupation(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherOccupation("");
                                  }
                                }}
                              >
                                <option value={occupation}>{occupation}</option>
                                <option value="Accountant">Accountant</option>
                                <option value="Artist">Artist</option>
                                <option value="Engineer">Engineer</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Software Developer">Software Developer</option>
                                <option value="Manager">Manager</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Nurse">Nurse</option>
                                <option value="Designer">Designer</option>
                                <option value="Writer">Writer</option>
                                <option value="Farmer">Farmer</option>
                                <option value="Cook">Cook</option>
                                <option value="Clerk">Clerk</option>
                                <option value="Entrepreneur">Entrepreneur</option>
                                <option value="Police Officer">Police Officer</option>
                                <option value="Chef">Chef</option>
                                <option value="Salesperson">Salesperson</option>
                                <option value="Student">Student</option>
                                <option value="Homemaker">Homemaker</option>
                                <option value="Retired">Retired</option>
                                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                                <option value="Assistant Professor">Assistant Professor</option>
                                <option value="Associate Professor">Associate Professor</option>
                                <option value="Professor">Professor</option>
                                <option value="Manager">Manager</option>
                                <option value="Senior Manager">Senior Manager</option>
                                <option value="Consultant">Consultant</option>
                                <option value="Lecturer">Lecturer</option>
                                <option value="Director">Director</option>
                                <option value="Technical lead">Technical lead</option>
                                <option value="General Manager">General Manager</option>
                                <option value="Government Officer">Government Officer</option>
                                <option value="Minister">Minister</option>
                                <option value="MLA">MLA</option>
                                <option value="MP">MP</option>
                                <option value="Speaker">Speaker</option>
                                <option value="Managing Director">Managing Director</option>
                                <option value="Architect">Architect</option>
                                <option value="Interior Designer">Interior Designer</option>
                                <option value="Human Resource">Human Resource</option>
                                <option value="HR Manager">HR Manager</option>
                                <option value="Executive">Executive</option>
                                <option value="Senior Executive">Senior Executive</option>
                                <option value="Businessman">Businessman</option>
                                <option value="Franchise owner">Franchise owner</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {occupation === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherOccupation}
                                    onChange={(e) => setOtherOccupation(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            occupation
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Employed In:</div>
                        <div className="partner-value-div">
                          {editModecareer ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={employedin}
                                onChange={(e) => {
                                  setEmployedin(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherEmployedin("");
                                  }
                                }}
                              >
                                <option value={employedin}>{employedin}</option>
                                <option value="Private Sector">Private Sector</option>
                                <option value="Government Sector">Government Sector</option>
                                <option value="Public Sector">Public Sector</option>
                                <option value="Self-Employed">Self-Employed</option>
                                <option value="Not Employed">Not Employed</option>
                                <option value="Education">Education</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="IT/Technology">IT/Technology</option>
                                <option value="Finance">Finance</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Research">Research</option>
                                <option value="Retail">Retail</option>
                                <option value="Media/Entertainment">Media/Entertainment</option>
                                <option value="Non-Profit">Non-Profit</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {employedin === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherEmployedin}
                                    onChange={(e) => setOtherEmployedin(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            employedin
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Company Name:</div>
                        <div className="partner-value-div">
                          {editModecareer ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={company}
                                onChange={(e) => {
                                  setCompany(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherCompany("");
                                  }
                                }}
                              >
                                <option value={company}>{company}</option>
                                <option value="TCS">TCS</option>
                                <option value="ACCENTURE">ACCENTURE</option>
                                <option value="COGNIZANT">COGNIZANT</option>
                                <option value="Microsoft">Microsoft</option>
                                <option value="Google">Google</option>
                                <option value="NUTANIX">NUTANIX</option>
                                <option value="FLIPKART">FLIPKART</option>
                                <option value="AMAZON">AMAZON</option>
                                <option value="GOLDMAN SACHS">GOLDMAN SACHS</option>
                                <option value="WALMART">WALMART</option>
                                <option value="VIVAHSAHYOG.COM">VIVAHSAHYOG.COM</option>
                                <option value="WIPRO">WIPRO</option>
                                <option value="ICICI BANK">ICICI BANK</option>
                                <option value="BANK OF BARODA">BANK OF BARODA</option>
                                <option value="HDFC BANK">HDFC BANK</option>
                                <option value="SBI BANK">SBI BANK</option>
                                <option value="ZOMATO">ZOMATO</option>
                                <option value="HP">HP</option>
                                <option value="DELL">DELL</option>
                                <option value="SWIGGY">SWIGGY</option>
                                <option value="PAYTM">PAYTM</option>
                                <option value="PAYPAL">PAYPAL</option>
                                
                                  {/* Add other language options */}
                                  <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {company === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherCompany}
                                    onChange={(e) => setOtherCompany(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            company
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Job Location:</div>
                        <div className="partner-value-div">
                          {editModecareer ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={companylocation}
                                onChange={(e) => {
                                  setCompanylocation(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherCompanylocation("");
                                  }
                                }}
                              >
                                <option value={companylocation}>{companylocation}</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Gurugram">Gurugram</option>
                                <option value="Faridabad">Faridabad</option>
                                <option value="Noida">Noida</option>
                                <option value="Greater Noida">Greater Noida</option>
                                <option value="Ghaziabad">Ghaziabad</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Kolkata">Kolkata</option>
                                <option value="Bangalore">Bangalore</option>
                                <option value="Chennai">Chennai</option>
                                <option value="Hyderabad">Hyderabad</option>
                                <option value="Ahmedabad">Ahmedabad</option>
                                <option value="Pune">Pune</option>
                                <option value="Surat">Surat</option>
                                <option value="Lucknow">Lucknow</option>
                                <option value="Jaipur">Jaipur</option>
                                <option value="Kanpur">Kanpur</option>
                                <option value="Indore">Indore</option>
                                <option value="Nagpur">Nagpur</option>
                                <option value="Patna">Patna</option>
                                <option value="Amroha">Amroha</option>
                                <option value="Remote">Remote</option>
                                  {/* Add other language options */}
                                  <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {companylocation === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherCompanylocation}
                                    onChange={(e) => setOtherCompanylocation(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            companylocation
                          )}
                        </div>
                  </div>
                </>
              )}
              
              
            </div>
          ))}
        </div>
      </div>



      



      {userInfo?.family_details && (
        <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickfamily}/>
          {editModefamily && (
            <button onClick={handleSaveChangesfamily}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={family} alt="Partner" />
          <h1>Family</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 7 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Family Type:</div>
                    <div className="partner-value-div">
                    {editModefamily ? (
                          <select
                                className="partner-value-div"
                                value={familytype}
                                onChange={(e) => {
                                  setFamilytype(e.target.value);
                                }}
                              >
                                <option value={familytype}>{familytype}</option>
                                <option value="Rich">Rich</option>
                                <option value="Middle Class">Middle Class</option>
                                <option value="Poor">Poor</option>
                              </select>
                        ) : (
                          familytype
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">No. of brothers:</div>
                    <div className="partner-value-div">
                    {editModefamily ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={brother}
                            onChange={(e) => setBrother(e.target.value)}
                          />
                        ) : (
                          brother
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Father’s Occupation:</div>
                        <div className="partner-value-div">
                          {editModefamily ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={fatheroccupation}
                                onChange={(e) => {
                                  setFatheroccupation(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherFatheroccupation("");
                                  }
                                }}
                              >
                                <option value={fatheroccupation}>{fatheroccupation}</option>
                                <option value="Accountant">Accountant</option>
                                <option value="Artist">Artist</option>
                                <option value="Engineer">Engineer</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Software Developer">Software Developer</option>
                                <option value="Manager">Manager</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Nurse">Nurse</option>
                                <option value="Designer">Designer</option>
                                <option value="Writer">Writer</option>
                                <option value="Farmer">Farmer</option>
                                <option value="Cook">Cook</option>
                                <option value="Clerk">Clerk</option>
                                <option value="Entrepreneur">Entrepreneur</option>
                                <option value="Police Officer">Police Officer</option>
                                <option value="Chef">Chef</option>
                                <option value="Salesperson">Salesperson</option>
                                <option value="Student">Student</option>
                                <option value="Homemaker">Homemaker</option>
                                <option value="Retired">Retired</option>
                                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                                <option value="Assistant Professor">Assistant Professor</option>
                                <option value="Associate Professor">Associate Professor</option>
                                <option value="Professor">Professor</option>
                                <option value="Manager">Manager</option>
                                <option value="Senior Manager">Senior Manager</option>
                                <option value="Consultant">Consultant</option>
                                <option value="Lecturer">Lecturer</option>
                                <option value="Director">Director</option>
                                <option value="Technical lead">Technical lead</option>
                                <option value="General Manager">General Manager</option>
                                <option value="Government Officer">Government Officer</option>
                                <option value="Minister">Minister</option>
                                <option value="MLA">MLA</option>
                                <option value="MP">MP</option>
                                <option value="Speaker">Speaker</option>
                                <option value="Managing Director">Managing Director</option>
                                <option value="Architect">Architect</option>
                                <option value="Interior Designer">Interior Designer</option>
                                <option value="Human Resource">Human Resource</option>
                                <option value="HR Manager">HR Manager</option>
                                <option value="Executive">Executive</option>
                                <option value="Senior Executive">Senior Executive</option>
                                <option value="Businessman">Businessman</option>
                                <option value="Franchise owner">Franchise owner</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {fatheroccupation === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={otherfatheroccupation}
                                    onChange={(e) => setOtherFatheroccupation(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            fatheroccupation
                          )}
                        </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">No. of sisters:</div>
                    <div className="partner-value-div">
                    {editModefamily ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={sister}
                            onChange={(e) => setSister(e.target.value)}
                          />
                        ) : (
                          sister
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 4 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Mother’s Occupation:</div>
                        <div className="partner-value-div">
                        {editModefamily ? (
                            <div>
                              <select
                                className="partner-value-div"
                                value={motheroccupation}
                                onChange={(e) => {
                                  setMotheroccupation(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherMotheroccupation("");
                                  }
                                }}
                              >
                                <option value={motheroccupation}>{motheroccupation}</option>
                                <option value="Accountant">Accountant</option>
                                <option value="Artist">Artist</option>
                                <option value="Engineer">Engineer</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Software Developer">Software Developer</option>
                                <option value="Manager">Manager</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="Nurse">Nurse</option>
                                <option value="Designer">Designer</option>
                                <option value="Writer">Writer</option>
                                <option value="Farmer">Farmer</option>
                                <option value="Cook">Cook</option>
                                <option value="Clerk">Clerk</option>
                                <option value="Entrepreneur">Entrepreneur</option>
                                <option value="Police Officer">Police Officer</option>
                                <option value="Chef">Chef</option>
                                <option value="Salesperson">Salesperson</option>
                                <option value="Student">Student</option>
                                <option value="Homemaker">Homemaker</option>
                                <option value="Retired">Retired</option>
                                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                                <option value="Assistant Professor">Assistant Professor</option>
                                <option value="Associate Professor">Associate Professor</option>
                                <option value="Professor">Professor</option>
                                <option value="Manager">Manager</option>
                                <option value="Senior Manager">Senior Manager</option>
                                <option value="Consultant">Consultant</option>
                                <option value="Lecturer">Lecturer</option>
                                <option value="Director">Director</option>
                                <option value="Technical lead">Technical lead</option>
                                <option value="General Manager">General Manager</option>
                                <option value="Government Officer">Government Officer</option>
                                <option value="Minister">Minister</option>
                                <option value="MLA">MLA</option>
                                <option value="MP">MP</option>
                                <option value="Speaker">Speaker</option>
                                <option value="Managing Director">Managing Director</option>
                                <option value="Architect">Architect</option>
                                <option value="Interior Designer">Interior Designer</option>
                                <option value="Human Resource">Human Resource</option>
                                <option value="HR Manager">HR Manager</option>
                                <option value="Executive">Executive</option>
                                <option value="Senior Executive">Senior Executive</option>
                                <option value="Businessman">Businessman</option>
                                <option value="Franchise owner">Franchise owner</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {motheroccupation === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className="partner-value-div"
                                    placeholder="Specify"
                                    value={othermotheroccupation}
                                    onChange={(e) => setOtherMotheroccupation(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            motheroccupation
                          )}
                        </div>
                  </div>
                </>
              )}
              
              {index === 5 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Relatives’ Contact:</div>
                    <div className="partner-value-div">
                    {editModefamily ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={relativecontact}
                            onChange={(e) => setRelativecontact(e.target.value)}
                          />
                        ) : (
                          relativecontact
                        )}
                    </div>
                  </div>
                </>
              )}
              
              {index === 6 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Family Income:</div>
                    <div className="partner-value-div">
                    {editModefamily ? (
                          <select
                                className="partner-value-div"
                                value={familyincome}
                                onChange={(e) => {
                                  setFamilyincome(e.target.value);
                                }}
                              >
                                <option value={familyincome}>{familyincome}</option>
                                <option value="Less than 1,00,000">Less than 1,00,000</option>
                                <option value="1,00,000 - 2,00,000">1,00,000 - 2,00,000</option>
                                <option value="2,00,000 - 3,00,000">2,00,000 - 3,00,000</option>
                                <option value="3,00,000 - 4,00,000">3,00,000 - 4,00,000</option>
                                <option value="4,00,000 - 5,00,000">4,00,000 - 5,00,000</option>
                                <option value="5,00,000 - 6,00,000">5,00,000 - 6,00,000</option>
                                <option value="6,00,000 - 7,00,000">6,00,000 - 7,00,000</option>
                                <option value="7,00,000 - 8,00,000">7,00,000 - 8,00,000</option>
                                <option value="8,00,000 - 9,00,000">8,00,000 - 9,00,000</option>
                                <option value="9,00,000 - 10,00,000">9,00,000 - 10,00,000</option>
                                <option value="10,00,000 - 12,50,000">10,00,000 - 12,50,000</option>
                                <option value="12,50,000 - 15,00,000">12,50,000 - 15,00,000</option>
                                <option value="15,00,000 - 20,00,000">15,00,000 - 20,00,000</option>
                                <option value="20,00,000 - 30,00,000">20,00,000 - 30,00,000</option>
                                <option value="30,00,000 - 40,00,000">30,00,000 - 40,00,000</option>
                                <option value="40,00,000 - 50,00,000">40,00,000 - 50,00,000</option>
                                <option value="50,00,000 - 60,00,000">50,00,000 - 60,00,000</option>
                                <option value="60,00,000 - 70,00,000">60,00,000 - 70,00,000</option>
                                <option value="70,00,000 - 80,00,000">70,00,000 - 80,00,000</option>
                                <option value="80,00,000 - 90,00,000">80,00,000 - 90,00,000</option>
                                <option value="90,00,000 - 1,00,00,000">90,00,000 - 1,00,00,000</option>
                                <option value="1,00,00,000 - 1,25,00,000">1,00,00,000 - 1,25,00,000</option>
                                <option value="1,25,00,000 - 1,50,00,000">1,25,00,000 - 1,50,00,000</option>
                                <option value="1,50,00,000 - 2,00,00,000">1,50,00,000 - 2,00,00,000</option>
                                <option value="More than 2,00,00,000">More than 2,00,00,000</option>
                                <option value="More than 10,00,00,000">More than 10,00,00,000</option>
                                <option value="Not Applicable">Not Applicable</option>
                              </select>
                        ) : (
                          familyincome
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      )}

      <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickcontact}/>
          {editModecontact && (
            <button onClick={handleSaveChangescontact}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={contact} alt="Partner" />
          <h1>Contact Details</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">E-mail:</div>
                    <div className="partner-value-div">
                    {editModecontact ? (
                        <input  className="partner-value-div"
                            type="email"
                            value={emailid}
                            onChange={(e) => setEmailid(e.target.value)}
                          />
                        ) : (
                          emailid
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Phone:</div>
                    <div className="partner-value-div">
                    {editModecontact ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        ) : (
                          mobile
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Address:</div>
                    <div className="partner-value-div">
                    {editModecontact ? (
                        <input  className="partner-value-div"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        ) : (
                          address
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickkundali}/>
          {editModekundali && (
            <button onClick={handleSaveChangeskundali}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={Kundli} alt="Partner" />
          <h1>Kundali & Astro</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Manglik Status:</div>
                    <div className="partner-value-div">
                    {editModekundali ? (
                          <select
                                className="partner-value-div"
                                value={manglikstatus}
                                onChange={(e) => {
                                  setManglikstatus(e.target.value);
                                }}
                              >
                                <option value={manglikstatus}>{manglikstatus}</option>
                                <option value="Yes">Manglik</option>
                                <option value="No">Non-manglik</option>
                              </select>
                        ) : (
                          manglikstatus
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Birth Place:</div>
                    <div className="partner-value-div">
                    {editModekundali ? (
                        <input  className="partner-value-div"
                            type="text"
                            value={birthlocation}
                            onChange={(e) => setBirthlocation(e.target.value)}
                          />
                        ) : (
                          birthlocation
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Time of birth:</div>
                    <div className="partner-value-div">
                    {editModekundali ? (
                        <input  className="partner-value-div"
                            type="text"
                            value={birthtime}
                            onChange={(e) => setBirthtime(e.target.value)}
                          />
                        ) : (
                          birthtime
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClicklifestyle}/>
          {editModelifestyle && (
            <button onClick={handleSaveChangeslifestyle}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={lifestyle} alt="Partner" />
          <h1>Lifestyle</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 2 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Food style:</div>
                    <div className="partner-value-div">
                    {editModelifestyle ? (
                          <select
                                className="partner-value-div"
                                value={foodstyle}
                                onChange={(e) => {
                                  setFoodstyle(e.target.value);
                                }}
                              >
                                <option value={foodstyle}>{foodstyle}</option>
                                <option value="Veg">Veg</option>
                                <option value="Pure-Veg">Pure-Veg</option>
                                <option value="Egg">Egg</option>
                                <option value="Jain">Jain</option>
                                <option value="Vegan">Vegan</option>
                              </select>
                        ) : (
                          foodstyle
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Drinking Habit:</div>
                    <div className="partner-value-div">
                    {editModelifestyle ? (
                          <select
                                className="partner-value-div"
                                value={drinkinghabit}
                                onChange={(e) => {
                                  setDrinkinghabit(e.target.value);
                                }}
                              >
                                <option value={drinkinghabit}>{drinkinghabit}</option>
                                <option value="Non-Drinker">Non-Drinker</option>
                                <option value="Social Drinker">Social Drinker</option>
                                <option value="Regular Drinker">Regular Drinker</option>
                                <option value="Occasional Drinker">Occasional Drinker</option>
                                <option value="Heavy Drinker">Heavy Drinker</option>
                                <option value="Never Tried">Never Tried</option>
                                <option value="Quit Drinking">Quit Drinking</option>
                              </select>
                        ) : (
                          drinkinghabit
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="partner-grid-container2">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 1 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Hobbies:</div>
                    <div className="partner-value-div hobbies">
                    {editModelifestyle ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={hobbiesOptions}
                          value={hobbiesOptions.filter((option) => hobbies1.includes(option.value))}
                          onChange={handleHobbiesChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof hobbies1 === 'string' && hobbies1.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < hobbies1.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      
        {userInfo?.partner_pref && (
          <div className="partner">
      <img style={{margin: '10px 0', float: 'right', cursor: 'pointer'}} src={edit} alt="edit" onClick={handleEditClickpartner}/>
          {editModepartnerpref && (
            <button onClick={handleSaveChangepartner}
            style={{margin: '10px 10px', float: 'right', cursor: 'pointer', padding: '5px 10px', border: 'none', background: '#FF8A00', color: 'white', fontSize: '15px', fontWeight: '600', borderRadius: '5px'}}
            >
              Save Changes
            </button>
          )}
        <div className="partner-header">
          <img src={partner} alt="Partner" />
          <h1>Partner preferences</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 22 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Age:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnerminage}
                            onChange={(e) => setPartnerminage(e.target.value)}
                          />
                        ) : (
                          partnerminage
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Age:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnermaxage}
                            onChange={(e) => setPartnermaxage(e.target.value)}
                          />
                        ) : (
                          partnermaxage
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Weight:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnerminweight}
                            onChange={(e) => setPartnerminweight(e.target.value)}
                          />
                        ) : (
                          partnerminweight
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Weight:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnermaxweight}
                            onChange={(e) => setPartnermaxweight(e.target.value)}
                          />
                        ) : (
                          partnermaxweight
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 4 && (
                <>
                  {/* <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Height:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnerminheight}
                            onChange={(e) => setPartnerminheight(e.target.value)}
                          />
                        ) : (
                          partnerminheight
                        )}
                    </div>
                  </div> */}

                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Height:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnerminheight}
                                onChange={(e) => {
                                  setPartnerminheight(e.target.value);
                                }}
                              >
                                <option value={partnerminheight}>{partnerminheight}</option>
                                <option value="48">4 feet (48 inches)</option>
                                <option value="49">4 feet 1 inch (49 inches)</option>
                                <option value="50">4 feet 2 inches (50 inches)</option>
                                <option value="51">4 feet 3 inches (51 inches)</option>
                                <option value="52">4 feet 4 inches (52 inches)</option>
                                <option value="53">4 feet 5 inches (53 inches)</option>
                                <option value="54">4 feet 6 inches (54 inches)</option>
                                <option value="55">4 feet 7 inches (55 inches)</option>
                                <option value="56">4 feet 8 inches (56 inches)</option>
                                <option value="57">4 feet 9 inches (57 inches)</option>
                                <option value="58">4 feet 10 inches (58 inches)</option>
                                <option value="59">4 feet 11 inches (59 inches)</option>
                                <option value="60">5 feet (60 inches)</option>
                                <option value="61">5 feet 1 inch (61 inches)</option>
                                <option value="62">5 feet 2 inches (62 inches)</option>
                                <option value="63">5 feet 3 inches (63 inches)</option>
                                <option value="64">5 feet 4 inches (64 inches)</option>
                                <option value="65">5 feet 5 inches (65 inches)</option>
                                <option value="66">5 feet 6 inches (66 inches)</option>
                                <option value="67">5 feet 7 inches (67 inches)</option>
                                <option value="68">5 feet 8 inches (68 inches)</option>
                                <option value="69">5 feet 9 inches (69 inches)</option>
                                <option value="70">5 feet 10 inches (70 inches)</option>
                                <option value="71">5 feet 11 inches (71 inches)</option>
                                <option value="72">6 feet (72 inches)</option>
                                <option value="73">6 feet 1 inch (73 inches)</option>
                                <option value="74">6 feet 2 inches (74 inches)</option>
                                <option value="75">6 feet 3 inches (75 inches)</option>
                                <option value="76">6 feet 4 inches (76 inches)</option>
                                <option value="77">6 feet 5 inches (77 inches)</option>
                                <option value="78">6 feet 6 inches (78 inches)</option>
                                <option value="79">6 feet 7 inches (79 inches)</option>
                                <option value="80">6 feet 8 inches (80 inches)</option>
                                <option value="81">6 feet 9 inches (81 inches)</option>
                                <option value="82">6 feet 10 inches (82 inches)</option>
                                <option value="83">6 feet 11 inches (83 inches)</option>
                                <option value="84">7 feet (84 inches)</option>
                                <option value="85">7 feet 1 inch (85 inches)</option>
                                <option value="86">7 feet 2 inches (86 inches)</option>
                                <option value="87">7 feet 3 inches (87 inches)</option>
                                <option value="88">7 feet 4 inches (88 inches)</option>
                                <option value="89">7 feet 5 inches (89 inches)</option>
                                <option value="above 89">Above 7.5 feet</option>
                              </select>
                        ) : (
                          partnerminheight
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 5 && (
                <>
                  {/* <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Height:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnermaxheight}
                            onChange={(e) => setPartnermaxheight(e.target.value)}
                          />
                        ) : (
                          partnermaxheight
                        )}
                    </div>
                  </div> */}
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Height:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnermaxheight}
                                onChange={(e) => {
                                  setPartnermaxheight(e.target.value);
                                }}
                              >
                                <option value={partnermaxheight}>{partnermaxheight}</option>
                                <option value="48">4 feet (48 inches)</option>
                                <option value="49">4 feet 1 inch (49 inches)</option>
                                <option value="50">4 feet 2 inches (50 inches)</option>
                                <option value="51">4 feet 3 inches (51 inches)</option>
                                <option value="52">4 feet 4 inches (52 inches)</option>
                                <option value="53">4 feet 5 inches (53 inches)</option>
                                <option value="54">4 feet 6 inches (54 inches)</option>
                                <option value="55">4 feet 7 inches (55 inches)</option>
                                <option value="56">4 feet 8 inches (56 inches)</option>
                                <option value="57">4 feet 9 inches (57 inches)</option>
                                <option value="58">4 feet 10 inches (58 inches)</option>
                                <option value="59">4 feet 11 inches (59 inches)</option>
                                <option value="60">5 feet (60 inches)</option>
                                <option value="61">5 feet 1 inch (61 inches)</option>
                                <option value="62">5 feet 2 inches (62 inches)</option>
                                <option value="63">5 feet 3 inches (63 inches)</option>
                                <option value="64">5 feet 4 inches (64 inches)</option>
                                <option value="65">5 feet 5 inches (65 inches)</option>
                                <option value="66">5 feet 6 inches (66 inches)</option>
                                <option value="67">5 feet 7 inches (67 inches)</option>
                                <option value="68">5 feet 8 inches (68 inches)</option>
                                <option value="69">5 feet 9 inches (69 inches)</option>
                                <option value="70">5 feet 10 inches (70 inches)</option>
                                <option value="71">5 feet 11 inches (71 inches)</option>
                                <option value="72">6 feet (72 inches)</option>
                                <option value="73">6 feet 1 inch (73 inches)</option>
                                <option value="74">6 feet 2 inches (74 inches)</option>
                                <option value="75">6 feet 3 inches (75 inches)</option>
                                <option value="76">6 feet 4 inches (76 inches)</option>
                                <option value="77">6 feet 5 inches (77 inches)</option>
                                <option value="78">6 feet 6 inches (78 inches)</option>
                                <option value="79">6 feet 7 inches (79 inches)</option>
                                <option value="80">6 feet 8 inches (80 inches)</option>
                                <option value="81">6 feet 9 inches (81 inches)</option>
                                <option value="82">6 feet 10 inches (82 inches)</option>
                                <option value="83">6 feet 11 inches (83 inches)</option>
                                <option value="84">7 feet (84 inches)</option>
                                <option value="85">7 feet 1 inch (85 inches)</option>
                                <option value="86">7 feet 2 inches (86 inches)</option>
                                <option value="87">7 feet 3 inches (87 inches)</option>
                                <option value="88">7 feet 4 inches (88 inches)</option>
                                <option value="89">7 feet 5 inches (89 inches)</option>
                                <option value="above 89">Above 7.5 feet</option>
                              </select>
                        ) : (
                          partnermaxheight
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 6 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Country:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={countryOptions}
                          value={countryOptions.filter((option) => partnercountry.includes(option.value))}
                          onChange={handlepartnerCountryChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnercountry === 'string' && partnercountry.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnercountry.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 7 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Caste:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={casteOptions}
                          value={casteOptions.filter((option) => partnercaste.includes(option.value))}
                          onChange={handlepartnerCasteChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnercaste === 'string' && partnercaste.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnercaste.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 8 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Sub-Caste:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={subcasteOptions}
                          value={subcasteOptions.filter((option) => partnersubcaste.includes(option.value))}
                          onChange={handlepartnerSubcasteChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnersubcaste === 'string' && partnersubcaste.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnersubcaste.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 9 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Mother Tongue:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={mothertongueOptions}
                          value={mothertongueOptions.filter((option) => partnermothertongue.includes(option.value))}
                          onChange={handlepartnermothertongueChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnermothertongue === 'string' && partnermothertongue.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnermothertongue.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 10 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Marital Status:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={maritalstatusOptions}
                          value={maritalstatusOptions.filter((option) => partnermarital.includes(option.value))}
                          onChange={handlepartnermaritalstatusChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnermarital === 'string' && partnermarital.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnermarital.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 11 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Face Color:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={facecolorOptions}
                          value={facecolorOptions.filter((option) => partnerfacecolor.includes(option.value))}
                          onChange={handlepartnerfacecolorChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnerfacecolor === 'string' && partnerfacecolor.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerfacecolor.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 12 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Working Status:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={workingstatusOptions}
                          value={workingstatusOptions.filter((option) => partnerworking.includes(option.value))}
                          onChange={handlepartnerworkingstatusChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnerworking === 'string' && partnerworking.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerworking.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              
              {index === 13 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Drinking Habit:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnerdrinking}
                                onChange={(e) => {
                                  setPartnerdrinking(e.target.value);
                                }}
                              >
                                <option value={partnerdrinking}>{partnerdrinking}</option>
                                <option value="Non-Drinker">Non-Drinker</option>
                                <option value="Social Drinker">Social Drinker</option>
                                <option value="Regular Drinker">Regular Drinker</option>
                                <option value="Occasional Drinker">Occasional Drinker</option>
                                <option value="Heavy Drinker">Heavy Drinker</option>
                                <option value="Never Tried">Never Tried</option>
                                <option value="Quit Drinking">Quit Drinking</option>
                              </select>
                        ) : (
                          partnerdrinking
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 14 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Education:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={educationOptions}
                          value={educationOptions.filter((option) => partnereducation.includes(option.value))}
                          onChange={handlepartnereducationChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnereducation === 'string' && partnereducation.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnereducation.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 15 && (
                <>
                  {/* <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Income:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnerminincome}
                            onChange={(e) => setPartnerminincome(e.target.value)}
                          />
                        ) : (
                          partnerminincome
                        )}
                    </div>
                  </div> */}
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Income:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnerminincome}
                                onChange={(e) => {
                                  setPartnerminincome(e.target.value);
                                }}
                              >
                                <option value={partnerminincome}>{partnerminincome}</option>
                                <option value="0">0</option>
                                <option value="100000">1,00,000</option>
                                <option value="500000">5,00,000</option>
                                <option value="1000000">10,00,000</option>
                                <option value="1500000">15,00,000</option>
                                <option value="2000000">20,00,000</option>
                                <option value="2500000">25,00,000</option>
                                <option value="3000000">30,00,000</option>
                                <option value="3500000">35,00,000</option>
                                <option value="4000000">40,00,000</option>
                                <option value="45000000">45,00,000</option>
                                <option value="5000000">50,00,000</option>
                                <option value="5500000">55,00,000</option>
                                <option value="6000000">60,00,000</option>
                                <option value="6500000">65,00,000</option>
                                <option value="7000000">70,00,000</option>
                                <option value="7500000">75,00,000</option>
                                <option value="8000000">80,00,000</option>
                                <option value="8500000">85,00,000</option>
                                <option value="9000000">90,00,000</option>
                                <option value="9500000">95,00,000</option>
                                <option value="10000000">100,00,000</option>
                                <option value="100000000">10,00,00,000</option>
                              </select>
                        ) : (
                          partnerminincome
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 16 && (
                <>
                  {/* <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Income:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                        <input  className="partner-value-div"
                            type="number"
                            value={partnermaxincome}
                            onChange={(e) => setPartnermaxincome(e.target.value)}
                          />
                        ) : (
                          partnermaxincome
                        )}
                    </div>
                  </div> */}
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Income:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnermaxincome}
                                onChange={(e) => {
                                  setPartnermaxincome(e.target.value);
                                }}
                              >
                                <option value={partnermaxincome}>{partnermaxincome}</option>
                                <option value="0">0</option>
                                <option value="100000">1,00,000</option>
                                <option value="500000">5,00,000</option>
                                <option value="1000000">10,00,000</option>
                                <option value="1500000">15,00,000</option>
                                <option value="2000000">20,00,000</option>
                                <option value="2500000">25,00,000</option>
                                <option value="3000000">30,00,000</option>
                                <option value="3500000">35,00,000</option>
                                <option value="4000000">40,00,000</option>
                                <option value="45000000">45,00,000</option>
                                <option value="5000000">50,00,000</option>
                                <option value="5500000">55,00,000</option>
                                <option value="6000000">60,00,000</option>
                                <option value="6500000">65,00,000</option>
                                <option value="7000000">70,00,000</option>
                                <option value="7500000">75,00,000</option>
                                <option value="8000000">80,00,000</option>
                                <option value="8500000">85,00,000</option>
                                <option value="9000000">90,00,000</option>
                                <option value="9500000">95,00,000</option>
                                <option value="10000000">100,00,000</option>
                                <option value="100000000">10,00,00,000</option>
                                <option value="1000000000">10,00,000,000</option>
                              </select>
                        ) : (
                          partnermaxincome
                        )}
                    </div>
                  </div>
                </>
              )}
              {index === 17 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Photo Visible Status:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnerphoto}
                                onChange={(e) => {
                                  setPartnerphoto(e.target.value);
                                }}
                              >
                                <option value={partnerphoto}>{partnerphoto}</option>
                                <option value="Visible">Visible</option>
                                <option value="Not-visible">Not-visible</option>
                                
                              </select>
                        ) : (
                          partnerphoto
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 18 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Verified profile:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                          <select
                                className="partner-value-div"
                                value={partnerverifiedprofile}
                                onChange={(e) => {
                                  setPartnerverifiedprofile(e.target.value);
                                }}
                              >
                                <option value={partnerverifiedprofile}>{partnerverifiedprofile}</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                
                              </select>
                        ) : (
                          partnerverifiedprofile
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 19 && (
                <>
                <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Cities:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={CityOptions}
                          value={CityOptions.filter((option) => partnercity.includes(option.value))}
                          onChange={handlepartnercityChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnercity === 'string' && partnercity.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnercity.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 20 && (
                <>
                <div style={{ display: "flex" }}>
                    <div className="partner-title-div">States:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={stateOptions}
                          value={stateOptions.filter((option) => partnerstate.includes(option.value))}
                          onChange={handlepartnerstateChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnerstate === 'string' && partnerstate.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerstate.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 21 && (
                <div style={{ display: "flex" }}>
                <div className="partner-title-div">Gotra:</div>
                    <div className="partner-value-div">
                    {editModepartnerpref ? (
                            <div>
                              <select
                                className={inputClassName}
                                value={partnergotra}
                                onChange={(e) => {
                                  setPartnergotra(e.target.value);
                                  // Reset otherMotherTongue when a different option is selected
                                  if (e.target.value !== "Other (Specify)") {
                                    setOtherpartnergotra("");
                                  }
                                }}
                              >
                                <option value={partnergotra}>{partnergotra}</option>
                                <option value="Bharadwaj">Bharadwaj</option>
                                <option value="Kashyap">Kashyap</option>
                                <option value="Vatsa">Vatsa</option>
                                <option value="Gautam">Gautam</option>
                                <option value="Shandilya">Shandilya</option>
                                <option value="Atri">Atri</option>
                                <option value="Vasistha">Vasistha</option>
                                <option value="Kaushik">Kaushik</option>
                                <option value="Garg">Garg</option>
                                <option value="Parashara">Parashara</option>
                                <option value="Sandilya">Sandilya</option>
                                <option value="Vashishtha">Vashishtha</option>
                                <option value="Jamadagni">Jamadagni</option>
                                <option value="Angirasa">Angirasa</option>
                                <option value="Kaundinya">Kaundinya</option>
                                <option value="Suryavanshi">Suryavanshi</option>
                                <option value="Chandravanshi">Chandravanshi</option>
                                <option value="Agnivanshi">Agnivanshi</option>
                                <option value="Nagvanshi">Nagvanshi</option>
                                <option value="Kachwaha">Kachwaha</option>
                                <option value="Rathore">Rathore</option>
                                <option value="Tomar">Tomar</option>
                                <option value="Solanki">Solanki</option>
                                <option value="Chauhan">Chauhan</option>
                                <option value="Parmar">Parmar</option>
                                <option value="Gohil">Gohil</option>
                                <option value="Pratihar">Pratihar</option>
                                <option value="Parihar">Parihar</option>
                                <option value="Rajput">Rajput</option>
                                <option value="Rajawat">Rajawat</option>
                                <option value="Agarwal">Agarwal</option>
                                <option value="Garg">Garg</option>
                                <option value="Bansal">Bansal</option>
                                <option value="Mittal">Mittal</option>
                                <option value="Singhal">Singhal</option>
                                <option value="Kansal">Kansal</option>
                                <option value="Mangal">Mangal</option>
                                <option value="Madhukul">Madhukul</option>
                                <option value="Goyal">Goyal</option>
                                <option value="Jindal">Jindal</option>
                                <option value="Tayal">Tayal</option>
                                <option value="Kuchhal">Kuchhal</option>
                                <option value="Goyal">Goyal</option>
                                <option value="Mangal">Mangal</option>
                                <option value="Khandelwal">Khandelwal</option>
                                <option value="Gupta">Gupta</option>
                                <option value="Maheshwari">Maheshwari</option>
                                <option value="Oswal">Oswal</option>
                                <option value="Porwal">Porwal</option>
                                <option value="Other (Specify)">Other (Specify)</option>
                              </select>
                              {partnergotra === "Other (Specify)" && (
                                <div style={{ marginTop: '10px' }}>
                                  <input
                                    type="text"
                                    className={inputClassName}
                                    placeholder="Specify"
                                    value={otherpartnergotra}
                                    onChange={(e) => setOtherpartnergotra(e.target.value)}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            partnergotra
                          )}
                    </div>
                </div>

              )}

              
             
            </div>
          ))}
        </div>

        <div className="partner-grid-container2">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 2 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Hobbies:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={hobbiesOptions}
                          value={hobbiesOptions.filter((option) => partnerhobbies.includes(option.value))}
                          onChange={handlepartnerHobbiesChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnerhobbies === 'string' && partnerhobbies.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerhobbies.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}

              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Food Style:</div>
                    <div className="partner-value-div hobbies">
                    {editModepartnerpref ? (
                      <Select 
                          className="partner-value-div hobbies" 
                          isMulti
                          options={foodstyleOptions}
                          value={foodstyleOptions.filter((option) => partnerfoodstyle.includes(option.value))}
                          onChange={handleFoodstyleChange}
                          
                        />
                        ) : (
                          <div
                          
                        >
                          {typeof partnerfoodstyle === 'string' && partnerfoodstyle.split(',').map((word, index) => (
                            <span style={{background: '#999999', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerfoodstyle.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        
      </div>
        )}
      
          </>
        )}
      

      <Aboutdownload />
      <Footer />
    </div>
  );
};

export default Myprofile;
