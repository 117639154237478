import React from "react";
import "./multistep-bar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = ({ page, onPageNumberClick }) => {
  var stepPercentage = 0;
  if (page === "pageone") {
    stepPercentage = 16;
  } else if (page === "pagetwo") {
    stepPercentage = 37;
  } else if (page === "pagethree") {
    stepPercentage = 53;
  } else if (page === "pagefour") {
    stepPercentage = 69;
  } else if (page === "pagefive") {
    stepPercentage = 85;
  } else if (page === "pagesix") {
    stepPercentage = 100;
  }
  else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep1 ${accomplished ? "accomplished" : null}`}
            onClick={() => onPageNumberClick("1")}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep1 ${accomplished ? "accomplished" : null}`}
            onClick={() => onPageNumberClick("2")}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          
          <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
            <div onClick={() => onPageNumberClick("3")}
            >
              {index + 1}
            </div>
            
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          
          <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
            <div onClick={() => onPageNumberClick("4")}
            >
              {index + 1}
            </div>
           
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          
          <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
            <div onClick={() => onPageNumberClick("5")}
            >
              {index + 1}
            </div>
           
          </div>
        )}
      </Step>
      <Step >
        {({ accomplished, index }) => (
          <div  
            className={`indexedStep1 ${accomplished ? "accomplished" : null}`}
            onClick={() => onPageNumberClick("6")}
          >
            <i className="fa fa-heart" ></i>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;