import { useEffect, useState } from "react";

const usePersonalitymeter = (id) => {
    const userId = sessionStorage.getItem("userId");
  const [personalityInfo, setPersonalityInfo] = useState(null);

  useEffect(() => {
    fetchData(userId);
  }, [userId]);

  const fetchData = async (userId) => {
    try {
      const data = await fetch(`/prod/api_code/cal_meter.php?user_id=${userId}&friend_id=${id}`, {
        method: 'GET', // Specify the HTTP method (GET in this case)
        headers: {
          Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
          'Content-Type': 'application/json', // Adjust the content type if needed
          
          // Add any other headers you need here
        },
       
      });
      const json = await data.json();
      setPersonalityInfo(json);
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
      // Handle the error (show an error message, etc.)
    }
  };

  return personalityInfo;
};

export default usePersonalitymeter;