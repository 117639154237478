import React from "react";
import tachyons from "tachyons";
import step1 from "./images/step1.jpeg";
import step2 from "./images/step2.png";
import step3 from "./images/step3.png";


export const Threesteps = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="section-title">
          <h2
            className="text-center"
            style={{
              color: "#727272",
              fontSize: "38px",
              fontWeight: "575",
              textTransform: "none",
              fontFamily: "Lemon",
            }}
          >
            Find your life partner in 3 simple steps
          </h2>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            {" "}
              <img style={{height: '230px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', border: '1px solid #727272', padding: '10px 100px', borderRadius: '20px'}} src={step1} alt="img" />
            <div className="service-desc">
              <h3 style={{fontSize: '24px', fontWeight: '700', color: '#878787', marginBottom: '-10px'}}>1.</h3>
              <h3 style={{fontSize: '30px', fontWeight: '700', color: '#01526E'}}>Register & Sign Up</h3>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
              <img style={{  border: '1px solid #727272', padding: '10px 60px', height: '230px',borderRadius: '20px' }} src={step2} alt="img" />
            <div className="service-desc">
              <h3 style={{fontSize: '24px', fontWeight: '700', color: '#878787', marginBottom: '-10px'}}>2.</h3>
              <h3 style={{fontSize: '30px', fontWeight: '700', color: '#01526E'}}>Browse & Connect</h3>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
              <img style={{height: '230px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', border: '1px solid #727272', padding: '10px 60px', borderRadius: '20px'}} src={step3} alt="img" />
            <div className="service-desc">
              <h3 style={{fontSize: '24px', fontWeight: '700', color: '#878787', marginBottom: '-10px'}}>3.</h3>
              <h3 style={{fontSize: '30px', fontWeight: '700', color: '#01526E'}}>Search & Interact</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
