import React, {useEffect} from "react";
import { Termsnavigation } from "./Termsnavigation";
import Footer from "./Footer";

const Refundpolicy = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Termsnavigation />

      <div
        style={{
          marginTop: "80px",
          padding: "20px 50px",
          background:
            "linear-gradient(180deg, hsla(45, 100%, 59%, 1) 0%, hsla(48, 63%, 80%, 1) 100%)",
          paddingBottom: "250px",
        }}
      >
        <h3
          style={{
            fontSize: "35px",
            fontWeight: "700",
            textAlign: "center",
            color: "white",
            paddingBottom: "30px",
            fontFamily: "Lemon",
          }}
        >
          Cancellation & Refund Policy
        </h3>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          RADHA KRISHNA TECHNOLOGY SOLUTIONS, the parent firm of VivahSahyog.com being an IT service provider follows a strict policy on refund and cancellation which allows such request only in special cases.
        </p>

        <ul
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <li>
            Refund  and Cancellation Policy
            <br></br>
            Due to nature of business “NO REFUND”,“NO CANCELLATION”  will be entertained once the Payment has been made.
          </li>
          <br/>
          <li>
            Cancellation Policy(In Special cases)
            <br></br>
            Please note membership purchase can only be canceled within 5 mins of the purchase. With due reason cancellation request need to be mailed to
          </li>
          <br/>

          <li>
          payment@vivahsahyog.com to reverse the transaction. Only in genuine cases like technical issues and fradulent transactions this request will be entertained.
          </li>
          <br/>

          <li>
          Else in no condition cancellation request will be entertained.
          </li>
          <br/>
        </ul>

        <hr></hr>
      </div>
      <Footer/>
    </div>
  );
};

export default Refundpolicy;
