import React, { useState } from 'react';
import './search.css'; // Import your custom CSS file
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";


const Idsearch = () => {
  const navigate = useNavigate();

  const [idnumber, setIdnumber] = useState('');
  const [fullname4, setFullname4] = useState('');

  const [fullname4Error, setFullname4Error] = useState("");
  const [idnumberError, setIdnumberError] = useState("");

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleSearch = async () => {
    if (
      fullname4.trim() !== "" || 
      idnumber.trim() !== ""
    ){
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "fullname": capitalizeFirstLetter(fullname4),
            "special_id" : idnumber,
            
            idsearch: true,
          }),
        });
  
        const data = await response.json();
        if (data !== null) {
          navigate('/idsearchresultnavbar', { state: { responseData: data } });
        }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    }
    else {
      setFullname4Error(fullname4.trim() === "" ? "Please enter fullname" : "");
      setIdnumberError(idnumber.trim() === "" ? "Please enter Id" : "");

    }
  }



  return (
    <>
    <div className='advance-search'>
    <div className="custom-row">
      <div className="custom-col">
        <div className="form-group">
          <label htmlFor="idnumber">ID Number</label>
          <input
            className="form-control"
            id="idnumber"
            value={idnumber}
            onChange={(e) => setIdnumber(e.target.value)}
          /> 
        </div>
        <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
          {idnumberError}
        </div>
      </div>

      <div className="custom-col">
        <div className="form-group">
          <label htmlFor="fullname4">Full Name *</label>
          <input
            className="form-control"
            id="fullname4"
            value={fullname4}
            onChange={(e) => setFullname4(e.target.value)}
          /> 
          
        </div>
        <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
          {fullname4Error}
        </div>
      </div>
    </div>


    </div>

    <button className='advance-search-btn' style={{marginBottom: '50px'}} onClick={handleSearch}>Search</button>

    <Aboutdownload />
    <Footer/>

  </>
  )
}

export default Idsearch
