import React, {useState} from "react";
import Select from "react-select";
import './RegisterForm.css'
// import tick from "./tick.jpg";
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from './images/search_2.png'
import img8 from './images/image_1.png'

const PageFour = ({onButtonClick}) => {

  const [education, setEducation] = useState("");
  const [otherEducation, setOtherEducation] = useState("");
  const [other_education, setOther_education] = useState("");
  const [otherOther_education, setOtherOther_education] = useState("");
  const [college, setCollege] = useState("");
  const [otherCollege, setOtherCollege] = useState("");
  const [hobbies, setHobbies] = useState([]);
  const [food_style, setFood_style] = useState("");
  const [drinking_habit, setDrinking_habit] = useState("");


  const [educationError, setEducationError] = useState("");
  const [otherEducationError, setOtherEducationError] = useState("");
  const [other_educationError, setOther_educationError] = useState("");
  const [otherOther_educationError, setOtherOther_educationError] = useState("");
  const [collegeError, setCollegeError] = useState("");
  const [otherCollegeError, setOtherCollegeError] = useState("");
  const [hobbiesError, setHobbiesError] = useState("");
  const [food_styleError, setFood_styleError] = useState("");
  const [drinking_habitError, setDrinking_habitError] = useState("");


  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };
  


  const handleCreateWorkspace = () => {
    if (
      education.trim() !== "" &&
      other_education.trim() !== "" &&
      college.trim() !== "" &&
      hobbies.length !== 0 &&
      // hobbies.length !== 0 &&
      food_style.trim() !== "" &&
      drinking_habit.trim() !== "" &&
      ((education === "Other (Specify)" && otherEducation.trim() !== "") ||
    education !== "Other (Specify)") &&
    ((other_education === "Other (Specify)" && otherOther_education.trim() !== "") ||
    other_education !== "Other (Specify)") &&
    ((college === "Other (Specify)" && otherCollege.trim() !== "") ||
    college !== "Other (Specify)") 
    ) {
      onButtonClick("pagefive", 
      education === "Other (Specify)" ? capitalizeFirstLetter(otherEducation) : education, 
      other_education === "Other (Specify)" ? capitalizeFirstLetter(otherOther_education) : other_education, 
      college === "Other (Specify)" ? capitalizeFirstLetter(otherCollege) : college, 
      hobbies.join(', '), 
      food_style, 
      drinking_habit);
      
      // console.log(hobbies.join(','))
    } else {
      setEducationError(
        education.trim() === ""
      ? "Please fill in the education"
      : "");
      setOther_educationError(other_education.trim() === "" ? "Please fill in the other education" : "");

      setCollegeError(
        college.trim() === "" ? "Please select the college" : ""
      );

      setOtherEducationError(
        education === "Other (Specify)" && otherOther_education.trim() === ""
          ? "Please specify highest education"
          : ""
      );
      setOtherOther_educationError(
        other_education === "Other (Specify)" && otherOther_education.trim() === ""
          ? "Please specify other education"
          : ""
      );
      setOtherCollegeError(
        college === "Other (Specify)" && otherCollege.trim() === ""
          ? "Please specify college"
          : ""
      );

      setHobbiesError(hobbies.length === 0  ? "Please fill in the hobbies" : "");
      setFood_styleError(
        food_style.trim() === "" ? "Please select the food style" : ""
      );
      setDrinking_habitError(drinking_habit.trim() === "" ? "Please select the drinking habit" : "");
    }
  };


  const hobbiesOptions = [
    { value: "Reading", label: "Reading" },
    { value: "Traveling", label: "Traveling" },
    { value: "Gardening", label: "Gardening" },
    { value: "Cooking", label: "Cooking" },
    { value: "Dancing", label: "Dancing" },
    { value: "Singing", label: "Singing" },
    { value: "Hiking", label: "Hiking" },
    { value: "Crafts", label: "Crafts" },
    { value: "Writing", label: "Writing" },
    { value: "Fitness", label: "fitness" },
    { value: "Cinema", label: "Cinema" },
    { value: "Gaming", label: "Gaming" },
    { value: "Yoga", label: "Yoga" },
    { value: "Painting", label: "Painting" },
    { value: "Photography", label: "Photography" },
    // ... (other options)
  ];

  const handleHobbiesChange = (selectedOptions) => {
    // Extract and store only the values in the selectedHobbies state
    const selectedValues = selectedOptions.map((option) => option.value);
    setHobbies(selectedValues);
  };

  return (
    <main
      className="black-80 center form-main-register"
      style={{  maxHeight: "30%", margin: "auto" }}
    >

             <div className="desktop-step" style={{ display: "flex", flexDirection: "column", }}>
                <div id="#form-step">
                  <i
                    style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                    className=" step fa"
                  >
                    <img src={img1} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Easy Registeration</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa"
                  style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Chat With Partner</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Video Calls</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img7} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Search Profiles Conveniently</h3>
                  </div>
                </div>
              </div>

              <div className="form-desktop-step">
      <form className="measure measure1" style={{ margin: "auto", padding: "20px",
                            background: "#FF4242",
                            borderRadius: "20px" }}>
                    <h4 style={{color: 'white'}}>Education</h4>
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
          
          
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="education"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Highest Education *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="education"
                id="education"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: educationError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={education}
                onChange={(e) => {
                  setEducation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherEducation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="12th Pass">12th Pass</option>
              <option value="Diploma">Diploma</option>
              <option value="Certification">Certification</option>
              <option value="Associates Degree">Associates Degree</option>
              <option value="Bachelors Degree">Bachelors Degree</option>
              <option value="B.A (Bachelor of Arts)">B.A (Bachelor of Arts)</option>
              <option value="B.Sc (Bachelor of Science)">B.Sc (Bachelor of Science)</option>
              <option value="B.Com (Bachelor of Commerce)">B.Com (Bachelor of Commerce)</option>
              <option value="B.Tech (Bachelor of Technology)">B.Tech (Bachelor of Technology)</option>
              <option value="BBA (Bachelor of Business Administration)">BBA (Bachelor of Business Administration)</option>
              <option value="BCA (Bachelor of Computer Applications)">BCA (Bachelor of Computer Applications)</option>
              <option value="LLB (Bachelor of Laws)">LLB (Bachelor of Laws)</option>
              <option value="MBBS (Bachelor of Medicine, Bachelor of Surgery)">MBBS (Bachelor of Medicine, Bachelor of Surgery)</option>
              <option value="Pharm.D (Doctor of Pharmacy)">Pharm.D (Doctor of Pharmacy)</option>
              <option value="BDS (Bachelor of Dental Surgery)">BDS (Bachelor of Dental Surgery)</option>
              <option value="BAMS (Bachelor of Ayurvedic Medicine and Surgery)">BAMS (Bachelor of Ayurvedic Medicine and Surgery)</option>
              <option value="BVSc (Bachelor of Veterinary Science)">BVSc (Bachelor of Veterinary Science)</option>
              <option value="BE (Bachelor of Engineering)">BE (Bachelor of Engineering)</option>
              <option value="Masters Degree">Masters Degree</option>
              <option value="M.A (Master of Arts)">M.A (Master of Arts)</option>
              <option value="M.Sc (Master of Science)">M.Sc (Master of Science)</option>
              <option value="M.Com (Master of Commerce)">M.Com (Master of Commerce)</option>
              <option value="M.Tech (Master of Technology)">M.Tech (Master of Technology)</option>
              <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
              <option value="MCA (Master of Computer Applications)">MCA (Master of Computer Applications)</option>
              <option value="MFA (Master of Fine Arts)">MFA (Master of Fine Arts)</option>
              <option value="MSW (Master of Social Work)">MSW (Master of Social Work)</option>
              <option value="LL.M (Master of Laws)">LL.M (Master of Laws)</option>
              <option value="MD (Doctor of Medicine)">MD (Doctor of Medicine)</option>
              <option value="MPharm (Master of Pharmacy)">MPharm (Master of Pharmacy)</option>
              <option value="MDS (Master of Dental Surgery)">MDS (Master of Dental Surgery)</option>
              <option value="MS (Master of Surgery)">MS (Master of Surgery)</option>
              <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
              <option value="M.Phil (Master of Philosophy)">M.Phil (Master of Philosophy)</option>
              <option value="Ph.D. (Doctor of Philosophy)">Ph.D. (Doctor of Philosophy)</option>
              <option value="Other Masters Degree (Specify)">Other Masters Degree (Specify)</option>
              <option value="Doctorate (Post-Doctoral)">Doctorate (Post-Doctoral)</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {education === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherEducation}
                  onChange={(e) => setOtherEducation(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherEducationError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {educationError || otherEducationError}
          </div>


          
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="college"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              College *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph5 pv4 mb4 dib black w-100"
                name="college"
                id="college"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: collegeError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={college}
                onChange={(e) => {
                  setCollege(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCollege("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="IIT Kharagpur">IIT Kharagpur</option>
              <option value="IIT Bombay">IIT Bombay</option>
              <option value="IIT Madras">IIT Madras</option>
              <option value="IIT Kanpur">IIT Kanpur</option>
              <option value="IIT Delhi">IIT Delhi</option>
              <option value="IIT Guwahati">IIT Guwahati</option>
              <option value="IIT Roorkee">IIT Roorkee</option>
              <option value="IIT Ropar">IIT Ropar</option>
              <option value="IIT Bhubaneswar">IIT Bhubaneswar</option>
              <option value="IIT Gandhinagar">IIT Gandhinagar</option>
              <option value="IIT Hyderabad">IIT Hyderabad</option>
              <option value="IIT Jodhpur">IIT Jodhpur</option>
              <option value="IIT Patna">IIT Patna</option>
              <option value="IIT Indore">IIT Indore</option>
              <option value="IIT Mandi">IIT Mandi</option>
              <option value="IIT Varanasi">IIT Varanasi</option>
              <option value="IIT Palakkad">IIT Palakkad</option>
              <option value="IIT Tirupati">IIT Tirupati</option>
              <option value="IIT Dhanbad">IIT Dhanbad</option>
              <option value="IIT Bhilai">IIT Bhilai</option>
              <option value="IIT Dharwad">IIT Dharwad</option>
              <option value="IIT Jammu">IIT Jammu</option>
              <option value="IIT Goa">IIT Goa</option>

              <option value="Birla Institute of Technology and Science - Pilani">Birla Institute of Technology and Science - Pilani</option>
              <option value="Birla Institute of Technology and Science - Pilani, Goa campus">Birla Institute of Technology and Science - Pilani, Goa campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Hyderabad Campus">Birla Institute of Technology and Science - Pilani, Hyderabad Campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Dubai Campus">Birla Institute of Technology and Science - Pilani, Dubai Campus</option>
              <option value="Birla Institute of Technology and Science - Pilani, Pilani Campus">Birla Institute of Technology and Science - Pilani, Pilani Campus</option>

              <option value="IIM Ahmedabad">IIM Ahmedabad</option>
              <option value="IIM Bangalore">IIM Bangalore</option>
              <option value="IIM Calcutta">IIM Calcutta</option>
              <option value="IIM Lucknow">IIM Lucknow</option>
              <option value="IIM Indore">IIM Indore</option>
              <option value="IIM Kozhikode">IIM Kozhikode</option>
              <option value="IIM Raipur">IIM Raipur</option>
              <option value="IIM Ranchi">IIM Ranchi</option>
              <option value="IIM Rohtak">IIM Rohtak</option>
              <option value="IIM Kashipur">IIM Kashipur</option>
              <option value="IIM Tiruchirappalli">IIM Tiruchirappalli</option>
              <option value="IIM Udaipur">IIM Udaipur</option>
              <option value="IIM Amritsar">IIM Amritsar</option>
              <option value="IIM Bodh Gaya">IIM Bodh Gaya</option>
              <option value="IIM Nagpur">IIM Nagpur</option>
              <option value="IIM Sambalpur">IIM Sambalpur</option>
              <option value="IIM Sirmaur">IIM Sirmaur</option>
              <option value="IIM Visakhapatnam">IIM Visakhapatnam</option>
              <option value="IIM Jammu">IIM Jammu</option>
              <option value="IIM Mumbai">IIM Mumbai</option>

              <option value="XLRI Ã¢â‚¬â€œ Xavier School of Management">XLRI Ã¢â‚¬â€œ Xavier School of Management</option>
              <option value="S.P Jain College of Management and Research">S.P Jain College of Management and Research</option>
              <option value="NIT Warangal(NITW)">NIT Warangal(NITW)</option>
              <option value="NIT Bhopal(MANIT)">NIT Bhopal(MANIT)</option>
              <option value="NIT Nagpur(VNIT)">NIT Nagpur(VNIT)</option>
              <option value="NIT Durgapur(NITDGP)">NIT Durgapur(NITDGP)</option>
              <option value="NIT Jamshedpur(NITJSR)">NIT Jamshedpur(NITJSR)</option>
              <option value="NIT Karnataka(NITK)">NIT Karnataka(NITK)</option>
              <option value="NIT Srinagar(NITSRI)">NIT Srinagar(NITSRI)</option>
              <option value="NIT Allahabad(MNNIT)">NIT Allahabad(MNNIT)</option>
              <option value="NIT Surat(SVNIT)">NIT Surat(SVNIT)</option>
              <option value="NIT Calicut(NITC)">NIT Calicut(NITC)</option>
              <option value="NIT Rourkela(NITR)">NIT Rourkela(NITR)</option>
              <option value="NIT Jaipur(MNIT)">NIT Jaipur(MNIT)</option>
              <option value="NIT Kurukshetra(NITKKR)">NIT Kurukshetra(NITKKR)</option>
              <option value="NIT Tiruchirappalli(NITT)">NIT Tiruchirappalli(NITT)</option>
              <option value="NIT Silchar(NITS)">NIT Silchar(NITS)</option>
              <option value="NIT Hamirpur(NITH)">NIT Hamirpur(NITH)</option>
              <option value="NIT Jalandhar(NITJ)">NIT Jalandhar(NITJ)</option>
              <option value="NIT Patna(NITP)">NIT Patna(NITP)</option>
              <option value="NIT Raipur(NITRR)">NIT Raipur(NITRR)</option>
              <option value="NIT Agartala(NITA)">NIT Agartala(NITA)</option>
              <option value="NIT Arunachal Pradesh(NITAP)">NIT Arunachal Pradesh(NITAP)</option>
              <option value="NIT Delhi(NITD)">NIT Delhi(NITD)</option>
              <option value="NIT Goa(NITG)">NIT Goa(NITG)</option>
              <option value="NIT Manipur(NITMN)">NIT Manipur(NITMN)</option>
              <option value="NIT Meghalaya(NITM)">NIT Meghalaya(NITM)</option>
              <option value="NIT Mizoram(NITMZ)">NIT Mizoram(NITMZ)</option>
              <option value="NIT Nagaland(NITN)">NIT Nagaland(NITN)</option>
              <option value="NIT Puducherry(NITPY)">NIT Puducherry(NITPY)</option>
              <option value="NIT Sikkim(NITSKM)">NIT Sikkim(NITSKM)</option>
              <option value="NIT Uttarakhand(NITUK)">NIT Uttarakhand(NITUK)</option>
              <option value="NIT Andhra Pradesh NITANP">NIT Andhra Pradesh NITANP</option>
              <option value="Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)">Atal Bihari Vajpayee Indian Institute of Information Technology and Management(ABV-IIITMG)</option>
              <option value="Indian Institute of Information Technology, Allahabad(IIITA)">Indian Institute of Information Technology, Allahabad(IIITA)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)">Indian Institute of Information Technology, Design and Manufacturing, Jabalpur(IIITDMJ)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)">Indian Institute of Information Technology, Design and Manufacturing, Kancheepuram(IIITDM)</option>
              <option value="Indian Institute of Information Technology, Sri City(IIITSC)">Indian Institute of Information Technology, Sri City(IIITSC)</option>
              <option value="Indian Institute of Information Technology, Guwahati(IIITG)">Indian Institute of Information Technology, Guwahati(IIITG)</option>
              <option value="Indian Institute of Information Technology, Vadodara(IIITV)">Indian Institute of Information Technology, Vadodara(IIITV)</option>
              <option value="Indian Institute of Information Technology, Kota(IIIT Kota)">Indian Institute of Information Technology, Kota(IIIT Kota)</option>
              <option value="Indian Institute of Information Technology, Tiruchirappalli(IIITT)">Indian Institute of Information Technology, Tiruchirappalli(IIITT)</option>
              <option value="Indian Institute of Information Technology, Una(IIITU)">Indian Institute of Information Technology, Una(IIITU)</option>
              <option value="Indian Institute of Information Technology, Sonepat(IIIT Sonepat)">Indian Institute of Information Technology, Sonepat(IIIT Sonepat)</option>
              <option value="Indian Institute of Information Technology, Kalyani(IIIT Kalyani)">Indian Institute of Information Technology, Kalyani(IIIT Kalyani)</option>
              <option value="Indian Institute of Information Technology, Lucknow(IIITL)">Indian Institute of Information Technology, Lucknow(IIITL)</option>
              <option value="Indian Institute of Information Technology, Dharwad(IIITDWD">Indian Institute of Information Technology, Dharwad(IIITDWD)</option>
              <option value="Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)">Indian Institute of Information Technology, Design and Manufacturing, Kurnool(IIITDM Kurnool)</option>
              <option value="Indian Institute of Information Technology, Kottayam(IIIT Kottayam)">Indian Institute of Information Technology, Kottayam(IIIT Kottayam)</option>
              <option value="Indian Institute of Information Technology, Manipur(IIITM)">Indian Institute of Information Technology, Manipur(IIITM)</option>
              <option value="Indian Institute of Information Technology, Nagpur(IIITN)">Indian Institute of Information Technology, Nagpur(IIITN)</option>
              <option value="Indian Institute of Information Technology, Pune(IIITP)">Indian Institute of Information Technology, Pune(IIITP)</option>
              <option value="Indian Institute of Information Technology, Ranchi(IIIT Ranchi)">Indian Institute of Information Technology, Ranchi(IIIT Ranchi)</option>
              <option value="Indian Institute of Information Technology, Surat(IIIT Surat)">Indian Institute of Information Technology, Surat(IIIT Surat)</option>
              <option value="Indian Institute of Information Technology, Bhopal(IIIT Bhopal)">Indian Institute of Information Technology, Bhopal(IIIT Bhopal)</option>
              <option value="Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)">Indian Institute of Information Technology, Bhagalpur(IIIT Bhagalpur)</option>
              <option value="Indian Institute of Information Technology, Agartala(IIIT Agartala)">Indian Institute of Information Technology, Agartala(IIIT Agartala)</option>
              <option value="Indian Institute of Information Technology, Raichur">Indian Institute of Information Technology, Raichur</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {college === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherCollege}
                  onChange={(e) => setOtherCollege(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherCollegeError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {collegeError || otherCollegeError}
          </div>


          
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="other_education"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
            >
              Other Education *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="other_education"
                id="other_education"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: other_educationError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={other_education}
                onChange={(e) => {
                  setOther_education(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherOther_education("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="12th Pass">12th Pass</option>
              <option value="Diploma">Diploma</option>
              <option value="Certification">Certification</option>
              <option value="Associates Degree">Associates Degree</option>
              <option value="Bachelors Degree">Bachelors Degree</option>
              <option value="B.A (Bachelor of Arts)">B.A (Bachelor of Arts)</option>
              <option value="B.Sc (Bachelor of Science)">B.Sc (Bachelor of Science)</option>
              <option value="B.Com (Bachelor of Commerce)">B.Com (Bachelor of Commerce)</option>
              <option value="B.Tech (Bachelor of Technology)">B.Tech (Bachelor of Technology)</option>
              <option value="BBA (Bachelor of Business Administration)">BBA (Bachelor of Business Administration)</option>
              <option value="BCA (Bachelor of Computer Applications)">BCA (Bachelor of Computer Applications)</option>
              <option value="LLB (Bachelor of Laws)">LLB (Bachelor of Laws)</option>
              <option value="MBBS (Bachelor of Medicine, Bachelor of Surgery)">MBBS (Bachelor of Medicine, Bachelor of Surgery)</option>
              <option value="Pharm.D (Doctor of Pharmacy)">Pharm.D (Doctor of Pharmacy)</option>
              <option value="BDS (Bachelor of Dental Surgery)">BDS (Bachelor of Dental Surgery)</option>
              <option value="BAMS (Bachelor of Ayurvedic Medicine and Surgery)">BAMS (Bachelor of Ayurvedic Medicine and Surgery)</option>
              <option value="BVSc (Bachelor of Veterinary Science)">BVSc (Bachelor of Veterinary Science)</option>
              <option value="BE (Bachelor of Engineering)">BE (Bachelor of Engineering)</option>
              <option value="Masters Degree">Masters Degree</option>
              <option value="M.A (Master of Arts)">M.A (Master of Arts)</option>
              <option value="M.Sc (Master of Science)">M.Sc (Master of Science)</option>
              <option value="M.Com (Master of Commerce)">M.Com (Master of Commerce)</option>
              <option value="M.Tech (Master of Technology)">M.Tech (Master of Technology)</option>
              <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
              <option value="MCA (Master of Computer Applications)">MCA (Master of Computer Applications)</option>
              <option value="MFA (Master of Fine Arts)">MFA (Master of Fine Arts)</option>
              <option value="MSW (Master of Social Work)">MSW (Master of Social Work)</option>
              <option value="LL.M (Master of Laws)">LL.M (Master of Laws)</option>
              <option value="MD (Doctor of Medicine)">MD (Doctor of Medicine)</option>
              <option value="MPharm (Master of Pharmacy)">MPharm (Master of Pharmacy)</option>
              <option value="MDS (Master of Dental Surgery)">MDS (Master of Dental Surgery)</option>
              <option value="MS (Master of Surgery)">MS (Master of Surgery)</option>
              <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
              <option value="M.Phil (Master of Philosophy)">M.Phil (Master of Philosophy)</option>
              <option value="Ph.D. (Doctor of Philosophy)">Ph.D. (Doctor of Philosophy)</option>
              <option value="Other Masters Degree (Specify)">Other Masters Degree (Specify)</option>
              <option value="Doctorate (Post-Doctoral)">Doctorate (Post-Doctoral)</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {other_education === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherOther_education}
                  onChange={(e) => setOtherOther_education(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherOther_educationError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {other_educationError || otherOther_educationError}
          </div>



          
          <div className="mv3" style={{display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="hobbies"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
            >
              Hobbies *
            </label>
            <Select 
              className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
              isMulti
              options={hobbiesOptions}
              value={hobbiesOptions.filter((option) => hobbies.includes(option.value))}
              onChange={handleHobbiesChange}
            />

          </div>
          <br></br>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {hobbiesError}
          </div>

          

          <div className="mv3" >
            <label
              className="db lh-copy f6 mb1"
              htmlFor="food_style"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Food Style *
            </label>
            <select
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              name="food_style"
              id="food_style"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: food_styleError ? "red" : "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={food_style}
              onChange={(e) => setFood_style(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Veg">Veg</option>
              <option value="Pure-Veg">Pure-Veg</option>
              <option value="Egg">Egg</option>
              <option value="Jain">Jain</option>
              <option value="Vegan">Vegan</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {food_styleError}
          </div>

          <div className="mv3" >
            <label
              className="db lh-copy f6 mb1"
              htmlFor="drinking_habit"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Drinking Habit
            </label>
            <select
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              name="drinking_habit"
              id="drinking_habit"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: drinking_habitError ? "red" : "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={drinking_habit}
              onChange={(e) => setDrinking_habit(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Non-Drinker">Non-Drinker</option>
              <option value="Social Drinker">Social Drinker</option>
              <option value="Regular Drinker">Regular Drinker</option>
              <option value="Occasional Drinker">Occasional Drinker</option>
              <option value="Heavy Drinker">Heavy Drinker</option>
              <option value="Never Tried">Never Tried</option>
              <option value="Quit Drinking">Quit Drinking</option>
              {/* Add more options as needed */}
            </select>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {drinking_habitError}
          </div>

        </fieldset>
        <div>
          <input
            className="f6 grow br3 ph3 pv2 mb4 dib white"
            style={{
              borderStyle: "none",
              // width: "100%",
              padding: '7px 30px',
              backgroundColor: "rgb(204, 0, 0)",
              fontSize: '2rem',
              fontWeight: "bold"
            }}
            type="button"
            value="Next"
            onClick={handleCreateWorkspace}
          />
        </div>
      </form>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }} className="desktop-step">
        <div id="#form-step">
          <i className=" step fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img src={img4} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Verified Profiles</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "27px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img5} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Affordable Membership Plans</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img6} alt="img" />
          </i>
          <div className="service-desc">
            <h3>15+ available filters for Matchmaking</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img8} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Photo Based Additional Verification</h3>
          </div>
        </div>
      </div>
    </main>
  );
}
export default PageFour;