import React from "react";
// import './fullScreenmodal.css'


// import "./styles.css";

function Modal({ children, shown, close }) {
  return shown ? (
    <div
      className="modal-backdrop"  style={{background: '#f8f6f67a'}}
      
    >
      <div style={{height: '100%', border: 'none', width: '100%', margin: 'auto', overflow: 'auto'}}
        className="modal-content"
        onClick={e => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
      <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}>
        <button onClick={close} style={{background: 'none', border: 'none', fontSize: '30px', }}>&#10006;</button>
        </div>
        {children}
      </div>
    </div>
  ) : null;
}


export default Modal;
