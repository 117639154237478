import React, { useState } from 'react';
import './search.css';
import Advancesearch from './Advancesearch';
import Locationsearch from './Locationsearch';
import Collegesearch from './Collegesearch';
import { useLocation } from "react-router-dom";
import { Loginnavigation } from './Loginnavigation';
import IdSearchResult from './IdSearchResult';
import Communitysearch from './Communitysearch';


const IdSearchresultNavbar = () => {
  const location = useLocation();
  
  
    
  const [activeSearch, setActiveSearch] = useState('Idsearch');
  const userinfo = location.state?.responseData || null;

  const handleSearchButtonClick = (searchType) => {
    setActiveSearch(searchType);
  };

  return (
    <div>
      <Loginnavigation/>
      <div className='search-filter'>
        <button style={{background: 'none', color: '#222222'}}
          onClick={() => handleSearchButtonClick('Idsearch')}
          className={activeSearch === 'Idsearch' ? 'active' : ''}
        >
          Results({userinfo?.users.length})
        </button>
        <button
          onClick={() => handleSearchButtonClick('Advance')}
          className={activeSearch === 'Advance' ? 'active' : ''}
        >
          Advance Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Location')}
          className={activeSearch === 'Location' ? 'active' : ''}
        >
          Location Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Community')}
          className={activeSearch === 'Community' ? 'active' : ''}
        >
          Community Based Searh
        </button>
        <button
          onClick={() => handleSearchButtonClick('College')}
          className={activeSearch === 'College' ? 'active' : ''}
        >
          College Based Search
        </button>
        
      </div>

      {activeSearch === 'Idsearch' && <IdSearchResult userinfo={userinfo?.users}/>}
      {activeSearch === 'Advance' && <Advancesearch/>}
      {activeSearch === 'Location' && <Locationsearch />}
      {activeSearch === 'Community' && <Communitysearch />}
      {activeSearch === 'College' && <Collegesearch />}
      
    </div>
  );
};

export default IdSearchresultNavbar;