import React, { useState, useEffect } from "react";
import './RegisterForm.css'
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from './images/search_2.png'
import img8 from './images/image_1.png'
import { districtData } from "../data/city";
import { cityData } from "../data/city";


const PageTwo = ({ onButtonClick
  }) => {
  const [fullname, setFullname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState(""); // Initialize with an empty string
  const [facecolor, setFacecolor] = useState("");
  const [age, setAge] = useState("");
  const [birth_time, setBirth_time] = useState("");
  const [birth_location, setBirth_location] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [otherDistrict, setOtherDistrict] = useState("");
  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [state, setState] = useState("");
  const [otherState, setOtherState] = useState("");
  const [country, setCountry] = useState("");
  const [otherCountry, setOtherCountry] = useState("");


  const [fullnameError, setFullnameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDobError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");
  const [facecolorError, setFacecolorError] = useState("");
  const [ageError, setAgeError] = useState("");
  // const [birth_locationError, setBirth_locationError] = useState("");
  // const [addressError, setAddressError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [otherDistrictError, setOtherDistrictError] = useState("");
  const [cityError, setCityError] = useState("");
  const [otherCityError, setOtherCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [otherStateError, setOtherStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [otherCountryError, setOtherCountryError] = useState("");

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  


  const filteredDistricts = districtData.states.find(s => s.state === state)?.districts || [];

  const filteredcities = cityData.states.find(s => s.state === state)?.city || [];


  

  const calculateAge = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
    return age_now;
  }
  

  useEffect(() => {
    if (dob.trim() !== "") {
      const age_calculated = calculateAge(dob);
      setAge(age_calculated.toString()); // Convert to string before setting in the state
    }
  }, [dob]);



  const handleCreateWorkspace = async () => {

    const age_calculated = calculateAge(dob);
  
    // Regular expression for validating 'firstname lastname' format
    const fullNameRegex = /^[A-Z][a-z]+ [A-Z][a-z]+$/;
  
    if (
      fullNameRegex.test(fullname) && // Check for 'firstname lastname' format
      gender.trim() !== "" &&
      dob.trim() !== "" &&
      height.trim() !== "" &&
      weight.trim() !== "" &&
      parseFloat(weight) >= 0 &&
      facecolor.trim() !== "" &&
      age.trim() !== "" &&
      parseFloat(age) >= 18 &&
      parseFloat(age) === age_calculated &&
      district.trim() !== "" &&
      city.trim() !== "" &&
      state.trim() !== "" &&
      country.trim() !== "" &&
      ((district === "Other (Specify)" && otherDistrict.trim() !== "") ||
        district !== "Other (Specify)") &&
      ((city === "Other (Specify)" && otherCity.trim() !== "") ||
        city !== "Other (Specify)") &&
      ((state === "Other (Specify)" && otherState.trim() !== "") ||
        state !== "Other (Specify)") &&
      ((country === "Other (Specify)" && otherCountry.trim() !== "") ||
        country !== "Other (Specify)") 
    ) {
      onButtonClick("pagethree", fullname, gender, dob, height, weight, facecolor, age, birth_time,
        birth_location !== '' ? capitalizeFirstLetter(birth_location) : birth_location,
        address !== '' ? capitalizeFirstLetter(address) : address,
        district === "Other (Specify)" ? capitalizeFirstLetter(otherDistrict) : district,
        city === "Other (Specify)" ? capitalizeFirstLetter(otherCity) : city,
        state === "Other (Specify)" ? capitalizeFirstLetter(otherState) : state,
        country === "Other (Specify)" ? capitalizeFirstLetter(otherCountry) : country,)
    } else {
      setFullnameError(!fullNameRegex.test(fullname) ? "Please enter valid full name in the format 'Firstname Lastname'" : "");

      setGenderError(gender.trim() === "" ? "Please fill in the gender" : "");

      setDobError(dob.trim() === "" ? "Please fill in the date of birth" : !(parseFloat(age_calculated) > 18) ? "Please enter a valid dob, age must be greater than 18" : "");

      setHeightError(height.trim() === "" ? "Please fill in the height" : "");

      setWeightError(weight.trim() === "" ? "Please fill in the weight" : !(parseFloat(weight) >= 0) ? "Please enter valid weight" : "");
        
      setFacecolorError(facecolor.trim() === "" ? "Please fill in the face color" : "");

      setAgeError(
        age.trim() === "" ? "Please fill in the age" :
        !(parseFloat(age) > 18) ? "Age must be greater than 18" :
        parseFloat(age) !== age_calculated ? `Please enter your correct age` : ""
    );

      

      setDistrictError(district.trim() === "" ? "Please fill in the district" : "");

      // setBirth_locationError(birth_location.trim() === "" ? "Please fill in the birth location" : "");
      // setBirth_timeError(birth_time.trim() === "" ? "Please fill in the birth time" : "");

      // setAddressError(address.trim() === "" ? "Please fill in the address" : "");

      setCityError(city.trim() === "" ? "Please fill in the city" : "");

      setStateError(state.trim() === "" ? "Please fill in the state" : "");

      setCountryError(country.trim() === "" ? "Please fill in the country" : "");

      setOtherDistrictError(
        district === "Other (Specify)" && otherDistrict.trim() === ""
          ? "Please specify district"
          : ""
      );;

      setOtherCityError(
        city === "Other (Specify)" && otherCity.trim() === ""
          ? "Please specify mother tongue"
          : ""
      );;

      setOtherStateError(
        state === "Other (Specify)" && otherState.trim() === ""
          ? "Please specify state"
          : ""
      );;

      setOtherCountryError(
        country === "Other (Specify)" && otherCountry.trim() === ""
          ? "Please specify country"
          : ""
      );

    
    }

  };

  return (
    <main
      className="black-80 center form-main-register"
      style={{maxHeight: "30%", margin: "auto" }}
    >

        <div className="desktop-step" style={{ display: "flex", flexDirection: "column",}}>
                <div id="#form-step">
                  <i
                    style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                    className=" step fa"
                  >
                    <img src={img1} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Easy Registeration</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa"
                  style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Chat With Partner</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Video Calls</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img7} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Search Profiles Conveniently</h3>
                  </div>
                </div>
              </div>
      
      <div className="form-desktop-step">
      <form className="measure measure1" style={{margin: "auto", padding: "20px",
                            background: "#FF4242",
                            borderRadius: "20px" }}>
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
          <div className="mv3" >
            <label
              className="db lh-copy f6 mb1"
              htmlFor="fullname"
              style={{
                textAlign: "left",
                fontSize: "2rem",
                color: 'white', 
                fontWeight: '575'
              }}
            >
              Full Name *
            </label>
            <input
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              placeholder="Firstname Lastname"
              type="text"
              name="fullname"
              id="fullname"
              // placeholder="example@example.com"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {fullnameError}
          </div>

          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >
            <div style={{ flex: 1, marginRight: '20px', display:"flex", flexDirection:"column" }}>
              <div>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="gender"
                style={{
                  textAlign: "left",
                fontSize: "2rem",
                color: "white",
                fontWeight: "575",
                display: "block", // Add this style to make the label a block element
                }}
              >
                Gender *
              </label>
              </div>
              <div
              className="relative"
              style={{
                background: "#950A06",
                padding: "2px 5px",
                borderRadius:"5px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "10px", // Add margin-top to separate radio buttons from the label
              }}
            >
              {["Male", "Female"].map((option) => (
                <label key={option} style={{ marginRight: "10px", marginBottom: "10px", color:'white', fontSize:"12px" }}>
                  <input
                    type="radio"
                    name="gender"
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                    style={{ marginRight: "5px", }}
                  />
                  {option}
                </label>
              ))}
              {/* Repeat the same pattern for other options */}
            </div>
              
            </div>
            
            

            <div style={{ flex: 1, width: '50%'}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="dob"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'

                }}
              >
                DOB *
              </label>
              <input
                type="date"
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="dob"
                id="dob"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: dobError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-15px', textAlign: 'left',marginBottom: '10px'}}>
              {dobError}
              </div>
            </div>
            
          </div>
          

          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >
            <div style={{ flex: 1,  marginRight: '20px'}}>
            <label
                className="db lh-copy f6 mb1"
                htmlFor="height"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'
                }}
              >
                Height *
              </label>
              <select
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="Height"
                id="Height"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: heightError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              >
                <option value="">Select</option>
                <option value="48">4 feet (48 inches)</option>
                <option value="49">4 feet 1 inch (49 inches)</option>
                <option value="50">4 feet 2 inches (50 inches)</option>
                <option value="51">4 feet 3 inches (51 inches)</option>
                <option value="52">4 feet 4 inches (52 inches)</option>
                <option value="53">4 feet 5 inches (53 inches)</option>
                <option value="54">4 feet 6 inches (54 inches)</option>
                <option value="55">4 feet 7 inches (55 inches)</option>
                <option value="56">4 feet 8 inches (56 inches)</option>
                <option value="57">4 feet 9 inches (57 inches)</option>
                <option value="58">4 feet 10 inches (58 inches)</option>
                <option value="59">4 feet 11 inches (59 inches)</option>
                <option value="60">5 feet (60 inches)</option>
                <option value="61">5 feet 1 inch (61 inches)</option>
                <option value="62">5 feet 2 inches (62 inches)</option>
                <option value="63">5 feet 3 inches (63 inches)</option>
                <option value="64">5 feet 4 inches (64 inches)</option>
                <option value="65">5 feet 5 inches (65 inches)</option>
                <option value="66">5 feet 6 inches (66 inches)</option>
                <option value="67">5 feet 7 inches (67 inches)</option>
                <option value="68">5 feet 8 inches (68 inches)</option>
                <option value="69">5 feet 9 inches (69 inches)</option>
                <option value="70">5 feet 10 inches (70 inches)</option>
                <option value="71">5 feet 11 inches (71 inches)</option>
                <option value="72">6 feet (72 inches)</option>
                <option value="73">6 feet 1 inch (73 inches)</option>
                <option value="74">6 feet 2 inches (74 inches)</option>
                <option value="75">6 feet 3 inches (75 inches)</option>
                <option value="76">6 feet 4 inches (76 inches)</option>
                <option value="77">6 feet 5 inches (77 inches)</option>
                <option value="78">6 feet 6 inches (78 inches)</option>
                <option value="79">6 feet 7 inches (79 inches)</option>
                <option value="80">6 feet 8 inches (80 inches)</option>
                <option value="81">6 feet 9 inches (81 inches)</option>
                <option value="82">6 feet 10 inches (82 inches)</option>
                <option value="83">6 feet 11 inches (83 inches)</option>
                <option value="84">7 feet (84 inches)</option>
                <option value="85">7 feet 1 inch (85 inches)</option>
                <option value="86">7 feet 2 inches (86 inches)</option>
                <option value="87">7 feet 3 inches (87 inches)</option>
                <option value="88">7 feet 4 inches (88 inches)</option>
                <option value="89">7 feet 5 inches (89 inches)</option>
                <option value="above 89">Above 7.5 feet</option>
                
              </select>
            </div>
            

            <div style={{ flex: 1, width: '50%'}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="weight"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'

                }}
              >
                Weight(in kg) *
              </label>
              <input
                type="number"
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="weight"
                id="weight"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: weightError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>
            
          </div>
          

          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >
            <div style={{ flex: 1,  marginRight: '20px'}}>
            <label
                className="db lh-copy f6 mb1"
                htmlFor="facecolor"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'
                }}
              >
                Face color *
              </label>
              <select
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="facecolor"
                id="facecolor"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: facecolorError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={facecolor}
                onChange={(e) => setFacecolor(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Fair">Fair</option>
                <option value="Medium Skin">Medium Skin</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Brown">Brown</option>
                <option value="Dark">Dark</option>
                
              </select>
            </div>

            <div style={{ flex: 1, width: '50%'}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="age"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'

                }}
              >
                Age *
              </label>
              <input
                  type="number"
                  className="f6 br3 ph3 pv4 mb4 black w-100"
                  name="age"
                  id="age"
                  readOnly
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: ageError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={age}
                />

           <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-15px', textAlign: 'left',marginBottom: '10px'}}>
            {ageError}
          </div>
            </div>
          </div>


          

          

          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >
            <div style={{ flex: 1,  marginRight: '20px', display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="state"
                style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
              >
                State *
              </label>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <select
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  name="state"
                  id="state"
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: stateError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    // Reset otherMotherTongue when a different option is selected
                    if (e.target.value !== "Other (Specify)") {
                      setOtherState("");
                    }
                  }}
                >
                  <option value="">Please Select District First</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chattisgarh">Chattisgarh</option>
                <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Madhya Pradesh">MadhyaPradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">TamilNadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">UttarPradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                  {/* Add other language options */}
                  <option value="Other (Specify)">Other (Specify)</option>
                </select>

                {state === "Other (Specify)" && (
                  <input
                    type="text" 
                    className="f6 br3 ph5 pv4 mb4 dib black w-100"
                    placeholder="Specify"
                    value={otherState}
                    onChange={(e) => setOtherState(e.target.value)}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: otherStateError ? "red" : "rgb(126 121 121 / 95%)",
                      fontSize: "1.5rem",
                      paddingBottom: "15px",
                      
                    }}
                  />
                )}
              </div>
            </div>
            

            <div style={{ flex: 1,  marginRight: '20px', display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <label
                className="db lh-copy f6 mb1"
                htmlFor="city"
                style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
              >
                City *
              </label>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <select
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  name="city"
                  id="city"
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: cityError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                    // Reset otherMotherTongue when a different option is selected
                    if (e.target.value !== "Other (Specify)") {
                      setOtherCity("");
                    }
                  }}
                >
                  <option value="">Select</option>
                  {filteredcities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}


                  {/* Add other language options */}
                  <option value="Other (Specify)">Other (Specify)</option>
                </select>

                {city === "Other (Specify)" && (
                  <input
                    type="text" 
                    className="f6 br3 ph5 pv4 mb4 dib black w-100"
                    placeholder="Specify"
                    value={otherCity}
                    onChange={(e) => setOtherCity(e.target.value)}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: otherCityError ? "red" : "rgb(126 121 121 / 95%)",
                      fontSize: "1.5rem",
                      paddingBottom: "15px",
                      
                    }}
                  />
                )}
              </div>
            </div>
          </div>


          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >

            <div style={{ flex: 1,  marginRight: '20px', display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="district"
                style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
              >
                District *
              </label>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <select
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  name="district"
                  id="district"
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: districtError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={district}
                  onChange={(e) => {
                    setDistrict(e.target.value);
                    if (e.target.value !== "Other (Specify)") {
                      setOtherDistrict("");
                    }
                  }}
                >
                  <option value="">Select the State First</option>
                  {filteredDistricts.map((district, index) => (
                    <option key={index} value={district}>
                      {district}
                    </option>
                  ))}

                  {/* Add other language options */}
                  <option value="Other (Specify)">Other (Specify)</option>
                </select>

                {district === "Other (Specify)" && (

                  <input
                    type="text" 
                    className="f6 br3 ph5 pv4 mb4 dib black w-100"
                    placeholder="Specify"
                    value={otherDistrict}
                    onChange={(e) => setOtherDistrict(e.target.value)}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: otherDistrictError ? "red" : "rgb(126 121 121 / 95%)",
                      fontSize: "1.5rem",
                      paddingBottom: "15px",
                      
                    }}
                  />
                )}
              </div>
            </div>
            
            

            <div style={{ flex: 1,  marginRight: '20px', display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <label
                className="db lh-copy f6 mb1"
                htmlFor="country"
                style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
              >
                Country *
              </label>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <select
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  name="country"
                  id="country"
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: countryError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    // Reset otherMotherTongue when a different option is selected
                    if (e.target.value !== "Other (Specify)") {
                      setOtherCountry("");
                    }

                    // If the selected country is not 'India', set state to 'Other (Specify)'
                    if (e.target.value !== "India") {
                      setState("Other (Specify)");
                      setOtherState("");
                    }

                    // If the selected country is not 'India', set state to 'Other (Specify)'
                    if (e.target.value !== "India") {
                      setCity("Other (Specify)");
                      setOtherCity("");
                    }

                    // If the selected country is not 'India', set state to 'Other (Specify)'
                    if (e.target.value !== "India") {
                      setDistrict("Other (Specify)");
                      setOtherDistrict("");
                    }
                  }}
                >
                  <option value="">Select</option>
                  <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Australia">Australia</option>
                <option value="India">India</option>
                <option value="Germany">Germany</option>
                <option value="France">France</option>
                <option value="Spain">Spain</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="China">China</option>
                <option value="Brazil">Brazil</option>
                <option value="Russia">Russia</option>
                <option value="South Africa">South Africa</option>
                  {/* Add other language options */}
                  <option value="Other (Specify)">Other (Specify)</option>
                </select>

                {country === "Other (Specify)" && (
                  <input
                    type="text" 
                    className="f6 br3 ph5 pv4 mb4 dib black w-100"
                    placeholder="Specify"
                    value={otherCountry}
                    onChange={(e) => setOtherCountry(e.target.value)}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: otherCountryError ? "red" : "rgb(126 121 121 / 95%)",
                      fontSize: "1.5rem",
                      paddingBottom: "15px",
                      
                    }}
                  />
                )}

                {/* {country !== "India" && (
                  <input
                    type="text"
                    className="f6 br3 ph5 pv4 mb4 dib black w-100"
                    placeholder="Specify"
                    value={otherState}
                    onChange={(e) => setOtherState(e.target.value)}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: otherStateError ? "red" : "rgb(126 121 121 / 95%)",
                      fontSize: "1.5rem",
                      paddingBottom: "15px",
                    }}
                  />
                )} */}
              </div>
            </div>
          </div>


          <div className="mv3">
            <label
              className="db lh-copy f6 mb1"
              htmlFor="address"
              style={{
                textAlign: "left",
                fontSize: "2rem",
                color: 'white', fontWeight: '575'
              }}
            >
              Address (one linear) (optional)
            </label>
            <input
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              type="text"
              name="address"
              id="address"
              // placeholder="example@example.com"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div
            className="mv3"
            style={{ display: "flex", alignoptions: "center" }}
          >
            <div style={{ flex: 1, marginRight: '20px' }}>
            <label
                className="db lh-copy f6 mb1"
                htmlFor="birth_time"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'

                }}
              >
                Birth Time (optional)
              </label>
              <input
                type="time"
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="birth_time"
                id="birth_time"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={birth_time}
                onChange={(e) => setBirth_time(e.target.value)}
              />
            </div>

            <div style={{ flex: 1, width: '50%'}}>
              <label
                className="db lh-copy f6 mb1"
                htmlFor="birth_location"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: 'white', fontWeight: '575'

                }}
              >
                Birth Location (optional)
              </label>
              <input
                type="text"
                className="f6 br3 ph3 pv4 mb4 black w-100"
                name="birth_location"
                id="birth_location"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor:  "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={birth_location}
                onChange={(e) => setBirth_location(e.target.value)}
              />
              
              
            </div>
            
          </div>

        </fieldset>
        <div >
          <input
            className="f6 grow br3 ph3 pv2 mb4 dib white"
            style={{
              borderStyle: "none",
              width: "100%",
              padding: "7px 30px",
              backgroundColor: "rgb(204, 0, 0)",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
            type="button"
            value="Next"
            onClick={handleCreateWorkspace}
          />
        </div>
      </form>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }} className="desktop-step">
        <div id="#form-step">
          <i className=" step fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img src={img4} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Verified Profiles</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "27px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img5} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Affordable Membership Plans</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img6} alt="img" />
          </i>
          <div className="service-desc">
            <h3>15+ available filters for Matchmaking</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img8} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Photo Based Additional Verification</h3>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageTwo;
