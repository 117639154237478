import React, { useState } from "react";
import "./search.css"; // Import your custom CSS file
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./searchsliders.css";
import { cityData, districtData } from "../data/city";
import { useNavigate } from "react-router-dom";


const Advancesearch = () => {
  const gender = sessionStorage.getItem("gender");
  const religion = sessionStorage.getItem("religion");
  const navigate = useNavigate();
  // console.log(responseData)

  const [caste, setCaste] = useState("");
  const [otherCaste, setOtherCaste] = useState("");
  const [subcaste, setSubcaste] = useState("");
  const [otherSubCaste, setOtherSubCaste] = useState("");
  const [facecolor, setFacecolor] = useState("");
  const [foodstyle, setFoodstyle] = useState("");
  const [drinking, setDrinking] = useState("");
  const [working, setWorking] = useState("");
  // const [residential, setResidental] = useState("");
  const [country, setCountry] = useState("");
  const [otherCountry, setOtherCountry] = useState("");
  const [state, setState] = useState("");
  const [otherState, setOtherState] = useState("");
  const [district, setDistrict] = useState("");
  const [otherDistrict, setOtherDistrict] = useState("");
  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [marital, setMarital] = useState("");
  const [income, setIncome] = useState("");
  const [familybackground, setFamilybackground] = useState("");
  const [personality, setPersonality] = useState("");
  const [gotra, setGotra] = useState("")
  const [otherGotra, setOtherGotra] = useState("");
  const [companylocation, setCompanylocation] = useState("");
  const [otherCompanylocation, setOtherCompanylocation] = useState("");
  const [photo, setPhoto] = useState("");
  const [education, setEducation] = useState("");
  const [otherEducation, setOtherEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [otherProfession, setOtherProfession] = useState("");

  
  const [casteError, setCasteError] = useState("");
  const [otherCasteError, setOtherCasteError] = useState("");
  const [subcasteError, setSubcasteError] = useState("");
  const [otherSubCasteError, setOtherSubCasteError] = useState("");
  const [facecolorError, setFacecolorError] = useState("");
  const [foodstyleError, setFoodstyleError] = useState("");
  const [drinkingError, setDrinkingError] = useState("");
  const [workingError, setWorkingError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [otherCountryError, setOtherCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [otherStateError, setOtherStateError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [otherDistrictError, setOtherDistrictError] = useState("");
  const [cityError, setCityError] = useState("");
  const [otherCityError, setOtherCityError] = useState("");
  const [maritalError, setMaritalError] = useState("");
  const [gotraError, setGotraError] = useState("");
  const [otherGotraError, setOtherGotraError] = useState("");


  const [weightRange, setWeightRange] = useState({ min: 0, max: 100 });
  const [ageRange, setAgeRange] = useState({ min: 18, max: 100 });

  const [heightRange, setHeightRange] = useState({
    min: 5 * 12, // Default to 5 feet
    max: 6 * 12, // Default to 6 feet
  });
  const formatValue = (value) => {
    const feet = Math.floor(value / 12);
    const inches = value % 12;
    return `${feet}'${inches}''`;
  };

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const filteredDistricts = districtData.states.find(s => s.state === state)?.districts || [];

  const filteredcities = cityData.states.find(s => s.state === state)?.city || [];


  const handleSearch = async () => {
    if (
      caste.trim() !== "" &&
      subcaste.trim() !== "" &&
      facecolor.trim() !== "" &&
      foodstyle.trim() !== "" &&
      drinking.trim() !== "" &&
      working.trim() !== ""&&
      country.trim() !== "" &&
      state.trim() !== "" &&
      district.trim() !== "" &&
      city.trim() !== "" &&
      marital.trim() !== "" &&
      gotra.trim() !== "" &&
      // ageRange.min.trim() !== "" &&
      // ageRange.max.trim() !== "" &&
      ((caste === "Other (Specify)" && otherCaste.trim() !== "") ||
      caste !== "Other (Specify)") &&
      ((subcaste === "Other (Specify)" && otherSubCaste.trim() !== "") ||
      subcaste !== "Other (Specify)") &&
      ((country === "Other (Specify)" && otherCountry.trim() !== "") ||
      country !== "Other (Specify)") &&
      ((state === "Other (Specify)" && otherState.trim() !== "") ||
      state !== "Other (Specify)") &&
      ((district === "Other (Specify)" && otherDistrict.trim() !== "") ||
      district !== "Other (Specify)") &&
      ((city === "Other (Specify)" && otherCity.trim() !== "") ||
      city !== "Other (Specify)") &&
      ((gotra === "Other (Specify)" && otherGotra.trim() !== "") ||
      gotra !== "Other (Specify)")
   
    ){
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "face_color": facecolor,
            "food_style": foodstyle,
            "drinking_habbit": drinking,
            "country": country === "Other (Specify)" ? capitalizeFirstLetter(otherCountry) : country,
            "state": state === "Other (Specify)" ? capitalizeFirstLetter(otherState) : state,
            "district": district === "Other (Specify)" ? capitalizeFirstLetter(otherDistrict) : district,
            "city": city === "Other (Specify)" ? capitalizeFirstLetter(otherCity) : city,
            "marital_status": marital,
            "gotra": gotra === "Other (Specify)" ? capitalizeFirstLetter(otherGotra) : gotra,
            "caste": caste === "Other (Specify)" ? capitalizeFirstLetter(otherCaste) : caste,
            "sub_caste": subcaste === "Other (Specify)" ? capitalizeFirstLetter(otherSubCaste) : subcaste,
            "gender": gender,
            "religion": religion,
            "min_age": ageRange.min,
            "max_age": ageRange.max,
            "working_status": working,
            "company_location": companylocation === "Other (Specify)" ? capitalizeFirstLetter(otherCompanylocation) : companylocation,
            "min_height": heightRange.min,
            "max_height": heightRange.max,
            "min_weight": weightRange.min,
            "max_weight": weightRange.max,
            "education": education === "Other (Specify)" ? capitalizeFirstLetter(otherEducation) : education,
            "occupation": profession === "Other (Specify)" ? capitalizeFirstLetter(otherProfession) : profession,
            
            advancesearch: true,
          }),
        });
  
        const data = await response.json();
        // setResponseData(data);

        // Redirect to AdvanceSearchResults component with responseData as prop
      if (data !== null) {
        navigate('/advancesearchresultnavbar', { state: { responseData: data } });
      }
        
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    }
    else {
      setCasteError(caste.trim() === "" ? "Please select caste" : "");
      setOtherCasteError(
          caste === "Other (Specify)" && otherCaste.trim() === ""
            ? "Please specify caste"
            : ""
      );

      setSubcasteError(subcaste.trim() === "" ? "Please select sub-caste" : "");
      setOtherSubCasteError(
          subcaste === "Other (Specify)" && otherSubCaste.trim() === ""
            ? "Please specify sub-caste"
            : ""
      );

      setFacecolorError(facecolor.trim() === "" ? "Please select facecolor" : "");

      setFoodstyleError(foodstyle.trim() === "" ? "Please select foodstyle" : "");

      setDrinkingError(drinking.trim() === "" ? "Please select drinking" : "");

      setWorkingError(working.trim() === "" ? "Please select working" : "");

      setCountryError(country.trim() === "" ? "Please select country" : "");
      setOtherCountryError(
          country === "Other (Specify)" && otherCountry.trim() === ""
            ? "Please specify country"
            : ""
      );

      setStateError(state.trim() === "" ? "Please select state" : "");
      setOtherStateError(
          state === "Other (Specify)" && otherState.trim() === ""
            ? "Please specify state"
            : ""
      );

      setDistrictError(district.trim() === "" ? "Please select district" : "");
      setOtherDistrictError(
          district === "Other (Specify)" && otherDistrict.trim() === ""
            ? "Please specify district"
            : ""
      );

      setCityError(city.trim() === "" ? "Please select city" : "");
      setOtherCityError(
        city === "Other (Specify)" && otherCity.trim() === ""
          ? "Please specify city"
          : ""
      );

      setMaritalError(marital.trim() === "" ? "Please select marital status" : "");

      setGotraError(gotra.trim() === "" ? "Please select gotra" : "");
      setOtherGotraError(
        gotra === "Other (Specify)" && otherGotra.trim() === ""
          ? "Please specify gotra"
          : ""
      );


    }
  }



  return (
    <>
      <div className="advance-search">
        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="caste">Caste</label>
              <select
                className="form-control"
                id="caste"
                value={caste}
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: casteError ? "red" : "#FBC913"}}
                onChange={(e) => {
                  setCaste(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCaste("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Brahmin">Brahmin</option>
                <option value="Kshatriya">Kshatriya</option>
                <option value="Vaishya">Vaishya</option>
                <option value="Shudra">Shudra</option>
                <option value="Scheduled Castes (SC)">
                  Scheduled Castes (SC)
                </option>
                <option value="Scheduled Tribes (ST)">
                  Scheduled Tribes (ST)
                </option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {caste === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherCaste}
                  onChange={(e) => setOtherCaste(e.target.value)}
                  style={{ marginTop: "20px",borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherCasteError ? "red" : "#FBC913" }}
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {casteError || otherCasteError}
            </div>
          </div>
          

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="subcaste">Sub-Caste</label>
              <select
                className="form-control"
                id="subcaste"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: subcasteError ? "red" : "#FBC913"}}
                value={subcaste}
                onChange={(e) => {
                  setSubcaste(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherSubCaste("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Not Applicable">Not Applicable</option>
                <option value="Agarwal">Agarwal</option>
                <option value="Dalit">Dalit</option>
                <option value="Adi Dravida">Adi Dravida</option>
                <option value="Chamar">Chamar</option>
                <option value="Tribal">Tribal</option>
                <option value="Gond">Gond</option>
                <option value="Munda">Munda</option>
                <option value="Yadav">Yadav</option>
                <option value="Kurmi">Kurmi</option>
                <option value="Maurya">Maurya</option>
                <option value="Nair">Nair</option>
                <option value="Nadar">Nadar</option>
                <option value="Vanniyar">Vanniyar</option>
                <option value="Brahmin">Brahmin</option>
                <option value="Iyer">Iyer</option>
                <option value="Iyengar">Iyengar</option>
                <option value="Smarta Brahmin">Smarta Brahmin</option>
                <option value="Rajput">Rajput</option>
                <option value="Thakur">Thakur</option>
                <option value="Kshatriya Raju">Kshatriya Raju</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {subcaste === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherSubCaste}
                  onChange={(e) => setOtherSubCaste(e.target.value)}
                  style={{
                    marginTop: "20px",
                    borderStyle: "solid",
                   borderWidth: "1px",
                    borderColor: otherSubCasteError ? "red" : "#FBC913"}}
                  
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {subcasteError || otherSubCasteError}
            </div>
          </div>
        </div>
        

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="facecolor">Face Color</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: facecolorError ? "red" : "#FBC913"}}
                className="form-control"
                id="facecolor"
                value={facecolor}
                onChange={(e) => setFacecolor(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Fair">Fair</option>
                <option value="Medium Skin">Medium Skin</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Brown">Brown</option>
                <option value="Dark">Dark</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {facecolorError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="foodstyle">Food Style</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: foodstyleError ? "red" : "#FBC913"}}
                className="form-control"
                id="foodstyle"
                value={foodstyle}
                onChange={(e) => setFoodstyle(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Veg">Veg</option>
                <option value="Pure-Veg">Pure-Veg</option>
                <option value="Egg">Egg</option>
                <option value="Jain">Jain</option>
                <option value="Vegan">Vegan</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {foodstyleError}
            </div>
          </div>
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="drinking">Drinking Habit</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: drinkingError ? "red" : "#FBC913"}}
                className="form-control"
                id="drinking"
                value={drinking}
                onChange={(e) => setDrinking(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Non-Drinker">Non-Drinker</option>
                <option value="Social Drinker">Social Drinker</option>
                <option value="Regular Drinker">Regular Drinker</option>
                <option value="Occasional Drinker">Occasional Drinker</option>
                <option value="Heavy Drinker">Heavy Drinker</option>
                <option value="Never Tried">Never Tried</option>
                <option value="Quit Drinking">Quit Drinking</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {drinkingError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="working">Working Status</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: workingError ? "red" : "#FBC913"}}
                className="form-control"
                id="working"
                value={working}
                onChange={(e) => setWorking(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Working">Working</option>
                <option value="Non-working">Non-working</option>
                <option value="Student">Student</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {workingError}
            </div>
          </div>
        </div>

        <div className="custom-row">
          {/* <div className="custom-col">
            <div className="form-group">
              <label htmlFor="residential">Residential Status</label>
              <select
                className="form-control"
                id="residential"
                value={residential}
                onChange={(e) => setResidental(e.target.value)}
              >
                <option value="">Select</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
            </div>
          </div> */}

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: countryError ? "red" : "#FBC913"}}
                className="form-control"
                id="country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCountry("");
                  }
                }}

                // onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Australia">Australia</option>
                <option value="India">India</option>
                <option value="Germany">Germany</option>
                <option value="France">France</option>
                <option value="Spain">Spain</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="China">China</option>
                <option value="Brazil">Brazil</option>
                <option value="Russia">Russia</option>
                <option value="South Africa">South Africa</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {country === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherCountry}
                  onChange={(e) => setOtherCountry(e.target.value)}
                  style={{ marginTop: "20px", borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: otherCountryError ? "red" : "#FBC913"}} 
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {countryError || otherCountryError}
            </div>
          </div>


          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="country">State</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: stateError ? "red" : "#FBC913"}}
                className="form-control"
                id="state"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherState("");
                  }
                }}

                // onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chattisgarh">Chattisgarh</option>
                <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Madhya Pradesh">MadhyaPradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">TamilNadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">UttarPradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {state === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherState}
                  onChange={(e) => setOtherState(e.target.value)}
                  style={{ marginTop: "20px", borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: otherStateError ? "red" : "#FBC913"}} 
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {stateError || otherStateError}
            </div>
          </div>

        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="city">District</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: districtError ? "red" : "#FBC913"}}
                className="form-control"
                id="district"
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherDistrict("");
                  }
                }}

                // onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select</option>
                {filteredDistricts.map((district, index) => (
                    <option key={index} value={district}>
                      {district}
                    </option>
                  ))}
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {district === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherDistrict}
                  onChange={(e) => setOtherDistrict(e.target.value)}
                  style={{ marginTop: "20px", borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: otherDistrictError ? "red" : "#FBC913"}} 
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {districtError || otherDistrictError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="city">City</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: cityError ? "red" : "#FBC913"}}
                className="form-control"
                id="city"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCity("");
                  }
                }}

                // onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select</option>
                {filteredcities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {city === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherCity}
                  onChange={(e) => setOtherCity(e.target.value)}
                  style={{ marginTop: "20px", borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: otherCityError ? "red" : "#FBC913"}} 
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {cityError || otherCityError}
            </div>
          </div>

        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="marital">Marital Status</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: maritalError ? "red" : "#FBC913"}}
                className="form-control"
                id="marital"
                value={marital}
                onChange={(e) => setMarital(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
                <option value="Seperated">Seperated</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {maritalError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="income">Income</label>
              <select
                className="form-control"
                id="income"
                value={income}
                onChange={(e) => setIncome(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Less than 1,00,000">Less than 1,00,000</option>
                <option value="1,00,000 - 2,00,000">1,00,000 - 2,00,000</option>
                <option value="2,00,000 - 3,00,000">2,00,000 - 3,00,000</option>
                <option value="3,00,000 - 4,00,000">3,00,000 - 4,00,000</option>
                <option value="4,00,000 - 5,00,000">4,00,000 - 5,00,000</option>
                <option value="5,00,000 - 6,00,000">5,00,000 - 6,00,000</option>
                <option value="6,00,000 - 7,00,000">6,00,000 - 7,00,000</option>
                <option value="7,00,000 - 8,00,000">7,00,000 - 8,00,000</option>
                <option value="8,00,000 - 9,00,000">8,00,000 - 9,00,000</option>
                <option value="9,00,000 - 10,00,000">
                  9,00,000 - 10,00,000
                </option>
                <option value="10,00,000 - 12,50,000">
                  10,00,000 - 12,50,000
                </option>
                <option value="12,50,000 - 15,00,000">
                  12,50,000 - 15,00,000
                </option>
                <option value="15,00,000 - 20,00,000">
                  15,00,000 - 20,00,000
                </option>
                <option value="20,00,000 - 30,00,000">
                  20,00,000 - 30,00,000
                </option>
                <option value="30,00,000 - 40,00,000">
                  30,00,000 - 40,00,000
                </option>
                <option value="40,00,000 - 50,00,000">
                  40,00,000 - 50,00,000
                </option>
                <option value="50,00,000 - 60,00,000">
                  50,00,000 - 60,00,000
                </option>
                <option value="60,00,000 - 70,00,000">
                  60,00,000 - 70,00,000
                </option>
                <option value="70,00,000 - 80,00,000">
                  70,00,000 - 80,00,000
                </option>
                <option value="80,00,000 - 90,00,000">
                  80,00,000 - 90,00,000
                </option>
                <option value="90,00,000 - 1,00,00,000">
                  90,00,000 - 1,00,00,000
                </option>
                <option value="1,00,00,000 - 1,25,00,000">
                  1,00,00,000 - 1,25,00,000
                </option>
                <option value="1,25,00,000 - 1,50,00,000">
                  1,25,00,000 - 1,50,00,000
                </option>
                <option value="1,50,00,000 - 2,00,00,000">
                  1,50,00,000 - 2,00,00,000
                </option>
                <option value="More than 2,00,00,000">
                  More than 2,00,00,000
                </option>
                <option value="More than 10,00,00,000">
                  More than 10,00,00,000
                </option>
                <option value="Not Applicable">Not Applicable</option>
              </select>
            </div>
          </div>
        </div>

        <div className="custom-row">
          
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="familybackground">Family Background</label>
              <select
                className="form-control"
                id="familybackground"
                value={familybackground}
                onChange={(e) => setFamilybackground(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Poor">Poor</option>
                <option value="Middle Class">Middle Class</option>
                <option value="Upper Middle">Upper Middle</option>
                <option value="Rich">Rich</option>
                <option value="Highly Rich">Highly Rich</option>
              </select>
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="companylocation">Company Location</label>
              <select
                className="form-control"
                id="companylocation"
                value={companylocation}
                onChange={(e) => {
                  setCompanylocation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCompanylocation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Delhi">Delhi</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Faridabad">Faridabad</option>
                <option value="Noida">Noida</option>
                <option value="Greater Noida">Greater Noida</option>
                <option value="Ghaziabad">Ghaziabad</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Chennai">Chennai</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Ahmedabad">Ahmedabad</option>
                <option value="Pune">Pune</option>
                <option value="Surat">Surat</option>
                <option value="Lucknow">Lucknow</option>
                <option value="Jaipur">Jaipur</option>
                <option value="Kanpur">Kanpur</option>
                <option value="Indore">Indore</option>
                <option value="Nagpur">Nagpur</option>
                <option value="Patna">Patna</option>
                <option value="Amroha">Amroha</option>
                <option value="Remote">Remote</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>
              {companylocation === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherCompanylocation}
                  onChange={(e) => setOtherCompanylocation(e.target.value)}
                  style={{ marginTop: "20px" }}
                />
              )}
            </div>
          </div>

        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="personality">Personality Meter</label>
              <select
                className="form-control"
                id="personality"
                value={personality}
                onChange={(e) => setPersonality(e.target.value)}
              >
                <option value="">Select</option>
                <option value="0-100">0-100</option>
                <option value="80-100">80-100</option>
                <option value="90-100">90-100</option>
              </select>
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="gotra">Gotra</label>
              <select
                className="form-control"
                id="gotra"
                value={gotra}
                onChange={(e) => setGotra(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Bharadwaj">Bharadwaj</option>
                <option value="Kashyap">Kashyap</option>
                <option value="Vatsa">Vatsa</option>
                <option value="Gautam">Gautam</option>
                <option value="Shandilya">Shandilya</option>
                <option value="Atri">Atri</option>
                <option value="Vasistha">Vasistha</option>
                <option value="Kaushik">Kaushik</option>
                <option value="Garg">Garg</option>
                <option value="Parashara">Parashara</option>
                <option value="Sandilya">Sandilya</option>
                <option value="Vashishtha">Vashishtha</option>
                <option value="Jamadagni">Jamadagni</option>
                <option value="Angirasa">Angirasa</option>
                <option value="Kaundinya">Kaundinya</option>
                <option value="Suryavanshi">Suryavanshi</option>
                <option value="Chandravanshi">Chandravanshi</option>
                <option value="Agnivanshi">Agnivanshi</option>
                <option value="Nagvanshi">Nagvanshi</option>
                <option value="Kachwaha">Kachwaha</option>
                <option value="Rathore">Rathore</option>
                <option value="Tomar">Tomar</option>
                <option value="Solanki">Solanki</option>
                <option value="Chauhan">Chauhan</option>
                <option value="Parmar">Parmar</option>
                <option value="Gohil">Gohil</option>
                <option value="Pratihar">Pratihar</option>
                <option value="Parihar">Parihar</option>
                <option value="Rajput">Rajput</option>
                <option value="Rajawat">Rajawat</option>
                <option value="Agarwal">Agarwal</option>
                <option value="Garg">Garg</option>
                <option value="Bansal">Bansal</option>
                <option value="Mittal">Mittal</option>
                <option value="Singhal">Singhal</option>
                <option value="Kansal">Kansal</option>
                <option value="Mangal">Mangal</option>
                <option value="Madhukul">Madhukul</option>
                <option value="Goyal">Goyal</option>
                <option value="Jindal">Jindal</option>
                <option value="Tayal">Tayal</option>
                <option value="Kuchhal">Kuchhal</option>
                <option value="Goyal">Goyal</option>
                <option value="Mangal">Mangal</option>
                <option value="Khandelwal">Khandelwal</option>
                <option value="Gupta">Gupta</option>
                <option value="Maheshwari">Maheshwari</option>
                <option value="Oswal">Oswal</option>
                <option value="Porwal">Porwal</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {gotra === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherGotra}
                  onChange={(e) => setOtherGotra(e.target.value)}
                  style={{
                    marginTop: '20px' 
                  }}
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {gotraError || otherGotraError}
            </div>
          </div>

          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="photo">Photo Visible Status</label>
              <select
                className="form-control"
                id="photo"
                value={photo}
                onChange={(e) => setPhoto(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="education">Education</label>
              <select
                className="form-control"
                id="education"
                value={education}
                onChange={(e) => {
                  setEducation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherEducation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="12th Pass">12th Pass</option>
                <option value="Diploma">Diploma</option>
                <option value="Certification">Certification</option>
                <option value="Associates Degree">Associates Degree</option>
                <option value="Bachelors Degree">Bachelors Degree</option>
                <option value="B.A (Bachelor of Arts)">
                  B.A (Bachelor of Arts)
                </option>
                <option value="B.Sc (Bachelor of Science)">
                  B.Sc (Bachelor of Science)
                </option>
                <option value="B.Com (Bachelor of Commerce)">
                  B.Com (Bachelor of Commerce)
                </option>
                <option value="B.Tech (Bachelor of Technology)">
                  B.Tech (Bachelor of Technology)
                </option>
                <option value="BBA (Bachelor of Business Administration)">
                  BBA (Bachelor of Business Administration)
                </option>
                <option value="BCA (Bachelor of Computer Applications)">
                  BCA (Bachelor of Computer Applications)
                </option>
                <option value="LLB (Bachelor of Laws)">
                  LLB (Bachelor of Laws)
                </option>
                <option value="MBBS (Bachelor of Medicine, Bachelor of Surgery)">
                  MBBS (Bachelor of Medicine, Bachelor of Surgery)
                </option>
                <option value="Pharm.D (Doctor of Pharmacy)">
                  Pharm.D (Doctor of Pharmacy)
                </option>
                <option value="BDS (Bachelor of Dental Surgery)">
                  BDS (Bachelor of Dental Surgery)
                </option>
                <option value="BAMS (Bachelor of Ayurvedic Medicine and Surgery)">
                  BAMS (Bachelor of Ayurvedic Medicine and Surgery)
                </option>
                <option value="BVSc (Bachelor of Veterinary Science)">
                  BVSc (Bachelor of Veterinary Science)
                </option>
                <option value="BE (Bachelor of Engineering)">
                  BE (Bachelor of Engineering)
                </option>
                <option value="Masters Degree">Masters Degree</option>
                <option value="M.A (Master of Arts)">
                  M.A (Master of Arts)
                </option>
                <option value="M.Sc (Master of Science)">
                  M.Sc (Master of Science)
                </option>
                <option value="M.Com (Master of Commerce)">
                  M.Com (Master of Commerce)
                </option>
                <option value="M.Tech (Master of Technology)">
                  M.Tech (Master of Technology)
                </option>
                <option value="MBA (Master of Business Administration)">
                  MBA (Master of Business Administration)
                </option>
                <option value="MCA (Master of Computer Applications)">
                  MCA (Master of Computer Applications)
                </option>
                <option value="MFA (Master of Fine Arts)">
                  MFA (Master of Fine Arts)
                </option>
                <option value="MSW (Master of Social Work)">
                  MSW (Master of Social Work)
                </option>
                <option value="LL.M (Master of Laws)">
                  LL.M (Master of Laws)
                </option>
                <option value="MD (Doctor of Medicine)">
                  MD (Doctor of Medicine)
                </option>
                <option value="MPharm (Master of Pharmacy)">
                  MPharm (Master of Pharmacy)
                </option>
                <option value="MDS (Master of Dental Surgery)">
                  MDS (Master of Dental Surgery)
                </option>
                <option value="MS (Master of Surgery)">
                  MS (Master of Surgery)
                </option>
                <option value="MBA (Master of Business Administration)">
                  MBA (Master of Business Administration)
                </option>
                <option value="M.Phil (Master of Philosophy)">
                  M.Phil (Master of Philosophy)
                </option>
                <option value="Ph.D. (Doctor of Philosophy)">
                  Ph.D. (Doctor of Philosophy)
                </option>
                <option value="Other Masters Degree (Specify)">
                  Other Masters Degree (Specify)
                </option>
                <option value="Doctorate (Post-Doctoral)">
                  Doctorate (Post-Doctoral)
                </option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {education === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherEducation}
                  onChange={(e) => setOtherEducation(e.target.value)}
                  style={{
                    marginTop: "20px",
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="profession">Profession</label>
              <select
                className="form-control"
                id="profession"
                value={profession}
                onChange={(e) => {
                  setProfession(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherProfession("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Accountant">Accountant</option>
                <option value="Artist">Artist</option>
                <option value="Engineer">Engineer</option>
                <option value="Doctor">Doctor</option>
                <option value="Teacher">Teacher</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Manager">Manager</option>
                <option value="Lawyer">Lawyer</option>
                <option value="Nurse">Nurse</option>
                <option value="Designer">Designer</option>
                <option value="Writer">Writer</option>
                <option value="Farmer">Farmer</option>
                <option value="Cook">Cook</option>
                <option value="Clerk">Clerk</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Police Officer">Police Officer</option>
                <option value="Chef">Chef</option>
                <option value="Salesperson">Salesperson</option>
                <option value="Student">Student</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Retired">Retired</option>
                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Manager">Manager</option>
                <option value="Senior Manager">Senior Manager</option>
                <option value="Consultant">Consultant</option>
                <option value="Lecturer">Lecturer</option>
                <option value="Director">Director</option>
                <option value="Technical lead">Technical lead</option>
                <option value="General Manager">General Manager</option>
                <option value="Government Officer">Government Officer</option>
                <option value="Minister">Minister</option>
                <option value="MLA">MLA</option>
                <option value="MP">MP</option>
                <option value="Speaker">Speaker</option>
                <option value="Managing Director">Managing Director</option>
                <option value="Architect">Architect</option>
                <option value="Interior Designer">Interior Designer</option>
                <option value="Human Resource">Human Resource</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Executive">Executive</option>
                <option value="Senior Executive">Senior Executive</option>
                <option value="Businessman">Businessman</option>
                <option value="Franchise owner">Franchise owner</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>
              {profession === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherProfession}
                  onChange={(e) => setOtherProfession(e.target.value)}
                  style={{
                    marginTop: "20px",
                  }}
                />
              )}
            </div>
          </div>

          <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="weightRange">Weight(in kg)</label>
              <br />
              <br />
              <br />
              <InputRange
                minValue={0}
                maxValue={100}
                value={weightRange}
                onChange={(value) => setWeightRange(value)}
              />
            </div>
          </div>
        </div>

        <div className="custom-row">
          <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="ageRange">Age</label>
              <br />
              <br />
              <br />
              <InputRange
                minValue={18}
                maxValue={100}
                value={ageRange}
                onChange={(value) => setAgeRange(value)}
              />
            </div>
          </div>

          <div className="custom-col search-rowwise">
            <div className="form-group">
              <label htmlFor="heightRange">Height</label>
              <br />
              <br />
              <br />
              <InputRange
                minValue={1}
                maxValue={10 * 12} // Assuming a maximum of 10 feet
                value={{ min: heightRange.min, max: heightRange.max }}
                onChange={(value) => {
                  setHeightRange({
                    min: value.min,
                    max: value.max,
                  });
                }}
                formatLabel={(value) => formatValue(value)}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        className="advance-search-btn"
        style={{ marginTop: "50px", marginBottom: "50px" }}
        onClick={handleSearch}
      >
        Search
      </button>

      <Aboutdownload />
      <Footer />
    </>
  );
};

export default Advancesearch;
