import React, {useState,  useRef} from "react";
import {  useLocation, useNavigate } from "react-router-dom";


export const SettingsChangepassword = () => {
  const [showForgotPasswordPopup1, setShowForgotPasswordPopup1] = useState(true);
  const [emailforgot, setEmailforgot] = useState("");
  const [emailforgoterror, setEmailforgoterror] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const userId = sessionStorage.getItem("userId");





  const [code1, setCode1] = useState("");
  const [timestamp1, setTimestamp1] = useState("");
  const [showResetPasswordField1, setShowResetPasswordField1] = useState(false);
  const [otp1, setOtp1] = useState("")
  const [email1, setEmail1] = useState("");
  const [oldpassword1, setOldpassword1] = useState("")
  const [resetPassword1, setResetPassword1] = useState("");
  const [resetPasswordError1, setResetPasswordError1] = useState("");
  const[otpError1, setOtpError1] = useState("");
  const [emailError1, setEmailError1] = useState("");
  const [oldPasswordError1, setOldPasswordError1] = useState("");



  const handleForgotEmailSubmit1 = async () => {
    if (emailforgot.trim() !== "") {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailforgot,
            action: "emailOtp1",
          }),
        });
  
        const data = await response.json();
        setCode1(data.otpdata.code);
        setTimestamp1(data.otpdata.timestamp);
  
        if (data.otpdata.message === "Mail has been sent successfully!") {
          window.alert("Otp is sent to your email. Please enter OTP to reset the password");
  
          // Show the reset password field
          setShowResetPasswordField1(true);
        } else {
          window.alert("Please enter a valid email address");
        }
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setEmailforgoterror(emailforgot.trim() === "" ? "Please enter your email" : "");
    }
  };


  const isPasswordValid = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(resetPassword1);


  const handleResetPassword1 = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (otp1.trim() !== "" && resetPassword1.trim() !== "" &&
    isPasswordValid && otp1 === code1 && oldpassword1.trim() !== "" && email1.trim() !== "" && emailRegex.test(email1.trim())
    ) {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/myprofile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email1,
            otp_check: 1,
            new_password: resetPassword1,
            old_password: oldpassword1,
            action: "change password1",
          }),
        });
  
        const data = await response.json();
  
        if (data.reset.message) {
          window.alert(data.reset.message);
          setShowForgotPasswordPopup1(false);
          navigate("/myprofile")
        }

        window.alert(data);
        setShowForgotPasswordPopup1(false);
  
          
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setResetPasswordError1(
        resetPassword1.trim() === ""
          ? "Please enter new password"
          : !isPasswordValid
          ? "Password must be at least 10 characters long and contain at least one special character and one capital letter"
          : ""
      );

      setOtpError1(
        otp.trim() === "" ? "Please enter otp" : otp !== code
          ? "OTP not verified"
          : ""
      );

      setOldPasswordError1(
        oldpassword1.trim() === "" ? "Please enter old password" : ""
      );

      setEmailError1(
        email1.trim() === ""
            ? "Please enter your email"
            : !emailRegex.test(email1.trim())
            ? "Please enter a valid email address"
            : ""
    );

    }

  }



  return (
    <div style={{height:"100vh", background: "linear-gradient(180deg,#fcd232,#e02e1b)"}}>
      


      {showForgotPasswordPopup1 && (
      <div className="forgot-password-popup accountancy" ref={popupRef}>
        <div className="popup-content">
          
          <br></br><br></br>


          {showResetPasswordField1 ? (
    <>
    <h2 style={{fontWeight: "700", fontSize: "30px", textTransform:"none",}}>Change Password</h2>
      <p style={{marginTop:"-10px", }}>Enter OTP and other fields to change password</p>
      <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
          type="email"
          placeholder="Enter your email"
          value={email1}
          onChange={(e) => setEmail1(e.target.value)}
        />
        <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px', marginLeft:"12px" }}>
          {emailError1}
        </div>

      <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
        type="number"
        placeholder="Enter OTP"
        value={otp1}
        onChange={(e) => setOtp1(e.target.value)}
      />
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {otpError1}
      </div>

      <input style={{width:"250px", padding: "12px",fontSize: "16px"}}
        type="text"
        placeholder="Enter Old Password"
        value={oldpassword1}
        onChange={(e) => setOldpassword1(e.target.value)}
      />
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {oldPasswordError1}
      </div>

      
      <input style={{width:"250px", padding: "12px",fontSize: "16px"}}
        type="text"
        placeholder="Enter new Password"
        value={resetPassword1}
        onChange={(e) => setResetPassword1(e.target.value)}
      />
      <br></br>
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {resetPasswordError1}
      </div>
      <div className="otp-btn-forgot" onClick={handleResetPassword1}>Change Password</div>
    </>
  ):(


          <>
          <h2 style={{fontWeight: "700", fontSize: "30px", textTransform:"none",}}>Change Password</h2>
          <p style={{marginTop:"-10px"}}>Enter your email address</p>
          <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
            type="email"
            placeholder="Enter your email"
            value={emailforgot}
            onChange={(e) => setEmailforgot(e.target.value)}
          />
          <br></br>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px', marginLeft:"12px" }}>
            {emailforgoterror}
            </div>
          <div className="otp-btn-forgot" onClick={handleForgotEmailSubmit1}>GET OTP</div>
          </>
  )}
        </div>
      </div>
    )}





      
    </div>
    




  );
};
