export const districtData = {  
   "states":[  
      {  
         "state":"Andhra Pradesh",
         "districts":[  
            "Anantapur",
            "Chittoor",
            "East Godavari",
            "Guntur",
            "Krishna",
            "Kurnool",
            "Nellore",
            "Prakasam",
            "Srikakulam",
            "Visakhapatnam",
            "Vizianagaram",
            "West Godavari",
            "YSR Kadapa"
         ]
      },
      {  
         "state":"Arunachal Pradesh",
         "districts":[  
            "Tawang",
            "West Kameng",
            "East Kameng",
            "Papum Pare",
            "Kurung Kumey",
            "Kra Daadi",
            "Lower Subansiri",
            "Upper Subansiri",
            "West Siang",
            "East Siang",
            "Siang",
            "Upper Siang",
            "Lower Siang",
            "Lower Dibang Valley",
            "Dibang Valley",
            "Anjaw",
            "Lohit",
            "Namsai",
            "Changlang",
            "Tirap",
            "Longding"
         ]
      },
      {  
         "state":"Assam",
         "districts":[  
            "Baksa",
            "Barpeta",
            "Biswanath",
            "Bongaigaon",
            "Cachar",
            "Charaideo",
            "Chirang",
            "Darrang",
            "Dhemaji",
            "Dhubri",
            "Dibrugarh",
            "Goalpara",
            "Golaghat",
            "Hailakandi",
            "Hojai",
            "Jorhat",
            "Kamrup Metropolitan",
            "Kamrup",
            "Karbi Anglong",
            "Karimganj",
            "Kokrajhar",
            "Lakhimpur",
            "Majuli",
            "Morigaon",
            "Nagaon",
            "Nalbari",
            "Dima Hasao",
            "Sivasagar",
            "Sonitpur",
            "South Salmara-Mankachar",
            "Tinsukia",
            "Udalguri",
            "West Karbi Anglong"
         ]
      },
      {  
         "state":"Bihar",
         "districts":[  
            "Araria",
            "Arwal",
            "Aurangabad",
            "Banka",
            "Begusarai",
            "Bhagalpur",
            "Bhojpur",
            "Buxar",
            "Darbhanga",
            "East Champaran (Motihari)",
            "Gaya",
            "Gopalganj",
            "Jamui",
            "Jehanabad",
            "Kaimur (Bhabua)",
            "Katihar",
            "Khagaria",
            "Kishanganj",
            "Lakhisarai",
            "Madhepura",
            "Madhubani",
            "Munger (Monghyr)",
            "Muzaffarpur",
            "Nalanda",
            "Nawada",
            "Patna",
            "Purnia (Purnea)",
            "Rohtas",
            "Saharsa",
            "Samastipur",
            "Saran",
            "Sheikhpura",
            "Sheohar",
            "Sitamarhi",
            "Siwan",
            "Supaul",
            "Vaishali",
            "West Champaran"
         ]
      },
      {  
         "state":"Chandigarh",
         "districts":[  
            "Chandigarh"
         ]
      },
      {  
         "state":"Chattisgarh",
         "districts":[  
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Bemetara",
            "Bijapur",
            "Bilaspur",
            "Dantewada (South Bastar)",
            "Dhamtari",
            "Durg",
            "Gariyaband",
            "Janjgir-Champa",
            "Jashpur",
            "Kabirdham (Kawardha)",
            "Kanker (North Bastar)",
            "Kondagaon",
            "Korba",
            "Korea (Koriya)",
            "Mahasamund",
            "Mungeli",
            "Narayanpur",
            "Raigarh",
            "Raipur",
            "Rajnandgaon",
            "Sukma",
            "Surajpur  ",
            "Surguja"
         ]
      },
      {  
         "state":"Dadra and Nagar Haveli",
         "districts":[  
            "Dadra & Nagar Haveli"
         ]
      },
      {  
         "state":"Daman and Diu",
         "districts":[  
            "Daman",
            "Diu"
         ]
      },
      {  
         "state":"Delhi",
         "districts":[  
            "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East  Delhi",
            "North West  Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West  Delhi",
            "West Delhi"
         ]
      },
      {  
         "state":"Goa",
         "districts":[  
            "North Goa",
            "South Goa"
         ]
      },
      {  
         "state":"Gujarat",
         "districts":[  
            "Ahmedabad",
            "Amreli",
            "Anand",
            "Aravalli",
            "Banaskantha (Palanpur)",
            "Bharuch",
            "Bhavnagar",
            "Botad",
            "Chhota Udepur",
            "Dahod",
            "Dangs (Ahwa)",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Jamnagar",
            "Junagadh",
            "Kachchh",
            "Kheda (Nadiad)",
            "Mahisagar",
            "Mehsana",
            "Morbi",
            "Narmada (Rajpipla)",
            "Navsari",
            "Panchmahal (Godhra)",
            "Patan",
            "Porbandar",
            "Rajkot",
            "Sabarkantha (Himmatnagar)",
            "Surat",
            "Surendranagar",
            "Tapi (Vyara)",
            "Vadodara",
            "Valsad"
         ]
      },
      {  
         "state":"Haryana",
         "districts":[  
            "Ambala",
            "Bhiwani",
            "Charkhi Dadri",
            "Faridabad",
            "Fatehabad",
            "Gurgaon",
            "Hisar",
            "Jhajjar",
            "Jind",
            "Kaithal",
            "Karnal",
            "Kurukshetra",
            "Mahendragarh",
            "Mewat",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Rewari",
            "Rohtak",
            "Sirsa",
            "Sonipat",
            "Yamunanagar"
         ]
      },
      {  
         "state":"Himachal Pradesh",
         "districts":[  
            "Bilaspur",
            "Chamba",
            "Hamirpur",
            "Kangra",
            "Kinnaur",
            "Kullu",
            "Lahaul &amp; Spiti",
            "Mandi",
            "Shimla",
            "Sirmaur (Sirmour)",
            "Solan",
            "Una"
         ]
      },
      {  
         "state":"Jammu and Kashmir",
         "districts":[  
            "Anantnag",
            "Bandipore",
            "Baramulla",
            "Budgam",
            "Doda",
            "Ganderbal",
            "Jammu",
            "Kargil",
            "Kathua",
            "Kishtwar",
            "Kulgam",
            "Kupwara",
            "Leh",
            "Poonch",
            "Pulwama",
            "Rajouri",
            "Ramban",
            "Reasi",
            "Samba",
            "Shopian",
            "Srinagar",
            "Udhampur"
         ]
      },
      {  
         "state":"Jharkhand",
         "districts":[  
            "Bokaro",
            "Chatra",
            "Deoghar",
            "Dhanbad",
            "Dumka",
            "East Singhbhum",
            "Garhwa",
            "Giridih",
            "Godda",
            "Gumla",
            "Hazaribag",
            "Jamtara",
            "Khunti",
            "Koderma",
            "Latehar",
            "Lohardaga",
            "Pakur",
            "Palamu",
            "Ramgarh",
            "Ranchi",
            "Sahibganj",
            "Seraikela-Kharsawan",
            "Simdega",
            "West Singhbhum"
         ]
      },
      {  
         "state":"Karnataka",
         "districts":[  
            "Bagalkot",
            "Ballari (Bellary)",
            "Belagavi (Belgaum)",
            "Bengaluru (Bangalore) Rural",
            "Bengaluru (Bangalore) Urban",
            "Bidar",
            "Chamarajanagar",
            "Chikballapur",
            "Chikkamagaluru (Chikmagalur)",
            "Chitradurga",
            "Dakshina Kannada",
            "Davangere",
            "Dharwad",
            "Gadag",
            "Hassan",
            "Haveri",
            "Kalaburagi (Gulbarga)",
            "Kodagu",
            "Kolar",
            "Koppal",
            "Mandya",
            "Mysuru (Mysore)",
            "Raichur",
            "Ramanagara",
            "Shivamogga (Shimoga)",
            "Tumakuru (Tumkur)",
            "Udupi",
            "Uttara Kannada (Karwar)",
            "Vijayapura (Bijapur)",
            "Yadgir"
         ]
      },
      {  
         "state":"Kerala",
         "districts":[  
            "Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad"
         ]
      },
      {  
         "state":"Ladakh",
         "districts":[  
            "Agatti",
            "Amini",
            "Androth",
            "Bithra",
            "Chethlath",
            "Kavaratti",
            "Kadmath",
            "Kalpeni",
            "Kilthan",
            "Minicoy"
         ]
      },
      {  
         "state":"Madhya Pradesh",
         "districts":[  
            "Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Ashoknagar",
            "Balaghat",
            "Barwani",
            "Betul",
            "Bhind",
            "Bhopal",
            "Burhanpur",
            "Chhatarpur",
            "Chhindwara",
            "Damoh",
            "Datia",
            "Dewas",
            "Dhar",
            "Dindori",
            "Guna",
            "Gwalior",
            "Harda",
            "Hoshangabad",
            "Indore",
            "Jabalpur",
            "Jhabua",
            "Katni",
            "Khandwa",
            "Khargone",
            "Mandla",
            "Mandsaur",
            "Morena",
            "Narsinghpur",
            "Neemuch",
            "Panna",
            "Raisen",
            "Rajgarh",
            "Ratlam",
            "Rewa",
            "Sagar",
            "Satna",
            "Sehore",
            "Seoni",
            "Shahdol",
            "Shajapur",
            "Sheopur",
            "Shivpuri",
            "Sidhi",
            "Singrauli",
            "Tikamgarh",
            "Ujjain",
            "Umaria",
            "Vidisha"
         ]
      },
      {  
         "state":"Maharashtra",
         "districts":[  
            "Ahmednagar",
            "Akola",
            "Amravati",
            "Aurangabad",
            "Beed",
            "Bhandara",
            "Buldhana",
            "Chandrapur",
            "Dhule",
            "Gadchiroli",
            "Gondia",
            "Hingoli",
            "Jalgaon",
            "Jalna",
            "Kolhapur",
            "Latur",
            "Mumbai City",
            "Mumbai Suburban",
            "Nagpur",
            "Nanded",
            "Nandurbar",
            "Nashik",
            "Osmanabad",
            "Palghar",
            "Parbhani",
            "Pune",
            "Raigad",
            "Ratnagiri",
            "Sangli",
            "Satara",
            "Sindhudurg",
            "Solapur",
            "Thane",
            "Wardha",
            "Washim",
            "Yavatmal"
         ]
      },
      {  
         "state":"Manipur",
         "districts":[  
            "Bishnupur",
            "Chandel",
            "Churachandpur",
            "Imphal East",
            "Imphal West",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Thoubal",
            "Ukhrul"
         ]
      },
      {  
         "state":"Meghalaya",
         "districts":[  
            "East Garo Hills",
            "East Jaintia Hills",
            "East Khasi Hills",
            "North Garo Hills",
            "Ri Bhoi",
            "South Garo Hills",
            "South West Garo Hills ",
            "South West Khasi Hills",
            "West Garo Hills",
            "West Jaintia Hills",
            "West Khasi Hills"
         ]
      },
      {  
         "state":"Mizoram",
         "districts":[  
            "Aizawl",
            "Champhai",
            "Kolasib",
            "Lawngtlai",
            "Lunglei",
            "Mamit",
            "Saiha",
            "Serchhip"
         ]
      },
      {  
         "state":"Nagaland",
         "districts":[  
            "Dimapur",
            "Kiphire",
            "Kohima",
            "Longleng",
            "Mokokchung",
            "Mon",
            "Peren",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto"
         ]
      },
      {  
         "state":"Odisha",
         "districts":[  
            "Angul",
            "Balangir",
            "Balasore",
            "Bargarh",
            "Bhadrak",
            "Boudh",
            "Cuttack",
            "Deogarh",
            "Dhenkanal",
            "Gajapati",
            "Ganjam",
            "Jagatsinghapur",
            "Jajpur",
            "Jharsuguda",
            "Kalahandi",
            "Kandhamal",
            "Kendrapara",
            "Kendujhar (Keonjhar)",
            "Khordha",
            "Koraput",
            "Malkangiri",
            "Mayurbhanj",
            "Nabarangpur",
            "Nayagarh",
            "Nuapada",
            "Puri",
            "Rayagada",
            "Sambalpur",
            "Sonepur",
            "Sundargarh"
         ]
      },
      {  
         "state":"Puducherry",
         "districts":[  
            "Karaikal",
            "Mahe",
            "Pondicherry",
            "Yanam"
         ]
      },
      {  
         "state":"Punjab",
         "districts":[  
            "Amritsar",
            "Barnala",
            "Bathinda",
            "Faridkot",
            "Fatehgarh Sahib",
            "Fazilka",
            "Ferozepur",
            "Gurdaspur",
            "Hoshiarpur",
            "Jalandhar",
            "Kapurthala",
            "Ludhiana",
            "Mansa",
            "Moga",
            "Muktsar",
            "Nawanshahr (Shahid Bhagat Singh Nagar)",
            "Pathankot",
            "Patiala",
            "Rupnagar",
            "Sahibzada Ajit Singh Nagar (Mohali)",
            "Sangrur",
            "Tarn Taran"
         ]
      },
      {  
         "state":"Rajasthan",
         "districts":[  
            "Ajmer",
            "Alwar",
            "Banswara",
            "Baran",
            "Barmer",
            "Bharatpur",
            "Bhilwara",
            "Bikaner",
            "Bundi",
            "Chittorgarh",
            "Churu",
            "Dausa",
            "Dholpur",
            "Dungarpur",
            "Hanumangarh",
            "Jaipur",
            "Jaisalmer",
            "Jalore",
            "Jhalawar",
            "Jhunjhunu",
            "Jodhpur",
            "Karauli",
            "Kota",
            "Nagaur",
            "Pali",
            "Pratapgarh",
            "Rajsamand",
            "Sawai Madhopur",
            "Sikar",
            "Sirohi",
            "Sri Ganganagar",
            "Tonk",
            "Udaipur"
         ]
      },
      {  
         "state":"Sikkim",
         "districts":[  
            "East Sikkim",
            "North Sikkim",
            "South Sikkim",
            "West Sikkim"
         ]
      },
      {  
         "state":"Tamil Nadu",
         "districts":[  
            "Ariyalur",
            "Chennai",
            "Coimbatore",
            "Cuddalore",
            "Dharmapuri",
            "Dindigul",
            "Erode",
            "Kanchipuram",
            "Kanyakumari",
            "Karur",
            "Krishnagiri",
            "Madurai",
            "Nagapattinam",
            "Namakkal",
            "Nilgiris",
            "Perambalur",
            "Pudukkottai",
            "Ramanathapuram",
            "Salem",
            "Sivaganga",
            "Thanjavur",
            "Theni",
            "Thoothukudi (Tuticorin)",
            "Tiruchirappalli",
            "Tirunelveli",
            "Tiruppur",
            "Tiruvallur",
            "Tiruvannamalai",
            "Tiruvarur",
            "Vellore",
            "Viluppuram",
            "Virudhunagar"
         ]
      },
      {  
         "state":"Telangana",
         "districts":[  
            "Adilabad",
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhoopalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Mahabubnagar",
            "Mancherial",
            "Medak",
            "Medchal",
            "Nagarkurnool",
            "Nalgonda",
            "Nirmal",
            "Nizamabad",
            "Peddapalli",
            "Rajanna Sircilla",
            "Rangareddy",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Warangal (Rural)",
            "Warangal (Urban)",
            "Yadadri Bhuvanagiri"
         ]
      },
      {  
         "state":"Tripura",
         "districts":[  
            "Dhalai",
            "Gomati",
            "Khowai",
            "North Tripura",
            "Sepahijala",
            "South Tripura",
            "Unakoti",
            "West Tripura"
         ]
      },
      {  
         "state":"Uttarakhand",
         "districts":[  
            "Almora",
            "Bageshwar",
            "Chamoli",
            "Champawat",
            "Dehradun",
            "Haridwar",
            "Nainital",
            "Pauri Garhwal",
            "Pithoragarh",
            "Rudraprayag",
            "Tehri Garhwal",
            "Udham Singh Nagar",
            "Uttarkashi"
         ]
      },
      {  
         "state":"Uttar Pradesh",
         "districts":[  
            "Agra",
            "Aligarh",
            "Allahabad",
            "Ambedkar Nagar",
            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
            "Amroha (J.P. Nagar)",
            "Auraiya",
            "Azamgarh",
            "Baghpat",
            "Bahraich",
            "Ballia",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Bareilly",
            "Basti",
            "Bhadohi",
            "Bijnor",
            "Budaun",
            "Bulandshahr",
            "Chandauli",
            "Chitrakoot",
            "Deoria",
            "Etah",
            "Etawah",
            "Faizabad",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Gautam Buddha Nagar",
            "Ghaziabad",
            "Ghazipur",
            "Gonda",
            "Gorakhpur",
            "Hamirpur",
            "Hapur (Panchsheel Nagar)",
            "Hardoi",
            "Hathras",
            "Jalaun",
            "Jaunpur",
            "Jhansi",
            "Kannauj",
            "Kanpur Dehat",
            "Kanpur Nagar",
            "Kanshiram Nagar (Kasganj)",
            "Kaushambi",
            "Kushinagar (Padrauna)",
            "Lakhimpur - Kheri",
            "Lalitpur",
            "Lucknow",
            "Maharajganj",
            "Mahoba",
            "Mainpuri",
            "Mathura",
            "Mau",
            "Meerut",
            "Mirzapur",
            "Moradabad",
            "Muzaffarnagar",
            "Pilibhit",
            "Pratapgarh",
            "RaeBareli",
            "Rampur",
            "Saharanpur",
            "Sambhal (Bhim Nagar)",
            "Sant Kabir Nagar",
            "Shahjahanpur",
            "Shamali (Prabuddh Nagar)",
            "Shravasti",
            "Siddharth Nagar",
            "Sitapur",
            "Sonbhadra",
            "Sultanpur",
            "Unnao",
            "Varanasi"
         ]
      },
      {  
         "state":"West Bengal",
         "districts":[  
            "Alipurduar",
            "Bankura",
            "Birbhum",
            "Burdwan (Bardhaman)",
            "Cooch Behar",
            "Dakshin Dinajpur (South Dinajpur)",
            "Darjeeling",
            "Hooghly",
            "Howrah",
            "Jalpaiguri",
            "Kalimpong",
            "Kolkata",
            "Malda",
            "Murshidabad",
            "Nadia",
            "North 24 Parganas",
            "Paschim Medinipur (West Medinipur)",
            "Purba Medinipur (East Medinipur)",
            "Purulia",
            "South 24 Parganas",
            "Uttar Dinajpur (North Dinajpur)"
         ]
      }
   ]
}


export const cityData = {  
 "states":[  
    {  
       "state":"Andhra Pradesh",
       "city":[  
          "Adoni",
          "Amaravati",
          "Anantapur",
          "Chandragiri",
          "Chittoor",
          "Dowlaiswaram",
          "Eluru",
          "Guntur",
          "Kadapa",
          "Kakinada",
          "Kurnool",
          "Machilipatnam",
          "Nagarjunakoṇḍa",
          "Rajahmundry",
          "Srikakulam",
          "Tirupati",
          "Vijayawada",
          "Visakhapatnam",
          "Vizianagaram",
          "Yemmiganur"
       ]
    },
    {  
       "state":"Arunachal Pradesh",
       "city":[  
          "Itanagar"
          
       ]
    },
    {  
       "state":"Assam",
       "city":[  
          "Dhuburi",
          "Dibrugarh",
          "Dispur",
          "Guwahati",
          "Jorhat",
          "Nagaon",
          "Sivasagar",
          "Silchar",
          "Tezpur",
          "Tinsukia"
       ]
    },
    {  
       "state":"Bihar",
       "city":[  
          "Ara",
          "Barauni",
          "Begusarai",
          "Bettiah",
          "Bhagalpur",
          "Bihar Sharif",
          "Bodh Gaya",
          "Buxar",
          "Chapra",
          "Darbhanga",
          "Dehri",
          "Dinapur Nizamat",
          "Gaya",
          "Hajipur",
          "Jamalpur",
          "Katihar",
          "Madhubani",
          "Motihari",
          "Munger",
          "Muzaffarpur",
          "Patna",
          "Purnia",
          "Pusa",
          "Saharsa",
          "Samastipur",
          "Sasaram",
          "Sitamarhi",
          "Siwan"
       ]
    },
    {  
       "state":"Chandigarh",
       "city":[  
          "Chandigarh"
       ]
    },
    {  
       "state":"Chattisgarh",
       "city":[  
          "Ambikapur",
          "Bhilai",
          "Bilaspur",
          "Dhamtari",
          "Durg",
          "Jagdalpur",
          "Raipur",
          "Rajnandgaon"
       ]
    },
    {  
       "state":"Dadra and Nagar Haveli",
       "city":[  
          "Daman",
          "Diu",
          "Silvassa"
       ]
    },
    
    {  
       "state":"Delhi",
       "city":[  
          "Delhi",
          "New Delhi"
          
       ]
    },
    {  
       "state":"Goa",
       "city":[  
          "Madgaon",
          "Panaji"
       ]
    },
    {  
       "state":"Gujarat",
       "city":[  
          "Ahmadabad",
          "Amreli",
          "Bharuch",
          "Bhavnagar",
          "Bhuj",
          "Dwarka",
          "Gandhinagar",
          "Godhra",
          "Jamnagar",
          "Junagadh",
          "Kandla",
          "Khambhat",
          "Kheda",
          "Mahesana",
          "Morbi",
          "Nadiad",
          "Navsari",
          "Okha",
          "Palanpur",
          "Patan",
          "Porbandar",
          "Rajkot",
          "Surat",
          "Surendranagar",
          "Valsad",
          "Veraval"
       ]
    },
    {  
       "state":"Haryana",
       "city":[  
          "Ambala",
          "Bhiwani",
          "Chandigarh",
          "Faridabad",
          "Firozpur Jhirka",
          "Gurugram",
          "Hansi",
          "Hisar",
          "Jind",
          "Kaithal",
          "Karnal",
          "Kurukshetra",
          "Panipat",
          "Pehowa",
          "Rewari",
          "Rohtak",
          "Sirsa",
          "Sonipat"
       ]
    },
    {  
       "state":"Himachal Pradesh",
       "city":[  
          "Bilaspur",
          "Chamba",
          "Dalhousie",
          "Dharmshala",
          "Hamirpur",
          "Kangra",
          "Kullu",
          "Mandi",
          "Nahan",
          "Shimla",
          "Una"
       ]
    },
    {  
       "state":"Jammu and Kashmir",
       "city":[  
          "Anantnag",
          "Baramula",
          "Doda",
          "Gulmarg",
          "Jammu",
          "Kathua",
          "Punch",
          "Rajouri",
          "Srinagar",
          "Udhampur"
       ]
    },
    {  
       "state":"Jharkhand",
       "city":[  
          "Bokaro",
          "Chaibasa",
          "Deoghar",
          "Dhanbad",
          "Dumka",
          "Giridih",
          "Hazaribag",
          "Jamshedpur",
          "Jharia",
          "Rajmahal",
          "Ranchi",
          "Saraikela"
       ]
    },
    {  
       "state":"Karnataka",
       "city":[  
          "Badami",
          "Ballari",
          "Bengaluru",
          "Belagavi",
          "Bhadravati",
          "Bidar",
          "Chikkamagaluru",
          "Chitradurga",
          "Davangere",
          "Halebid",
          "Hassan",
          "Hubballi-Dharwad",
          "Kalaburagi",
          "Kolar",
          "Madikeri",
          "Mandya",
          "Mangaluru",
          "Mysuru",
          "Raichur",
          "Shivamogga",
          "Shravanabelagola",
          "Shrirangapattana",
          "Tumakuru",
          "Vijayapura"
       ]
    },
    {  
       "state":"Kerala",
       "city":[  
          "Alappuzha",
          "Vatakara",
          "Idukki",
          "Kannur",
          "Kochi",
          "Kollam",
          "Kottayam",
          "Kozhikode",
          "Mattancheri",
          "Palakkad",
          "Thalassery",
          "Thiruvananthapuram",
          "Thrissur"
       ]
    },
    {  
       "state":"Ladakh",
       "city":[  
          "Kargil",
          "Leh"
          
       ]
    },
    {  
       "state":"Madhya Pradesh",
       "city":[  
          "Balaghat",
          "Barwani",
          "Betul",
          "Bharhut",
          "Bhind",
          "Bhojpur",
          "Bhopal",
          "Burhanpur",
          "Chhatarpur",
          "Chhindwara",
          "Damoh",
          "Datia",
          "Dewas",
          "Dhar",
          "Dr. Ambedkar Nagar (Mhow)",
          "Guna",
          "Gwalior",
          "Hoshangabad",
          "Indore",
          "Itarsi",
          "Jabalpur",
          "Jhabua",
          "Khajuraho",
          "Khandwa",
          "Khargone",
          "Maheshwar",
          "Mandla",
          "Mandsaur",
          "Morena",
          "Murwara",
          "Narsimhapur",
          "Narsinghgarh",
          "Narwar",
          "Neemuch",
          "Nowgong",
          "Orchha",
          "Panna",
          "Raisen",
          "Rajgarh",
          "Ratlam",
          "Rewa",
          "Sagar",
          "Sarangpur",
          "Satna",
          "Sehore",
          "Seoni",
          "Shahdol",
          "Shajapur",
          "Sheopur",
          "Shivpuri",
          "Ujjain",
          "Vidisha"
       ]
    },
    {  
       "state":"Maharashtra",
       "city":[  
          "Ahmadnagar",
          "Akola",
          "Amravati",
          "Aurangabad",
          "Bhandara",
          "Bhusawal",
          "Bid",
          "Buldhana",
          "Chandrapur",
          "Daulatabad",
          "Dhule",
          "Jalgaon",
          "Kalyan",
          "Karli",
          "Kolhapur",
          "Mahabaleshwar",
          "Malegaon",
          "Matheran",
          "Mumbai",
          "Nagpur",
          "Nanded",
          "Nashik",
          "Osmanabad",
          "Pandharpur",
          "Parbhani",
          "Pune",
          "Ratnagiri",
          "Sangli",
          "Satara",
          "Sevagram",
          "Solapur",
          "Thane",
          "Ulhasnagar",
          "Vasai-Virar",
          "Wardha",
          "Yavatmal"
       ]
    },
    {  
       "state":"Manipur",
       "city":[  
          "Imphal"
          
       ]
    },
    {  
       "state":"Meghalaya",
       "city":[  
          "Cherrapunji",
          "Shillong"
          
       ]
    },
    {  
       "state":"Mizoram",
       "city":[  
          "Aizawl",
          "Lunglei"
          
       ]
    },
    {  
       "state":"Nagaland",
       "city":[  
          "Kohima",
          "Mon",
          "Phek",
          "Wokha",
          "Zunheboto"
       ]
    },
    {  
       "state":"Odisha",
       "city":[  
          "Balangir",
          "Baleshwar",
          "Baripada",
          "Bhubaneshwar",
          "Brahmapur",
          "Cuttack",
          "Dhenkanal",
          "Kendujhar",
          "Konark",
          "Koraput",
          "Paradip",
          "Phulabani",
          "Puri",
          "Sambalpur",
          "Udayagiri"
       ]
    },
    {  
       "state":"Puducherry",
       "city":[  
          "Karaikal",
          "Mahe",
          "Pondicherry",
          "Yanam"
       ]
    },
    {  
       "state":"Punjab",
       "city":[  
          "Amritsar",
          "Batala",
          "Chandigarh",
          "Faridkot",
          "Firozpur",
          "Gurdaspur",
          "Hoshiarpur",
          "Jalandhar",
          "Kapurthala",
          "Ludhiana",
          "Nabha",
          "Patiala",
          "Rupnagar",
          "Sangrur"
       ]
    },
    {  
       "state":"Rajasthan",
       "city":[  
          "Abu",
          "Ajmer",
          "Alwar",
          "Amer",
          "Barmer",
          "Beawar",
          "Bharatpur",
          "Bhilwara",
          "Bikaner",
          "Bundi",
          "Chittaurgarh",
          "Churu",
          "Dhaulpur",
          "Dungarpur",
          "Ganganagar",
          "Hanumangarh",
          "Jaipur",
          "Jaisalmer",
          "Jalor",
          "Jhalawar",
          "Jhunjhunu",
          "Jodhpur",
          "Kishangarh",
          "Kota",
          "Merta",
          "Nagaur",
          "Nathdwara",
          "Pali",
          "Phalodi",
          "Pushkar",
          "Sawai Madhopur",
          "Shahpura",
          "Sikar",
          "Sirohi",
          "Tonk",
          "Udaipur"
       ]
    },
    {  
       "state":"Sikkim",
       "city":[  
          "Gangtok",
          "Gyalshing",
          "Lachung",
          "Mangan"
       ]
    },
    {  
       "state":"Tamil Nadu",
       "city":[  
          "Arcot",
          "Chengalpattu",
          "Chennai",
          "Chidambaram",
          "Coimbatore",
          "Cuddalore",
          "Dharmapuri",
          "Dindigul",
          "Erode",
          "Kanchipuram",
          "Kanniyakumari",
          "Kodaikanal",
          "Kumbakonam",
          "Madurai",
          "Mamallapuram",
          "Nagappattinam",
          "Nagercoil",
          "Palayamkottai",
          "Pudukkottai",
          "Rajapalayam",
          "Ramanathapuram",
          "Salem",
          "Thanjavur",
          "Tiruchchirappalli",
          "Tirunelveli",
          "Tiruppur",
          "Thoothukudi",
          "Udhagamandalam",
          "Vellore"
       ]
    },
    {  
       "state":"Telangana",
       "city":[  
          "Hyderabad",
          "Karimnagar",
          "Khammam",
          "Mahbubnagar",
          "Nizamabad",
          "Sangareddi",
          "Warangal"
       ]
    },
    {  
       "state":"Tripura",
       "city":[  
          "Agartala"
       ]
    },
    {  
       "state":"Uttarakhand",
       "city":[  
          "Almora",
          "Dehra Dun",
          "Haridwar",
          "Mussoorie",
          "Nainital",
          "Pithoragarh"
       ]
    },
    {  
       "state":"Uttar Pradesh",
       "city":[  
          "Agra",
          "Aligarh",
          "Amroha",
          "Ayodhya",
          "Azamgarh",
          "Bahraich",
          "Ballia",
          "Banda",
          "Bara Banki",
          "Bareilly",
          "Basti",
          "Bijnor",
          "Bithur",
          "Budaun",
          "Bulandshahr",
          "Deoria",
          "Etah",
          "Etawah",
          "Faizabad",
          "Farrukhabad-cum-Fatehgarh",
          "Fatehpur",
          "Fatehpur Sikri",
          "Ghaziabad",
          "Ghazipur",
          "Gonda",
          "Gorakhpur",
          "Hamirpur",
          "Hardoi",
          "Hathras",
          "Jalaun",
          "Jaunpur",
          "Jhansi",
          "Kannauj",
          "Kanpur",
          "Lakhimpur",
          "Lalitpur",
          "Lucknow",
          "Mainpuri",
          "Mathura",
          "Meerut",
          "Mirzapur-Vindhyachal",
          "Moradabad",
          "Muzaffarnagar",
          "Partapgarh",
          "Pilibhit",
          "Prayagraj",
          "Rae Bareli",
          "Rampur",
          "Saharanpur",
          "Sambhal",
          "Shahjahanpur",
          "Sitapur",
          "Sultanpur",
          "Tehri",
          "Varanasi"
       ]
    },
    {  
       "state":"West Bengal",
       "city":[  
          "Alipore",
          "Alipur Duar",
          "Asansol",
          "Baharampur",
          "Bally",
          "Balurghat",
          "Bankura",
          "Baranagar",
          "Barasat",
          "Barrackpore",
          "Basirhat",
          "Bhatpara",
          "Bishnupur",
          "Budge Budge",
          "Burdwan",
          "Chandernagore",
          "Darjeeling",
          "Diamond Harbour",
          "Dum Dum",
          "Durgapur",
          "Halisahar",
          "Haora",
          "Hugli",
          "Ingraj Bazar",
          "Jalpaiguri",
          "Kalimpong",
          "Kamarhati",
          "Kanchrapara",
          "Kharagpur",
          "Cooch Behar",
          "Kolkata",
          "Krishnanagar",
          "Malda",
          "Midnapore",
          "Murshidabad",
          "Nabadwip",
          "Palashi",
          "Panihati",
          "Purulia",
          "Raiganj",
          "Santipur",
          "Shantiniketan",
          "Shrirampur",
          "Siliguri",
          "Siuri",
          "Tamluk",
          "Titagarh"
       ]
    }
 ]
}


