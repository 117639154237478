import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Loginnavigation } from "./Loginnavigation";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import "./Myprofile.css"; 
import useProfile from "./utils/useProfile";
import name from "./images/name.png"
import useHandleimg from "./utils/useHandleimg";
import useHandlealbum from "./utils/useHandlealbum";
import religionimg from './images/religion-mymatch.png'
import heightimg from './images/height-mymatch.png'
import salary from './images/salary-mymatch.png'
import locationimg from './images/location-mymatch.png'
import dob from './images/dob-mymatch.png'
import video from './images/video.png'
import educationmymatches from './images/education-mymatches.png'
import careermymatches from './images/career-mymatches.png'
import familymymatches from './images/family-mymatches.png'
import contactmymatches from './images/contact-mymatches.png'
import kundalimymatches from './images/kundali-mymatches.png'
import lifestylemymatches from './images/lifestyle-mymatches.png'
import partnermymatches from './images/partner-mymatch.png'
import personalitymymatches from './images/personality-mymatch.png'
import {Piechart, Piechartcomparison } from "./Piechart";
import usePersonalitymeter from "./utils/usePersonalitymeter";
import usehandleSentinterest from "./utils/useSentinterest";
import telegram from './images/telegram.png'
import telesent from './images/telesent.png'
import down from './images/down.jpeg'
import useSentrequestlist from "./utils/useSentrequestlist";
import useUpdateContact from "./utils/useUpdateContact";
import { useNavigate } from "react-router-dom";
import useFetchpermission from "./utils/useFetchpermission";




const MyfavoriteCompleteProfile = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const userId = sessionStorage.getItem("userId");
  const userInfo = useProfile(id);
  const userImg = useHandleimg(id);
  const userAlbum = useHandlealbum(id);
  const personalityInfo = usePersonalitymeter(id)
  
  const sentInterestlist = useSentrequestlist(userId);

  

 
  const userupdateInfo = useUpdateContact(userId)
  console.log(userupdateInfo?.message)
  const [isOpen, setIsOpen] = useState(false);
  const [isfree, setIsfree] = useState(false)


  const permission = useFetchpermission(userId)
  const [isAstro, setIsAstro] = useState(false);
  const [isastrofree, setIsastrofree] = useState(false)
  const [isinterestfree, setIsinterestfree] = useState(false);


  const [hobbies1, setHobbies1] = useState(userInfo?.profile_data?.hobbies)
  const [partnerfoodstyle, setPartnerfoodstyle] = useState(userInfo?.partner_pref?.food_style)
  const [partnerhobbies, setPartnerhobbies] = useState(userInfo?.partner_pref?.hobbies)
  

  


 


  // for updating initial value


  useEffect(() => {
    if (userInfo?.profile_data?.hobbies !== null) {
      setHobbies1(userInfo?.profile_data?.hobbies);
    }
  }, [userInfo?.profile_data?.hobbies]);



  useEffect(() => {
    if (userInfo?.partner_pref?.food_style !== null) {
      setPartnerfoodstyle(userInfo?.partner_pref?.food_style);
    }
  }, [userInfo?.partner_pref?.food_style]);

  useEffect(() => {
    if (userInfo?.partner_pref?.hobbies !== null) {
      setPartnerhobbies(userInfo?.partner_pref?.hobbies);
    }
  }, [userInfo?.partner_pref?.hobbies]);






  // Settings for the image slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let inputClassName = "partner-value-div-basic";

  if (window.innerWidth > 865) {
    inputClassName = "partner-title-div-basic";
  }

  if (window.innerWidth <= 400) {
    inputClassName = "partner-value-div-basic";
  }


  const handSentinterest = () => {
    
    if (permission?.permissions?.interest && permission?.permissions?.interest === "1"){
      // Check if the current user ID exists in the friend_requests_sent array
    const isInterestSent = sentInterestlist?.friend_requests_sent.some(item => item.user_to === id);
  
    if (isInterestSent) {
      // Show alert if the user is already in sent
      alert("Request already sent to the user");
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleSentinterest(id);
      alert("Request Sent successfully");
    }
  
    window.location.reload();
    }
    if (permission?.permissions?.interest && permission?.permissions?.interest === "0") {
      setIsinterestfree(true);
    }
  };


  const handleUpgradePlans = () => {
    navigate("/subscriptionplans")
  }

  const handleOpenClick = () => {
    if (userupdateInfo?.message === 'Contact balance updated!') {
      setIsOpen(true);
    } else {
      // Show a pop-up-like element with the message "Plan not subscribed"
      setIsfree(true);
    }
  };

  const handleAstroClick = () => {
    if (permission?.permissions?.astro === '1') {
      setIsAstro(true);
    } else {
      // Show a pop-up-like element with the message "Plan not subscribed"
      setIsastrofree(true);
    }
  };
 

  return (
    <div>
      <Loginnavigation />
      <div
        className="first-section"
      >
        <div className="profile-img">
          {userImg?.photo_url ? (
            <Slider {...sliderSettings}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    border: "1px solid #777777",
                  }}
                  src={userImg?.photo_url}
                  alt="person"
                /> 
              </div>


              {/* Album Slides */}
              {userAlbum?.image_urls.map((imageUrl, index) => (
                <div
                  key={index + 1} // Use a unique key for each additional slide
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    border: "1px solid #777777",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={imageUrl}
                    alt={`person-${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
            
          ) : (
            <div
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative", // Add position relative to the container
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
                border: "1px solid #777777"
              }}
              src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
              alt="person"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <h3>Profile photo not found</h3>
            </div>
          </div>
                  )}
        <br></br>
        {/* <div style={{display:"flex", flexWrap:"wrap", alignItems:"center"}}>
        <div className="kundali-score" style={{fontWeight:"500", padding:"12px 10px", marginTop:"8px", background:"#FBC913", color:"white", borderRadius:"10px", marginRight:"10px"}}>
         Kundli Match Score(Astakoot) </div>
         <div className="kundali-score-number" style={{display:"flex",alignItems:"center",fontFamily:"Lemon", padding:"10px 10px", borderRadius:"50%", background:"#FF519A", color:"white"}}>20/36</div>
        </div> */}
        
        </div>
         

        <div className="basic-info mymatches-desktop" >
          <div className="partner" style={{marginRight: '0', marginTop: '0px', background:"none", border:"1px solid #777777"}}>
          
          
            <div className="partner-header" >
              <h1 style={{color:"#FF4930"}}>{userInfo?.profile_data?.full_name},{userInfo?.profile_data?.age}</h1>
              
            </div>

            {/* Responsive Grid */}
            <div className="partner-grid-container3 basic">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 11 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic"><img src={religionimg} alt="religion" /></div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.religion}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                          Caste 
                        </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.caste}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                        <img src={heightimg} alt="height" />
                        </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.height ? (
                            <>
                                {(() => {
                                    const heightInInches = userInfo?.profile_data?.height;
                                    const feet = Math.floor(heightInInches / 12);
                                    const inches = heightInInches % 12;

                                    return `${feet} feet ${inches} inches`;
                                })()}
                            </>
                        ) : (
                            "Height information not available"
                        )}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 3 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Sub-Caste </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.sub_caste}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 4 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                        <img src={salary} alt="salary" />
                        </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.income}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 5 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Gotra </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.gotra}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 6 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">
                        <img src={dob} alt="dob" />
                        </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.birth_date}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 7 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Marital Status </div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.marital_status}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 8 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic"><img src={locationimg} alt="location" /></div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.state}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 9 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Working Status</div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.working_status}
                        </div>
                      </div>
                    </>
                  )}
                  {index === 10 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className="partner-title-div-basic">Weight</div>
                        <div className="partner-value-div-basic">
                        {userInfo?.profile_data?.weight}kg
                        </div>
                      </div>
                    </>
                  )}
                  
                  
                </div>
                
              ))}
              
            </div>

            
          </div>

          <div className="partner" style={{marginRight: '0', background:"none", border:"none"}}>
          
            {/* Responsive Grid */}
            <div className="partner-grid-container3">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 3 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <button style={{color: "white", background:"#21ED00", borderRadius:"10px", padding:"5px 40px", boxShadow:"0px 4px 9px 5px #00000040",fontWeight:"700", fontSize:"23px", border:"none"}}>
                        <img style={{height:"25px"}} src={video} alt={video}/> Video Call</button>
                        
                      </div>
                    </>
                  )}
                  {index === 1 && (
                    <>
                      <div style={{ display: "flex" }}>
                      <button style={{color: "white", background:"#587DFF", borderRadius:"10px", padding:"5px 85px", boxShadow:"0px 4px 9px 5px #00000040", fontWeight:"700", fontSize:"23px", border:"none"}}>
                        Chat</button>
                      </div>
                    </>
                  )}
                  {index === 2 && (
                    <>
                      <div style={{ display: "flex" }}>
                        <button style={{color: "white", background:"#FF0000", borderRadius:"10px", padding:"5px 40px", boxShadow:"0px 4px 9px 5px #00000040",fontWeight:"700", fontSize:"23px", border:"none"}}
                        onClick={handSentinterest}
                        >
                         {sentInterestlist?.friend_requests_sent.some(item => item.user_to === id) ? <img style={{height:"25px"}} className="telegram" src={telegram} alt="telegram" /> : <img style={{height:"25px"}} className="telegram1" src={telesent} alt="telesent" />}
                        Send Interest</button>
                        
                      </div>

                      {isinterestfree && (
                          <div
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              padding: '20px',
                              background: 'white',
                              boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                              borderRadius: '5px',
                              textAlign: 'center',
                            }}
                          >
                            <span style={{float:"right", padding:"3px 3px", cursor:"pointer"}} onClick={() => setIsinterestfree(false)}>✕</span>
                            <p style={{color:"black"}}>Free Plan! Please upgrade your plan to send interest</p>
                            <button onClick={handleUpgradePlans} style={{color:"white", fontSize:"15px", fontWeight:"700px", padding:"5px 10px", borderRadius:"5px",background:"#d11a1a", border:"none"}}>UpGrade</button>
                          </div>
                        )}
                    </>
                  )}
                 
                  
                </div>
              ))}
            </div>
          </div>

          <div className="partner" style={{background:"none", border:"1px solid #777777", }}>
      
        <div className="partner-header">
          <img src={name} alt="Partner" />
          <h1 style={{color:"#FF4930"}}>About Me <span style={{color: '#B1B1B1', fontWeight: '400', fontSize:'25px'}}>(Max. 1000 char)</span></h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3 verified">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 1 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 0 && (
                    <>
                    
                      <div className="verified-div" style={{ display: "flex", alignContent: 'left', justifyContent: 'left'}}>
                        <p style={{ fontWeight: '500', color: '#FF8A00', margin: '0',textAlign:'justify'}}>
                        {userInfo?.profile_data?.about_me}
                        </p>
                      </div>
                    

                    </>
                  )}
                  
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>


      



      <div className="partner mymatches-education" style={{border:"1px solid #777777", background:"none",}}>
        <div className="partner-header">
          <img src={educationmymatches} alt="educationmymatches" />
          <h1 style={{color:"#FF4930"}}>Education</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 3 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Highest Degree </div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.highest_education}
                        </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                <div style={{ display: "flex" }}>
                        <div className="partner-title-div">College Name </div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.college_name}
                        </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Other Education </div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.other_education}
                        </div>
                  </div>
                </>
              )}
              
              
              
            </div>
          ))}
        </div>
      </div>

      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
      
        <div className="partner-header">
          <img src={careermymatches} alt="careermymatches" />
          <h1 style={{color:"#FF4930"}}>Career</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Working Status </div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.working_status}
                        </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Employed In </div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.employed_in}
                        </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Company Name:</div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.current_company}
                        </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Job Location:</div>
                        <div className="partner-value-div">
                          {userInfo?.profile_data?.company_location}
                        </div>
                  </div>
                </>
              )}
              
              
            </div>
          ))}
        </div>
      </div>

 
      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
      
        <div className="partner-header">
          <img src={familymymatches} alt="familymymatches" />
          <h1 style={{color:"#FF4930"}}>Family</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 6 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Family Type </div>
                    <div className="partner-value-div">
                    {userInfo?.family_details?.family_type}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">No. of brothers </div>
                    <div className="partner-value-div">
                    {userInfo?.family_details?.brother_count}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>

                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Father’s Occupation:</div>
                        <div className="partner-value-div">
                          {userInfo?.family_details?.father_occupation}
                        </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">No. of sisters </div>
                    <div className="partner-value-div">
                    {userInfo?.family_details?.sister_count}
                    </div>
                  </div>
                </>
              )}
              {index === 4 && (
                <>
                  <div style={{ display: "flex" }}>
                        <div className="partner-title-div">Mother’s Occupation </div>
                        <div className="partner-value-div">
                        {userInfo?.family_details?.mother_occupation}
                        </div>
                  </div>
                </>
              )}
              
              
              {index === 5 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Family Income:</div>
                    <div className="partner-value-div">
                    {userInfo?.family_details?.family_income}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
        <div className="partner-header" style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{display:"flex"}}>
          <img src={contactmymatches} alt="contactmymatches" />
          <h1 style={{color:"#FF4930"}}>Contact Details</h1>
          </div>
          <div style={{fontSize:"18px", fontWeight:"700", borderRadius:"10px", color:"black", padding:"5px 10px", }}
          onClick={handleOpenClick} 
          >
          <img style={{width:"40px", height:"20px", cursor:"pointer"}} src={down} alt="down"/></div>
        </div>

        {/* Responsive Grid */}
        {isOpen && (
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">E-mail </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.email}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Phone </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.phonenumber}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Address </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.address}
                    </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Relatives’ Contact:</div>
                    <div className="partner-value-div">
                    {userInfo?.family_details?.relative_contact}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        )}

        {isfree && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'white',
            boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          <span style={{float:"right", padding:"3px 3px", cursor:"pointer"}} onClick={() => setIsfree(false)}>✕</span>
          <p style={{color:"black"}}>Free Plan! Please upgrade your plan to view contacts</p>
          <button onClick={handleUpgradePlans} style={{color:"white", fontSize:"15px", fontWeight:"700px", padding:"5px 10px", borderRadius:"5px",background:"#d11a1a", border:"none"}}>UpGrade</button>
        </div>
      )}


        
      </div>

      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
      
        <div className="partner-header" style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{display:"flex"}}>
          <img src={kundalimymatches} alt="kundlimymatches" />
          <h1 style={{color:"#FF4930"}}>Kundali & Astro</h1>
          </div>
          {/* <div style={{fontSize:"18px", fontWeight:"700", borderRadius:"10px", color:"white", background:"#5766CE", padding:"5px 10px", }}>
          Kundli Score</div> */}
          <div style={{fontSize:"18px", fontWeight:"700", borderRadius:"10px", color:"black", padding:"5px 10px", }}
          onClick={handleAstroClick} 
          >
          <img style={{width:"40px", height:"20px", cursor:"pointer"}} src={down} alt="down"/></div>
        </div>

        {/* Responsive Grid */}
        {isAstro && (
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Manglik Status </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.manglik_status}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Birth Place </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.birth_location}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Time of birth </div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.birth_time}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        )}
        {isastrofree && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'white',
            boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          <span style={{float:"right", padding:"3px 3px", cursor:"pointer"}} onClick={() => setIsastrofree(false)}>✕</span>
          <p style={{color:"black"}}>Free Plan! Please upgrade your plan to view Kundli</p>
          <button onClick={handleUpgradePlans} style={{color:"white", fontSize:"15px", fontWeight:"700px", padding:"5px 10px", borderRadius:"5px",background:"#d11a1a", border:"none"}}>UpGrade</button>
        </div>
      )}
      </div>

      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
      
        <div className="partner-header" >
          <img src={lifestylemymatches} alt="lifestylemymatches" />
          <h1 style={{color:"#FF4930"}}>Lifestyle</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 2 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Food style </div>
                    <div className="partner-value-div" >
                    {userInfo?.profile_data?.food_style}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Drinking Habit:</div>
                    <div className="partner-value-div">
                    {userInfo?.profile_data?.drinking_habit}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="partner-grid-container2">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 1 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Hobbies:</div>
                    <div className="partner-value-div hobbies">
                    
                          <div
                          
                        >
                          {typeof hobbies1 === 'string' && hobbies1.split(',').map((word, index) => (
                            <span style={{background: '#FBC913', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < hobbies1.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>


      <div className="partner" style={{background:"none", border:"1px solid #777777",}}>
      
        <div className="partner-header">
          <img src={personalitymymatches} alt="Personalitymymatch" />
          <h1 style={{color:"#FF4930"}}>Personality Meter</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3 verified">
              {/* Create 16 rows and 2 columns for the first two columns */}
              {Array.from({ length: 2 }, (_, index) => (
                <div key={index} className="partner-grid-item">
                  {/* Your content for each grid item goes here */}
                  {index === 1 && (
                    <>
                    
                      <div className="verified-div" style={{ display: "flex", alignContent: 'left', justifyContent: 'left'}}>
                        <p style={{ fontWeight: '500', color: '#B1B1B1', margin: '0',textAlign:'justify'}}>
                        Percentage partner preferences matching: <span style={{color:"#FF8A00"}}> {personalityInfo?.partner_pref_rating}% ({personalityInfo?.match_score}/20)</span>
                        </p>
                      </div>
                      <div className="verified-div" style={{ display: "flex", alignContent: 'left', justifyContent: 'left',marginTop:"10px"}}>
                        <p style={{ fontWeight: '500', color: '#B1B1B1', margin: '0',textAlign:'justify'}}>
                        Reviews Percentage: <span style={{color:"#FF8A00"}}> {personalityInfo?.review_rating}%</span>
                        </p>
                      </div>

                      <div className="verified-div verified-div-chart" style={{ display: "flex", marginTop:"20px", flexWrap:"wrap"}}>
                        <div className="verified-div-chart1">
                        <button style={{ fontWeight: '500', color: 'white', margin: '0',background:"#909090",fontSize:"20px",
                        padding:"8px 10px", borderRadius:"10px", border:"none"}}>
                        See Review Ratings
                        </button>
                         
                        {/* <h2 style={{color:"#FF4242", fontSize:"20px", fontWeight:"500", marginTop:"10px", textAlign:"center"}}>Overall Personality Score</h2> */}
                        <Piechart personality_score={personalityInfo?.overall_personality_meter}/>
                        
                        </div>

                        <div className="verified-div-chart2">
                        <button style={{ fontWeight: '500', color: 'white', margin: '0',background:"#909090", padding:"8px 10px", 
                        borderRadius:"10px", fontSize:"20px", border:"none"}}>
                        See Comparision Chart
                        </button>

                        
                        <Piechartcomparison partnerShare={personalityInfo?.partner_pref_rating} reviewShare={personalityInfo?.review_rating}/>
                        
                        </div>
                      </div>
                      
                    

                    </>
                  )}
                  
                  
                </div>
              ))}
            </div>
          </div>




      <div className="partner" style={{border:"1px solid #777777", background:"none"}}>
      
        <div className="partner-header">
          <img src={partnermymatches} alt="Partnermymatches" />
          <h1>Partner preferences</h1>
        </div>

        {/* Responsive Grid */}
        <div className="partner-grid-container3">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 19 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Age </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.min_age}
                    </div>
                  </div>
                </>
              )}
              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Age </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.max_age}
                    </div>
                  </div>
                </>
              )}
              {index === 2 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Weight </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.min_weight}
                    </div>
                  </div>
                </>
              )}
              {index === 3 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Weight </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.max_weight}
                    </div>
                  </div>
                </>
              )}
              {index === 4 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Height </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.min_height}
                    </div>
                  </div>
                </>
              )}

              {index === 5 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Height </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.max_height}
                    </div>
                  </div>
                </>
              )}
              {index === 6 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Country </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.country}
                    </div>
                  </div>
                </>
              )}
              {index === 7 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Caste </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.caste}
                    </div>
                  </div>
                </>
              )}
              {index === 8 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Sub-Caste </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.sub_caste}
                    </div>
                  </div>
                </>
              )}
              {index === 9 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Mother Tongue </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.mother_tongue}
                    </div>
                  </div>
                </>
              )}
              {index === 10 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Marital Status</div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.marital_status}
                    </div>
                  </div>
                </>
              )}
              {index === 11 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Face Color </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.face_color}
                    </div>
                  </div>
                </>
              )}
              {index === 12 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Working Status </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.working_status}
                    </div>
                  </div>
                </>
              )}
              
              {index === 13 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Drinking Habit </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.drinking_habit}
                    </div>
                  </div>
                </>
              )}
              {index === 14 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Education </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.education}
                    </div>
                  </div>
                </>
              )}
              {index === 15 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Min Income </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.min_income}
                    </div>
                  </div>
                </>
              )}
              {index === 16 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Max Income </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.max_income}
                    </div>
                  </div>
                </>
              )}
              {index === 17 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Photo Visible Status </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.photo_visible_status}
                    </div>
                  </div>
                </>
              )}

              {index === 18 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Verified profile </div>
                    <div className="partner-value-div">
                    {userInfo?.partner_pref?.verified_profile}
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="partner-grid-container2">
          {/* Create 16 rows and 2 columns for the first two columns */}
          {Array.from({ length: 2 }, (_, index) => (
            <div key={index} className="partner-grid-item">
              {/* Your content for each grid item goes here */}
              {index === 0 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Hobbies:</div>
                    <div className="partner-value-div hobbies">
                    
                          <div
                          
                        >
                          {typeof partnerhobbies === 'string' && partnerhobbies.split(',').map((word, index) => (
                            <span style={{background: '#FBC913', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerhobbies.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        
                    </div>
                  </div>
                </>
              )}

              {index === 1 && (
                <>
                  <div style={{ display: "flex" }}>
                    <div className="partner-title-div">Food Style:</div>
                    <div className="partner-value-div hobbies">
                    
                          <div
                          
                        >
                          {typeof partnerfoodstyle === 'string' && partnerfoodstyle.split(',').map((word, index) => (
                            <span style={{background: '#05FF00', borderRadius: '50px', color: 'white', padding: '0px 10px'}} key={index}>
                              {word.trim()} {/* Remove leading/trailing whitespaces */}
                              {index < partnerfoodstyle.split(',').length - 1 && ' '} {/* Add space except for the last word */}
                            </span>
                          ))}
                        </div>
                        
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        
      </div>

      <Aboutdownload />
      <Footer />
    </div>
  );
};

export default MyfavoriteCompleteProfile;