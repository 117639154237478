import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate(); // This should be called at the top level of your component.

    useEffect(() => {
        finalizeSubscription();
    }, []);

    const finalizeSubscription = async () => {
        const planId = localStorage.getItem("planid");
        const userId = localStorage.getItem("userId");
        const subscribe_user_status = localStorage.getItem("subscribe_user_status");

        if (subscribe_user_status === 'null' || subscribe_user_status === 'free' || subscribe_user_status === 'created') {
            const mode = subscribe_user_status === 'null' ? "create" : "update";
            try {
                const response = await fetch(`${process.env.REACT_APP_MAINURL}/final`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId, plan_id: planId, mode: mode }),
                });

                const result = await response.json(); // Parsing the JSON response from the server
                if (response.ok) {
                    window.alert("You have subscribed to the plan successfully");
                    navigate("/familydetails"); // Conditional navigation based on response
                } else {
                    throw new Error(result.message || "Unknown error"); // Handle server errors
                }
            } catch (error) {
                console.error("Error finalizing subscription:", error);
            }
        }
    };

    return (
        <div>
            <h2>Payment Successful!</h2>
        </div>
    );
};

export default PaymentSuccess;


