import React, { useState } from 'react';
import "./checkout.css";
import axios from 'axios';
import phonepe from './images/phonepe.png';
import { Paymentnavigation } from './Paymentnavigation';

const BiodataPayment = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const amount = sessionStorage.getItem("cost");
  
    const data = {
        name,
        amount,
        number: phoneNumber,
        email,
        address,
        city,
        state,
        zipCode,
        MUID: "MUID" + Date.now(),
        transactionId: 'T' + Date.now(),
    };
   
    
    const handlePayment = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post('/biodata/api/pay', { ...data })
            .then(response => {
                // Assuming the response includes a URL to redirect for payment
                window.location.href = response.data.url;
             
               // Redirects user to payment
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                window.alert("Error occurred while processing the request.");
            });
         

      };
    
    return (
        <>
            <Paymentnavigation />
            <div className='checkout-main'>
                <div className='checkout-center'>
                    <img src={phonepe} alt="" width={300} />
                </div>
                <div className='checkout-card'>
                    <form onSubmit={handlePayment}>
                        <div className='input-group'>
                            <label htmlFor="name">Name</label>
                            <input id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <input id="phoneNumber" type="number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="email">Email</label>
                            <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="address">Address</label>
                            <input id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="city">City</label>
                            <input id="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="state">State</label>
                            <input id="state" value={state} onChange={(e) => setState(e.target.value)} required />
                        </div>
                        <div className='input-group'>
                            <label htmlFor="zipCode">Zip Code</label>
                            <input id="zipCode" type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} required />
                        </div>
                        <div className='amount-display'>Amount: {amount} Rs</div>
                        <div className='submit-btn-container'>
                            <button type="submit" disabled={loading}>
                                {loading ? 'Processing...' : 'Pay Now'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default BiodataPayment;