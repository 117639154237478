import React, { useState,useEffect } from 'react';
import { Loginnavigation } from './Loginnavigation';
import Footer from './Footer';
import './Biodata.css'; // Import your CSS file
import svg from '../components/images/download.svg';
import { AiOutlineLike } from "react-icons/ai";
import { CiShare2 } from "react-icons/ci";
import Modal from "./fullScreenmodal";
import { SiSpringsecurity } from "react-icons/si";
import { CiFileOn } from "react-icons/ci";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

import { LuCrown } from "react-icons/lu";
import theme1 from "./images/theme1.png";
import theme2 from "./images/theme2.png";
import theme3 from "./images/theme3.png";
import theme4 from "./images/theme4.png";
import theme5 from "./images/theme5.png";
import theme6 from "./images/theme6.png";
import theme7 from "./images/theme7.png";
import theme8 from "./images/theme8.png";
import theme9 from "./images/theme9.png";
import theme10 from "./images/theme10.png";
import theme11 from "./images/theme11.png";
import theme12 from "./images/theme12.png";
import theme13 from "./images/theme13.png";
import theme14 from "./images/theme14.png";
import theme15 from "./images/theme15.png";
import theme16 from "./images/theme16.png";
import theme17 from "./images/theme17.png";
const themes = [
    { id: 1, title: "Theme 1", image: theme1 },
    { id: 2, title: "Theme 2", image: theme2 },
    { id: 3, title: "Theme 3", image: theme3 },
    { id: 4, title: "Theme 4", image: theme4 },
    { id: 5, title: "Theme 5", image: theme5 },
    { id: 6, title: "Theme 6", image: theme6 },
    { id: 7, title: "Theme 7", image: theme7 },
    { id: 8, title: "Theme 8", image: theme8 },
    { id: 9, title: "Theme 9", image: theme9 },
    { id: 10, title: "Theme 10", image: theme10 },
    { id: 11, title: "Theme 11", image: theme11 },
    { id: 12, title: "Theme 12", image: theme12 },
    { id: 13, title: "Theme 13", image: theme13 },
    { id: 14, title: "Theme 14", image: theme14 },
    { id: 15, title: "Theme 15", image: theme15 },
    { id: 16, title: "Theme 16", image: theme16 },
    { id: 17, title: "Theme 17", image: theme17 },
];

const Biodata = () => {
    const [selectedId, setSelectedId] = useState(null);
    const [planId, setPlanId] = useState(null);
    const navigate = useNavigate();
    sessionStorage.setItem("cost", 49)
    const [downloadModalShown, toggleDownloadModal] = useState(false);
    const handleClick = (id) => {
        setSelectedId(id);
        toggleDownloadModal(true);
       
      
    };

    useEffect(() => {
        // Retrieve planId from localStorage
        const storedPlanId = 7;
        // console.log(storedPlanId)
        if (storedPlanId) {
          setPlanId(parseInt(storedPlanId));
        }
      }, [planId]);
    return (
        <div>
            <Loginnavigation />
            <div className="biodata-container">
                <div className="header-container">
                    <h1 className='heading-data'>Biodata Themes</h1>
                    <button className="download-button"> <img src={svg}></img></button>
                </div>
                <div className="themes-grid">
                    {themes.map((theme) => (
                        <div
                            key={theme.id}
                            className={`theme-item ${selectedId === theme.id ? "selected" : ""}`}
                            onClick={() => handleClick(theme.id)}
                        >
                            <img src={theme.image} alt={theme.title} />
                            <h3>{theme.title}</h3>
                        </div>
                    ))}
                </div>
                {selectedId !== null && (
                  <Modal
                    shown={downloadModalShown}
                    close={() => toggleDownloadModal(false)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <div className="modal-content2">
                    
                      <div className="modal-image-section">
                        <img src={themes.find(theme => theme.id === selectedId).image} alt="Selected Template" className="modal-image" />
                        <div className="modal-buttons">
                          <button className="like-button"> <AiOutlineLike /> Like</button>
                          <button className="share-button"> <CiShare2/> Share</button>
                        </div>
                      </div>
                      <div className="modal-info-section">
                        <div className="modal-info-content">
                          <p style={{fontWeight:'bold',fontSize:'20px'}}> <LuCrown />  <span style={{color:'#FF8A00'}}>Premium</span> Image</p>
                          <p >Unlock this image by downloading this for just</p>
                          {planId && planId >= 1 ? (
                            <>
                            <div style={{background: 'yellow', marginBottom: '20px', border: 'none', width: '70px', height: '30px', borderRadius: '8px', padding: '3px', marginLeft: '2px', textDecoration: 'line-through', color: 'red'}}>Rs 49/-</div>
                            <button style={{background:'#FF8A00',width:'120px',height:'40px',color:'black',border:'none',borderRadius:'10px',marginBottom:'5px',fontWeight:'bold'}} onClick={() => navigate('/biodata-download', { state: {selectedId} })}>Download Now</button>
                         </>
                          ) : (
                            <>
                            <div style={{background:'yellow',marginBottom:'20px',border:'none',width:'70px',height:'30px',borderRadius:'8px',padding:'3px',marginLeft:'2px'}}>Rs 49/-</div>
                            <button style={{background:'#FF8A00',width:'120px',height:'40px',color:'black',border:'none',borderRadius:'10px',marginBottom:'5px',fontWeight:'bold'}} onClick={() => navigate('/subscriptionplans/checkout')}>Download Now</button>
                         </>
                          )}
                        
                         
                         
                         <p>Are You Only A Premium Member ? <a href='/login' style={{fontWeight:'bold'}}>Log In</a></p>

                        </div>
                        <div className="modal-extra-content">
                       <p><SiSpringsecurity /> <span>Premium License:</span>  More info</p>
                       <p><CiFileOn /> <span>File type:</span> PSD, JPG How to edit?</p>
                       <p><AiOutlineExclamationCircle /> <span>Any issue found?</span> Report content</p>
                       <button style={{background:'ghostwhite',width:'200px',height:'50px',padding:'10px',fontSize:'17px',fontWeight:'bold',border:'none',borderRadius:'10px'}}><MdOutlineBookmarkAdd /> Add to Collection</button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Biodata;