import React, { useState } from 'react';
import './search.css';
import CommunitySearchResult from './CommunitySearchResult';
import Advancesearch from './Advancesearch';
import Locationsearch from './Locationsearch';
import Collegesearch from './Collegesearch';
import Idsearch from './Idsearch';
import { useLocation } from "react-router-dom";
import { Loginnavigation } from './Loginnavigation';


const CommunitySearchresultNavbar = () => {
  const location = useLocation();
  
  
    
  const [activeSearch, setActiveSearch] = useState('Communitysearch');
  const userinfo = location.state?.responseData || null;
  console.log(userinfo)

  const handleSearchButtonClick = (searchType) => {
    setActiveSearch(searchType);
  };

  return (
    <div>
      <Loginnavigation/>
      <div className='search-filter'>
        <button style={{background: 'none', color: '#222222'}}
          onClick={() => handleSearchButtonClick('Communitysearch')}
          className={activeSearch === 'Communitysearch' ? 'active' : ''}
        >
          Results({userinfo.count})
        </button>
        <button
          onClick={() => handleSearchButtonClick('Advance')}
          className={activeSearch === 'Advance' ? 'active' : ''}
        >
          Advance Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('Location')}
          className={activeSearch === 'Location' ? 'active' : ''}
        >
          Location Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('College')}
          className={activeSearch === 'College' ? 'active' : ''}
        >
          College Based Search
        </button>
        <button
          onClick={() => handleSearchButtonClick('ID')}
          className={activeSearch === 'ID' ? 'active' : ''}
        >
          ID Based Search
        </button>
      </div>

      {activeSearch === 'Communitysearch' && <CommunitySearchResult userinfo={userinfo?.users}/>}
      {activeSearch === 'Advance' && <Advancesearch/>}
      {activeSearch === 'Location' && <Locationsearch />}
      {activeSearch === 'College' && <Collegesearch />}
      {activeSearch === 'ID' && <Idsearch />}
    </div>
  );
};

export default CommunitySearchresultNavbar;