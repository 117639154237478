import React, { useState } from "react";
import './RegisterForm.css'
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from './images/search_2.png'
import img8 from './images/image_1.png'

const PageThree = ({ onButtonClick, email,
  phoneNumber,
  password,
  profile,
  fullname,
  gender,
  dob,
  height,
  weight,
  facecolor,
  age,
  birth_time,
  birth_location,
  address,
  district,
  city,
  state,
  country,
}) => {
  const [martialStatus, setMartialStatus] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [otherMotherTongue, setOtherMotherTongue] = useState("");
  const [religion, setReligion] = useState("");
  const [otherReligion, setOtherReligion] = useState("");
  const [caste, setCaste] = useState("");
  const [otherCaste, setOtherCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");
  const [otherSubCaste, setOtherSubCaste] = useState("");
  const [gotra, setGotra] = useState("");
  const [otherGotra, setOtherGotra] = useState("");
  const [manglik, setManglik] = useState("");



  const [martialStatusError, setMartialStatusError] = useState("");
  const [motherTongueError, setMotherTongueError] = useState("");
  const [otherMotherTongueError, setOtherMotherTongueError] = useState("");
  const [religionError, setReligionError] = useState("");
  const [otherReligionError, setOtherReligionError] = useState("");
  const [casteError, setCasteError] = useState("");
  const [otherCasteError, setOtherCasteError] = useState("");
  const [subCasteError, setSubCasteError] = useState("");
  const [otherSubCasteError, setOtherSubCasteError] = useState("");
  const [gotraError, setGotraError] = useState("");
  const [otherGotraError, setOtherGotraError] = useState("");
  const [manglikError, setManglikError] = useState("");

   
  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleCreateWorkspace = async () => {
    if (
      religion.trim() !== "" &&
      martialStatus.trim() !== "" &&
      motherTongue.trim() !== "" &&
      caste.trim() !== "" &&
      subCaste.trim() !== "" &&
      gotra.trim() !== ""&&
      manglik.trim() !== "" &&
      ((motherTongue === "Other (Specify)" && otherMotherTongue.trim() !== "") ||
    motherTongue !== "Other (Specify)") &&
    ((religion === "Other (Specify)" && otherReligion.trim() !== "") ||
    religion !== "Other (Specify)") &&
    ((caste === "Other (Specify)" && otherCaste.trim() !== "") ||
    caste !== "Other (Specify)") &&
    ((subCaste === "Other (Specify)" && otherSubCaste.trim() !== "") ||
    subCaste !== "Other (Specify)") &&
    ((gotra === "Other (Specify)" && otherGotra.trim() !== "") ||
    gotra !== "Other (Specify)") 
    ) {
      

      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            username: email,
            phonenumber: phoneNumber,
            password: password,
            createfor: profile,
            full_name: fullname,
            gender: gender,
            date_of_birth: dob,
            birth_date: dob,
            height: height,
            weight: weight,
            face_color: facecolor,
            age: age,
            birth_time: birth_time,
            birth_location: birth_location,
            address: address,
            district: district,
            city: city,
            state: state,
            country: country,



            marital_status: martialStatus,
            mother_tongue: motherTongue === "Other (Specify)" ? capitalizeFirstLetter(otherMotherTongue) : motherTongue,
            religion: religion === "Other (Specify)" ? capitalizeFirstLetter(otherReligion) : religion,
            caste: caste === "Other (Specify)" ? capitalizeFirstLetter(otherCaste) : caste,
            sub_caste: subCaste === "Other (Specify)" ? capitalizeFirstLetter(otherSubCaste) : subCaste,
            gotra: gotra === "Other (Specify)" ? capitalizeFirstLetter(otherGotra) : gotra,
            manglik: manglik,
            highest_education: "",
            other_education : "",
            college_name: "",
            hobbies: "",
            food_style: "",
            drinking_habit: "",
            working_status: "",
            employed_in: "",
            occupation: "",
            income: "",
            current_company: "",
            company_location: "",
            register: true,
          }),
        });
  
        const data = await response.json();
  
        if (data.error === "Email already registered") {
          window.alert("Email already registered. Please use a different email.");
        }
        else {
          onButtonClick("pagefour",
      martialStatus, motherTongue === "Other (Specify)" ? capitalizeFirstLetter(otherMotherTongue) : motherTongue, 
      religion === "Other (Specify)" ? capitalizeFirstLetter(otherReligion) : religion, 
      caste === "Other (Specify)" ? capitalizeFirstLetter(otherCaste) : caste, 
      subCaste === "Other (Specify)" ? capitalizeFirstLetter(otherSubCaste) : subCaste, 
      gotra === "Other (Specify)" ? capitalizeFirstLetter(otherGotra) : gotra, 
      manglik);

          window.alert("You have successfully registered. Please fill additional information for more updates.");
        }
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
      
      
    } else {
      // Set error messages for each field
      setMartialStatusError(
        martialStatus.trim() === ""
      ? "Please select martial status"
      : "");
        setMotherTongueError(motherTongue.trim() === "" ? "Please select mother tongue" : "");
        setOtherMotherTongueError(
          motherTongue === "Other (Specify)" && otherMotherTongue.trim() === ""
            ? "Please specify mother tongue"
            : ""
        );

        setReligionError(
          religion.trim() === "" ? "Please select religion" : ""
        );
        setOtherReligionError(
          religion === "Other (Specify)" && otherReligion.trim() === ""
            ? "Please specify religion"
            : ""
        );

        setCasteError(caste.trim() === "" ? "Please select caste" : "");
        setOtherCasteError(
          caste === "Other (Specify)" && otherCaste.trim() === ""
            ? "Please specify caste"
            : ""
        );

        setSubCasteError(
          subCaste.trim() === "" ? "Please select subcaste" : ""
        );
        setOtherSubCasteError(
          subCaste === "Other (Specify)" && otherSubCaste.trim() === ""
            ? "Please specify sub caste"
            : ""
        );

        setGotraError(gotra.trim() === "" ? "Please select gotra" : "");
        setOtherGotraError(
          gotra === "Other (Specify)" && otherGotra.trim() === ""
            ? "Please specify gotra"
            : ""
        );

        setManglikError(manglik.trim() === "" ? "Please select the required field" : "");
    }
  };

  return (
    <main
      className="black-80 center form-main-register"
      style={{  maxHeight: "30%", margin: "auto" }}
    >


     <div className="desktop-step" style={{ display: "flex", flexDirection: "column", }}>
        <div id="#form-step">
          <i
            style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
            className=" step fa"
          >
            <img src={img1} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Easy Registeration</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa"
          style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Chat With Partner</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Video Calls</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img7} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Search Profiles Conveniently</h3>
          </div>
        </div>
      </div>
      
      <div className="form-desktop-step">
      <form className="measure measure1" style={{  margin: "auto", padding: "20px",
                            background: "#FF4242",
                            borderRadius: "20px" }}>

        <h4 style={{color: 'white'}}>Lifestyle</h4>
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
      

          {/* Add similar code for the other select fields */}
          {/* Martial Status */}
          <div className="mv3" style={{display: "flex", flexDirection:"column" }}>
            <div>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="martialStatus"
              style={{ textAlign: "left",
                fontSize: "2rem",
                color: "white",
                fontWeight: "575",
                display: "block" }}
            >
              Martial Status
            </label>
            </div>
            <div
              className="relative"
              style={{
                background: "#950A06",
                padding: "2px 5px",
                borderRadius:"5px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "10px", // Add margin-top to separate radio buttons from the label
                marginBottom:"20px"
              }}
            >
              {["Single", "Divorced", "Widow", "Seperated"].map((option) => (
                <label key={option} style={{ marginRight: "10px", marginBottom: "10px", color:'white', fontSize:"13px" }}>
                  <input
                    type="radio"
                    name="maritalStatus"
                    value={option}
                    checked={martialStatus === option}
                    onChange={(e) => setMartialStatus(e.target.value)}
                    style={{ marginRight: "5px", color: "white" }}
                  />
                  {option}
                </label>
              ))}
              {/* Repeat the same pattern for other options */}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {martialStatusError}
          </div>

          

          {/* Mother Tongue */}
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="motherTongue"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Mother Tongue
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="motherTongue"
                id="motherTongue"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: motherTongueError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={motherTongue}
                onChange={(e) => {
                  setMotherTongue(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherMotherTongue("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Spanish">Spanish</option>
                <option value="French">French</option>
                <option value="German">German</option>
                <option value="Chinese">Chinese</option>
                <option value="Arabic">Arabic</option>
                <option value="Russian">Russian</option>
                <option value="Japanese">Japanese</option>
                <option value="Portuguese">Portuguese</option>
                <option value="Bengali">Bengali</option>
                <option value="Telugu">Telugu</option>
                <option value="Tamil">Tamil</option>
                <option value="Punjabi">Punjabi</option>
                <option value="Marathi">Marathi</option>
                <option value="Gujarati">Gujarati</option>
                <option value="Kannada">Kannada</option>
                <option value="Odia">Odia</option>
                <option value="Malayalam">Malayalam</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {motherTongue === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherMotherTongue}
                  onChange={(e) => setOtherMotherTongue(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherMotherTongueError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {motherTongueError || otherMotherTongueError}
          </div>




          

          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="religion"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Religion
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="religion"
                id="religion"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: religionError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={religion}
                onChange={(e) => {
                  setReligion(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherReligion("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Christianity">Christianity</option>
                <option value="Islam">Islam</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Sikhism">Judaism</option>
                <option value="Sikhism">Sikhism</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {religion === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherReligion}
                  onChange={(e) => setOtherReligion(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherReligionError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {religionError || otherReligionError}
          </div>


          

          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="caste"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
            >
              Caste
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph5 pv4 mb4 dib black w-100"
                name="caste"
                id="caste"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: casteError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={caste}
                onChange={(e) => {
                  setCaste(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCaste("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Brahmin">Brahmin</option>
                <option value="Kshatriya">Kshatriya</option>
                <option value="Vaishya">Vaishya</option>
                <option value="Shudra">Shudra</option>
                <option value="Scheduled Castes (SC)">Scheduled Castes (SC)</option>
                <option value="Scheduled Tribes (ST)">Scheduled Tribes (ST)</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {caste === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherCaste}
                  onChange={(e) => setOtherCaste(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherCasteError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {casteError || otherCasteError}
          </div>


          
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="subCaste"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Sub Caste
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="subCaste"
                id="subCaste"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: subCasteError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={subCaste}
                onChange={(e) => {
                  setSubCaste(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherSubCaste("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Not Applicable">Not Applicable</option>
              <option value="Agarwal">Agarwal</option>
              <option value="Dalit">Dalit</option>
              <option value="Adi Dravida">Adi Dravida</option>
              <option value="Chamar">Chamar</option>
              <option value="Tribal">Tribal</option>
              <option value="Gond">Gond</option>
              <option value="Munda">Munda</option>
              <option value="Yadav">Yadav</option>
              <option value="Kurmi">Kurmi</option>
              <option value="Maurya">Maurya</option>
              <option value="Nair">Nair</option>
              <option value="Nadar">Nadar</option>
              <option value="Vanniyar">Vanniyar</option>
              <option value="Brahmin">Brahmin</option>
              <option value="Iyer">Iyer</option>
              <option value="Iyengar">Iyengar</option>
              <option value="Smarta Brahmin">Smarta Brahmin</option>
              <option value="Rajput">Rajput</option>
              <option value="Thakur">Thakur</option>
              <option value="Kshatriya Raju">Kshatriya Raju</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {subCaste === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph6 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherSubCaste}
                  onChange={(e) => setOtherSubCaste(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherSubCasteError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {subCasteError || otherSubCasteError}
          </div>


          
          <div className="mv3" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="gotra"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Gotra
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="gotra"
                id="gotra"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: gotraError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={gotra}
                onChange={(e) => {
                  setGotra(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherGotra("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Bharadwaj">Bharadwaj</option>
              <option value="Kashyap">Kashyap</option>
              <option value="Vatsa">Vatsa</option>
              <option value="Gautam">Gautam</option>
              <option value="Shandilya">Shandilya</option>
              <option value="Atri">Atri</option>
              <option value="Vasistha">Vasistha</option>
              <option value="Kaushik">Kaushik</option>
              <option value="Garg">Garg</option>
              <option value="Parashara">Parashara</option>
              <option value="Sandilya">Sandilya</option>
              <option value="Vashishtha">Vashishtha</option>
              <option value="Jamadagni">Jamadagni</option>
              <option value="Angirasa">Angirasa</option>
              <option value="Kaundinya">Kaundinya</option>
              <option value="Suryavanshi">Suryavanshi</option>
              <option value="Chandravanshi">Chandravanshi</option>
              <option value="Agnivanshi">Agnivanshi</option>
              <option value="Nagvanshi">Nagvanshi</option>
              <option value="Kachwaha">Kachwaha</option>
              <option value="Rathore">Rathore</option>
              <option value="Tomar">Tomar</option>
              <option value="Solanki">Solanki</option>
              <option value="Chauhan">Chauhan</option>
              <option value="Parmar">Parmar</option>
              <option value="Gohil">Gohil</option>
              <option value="Pratihar">Pratihar</option>
              <option value="Parihar">Parihar</option>
              <option value="Rajput">Rajput</option>
              <option value="Rajawat">Rajawat</option>
              <option value="Agarwal">Agarwal</option>
              <option value="Garg">Garg</option>
              <option value="Bansal">Bansal</option>
              <option value="Mittal">Mittal</option>
              <option value="Singhal">Singhal</option>
              <option value="Kansal">Kansal</option>
              <option value="Mangal">Mangal</option>
              <option value="Madhukul">Madhukul</option>
              <option value="Goyal">Goyal</option>
              <option value="Jindal">Jindal</option>
              <option value="Tayal">Tayal</option>
              <option value="Kuchhal">Kuchhal</option>
              <option value="Goyal">Goyal</option>
              <option value="Mangal">Mangal</option>
              <option value="Khandelwal">Khandelwal</option>
              <option value="Gupta">Gupta</option>
              <option value="Maheshwari">Maheshwari</option>
              <option value="Oswal">Oswal</option>
              <option value="Porwal">Porwal</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {gotra === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherGotra}
                  onChange={(e) => setOtherGotra(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherGotraError ? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {gotraError || otherGotraError}
          </div>


          <div className="mv3">
            <label
              className="db lh-copy f6 mb1"
              htmlFor="manglik"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Manglik
            </label>
            <select
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              name="manglik"
              id="manglik"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: manglikError ? "red" : "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={manglik}
              onChange={(e) => setManglik(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Manglik">Manglik</option>
              <option value="Non-manglik">Non-manglik</option>
            </select>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {manglikError}
          </div>



        </fieldset>
        <div >
          <input
            className="f6 grow br3 ph3 pv2 mb4 dib white"
            style={{
              borderStyle: "none",
              padding: "7px 30px",
              backgroundColor: "rgb(204, 0, 0)",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
            type="button"
            value="Submit"
            onClick={handleCreateWorkspace}
          />
        </div>
      </form>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }} className="desktop-step">
        <div id="#form-step">
          <i className=" step fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img src={img4} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Verified Profiles</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "27px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img5} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Affordable Membership Plans</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img6} alt="img" />
          </i>
          <div className="service-desc">
            <h3>15+ available filters for Matchmaking</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img8} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Photo Based Additional Verification</h3>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PageThree;

