import React, { useState, useEffect } from 'react';
import './search.css';
import SentInterest from './SentInterest';
import ReceivedInterest from './ReceivedInterest';
import AcceptedInterest from './AcceptedInterest';
import RejectInterest from './RejectInterest';
import { Loginnavigation } from './Loginnavigation';
import grid from './images/grid.png'
import SentInterestgrid from './SentInterestgrid';
import ReceivedInterestgrid from './ReceivedInterestgrid';
import AcceptInterestgrid from './AcceptInterestgrid';
import RejectInterestgrid from './RejectInterestgrid';
import useSentrequestlist from './utils/useSentrequestlist';
import useReceivedrequestlist from './utils/useReceivedrequestlist';
import useAcceptlist from './utils/useAcceptlist';
import useRejectlist from './utils/useRejectlist';
import loading1 from "./images/loading.gif"



const Interestfilter = () => {
  const [activeMatches, setActiveMatches] = useState(
    sessionStorage.getItem('activeMatches') || 'Sent'
  );
  const [displayGrid, setDisplayGrid] = useState(
    JSON.parse(sessionStorage.getItem('displayGrid')) || false
  );

  const userId = sessionStorage.getItem('userId');
  const sentInterestlist = useSentrequestlist(userId);
  const receivedInterestlist = useReceivedrequestlist(userId);
  const acceptUserlist = useAcceptlist(userId);
  const rejectUserlist = useRejectlist(userId);

  useEffect(() => {
    // Save activeMatches and displayGrid to sessionStorage when they change
    sessionStorage.setItem('activeMatches', activeMatches);
    sessionStorage.setItem('displayGrid', JSON.stringify(displayGrid));
  }, [activeMatches, displayGrid]);

  const handleMatchesButtonClick = (searchType) => {
    setActiveMatches(searchType);
  };

  const toggleGridView = () => {
    setDisplayGrid(!displayGrid);
  };

  return (
    <div>
      <Loginnavigation/>
      <div className='search-filter' >
        <button style={{padding: '1px 30px', borderRadius:"5px"}}
          onClick={() => handleMatchesButtonClick('Sent')}
          className={`mymatches-btn ${activeMatches === 'Sent' ? 'active' : ''}`}
        >
          Sent ({sentInterestlist?.friend_requests_sent?.length})
        </button>
        <button style={{padding: '1px 30px', borderRadius:"5px"}}
          onClick={() => handleMatchesButtonClick('Received')}
          className={`mymatches-btn ${activeMatches === 'Received' ? 'active' : ''}`}
        >
          Received ({receivedInterestlist?.friend_requests_received?.length})
        </button>
        <button style={{padding: '1px 30px', borderRadius:"5px"}}
          onClick={() => handleMatchesButtonClick('Accepted')}
          className={`mymatches-btn ${activeMatches === 'Accepted' ? 'active' : ''}`}
        >
          Accepted ({acceptUserlist?.accepted_interests?.length})
        </button>
        <button style={{padding: '1px 30px', borderRadius:"5px"}}
          onClick={() => handleMatchesButtonClick('Reject')}
          className={`mymatches-btn ${activeMatches === 'Reject' ? 'active' : ''}`}
        >
          Reject ({rejectUserlist?.list_rejected_interests?.length})
        </button>

        <div>
        <img style={{height: '50px', cursor: 'pointer'}} src={grid} alt="grid" onClick={toggleGridView}/>
        </div>

      </div>

      
      {!sentInterestlist?.friend_requests_sent ? (
          <div style={{ width: "100vw", textAlign: "center" }}>
          <img style={{height:"300px", width:"400px", margin:"auto"}} src={loading1} />
          </div>
        ) : (
          <>
          {activeMatches === 'Sent' && !displayGrid && <SentInterest sentInterestlist={sentInterestlist?.friend_requests_sent} />}
      {activeMatches === 'Sent' && displayGrid && <SentInterestgrid sentInterestlist={sentInterestlist?.friend_requests_sent} />}
      {activeMatches === 'Received' && !displayGrid && <ReceivedInterest receivedInterestlist={receivedInterestlist?.friend_requests_received}/>}
      {activeMatches === 'Received' && displayGrid && <ReceivedInterestgrid receivedInterestlist={receivedInterestlist?.friend_requests_received}/>}
      {activeMatches === 'Accepted' && !displayGrid && <AcceptedInterest acceptUserlist={acceptUserlist?.accepted_interests} />}
      {activeMatches === 'Accepted' && displayGrid && <AcceptInterestgrid acceptUserlist={acceptUserlist?.accepted_interests} />}
      {activeMatches === 'Reject' && !displayGrid && <RejectInterest rejectUserlist={rejectUserlist?.list_rejected_interests}  />}
      {activeMatches === 'Reject' && displayGrid && <RejectInterestgrid rejectUserlist={rejectUserlist?.list_rejected_interests}/>}
          </>
        )}
      
    </div>
  )
}

export default Interestfilter;