import React from 'react'
import img from "./google store.jpeg";
import logo_phone from "./logo_phone.png"

const Aboutdownload = () => {

  const handleHover1 = () => {
    const infoDiv1 = document.getElementById('infoDiv1');
    if (infoDiv1) {
      infoDiv1.style.visibility = 'visible';
    }
  };

  const handleLeave1 = () => {
    const infoDiv1 = document.getElementById('infoDiv1');
    if (infoDiv1) {
      infoDiv1.style.visibility = 'hidden';
    }
  };

  return (
    <div id='about-download'
        className="download-app"
        style={{
          // display: "flex",
          // flexWrap: "wrap",
          margin: "20px", // Reduce margin for small screens
          padding: "50px", // Reduce padding for small screens
          borderRadius: "20px",
          background: "#FF8A00",
          paddingBottom: "20px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className='about-download'
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "-80px", 
          }}
        >
          <h3
            className="h-download-app"
            style={{
              fontSize: "42px", 
              fontWeight: "570",
              color: "#D60E08",
              fontFamily: "Lemon",
            }}
          >
            Download Our App from
          </h3>
          <h3
            className="h-download-app"
            style={{
              fontSize: "42px", // Reduce font size for small screens
              fontWeight: "570",
              color: "#D60E08",
              fontFamily: "Lemon",
              marginTop: "-2px",
              marginBottom: "30px", // Reduce bottom margin for small screens
            }}
          >
            Google Play Store
          </h3>
          <p
            className="p-download-app"
            style={{
              fontSize: "28px", // Reduce font size for small screens
              fontWeight: "575",
              color: "white",
              fontFamily: "Lemon",
            }}
          >
            Enjoy the rich user experience
          </p>
          <p
            className="p-download-app"
            style={{
              fontSize: "28px", // Reduce font size for small screens
              fontWeight: "575",
              color: "white",
              fontFamily: "Lemon",
            }}
          >
            on your smartphone
          </p>

          <div>
            <a href="https://play.google.com/store/apps/details?id=com.app.vivahsahyog"
            onMouseOver={handleHover1}
              onMouseLeave={handleLeave1}
            >
              <img
                src={img}
                alt="Card Image"
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                  marginTop: "50px",
                }}
              />
            </a>

          
            <br></br>


          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logo_phone}
            alt="Card Image"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      </div>
  )
}

export default Aboutdownload
