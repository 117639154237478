import { useEffect, useState } from "react";

const useFetchpermission = (userId) => {
  const [userupdateInfo, setUserupdateInfo] = useState(null);

  useEffect(() => {
    fetchData(userId);
  }, [userId]);

  const fetchData = async (userId) => {
    try {
      const data = await fetch(`/prod/api_code/subscription_api/fetchPermissions.php?user_id=${userId}`, {
        method: 'GET', // Specify the HTTP method (GET in this case)
        headers: {
          Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
          'Content-Type': 'application/json', // Adjust the content type if needed
          
          // Add any other headers you need here
        },
       
      });
      const json = await data.json();
      setUserupdateInfo(json);
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
      // Handle the error (show an error message, etc.)
    }
  };

  return userupdateInfo;
};

export default useFetchpermission;