import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import "./styles.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { AboutNavigation } from "./AboutNavigation";
import { Container } from "react-bootstrap";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pages = React.forwardRef((props, ref) => {
  return (
    <div className="demoPage" ref={ref}>
      <p>{props.children}</p>
      <p>Page number: {props.number}</p>
    </div>
  );
});

function PdfViewer() {
  // const [loading, setLoading] = useState(true);
  const { filename } = useParams();

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    //   setTimeout(()=>{

    //       setLoading(false);
    //   },1000)
  };
  return (
    <>
      <AboutNavigation />
      <Container style={{ padding: "5px", marginTop: "80px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HTMLFlipBook width={500} height={600} showCover={true}>
            {[...Array(numPages).keys()].map((n) => (
              <Pages number={`${n + 1}`}>
                <Document
                  file={decodeURIComponent(filename)}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={n + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={500}
                    className="border-3 border-black"
                  />
                </Document>
              </Pages>
            ))}
          </HTMLFlipBook>
        </div>
      </Container>
    </>
  );
}

export default PdfViewer;
