import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import loginProfile from "./images/loginProfile.png";
import emailimg from "./images/emailimg.png";
import lock from "./images/lock.png";
import { Navigation } from "./navigation";
import Footer from "./Footer";
import axios from "axios";


const Login = () => {
  const [email, setEmail] = useState("");
  const [emailforgot, setEmailforgot] = useState("");
  const [emailforgoterror, setEmailforgoterror] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [code, setCode] = useState("");
  const [timestamp, setTimestamp] = useState("");


  const [otp, setOtp] = useState("")
  const [showResetPasswordField, setShowResetPasswordField] = useState(false);
const [resetPassword, setResetPassword] = useState("");
const [resetPasswordError, setResetPasswordError] = useState("");
const[otpError, setOtpError] = useState("");


  const navigate = useNavigate();
  const popupRef = useRef(null);
  

  const handleUsernameChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_MAINURL}/login`, {
        username: email,
        password: password,
        action: 'login',
      });
      

      
      
      if (response.data.success) {
        
        const userId = response.data.responseData.id;
        const gender = response.data.responseData.gender;
        const religion = response.data.responseData.religion;
        const subscribe_user_status = response.data.responseData.subscribe_user_status
        const family_details_status = response.data.responseData.family_details_status
        const partner_pref_status = response.data.responseData.partner_pref_status
        // setUserData(userData);- 

      // Store userId in localStorage
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("gender", gender);
      sessionStorage.setItem("religion", religion);
      sessionStorage.setItem("subscribe_user_status", subscribe_user_status);
      sessionStorage.setItem("family_details_status", family_details_status);
      sessionStorage.setItem("partner_pref_status", partner_pref_status);
      localStorage.setItem("userId",userId);


      // Successful login
      // Check subscribe_user_status and navigate accordingly
      if (subscribe_user_status === "null" || subscribe_user_status === "free") {
        navigate("/subscriptionplans");
        window.location.reload();
      } else if (subscribe_user_status === "created" && family_details_status === "empty") {
        navigate("/familydetails" );
        window.location.reload();
      }else if (subscribe_user_status === "created" && partner_pref_status === "empty"){
        navigate("/partnerdetails")
        window.location.reload();
      }else if (subscribe_user_status === "created" && partner_pref_status === "created" && family_details_status === "created"){
        navigate("/myprofile")
        window.location.reload();
      }
      // navigate("/subscriptionplans", { state: { userId, subscribe_user_status } });

      
      } else {
        // Handle unsuccessful login (show an error message, etc.)
        window.alert("Invalid emailId or password")
      }
    } catch (error) {
      window.alert("Invalid username or Password");
      // Handle the error (show an error message, etc.)
    }
  };


  const handleForgotPasswordClick = () => {
    setShowForgotPasswordPopup(true);
  };

  const handleCloseForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(false);
  };




  // Add event listener to close popup on scroll outside
  useEffect(() => {
    const handleScrollOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleCloseForgotPasswordPopup();
      }
    };

    document.addEventListener("scroll", handleScrollOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("scroll", handleScrollOutside);
    };
  }, []);


  const handleForgotEmailSubmit = async () => {
    if (emailforgot.trim() !== "") {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailforgot,
            action: "emailOtp",
          }),
        });
  
        const data = await response.json();
        setCode(data.otpdata.code);
        setTimestamp(data.otpdata.timestamp);
  
        if (data.otpdata.message === "Mail has been sent successfully!") {
          window.alert("Otp is sent to your email. Please enter OTP to reset the password");
  
          // Show the reset password field
          setShowResetPasswordField(true);
        } else {
          window.alert("Please enter a valid email address");
        }
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setEmailforgoterror(emailforgot.trim() === "" ? "Please enter your email" : "");
    }
  };

  const isPasswordValid = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(resetPassword);


  const handleResetPassword = async () => {
    if (otp.trim() !== "" && resetPassword.trim() !== "" &&
    isPasswordValid && otp === code
    ) {
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailforgot,
            otp_check: 1,
            new_password: resetPassword,
            action: "reset password",
          }),
        });
  
        const data = await response.json();

        if(data){
          window.alert('Password Changed Successfully')
          handleCloseForgotPasswordPopup();
        }
  
        // console.log(data.reset.message)
  
          
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setResetPasswordError(
        resetPassword.trim() === ""
          ? "Please enter new password"
          : !isPasswordValid
          ? "Password must be at least 10 characters long and contain at least one special character and one capital letter"
          : ""
      );

      setOtpError(
        otp.trim() === "" ? "Please enter otp" : otp !== code
          ? "OTP not verified"
          : ""
      );
    }

  }
  
  

  return (
    <>
      <Navigation/>
      <div className={`form-website-body ${showForgotPasswordPopup ? 'popup-open' : ''}`}>
      <div className="website-login-form">
        <img style={{display: 'flex', margin: 'auto', height: '150px', width: '150px'}} src={loginProfile} alt="loginprofile" />

        <form onSubmit={handleSubmit}>
          <div className="inputBox">
            <input
              type="text"
              name="text"
              required
              placeholder="username"
              // onkeyup="this.setAttribute('value', this.value);"
              value={email}
              onChange={handleUsernameChange}
            />
            <img
              className="img-login"
              style={{ height: "45px", width: "35px" }}
              src={emailimg}
              alt="email"
            />
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="password"
              required
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
            />
            <img
              className="img-login"
              style={{ height: "45px", width: "35px" }}
              src={lock}
              alt="email"
            />
          </div>

          <div className="remember-forgot" >
            <label style={{color: 'white', fontSize:'15px'}}>
              <input type="checkbox"  style={{marginRight: '5px'}}/>
              Remember me
            </label>
            <span style={{color: 'white', fontSize:'15px', marginLeft: '30px', cursor:'pointer'}} onClick={handleForgotPasswordClick} >Forgot password?</span>
          </div>
          
          
          
          <button
            style={{
              width: "28rem",
              border: "none",
              background: "#FF8A00",
              color: "white",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              fontSize: '24px',
              fontWeight: '575',
              borderRadius: '10px',
              marginTop: '40px'
            }}

            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>


    {showForgotPasswordPopup && (
      <div className="forgot-password-popup" ref={popupRef}>
        <div className="popup-content">
          <span style={{fontSize: '30px', float: 'right', cursor:"pointer"}}  className="close-popup" onClick={handleCloseForgotPasswordPopup}>
            &times;
          </span>
          <br></br><br></br>


          {showResetPasswordField ? (
    <>
    <h2 style={{fontWeight: "700", fontSize: "30px", textTransform:"none",}}>Reset Password</h2>
      <p style={{marginTop:"-10px", }}>Please enter the OTP sent to your email and set a new password</p>
      <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
        type="number"
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {otpError}
      </div>
      
      <input style={{width:"250px", padding: "12px",fontSize: "16px"}}
        type="text"
        placeholder="Enter new Password"
        value={resetPassword}
        onChange={(e) => setResetPassword(e.target.value)}
      />
      <br></br>
      <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: "left", marginBottom: "10px",marginLeft:"12px"  }}>
        {resetPasswordError}
      </div>
      <div className="otp-btn-forgot" onClick={handleResetPassword}>Reset Password</div>
    </>
  ):(


          <>
          <h2 style={{fontWeight: "700", fontSize: "30px", textTransform:"none",}}>Forgot Password</h2>
          <p style={{marginTop:"-10px"}}>Enter your email address</p>
          <input style={{width:"250px", padding: "12px",marginTop:"10px", fontSize: "16px"}}
            type="email"
            placeholder="Enter your email"
            value={emailforgot}
            onChange={(e) => setEmailforgot(e.target.value)}
          />
          <br></br>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px', marginLeft:"12px" }}>
            {emailforgoterror}
            </div>
          <div className="otp-btn-forgot" onClick={handleForgotEmailSubmit}>GET OTP</div>
          </>
  )}
        </div>
      </div>
    )}

    <Footer/>
    </>
  );
};

export default Login;
