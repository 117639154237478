export const phoneRegex = [
    {
      validation: /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    },
    { validation: /^((\+?971)|0)?5[024568]\d{7}$/ },
    { validation: /^(\+?973)?(3|6)\d{7}$/ },
    { validation: /^(\+?213|0)(5|6|7)\d{8}$/ },
    { validation: /^((\+?20)|0)?1[0125]\d{8}$/ },
    { validation: /^(\+?964|0)?7[0-9]\d{8}$/ },
    { validation: /^(\+?962|0)?7[789]\d{7}$/ },
    { validation: /^(\+?965)[569]\d{7}$/ },
    { validation: /^(!?(\+?966)|0)?5\d{8}$/ },
    { validation: /^(!?(\+?963)|0)?9\d{8}$/ },
    { validation: /^(\+?216)?[2459]\d{7}$/ },
    {
      validation: /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    },
    { validation: /^(\+?359|0)?8[789]\d{7}$/ },
    {
      validation: /^(\+?880|0)1[13456789][0-9]{8}$/,
    },
    {
      validation: /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    },
    {
      validation: /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    },
    {
      validation: /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
    },
    { validation: /^(\+43|0)\d{1,4}\d{3,12}$/ },
    { validation: /^(\+?30|0)?(69\d{8})$/ },
    { validation: /^(\+?61|0)4\d{8}$/ },
    { validation: /^(\+?44|0)7\d{9}$/ },
    { validation: /^(\+?44|0)1481\d{6}$/ },
    {
      validation: /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
    },
    {
      validation: /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    },
    {
      validation: /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    },
    { validation: /^(\+?353|0)8[356789]\d{7}$/ },
    { validation: /^(\+?91|0)?[6789]\d{9}$/ },
    { validation: /^(\+?254|0)(7|1)\d{8}$/ },
    {
      validation: /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    },
    { validation: /^(\+?230|0)?\d{8}$/ },
    { validation: /^(\+?234|0)?[789]\d{9}$/ },
    { validation: /^(\+?64|0)[28]\d{7,9}$/ },
    {
      validation:
        /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    },
    { validation: /^(\+?250|0)?[7]\d{8}$/ },
    { validation: /^(\+65)?[89]\d{7}$/ },
    {
      validation: /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
    },
    { validation: /^(\+?255|0)?[67]\d{8}$/ },
    { validation: /^(\+?256|0)?[7]\d{8}$/ },
    {
      validation:
        /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    },
    { validation: /^(\+?27|0)\d{9}$/ },
    { validation: /^(\+?26)?09[567]\d{7}$/ },
    { locale: "es-CL", iso2: "CL", validation: /^(\+?56|0)[2-9]\d{1}\d{7}$/ },
    {
      validation: /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
    },
    {
      validation: /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    },
    { validation: /^(\+?52)?(1|01)?\d{10,11}$/ },
    { validation: /^(\+?507)\d{7,8}$/ },
    { validation: /^(\+?595|0)9[9876]\d{7}$/ },
    { validation: /^(\+598|0)9[1-9][\d]{6}$/ },
    {
      validation: /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    },
    {
      validation: /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
    },
    {
      validation: /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    },
    { validation: /^(\+?679)?\s?\d{3}\s?\d{4}$/ },
    {
      validation: /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    },
    { validation: /^(\+?33|0)[67]\d{8}$/ },
    { validation: /^(\+?594|0|00594)[67]\d{8}$/ },
    { validation: /^(\+?590|0|00590)[67]\d{8}$/ },
    { validation: /^(\+?596|0|00596)[67]\d{8}$/ },
    { validation: /^(\+?262|0|00262)[67]\d{8}$/ },
    {
      validation: /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
    },
    { validation: /^(\+?36)(20|30|70)\d{7}$/ },
    {
      validation:
        /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    },
    { validation: /^(\+?39)?\s?3\d{2} ?\d{6,7}$/ },
    {
      validation: /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
    },
    { validation: /^(\+?7|8)?7\d{9}$/ },
    {
      validation: /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    },
    {
      validation:
        /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
    },
    { validation: /^(\+370|8)\d{8}$/ },
    {
      validation:
        /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
    },
    { validation: /^(\+?47)?[49]\d{7}$/ },
    { validation: /^(\+?977)?9[78]\d{8}$/ },
    { validation: /^(\+?32|0)4?\d{8}$/ },
    { validation: /^(\+?31|0)6?\d{8}$/ },
    { validation: /^(\+?47)?[49]\d{7}$/ },
    {
      validation: /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
    },
    {
      validation:
        /(?=^(\+?5{2}\-?|0)[1-9]{2}\-?\d{4}\-?\d{4}$)(^(\+?5{2}\-?|0)[1-9]{2}\-?[6-9]{1}\d{3}\-?\d{4}$)|(^(\+?5{2}\-?|0)[1-9]{2}\-?9[6-9]{1}\d{3}\-?\d{4}$)/,
    },
    { validation: /^(\+?351)?9[1236]\d{7}$/ },
    {
      validation: /^(\+?4?0)\s?7\d{2}(\/|\s|\.|\-)?\d{3}(\s|\.|\-)?\d{3}$/,
    },
    { validation: /^(\+?7|8)?9\d{9}$/ },
    {
      validation:
        /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
    },
    {
      validation: /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    },
    { validation: /^(\+3816|06)[- \d]{5,9}$/ },
    {
      validation: /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
    },
    { validation: /^(\+66|66|0)\d{9}$/ },
    { validation: /^(\+?90|0)?5\d{9}$/ },
    { validation: /^(\+?38|8)?0\d{9}$/ },
    {
      validation:
        /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
    },
    {
      validation:
        /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
    },
    { validation: /^(\+?886\-?|0)?9\d{8}$/ },
  ];
  