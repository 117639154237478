import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "react-responsive-modal/styles.css";
import "./plan.css";
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Myprofile.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import usehandleaddFavourite from './utils/usehandleaddfavorite';
import useFavoriteuserlist from './utils/useFavoriteuserlist';
import usehandleremoveFavourite from './utils/usehandleremovefavorite';
import useHandleimg from './utils/useHandleimg';
import useHandlealbum from './utils/useHandlealbum';



const Sliderimages = ({id}) => {
  const userImg = useHandleimg(id);
  const userAlbum = useHandlealbum(id);
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDivClick = () => {
    // Navigate to '/mymatchescompleteprofile' and pass the 'id' prop
    navigate(`/myreceivedinterestcompleteprofile/${id}`);
  };

  return (
    <>
    <Link to={`/myreceivedinterestcompleteprofile/${id}`} style={{ textDecoration: 'none' }}>
    {userImg?.photo_url ? (
                  <Slider {...sliderSettings}>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={handleDivClick}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "20px",
                          border: "1px solid #777777",
                        }}
                        src={userImg?.photo_url}
                        alt="person"
                      /> 
                    </div>


                    {/* Album Slides */}
                    {userAlbum?.image_urls.map((imageUrl, index) => (
                      <div
                        key={index + 1} // Use a unique key for each additional slide
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          border: "1px solid #777777",
                        }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "20px",
                          }}
                          src={imageUrl}
                          alt={`person-${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>
                  
                ) : (
                  <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative", // Add position relative to the container
                  }}
                >
                  <img className="img-mymatches"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                      border: "1px solid #777777"
                    }}
                    src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
                    alt="person"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <h3>Profile photo not found</h3>
                  </div>
                </div>
                        )}
    </Link>
      
    </>
  );
}


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

const ReceivedInterestgrid = ({ receivedInterestlist }) => {

    const useProfile = (favUserIds) => {
        const [userInfos, setUserInfos] = useState([]);
        const [allDataFetched, setAllDataFetched] = useState(false);
    
        useEffect(() => {
          const fetchDataForAllUsers = async () => {
            try {
              const promises = favUserIds.map(async (interest) => {
                const userId = interest.user_from;
                const data = await fetch(`/prod/api_code/profile.php?user_id=${userId}`,{
                  method: 'GET', // Specify the HTTP method (GET in this case)
                  headers: {
                    Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
                    'Content-Type': 'application/json', // Adjust the content type if needed
                    
                    // Add any other headers you need here
                  },
                 
                });
                const json = await data.json();
                return json;
              });
    
              const responses = await Promise.all(promises);
              setUserInfos(responses);
              setAllDataFetched(true);
            } catch (error) {
              console.error("Error fetching user profiles:", error.message);
              // Handle the error (show an error message, etc.)
            }
          };
    
          if (!allDataFetched) {
            fetchDataForAllUsers();
          }
        }, [favUserIds, allDataFetched]);
    
        return userInfos;
      };
    
      const favUserIds = receivedInterestlist;
      const userInfos = useProfile(favUserIds);
      const profileDataArray = userInfos.map((user) => user.profile_data);
      console.log(profileDataArray);

      const percentage = 66;



  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const groupedUserinfo = chunkArray(profileDataArray, 8);


  const userId = sessionStorage.getItem("userId");
  const userfav = useFavoriteuserlist(userId)
  const userfavlist = userfav?.favorites_list 

  
  
  

  const handleaddFavourite = (id) => {
    if (userfavlist?.includes(id)) {
      // Show alert if the user is already in favorites
      usehandleremoveFavourite(id)
      alert("User removed from favorites successfully")
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleaddFavourite(id);
      alert("User added to favorites successfully")
    }

    window.location.reload();
  };

  const getInterestId = (userId) => {
    const userInterest = receivedInterestlist.find(item => item.user_from === userId);
    return userInterest?.interest_id || null;
  };


  const handleaccept = async (friendid, requestid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/interestfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "userLoggedIn": userId,
          "friend_id": friendid,
          "request_id": requestid,
          
          isaccept: true,
        }),
      });

      const data = await response.json();
      window.location.reload();
      // Redirect to AdvanceSearchResults component with responseData as prop
    if (data) {
      window.alert("Accepted request successfully")
    }
      

    } catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }

  }

  const handlereject = async (requestid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/interestfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "userLoggedIn": userId,
          "request_id": requestid,
          
          isreject: true,
        }),
      });

      const data = await response.json();
      // setResponseData(data);

      // Redirect to AdvanceSearchResults component with responseData as prop
    if (data) {
      window.alert("Reject request successfully")
      window.location.reload();
    }
      

    } catch (err) {
      console.error(err);
      window.alert("Error occurred while processing the request.");
    }
  }

  
  

  return (
    <>

{receivedInterestlist && receivedInterestlist?.length > 0 ? (

    <div id="plans">
        {groupedUserinfo.map((group, index) => (
          <div key={index} className="App mymatches-grid-slider">
            <Carousel breakPoints={breakPoints}>
              {group.map((user, idx) => (
                <Item key={idx} classname="item-btn mymatches-grid-box"
                  style={{
                    height: '1000',
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    background: 'none',
                    justifyContent: 'normal',
                    boxShadow: 'none'
                  }}
                >

                <div className="profile-img mymatchesslider mymatchesslider-grid">
                <Sliderimages id={user?.id}/>

                <div className='mymatches-grid-title' >
                    
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <h1 style={{ textAlign: "left" }}>
                        {user?.full_name}
                        <Link style={{marginLeft:"10px", fontSize:"30px", color:"black"}} to={`/mymatchescompleteprofile/${user?.id}`}>&#128065;
                       </Link>
                    </h1>
                    <span
                      style={{
                        height: "40px",
                        width: "40px",
                        float: "right",
                        marginTop: "10px",
                        cursor: "pointer",
                        ...(userfavlist?.includes(user?.id)
                          ? { color: "#A0A0A0", fontSize: "30px" } // Styling for ❤️
                          : { color: "#A0A0A0", fontSize: "60px", marginTop: "-10px" } // Styling for ♡
                        ),
                      }}
                      onClick={() => handleaddFavourite(user?.id)}
                    >
                      {userfavlist?.includes(user?.id) ? "❤️" : "♡"}
                    </span>
                    </div>
                    
                    
      
                    
                    

                    <div className='two-column-list' style={{display: 'flex'}}>
                        <div className='column' style={{flex: '1'}}>
                            <ul>
                                <li>{user?.religion}</li>
                                <li>{user?.age}</li>
                                <li>{(() => {
                                    const heightInInches = user?.height;
                                    const feet = Math.floor(heightInInches / 12);
                                    const inches = heightInInches % 12;

                                    return `${feet} feet ${inches} inches`;
                                })()}</li>
                                <li>{user?.income}</li>
                                <li>{user?.working_status}</li>

                            </ul>
                        </div>
                        <div className='column' style={{flex: '1'}}>
                            <ul>
                                <li>{user?.gotra}</li>
                                <li>{user?.sub_caste}</li>
                                <li>{user?.city}</li>
                                <li>{user?.occupation}</li>
                                <li>{user?.marital_status}</li>
                            </ul>
                        </div>
                    </div>

                    <CircularProgressbar value={percentage} text={`${percentage}%`} />;
                    <p style={{textAlign:"center", fontSize:"19px", fontWeight:"500", color:"#949494", marginTop:"-40px"}}>Personality Meter</p>

                    <div className='two-column-list' style={{display: 'flex'}}>
                    <div className='column' style={{flex: '1'}}>
                        <ul>
                        <li style={{color:"white", background:"#40DB09", fontSize:"18px", fontWeight:"700", padding:"10px", border:"none", borderRadius:"5px"}}
                        onClick={() => handleaccept(user?.id, getInterestId(user?.id) )}
                        >&#x2713; Accept</li>
                        </ul>
                    </div>
                    <div className='column' style={{flex: '1'}}>
                        <ul>
                        <li style={{color:"white", background:"#FF3E3E", fontSize:"18px", fontWeight:"700", padding:"10px", border:"none", borderRadius:"5px"}}
                        onClick={() => handlereject(getInterestId(user?.id))}
                        >&#10006; Reject</li>
                        </ul>
                    </div>
                    </div>
                    
                </div>
                </div>
                
                  
                </Item>
              ))}
            </Carousel>
          </div>
        ))}
      </div>
      ) : (
        <h1>No Received request found</h1>
      )}
      

      <Aboutdownload />
      <Footer />
    </>
  );
};

export default ReceivedInterestgrid;
