
import React, {useEffect} from 'react';
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "react-responsive-modal/styles.css";
import "./plan.css";
import Aboutdownload from './Aboutdownload';
import Footer from './Footer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from 'react-router-dom';
import "./Myprofile.css";
import usehandleaddFavourite from './utils/usehandleaddfavorite';
import useFavoriteuserlist from './utils/useFavoriteuserlist';
import usehandleremoveFavourite from './utils/usehandleremovefavorite';
import useSentrequestlist from './utils/useSentrequestlist';
import usehandleSentinterest from './utils/useSentinterest';
import telegram from './images/telegram.png'
import telegram1 from './images/telegram1.png'
import useHandleimg from './utils/useHandleimg';
import useHandlealbum from './utils/useHandlealbum';



const Sliderimages = ({id}) => {
  const userImg = useHandleimg(id);
  const userAlbum = useHandlealbum(id);
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDivClick = () => {
    // Navigate to '/mymatchescompleteprofile' and pass the 'id' prop
    navigate(`/mymatchescompleteprofile/${id}`);
  };

  return (
    <>
     <Link to={`/mymatchescompleteprofile/${id}`} style={{ textDecoration: 'none' }}>
     {userImg?.photo_url ? (
                  <Slider {...sliderSettings}>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={handleDivClick}
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "20px",
                          border: "1px solid #777777",
                        }}
                        src={userImg?.photo_url}
                        alt="person"
                      /> 
                    </div>


                    {/* Album Slides */}
                    {userAlbum?.image_urls.map((imageUrl, index) => (
                      <div
                        key={index + 1} // Use a unique key for each additional slide
                        style={{
                          height: "100%",
                          width: "100%",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          border: "1px solid #777777",
                        }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "20px",
                          }}
                          src={imageUrl}
                          alt={`person-${index + 1}`}
                        />
                      </div>
                    ))}
                  </Slider>
                  
                ) : (
                  <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative", // Add position relative to the container
                  }}
                >
                  <img className="img-mymatches"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                      border: "1px solid #777777"
                    }}
                    src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
                    alt="person"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <h3>Profile photo not found</h3>
                  </div>
                </div>
                        )}
     </Link>
      
    </>
  );
}

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

const NearbyGrid = ({userinfo }) => {



  const chunkArray = (arr, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const groupedUserinfo = chunkArray(userinfo, 8);

  
  const userId = sessionStorage.getItem("userId");
  const userfav = useFavoriteuserlist(userId)
  const userfavlist = userfav?.favorites_list 

  const sentInterestlist = useSentrequestlist(userId);
  const sentinterestid = sentInterestlist?.friend_requests_sent;

  


  const handleaddFavourite = (id) => {
    if (userfavlist?.includes(id)) {
      // Show alert if the user is already in favorites
      usehandleremoveFavourite(id)
      alert("User removed from favorites successfully")
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleaddFavourite(id);
      alert("User added to favorites successfully")
    }

    window.location.reload();
  };

  

  const handSentinterest = (id) => {
    // Check if the current user ID exists in the friend_requests_sent array
    const isInterestSent = sentInterestlist?.friend_requests_sent.some(item => item.user_to === id);
  
    if (isInterestSent) {
      // Show alert if the user is already in sent
      alert("Request already sent to the user");
    } else {
      // Call the function to add favorite if the user is not in favorites
      usehandleSentinterest(id);
      alert("Request Sent successfully");
    }
  
    window.location.reload();
  };

  

  return (
    <>
      {userinfo?.length ? (
        <div id="plans">
        {groupedUserinfo.map((group, index) => (
          <div key={index} className="App mymatches-grid-slider">
            <Carousel breakPoints={breakPoints}>
              {group.map((user, idx) => (
                
                <Item key={idx} classname="item-btn mymatches-grid-box"
                  style={{
                    height: '800',
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    background: 'none',
                    justifyContent: 'normal',
                    boxShadow: 'none'
                  }}
                >

                <div className="profile-img mymatchesslider mymatchesslider-grid">
                <Sliderimages id={user?.id}/>

                <div className='mymatches-grid-title' >
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                    <h1 style={{ textAlign: "left" }}>
                      {user?.full_name}
                      <Link style={{marginLeft:"10px", fontSize:"30px", color:"black"}} to={`/mymatchescompleteprofile/${user?.id}`}>&#128065;
                     </Link>
                    </h1>

                    <div style={{display:"flex"}}>
                    <div>
                    <span
                      style={{
                        height: "40px",
                        width: "40px",
                        float: "right",
                        marginTop: "15px",
                        cursor: "pointer",
                        ...(sentInterestlist?.friend_requests_sent.some(item => item.user_to === user?.id)
                          ? { color: "#A0A0A0", fontSize: "30px" } 
                          : { color: "#A0A0A0", fontSize: "60px", marginTop: "15px" } 
                        ),
                      }}
                      onClick={() => handSentinterest(user?.id)}
                    >
                      {sentInterestlist?.friend_requests_sent.some(item => item.user_to === user?.id) ? <img className="telegram" src={telegram} alt="telegram" /> : <img className="telegram1" src={telegram1} alt="telegram1" />}
                    </span>
                    </div>

                    <div>
                    <span
                      style={{
                        height: "40px",
                        width: "40px",
                        float: "right",
                        marginTop: "10px",
                        cursor: "pointer",
                        ...(userfavlist?.includes(user?.id)
                          ? { color: "#A0A0A0", fontSize: "30px" } // Styling for ❤️
                          : { color: "#A0A0A0", fontSize: "60px", marginTop: "-10px" } // Styling for ♡
                        ),
                      }}
                      onClick={() => handleaddFavourite(user?.id)}
                    >
                      {userfavlist?.includes(user?.id) ? "❤️" : "♡"}
                    </span>
                    </div>
                    </div>
                    </div>
      
                    
                    

                    <div className='two-column-list' style={{display: 'flex'}}>
                        <div className='column' style={{flex: '1'}}>
                            <ul>
                                <li>{user?.religion}</li>
                                <li>{user?.age}</li>
                                <li>{user?.height}</li>
                                <li>{user?.income}</li>
                                <li>{user?.working_status}</li>
                            </ul>
                        </div>
                        <div className='column' style={{flex: '1'}}>
                            <ul>
                                <li>{user?.gotra}</li>
                                <li>{user?.sub_caste}</li>
                                <li>{user?.city}</li>
                                <li>{user?.occupation}</li>
                                <li>{user?.marital_status}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                
                  
                </Item>
              ))}
            </Carousel>
          </div>
        ))}
      </div>
            ) : (
              <div>
                <h3 style={{textAlign:"center"}}>No user found</h3>
              </div>
            )}

      <Aboutdownload />
      <Footer />
    </>
  );
};

export default NearbyGrid;