const userId = sessionStorage.getItem("userId");

const usehandleremoveFavourite = async(id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/mymatchesfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
        },
        body: JSON.stringify({
          "user_id": userId,
          "remove_user_id": id,
          
          
          isremovefavourite: true,
        }),
      });

      const data = await response.json();
      console.log(data)

      

    } catch (err) {
      console.error(err);
      // window.alert("Error occurred while processing the request.");
    }

  }

  export default usehandleremoveFavourite;