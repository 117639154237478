import React, { useState, useEffect } from 'react';
import './NewsletterModal.css'
import NewsletterPopupcontent from './NewsletterPop-upcontent';

const Popup = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(true);
  
    const handleClose = () => {
      setIsOpen(false);
      onClose();
    };
  
    return (
      isOpen && (
        <>
          <div className="newsletteroverlay" />
          <div className="newsletterpopup-overlay">
            <div className="newsletterpopup-content">
              {/* <p>This is a pop-up!</p> */}
              <button style={{fontSize: '25px', fontWeight: 'bold', border: 'none', background: 'none'}} className='newsletter-btn' onClick={handleClose}>✖</button>
              <NewsletterPopupcontent/>
              
            </div>
          </div>
        </>
      )
    );
  };
  
  export default Popup;

