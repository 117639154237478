import React, { useState, useEffect } from "react";
import "./Form.css";
import MultiRangeSlider from "./MultiRangeSlider";
import Modal from "./fullScreenmodal";
import MultiStepForm from "./MultiStepForm";
import img from "./google store.jpeg";

import { Navigation } from "./navigation";






export const Header = (props) => {
  const [modalShown, toggleModal] = React.useState(false);

  

  const handleHover1 = () => {
    const infoDiv1 = document.getElementById("infoDiv1");
    if (infoDiv1) {
      infoDiv1.style.visibility = "visible";
    }
  };

  const handleLeave1 = () => {
    const infoDiv1 = document.getElementById("infoDiv1");
    if (infoDiv1) {
      infoDiv1.style.visibility = "hidden";
    }
  };

  // 

  return (
    <div>
      <Navigation />
      <header id="header">
        <div className="intro"
        
        >
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="container">
                  <div className="col-md-6 intro-text new-col">
                    <div>
                      {/* <div
                        id="infoDiv2"
                        style={{
                          background: "white",
                          padding: "20px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "100px",
                          marginBottom: "auto",
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          width: "fit-content",
                          height: "fit-content",
                          visibility: "hidden", // initially hidden
                          borderRadius: "10px",
                          zIndex: 1, // Set a higher z-index to make it appear above the background image
                        }}
                      >
                        <h5>Please fill this form to register</h5>
                      </div> */}

                      <div
                        className="container mt-3 col-sm-6 new-height phone-left-margin new-width"
                        style={{
                          background: "#2e2d2d",
                          border: "15px solid white",
                          borderRadius: "15px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          position: "relative", // Set position to relative
                          // zIndex: 0, // Set a lower z-index
                        }}
                      >
                        <form
                          id="register"
                          onSubmit={(e) => e.preventDefault()}
                          className="new-form-register"
                          style={{
                            marginTop: "40px",
                          }}
                        >
                          <div class="col-sm-12 form-group">
                            <label
                              style={{
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "white",
                              }}
                              htmlFor="gender"
                            >
                              Looking for a...
                            </label>
                            <select
                              class="form-control custom-select browser-default"
                              name="gender"
                              id="gender"
                              style={{
                                borderRadius: "10px",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "black",
                              }}
                            >
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                            </select>
                          </div>
                          <div
                            class="col-sm-12 form-group"
                            style={{ marginBottom: "40px" }}
                          >
                            <label
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "white",
                              }}
                              for="name-l"
                            >
                              Age
                            </label>
                            <br />
                            <MultiRangeSlider
                              onChange={(min, max) => console.log(`${min}`)}
                              min={18}
                              max={100}
                            />
                            {/* <input  type="number" class="form-control" style={{borderRadius: '10px'}} name="lname" id="name-l" placeholder="22" required />  */}
                          </div>

                          <div
                            class="col-sm-12 form-group"
                            style={{ marginBottom: "40px" }}
                          >
                            <label
                              style={{
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "white",
                              }}
                              for="address-1"
                            >
                              Height in ft
                            </label>
                            <br />
                            <MultiRangeSlider
                              onChange={(min, max) => console.log(`$min`)}
                              min={4}
                              max={10}
                            />
                            {/* <input type="number" class="form-control" style={{borderRadius: '10px'}} name="Locality" id="address-1" placeholder="5" required /> */}
                          </div>
                          <div class="col-sm-12 form-group">
                            <label
                              style={{
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "white",
                              }}
                              htmlFor="profession"
                            >
                              Profession
                            </label>
                            <select
                              class="form-control custom-select browser-default"
                              style={{
                                borderRadius: "10px",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "black",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Accountant">Accountant</option>
                              <option value="Artist">Artist</option>
                              <option value="Engineer">Engineer</option>
                              <option value="Doctor">Doctor</option>
                              <option value="Teacher">Teacher</option>
                              <option value="Software Developer">Software Developer</option>
                              <option value="Manager">Manager</option>
                              <option value="Lawyer">Lawyer</option>
                              <option value="Nurse">Nurse</option>
                              <option value="Designer">Designer</option>
                              <option value="Writer">Writer</option>
                              <option value="Farmer">Farmer</option>
                              <option value="Cook">Cook</option>
                              <option value="Clerk">Clerk</option>
                              <option value="Entrepreneur">Entrepreneur</option>
                              <option value="Police Officer">Police Officer</option>
                              <option value="Chef">Chef</option>
                              <option value="Salesperson">Salesperson</option>
                              <option value="Student">Student</option>
                              <option value="Other">Other</option>

                            </select>
                          </div>

                          <div class="col-sm-12 form-group">
                            <label
                              style={{
                                fontWeight: "400",
                                fontSize: "20px",
                                color: "white",
                              }}
                              htmlForor="religion"
                            >
                              Religion
                            </label>
                            <select
                              class="form-control custom-select "
                              style={{
                                borderRadius: "10px",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "black",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Christianity">Christianity</option>
                              <option value="Islam">Islam</option>
                              <option value="Buddhism">Buddhism</option>
                              <option value="Hinduism">Hinduism</option>
                              <option value="Sikhism">Judaism</option>
                              <option value="Sikhism">Sikhism</option>
                              <option value="Other">Other</option>
                            </select>

                            {/* <input type="number" class="form-control" style={{borderRadius: '10px'}} name="Locality" id="address-1" placeholder="5" required /> */}
                          </div>

                          <div class="col-sm-12 form-group mb-0">
                            <button
                              class="btn"
                              style={{
                                background: "#AC0A00",
                                color: "white",
                                fontWeight: "600",
                                width: "100%",
                                borderRadius: "10px",
                                fontSize: "18px",
                              }}
                              onClick={() => {
                                toggleModal(!modalShown);
                              }}
                            >
                              Register for free
                            </button>
                            <Modal
                              shown={modalShown}
                              close={() => {
                                toggleModal(false);
                              }}
                            >
                              {/* <h1>Look! I'm inside the modal!</h1> */}
                              <MultiStepForm/>
                            </Modal>
                          </div>

                          {/* </div> */}
                        </form>
                      </div>

                      {/* <div
                        class="container mt-3 col-sm-6 new-height phone-margin"
                        style={{
                          background:
                            "url(https://i.ibb.co/0sZ3G5T/Smartphone-with-logo-new-1.png)",
                          // height: "600px",
                          // marginTop: "-50px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div> */}
                    </div>
                  </div>


                  <div className="col-md-6 intro-text new-col" >
                
                    

                    <a
                    href="https://play.google.com/store/apps/details?id=com.app.vivahsahyog"
                    className="btn btn-custom btn-lg page-scroll"
                    style={{ background: "none", marginTop: "-40px" }}
                    onMouseOver={handleHover1}
                    onMouseLeave={handleLeave1}
                  >
                    <img src={img} style={{width: '250px'}} className="google-img"/>
                  </a>{" "}
                  
                  </div>

                  
                </div>
              </div>
            </div>
            
          </div>
        </div>


        
      </header>
    </div>
  );
};
