import React, {useEffect} from "react";
import { Privacynavigation } from "./Privacynavigation";
import Footer from "./Footer";

const Privacypolicy = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Privacynavigation />

      <div
        style={{
          marginTop: "80px",
          padding: "20px 50px",
          background:
            "linear-gradient(180deg, hsla(45, 100%, 59%, 1) 0%, hsla(48, 63%, 80%, 1) 100%)",
          paddingBottom: "250px",
        }}
      >
        <h3
          style={{
            fontSize: "35px",
            fontWeight: "700",
            textAlign: "center",
            color: "white",
            paddingBottom: "30px",
            fontFamily: "Lemon",
          }}
        >
          Privacy Policy
        </h3>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          This privacy policy (“Policy”) relates to the manner RADHA KRISHNA
          TECHNOLOGY SOLUTIONS (“we”, “us”, “our”) in which we use, handle and
          process the data that you provide us in connection with using the
          products or services we offer. By using this website or by availing
          goods or services offered by us, you agree to the terms and conditions
          of this Policy, and consent to our use, storage, disclosure, and
          transfer of your information or data in the manner described in this
          Policy.
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We are committed to ensuring that your privacy is protected in
          accordance with applicable laws and regulations. We urge you to
          acquaint yourself with this Policy to familiarize yourself with the
          manner in which your data is being handled by us.
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          RADHA KRISHNA TECHNOLOGY SOLUTIONS may change this Policy periodically
          and we urge you to check this page for the latest version of the
          Policy in order to keep yourself updated.
        </p>

<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          What data is being collected
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We may collect the following information from you:
        </p>

        <ul
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <li>1. Name</li>
          <li>2. Contact information including address and email address</li>

          <li>3. Demographic information or, preferences or interests</li>

          <li>
            4. Personal Data or Other information relevant/ required for
            providing the goods or services to you
          </li>

          <li>
            5. The meaning of Personal Data will be as defined under relevant
            Indian laws
          </li>
        </ul>

<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <span style={{ fontWeight: "bold", color: '#e13737' }}>Note:</span> Notwithstanding
          anything under this Policy as required under applicable Indian laws,
          we will not be storing any credit card, debit card or any other
          similar card data of yours. Please also note that all data or
          information collected from you will be strictly in accordance with
          applicable laws and guidelines.
        </p>

<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          What we do with the data we gather
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We require this data to provide you with the goods or services offered
          by us including but not limited, for the below set out purposes:
        </p>

        <ul
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <li>1. Internal record keeping.</li>
          <li>2. For improving our products or services.</li>

          <li>
            3. For providing updates to you regarding our products or services
            including any special offers.
          </li>

          <li>4. To communicate information to you</li>

          <li>5.For internal training and quality assurance purposes</li>
        </ul>

<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          Who do we share your data with
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We may share your information or data with:
        </p>

        <ul
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <li>
            1. Third parties including our service providers in order to
            facilitate the provisions of goods or services to you, carry out
            your requests, respond to your queries, fulfil your orders or for
            other operational and business reasons.
          </li>
          <li>2. With our group companies (to the extent relevant)</li>

          <li>
            3. Our auditors or advisors to the extent required by them for
            performing their services
          </li>

          <li>
            4. Governmental bodies, regulatory authorities, law enforcement
            authorities pursuant to our legal
          </li>

          <li>5.obligations or compliance requirements.</li>
        </ul>

<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          How we use cookies
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We use "cookies" to collect information and to better understand
          customer behaviour. You can instruct your browser to refuse all
          cookies or to indicate when a cookie is being sent. However, if you do
          not accept cookies, you may not be able to avail our goods or services
          to the full extent. We do not control the use of cookies by third
          parties. The third party service providers have their own privacy
          policies addressing how they use such information.
        </p>
<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          Your rights relating to your data
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <span style={{ fontWeightl: "bold", color: '#e13737' }}>Right to Review - </span>You can
          review the data provided by you and can request us to correct or amend
          such data (to the extent feasible, as determined by us). That said, we
          will not be responsible for the authenticity of the data or
          information provided by you
        </p>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <span style={{ fontWeightl: "bold", color: '#e13737' }}>
            Withdrawal of your Consent -{" "}
          </span>
          You can choose not to provide your data, at any time while availing
          our goods or services or otherwise withdraw your consent provided to
          us earlier, in writing to our email ID: info@vivahsahyog.comIn the
          event you choose to not provide or later withdraw your consent, we may
          not be able to provide you our services or goods.Please note that
          these rights are subject to our compliance with applicable laws.
        </p>
<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          How long will we retain your information or data?
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We may retain your information or data (i) for as long as we are
          providing goods and services to you; and (ii) as permitted under
          applicable law, we may also retain your data or information even after
          you terminate the business relationship with us. However, we will
          process such information or data in accordance with applicable laws
          and this Policy.
        </p>
<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          Data Security
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          We will use commercially reasonable and legally required precautions
          to preserve the integrity and security of your information and data
        </p>
<br></br>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#e13737",
            textAlign: "justify",
          }}
        >
          Queries/ Grievance Officer
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          For any queries, questions or grievances about this Policy, please
          contact us using the contact information provided on this website.
        </p>

        <hr></hr>
      </div>

      <Footer/>
    </div>
  );
};

export default Privacypolicy;
