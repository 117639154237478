import React, { useState,useEffect  } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Myprofile.css";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import useHandleimg from "./utils/useHandleimg";
import useHandlealbum from "./utils/useHandlealbum";
import verifiedimg from "./images/verified.png"





 const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


const UserCard = ({ verified,id,full_name, religion, age, height, income, working_status, gotra, sub_caste, city, occupation, marital_status }) => {
  const userId = sessionStorage.getItem("userId");
  const userImg = useHandleimg(id);
  const userAlbum = useHandlealbum(id);
  const navigate = useNavigate();

  const handleDivClick = () => {
    // Navigate to '/mymatchescompleteprofile' and pass the 'id' prop
    navigate(`/myfavoritecompleteprofile/${id}`);
  };
  
  
  return (
    <div style={{ marginTop: "0px", marginBottom:"20px" }} className="first-section mymatches-slide">
    <Link to={`/myfavoritecompleteprofile/${id}`} style={{ textDecoration: 'none' }}>
    <div className="profile-img mymatchesslider col-md-4" onClick={handleDivClick}>
    {userImg?.photo_url ? (
            <Slider {...sliderSettings}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    border: "1px solid #777777",
                  }}
                  src={userImg?.photo_url}
                  alt="person"
                /> 
              </div>


              {/* Album Slides */}
              {userAlbum?.image_urls.map((imageUrl, index) => (
                <div
                  key={index + 1} // Use a unique key for each additional slide
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    border: "1px solid #777777",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={imageUrl}
                    alt={`person-${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
            
          ) : (
            <div
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative", // Add position relative to the container
            }}
          >
            <img className="img-mymatches"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "20px",
                border: "1px solid #777777"
              }}
              src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133352062-stock-illustration-default-placeholder-profile-icon.jpg"
              alt="person"
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <h3>Profile photo not found</h3>
            </div>
          </div>
                  )}
    </div>
    </Link>
    

    <div
      className="my-matches col-md-10  desktop"
      style={{
        background: "#fce6943d",
        border: "1px solid #FF8A00",
        boxShadow: "0px 4px 4px 0px #FBC91340",
        borderRadius: "5px",
      }}
    >
      
      <div style={{display:"flex"}}>
      <h1 style={{ textAlign: "left" }}>
      {verified === "yes" && (<img src={verifiedimg} alt="img" className="verifiedimg"/>)}
        {full_name}
        <Link style={{marginLeft:"10px", fontSize:"30px", color:"black"}} to={`/mymatchescompleteprofile/${id}`}>&#128065;
        </Link>
      </h1>
      <span style={{height:'40px', width:'40px', color:'#A0A0A0', float:"right", fontSize:"30px",marginTop:"10px"}}>❤️</span>
      </div>
      
      
      <div className="col-md-5 matches-row">
        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Religion: </div>
          <div className=" match-value col-md-2">
            {religion}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Age: </div>
          <div className=" match-value col-md-2">
            {age}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Height: </div>
          <div className=" match-value col-md-2">
          {(() => {
                  const heightInInches = height;
                  const feet = Math.floor(heightInInches / 12);
                  const inches = heightInInches % 12;

                  return `${feet} feet ${inches} inches`;
              })()}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Salary: </div>
          <div className=" match-value col-md-2">
            {income}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">
            Working Status:{" "}
          </div>
          <div className=" match-value col-md-2">
            {working_status}{" "}
          </div>
        </div>
      </div>

      <div className="col-md-5 matches-row">
        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Gotra: </div>
          <div className=" match-value col-md-2">
            {gotra}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Sub-caste: </div>
          <div className=" match-value col-md-2">
            {sub_caste}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">City: </div>
          <div className=" match-value col-md-2">
            {city}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">Profession: </div>
          <div className=" match-value col-md-2">
            {occupation}{" "}
          </div>
        </div>

        <div style={{ display: "flex" }} className="col-sub-div">
          <div className=" match-title col-md-3">
            Marital Status:{" "}
          </div>
          <div className=" match-value col-md-2">
            {marital_status}{" "}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
  };

const UserSlide = ({ userinfo }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {userinfo.map((users, index) => (
      <UserCard key={index} {...users} />
    ))}
  </div>
);

const UserList = ({ userinfo }) => (
  <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
    {[...Array(Math.ceil(userinfo.length / 5))].map((_, index) => (
      <UserSlide key={index} userinfo={userinfo.slice(index * 5, (index + 1) * 5)} />
    ))}
  </Slider>
);






const Myfavorite = ({ favuserlist }) => {
  const [loading, setLoading] = useState(true); // Loading state

  const useProfile = (favUserIds) => {
    const [userInfos, setUserInfos] = useState([]);
    const [allDataFetched, setAllDataFetched] = useState(false);

    useEffect(() => {
      const fetchDataForAllUsers = async () => {
        try {
          const promises = favUserIds.map(async (userId) => {
            const data = await fetch(`/prod/api_code/profile.php?user_id=${userId}`, {
              method: 'GET',
              headers: {
                Authorization: process.env.REACT_APP_AUTHORIZING_HEADER,
                'Content-Type': 'application/json',
              },
            });
            const json = await data.json();
            return json;
          });

          const responses = await Promise.all(promises);
          setUserInfos(responses);
          setAllDataFetched(true);
        } catch (error) {
          console.error("Error fetching user profiles:", error.message);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      };

      if (!allDataFetched) {
        fetchDataForAllUsers();
      }
    }, [favUserIds, allDataFetched]);

    return userInfos;
  };

  const favUserIds = favuserlist;
  const userInfos = useProfile(favUserIds);
  const profileDataArray = userInfos.map((user) => user.profile_data);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {favuserlist && favuserlist?.length > 0 ? (
            <div>
              <UserList userinfo={profileDataArray} />
            </div>
          ) : (
            <h1>No favorite user found</h1>
          )}
          <Aboutdownload />
          <Footer />
        </>
      )}
    </>
  );
};

export default Myfavorite;
