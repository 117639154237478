import React, {useState, useEffect,} from "react";
import vs from './images/login-logo.png'
import { Link } from "react-router-dom";

export const Paymentnavigation = (props) => {
  const [scrolled, setScrolled] = useState(false);
 

  const userId = sessionStorage.getItem("userId");




  


  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount and unmount

  const navClass = scrolled ? 'navbar-scrolled' : 'nav-grey';

  










  return (
    <>
      <nav id="menu" style={{background: '#FF8A00'}} className={`navbar navbar-default navbar-fixed-top ${navClass}`}>
      <div className="container">
        {/* <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button> */}
          <Link className="navbar-brand page-scroll" to="/">
            <img style={{ width: "120px", height: "90px", marginTop: "-20px" }} src={vs} alt="logo" />
          </Link>
        {/* </div> */}

        
      </div>
      </nav>


   
    </>
    




  );
};
