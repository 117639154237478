import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import PlanPage from "./components/PlanPage";
import Termscondition from "./components/Termscondition";
import Privacypolicy from "./components/Privacypolicy";
import Refundpolicy from "./components/Refundpolicy";
import Login from "./components/Login";
import Myprofile from "./components/Myprofile";
import Search from "./components/Search";
import Mymatchesfilter from "./components/Mymatchesfilter";
import AdvanceSearchResult from "./components/AdvanceSearchResult";
import CommunitySearchresultNavbar from "./components/CommunitySearchresultNavbar";
import LocationSearchresultNavbar from "./components/LocationSearchresultNavbar";
import AdvanceSearchresultNavbar from "./components/AdvanceSearchresultNavbar";
import IdSearchresultNavbar from "./components/IdSearchresultNavbar";
import Myfavoritefilter from "./components/Myfavoritefilter";
import Interestfilter from "./components/Interestfilter";
import MymatchesCompleteProfile from "./components/MymatchesCompleteProfile";
import PlanSubscribe from "./components/PlanSubscribe";
import MyfavoriteCompleteProfile from "./components/MyfavoriteCompleteProfile";
import MySentInterestCompleteProfile from "./components/MySentInterestCompleteProfile";
import MyreceivedinterestCompleteProfile from "./components/MyreceivedinterestCompleteProfile";
import MyacceptinterestCompleteProfile from "./components/MyacceptinterestCompleteProfile";
import MyrejectinterestCompleteProfile from "./components/MyrejectinterestCompleteProfile";
import Error from "./components/Error";
import FamilyDetails from "./components/FamilyDetails";
import PartnerDetails from "./components/PartnerDetails";
import About from "./components/About";
import { Accountdeletion } from "./components/Accountdeletion";
import { SettingsChangepassword } from "./components/SettingsChangepassword";
import Kyc from "./components/Kyc";
import PricingCard from "./components/PricingCard";
import Checkout from "./components/Checkout";

import PaymentSuccess from "./components/PaymentSuccess";
import Biodata from "./components/Biodata";
import BiodataForm from "./components/BiodataForm";
import BiodataDownload from "./components/BiodataDownload";
import BiodataPayment from "./components/BiodataPayment";
import BiodataDownloadForm from "./components/BiodataDownloadForm";
import Magazine from "./components/Magazine";
import PdfViewer from "./components/PdfViewer";
import Subscribe from "./components/Subscribe";

const Entry = () => {
  const userId = sessionStorage.getItem("userId");

  return (
    <div>
      <BrowserRouter>
        {/* <Navigation/> */}
        <Routes>
          <Route path="/" element={<App />} />

          {/* <Route path='/aboutus' element={<About/>}/> */}
          <Route path="/planpage" element={<PlanPage />} />
          <Route path="/biodata-form" element={<BiodataForm />} />

          <Route path="/terms" element={<Termscondition />} />

          <Route path="/privacy" element={<Privacypolicy />} />

          <Route path="/refundpolicy" element={<Refundpolicy />} />

          <Route path="/aboutus" element={<About />} />

          <Route path="/magazine" element={<Magazine />} />
          <Route path="/subscribe" element={<Subscribe />} />

          <Route path="/view/:filename" element={<PdfViewer />} />

          <Route path="/login" element={<Login />} />
          <Route path="/biodata/payment" element={<BiodataPayment />} />
          <Route path="/biodata-downloadF" element={<BiodataDownloadForm />} />
          {userId ? (
            <>
              <Route path="/subscriptionplans" element={<PlanSubscribe />} />
              <Route path="/familydetails" element={<FamilyDetails />} />
              <Route path="/partnerdetails" element={<PartnerDetails />} />
              <Route path="/myprofile" element={<Myprofile />} />
              <Route path="/search" element={<Search />} />
              <Route path="/mymatchesfilter" element={<Mymatchesfilter />} />
              <Route
                path="/advancesearchresult"
                element={<AdvanceSearchResult />}
              />
              <Route
                path="/advancesearchresultnavbar"
                element={<AdvanceSearchresultNavbar />}
              />
              <Route
                path="/locationsearchresultnavbar"
                element={<LocationSearchresultNavbar />}
              />
              <Route
                path="/communitysearchresultnavbar"
                element={<CommunitySearchresultNavbar />}
              />
              <Route
                path="/collegesearchresultnavbar"
                element={<CommunitySearchresultNavbar />}
              />
              <Route
                path="/idsearchresultnavbar"
                element={<IdSearchresultNavbar />}
              />
              <Route path="/myfavoritefilter" element={<Myfavoritefilter />} />
              <Route path="/interestfilter" element={<Interestfilter />} />
              <Route
                path="/mymatchescompleteprofile/:id"
                element={<MymatchesCompleteProfile />}
              />
              <Route
                path="/myfavoritecompleteprofile/:id"
                element={<MyfavoriteCompleteProfile />}
              />
              <Route
                path="/mysentinterestcompleteprofile/:id"
                element={<MySentInterestCompleteProfile />}
              />
              <Route
                path="/myreceivedinterestcompleteprofile/:id"
                element={<MyreceivedinterestCompleteProfile />}
              />
              <Route
                path="/myacceptinterestcompleteprofile/:id"
                element={<MyacceptinterestCompleteProfile />}
              />
              <Route
                path="/myrejectinterestcompleteprofile/:id"
                element={<MyrejectinterestCompleteProfile />}
              />
              <Route path="/accountdeletion" element={<Accountdeletion />} />
              <Route
                path="/settingchangepassword"
                element={<SettingsChangepassword />}
              />
              <Route path="/kyc" element={<Kyc />} />
              <Route
                path="/subscriptionplans/pricingplan"
                element={<PricingCard />}
              />
              <Route
                path="/subscriptionplans/checkout"
                element={<Checkout />}
              />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/biodata-download" element={<BiodataDownload />} />
              <Route path="/biodata" element={<Biodata />} />
            </>
          ) : null}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Entry;
