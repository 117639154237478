import React, {useState} from 'react'
import "./search.css";
import { Loginnavigation } from './Loginnavigation'
import Aboutdownload from './Aboutdownload'
import Footer from './Footer'
import "react-input-range/lib/css/index.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { districtData } from '../data/city';
import { cityData } from '../data/city';

const PartnerDetails = () => {
  const navigate = useNavigate();

  const [minage, setMinage] = useState("");
  const [maxage, setMaxage] = useState("");
  const [minweight, setMinweight] = useState("");
  const [maxweight, setMaxweight] = useState("");
  const [minheight, setMinheight] = useState("");
  const [maxheight, setMaxheight] = useState("");
  const [minincome, setMinincome] = useState("");
  const [maxincome, setMaxincome] = useState("");
  const [country, setCountry] = useState("");
  const [caste, setCaste] = useState("");
  const [subcaste, setSubcaste] = useState("");
  const [mothertongue, setMothertongue] = useState("");
  const [maritalstatus, setMaritalstatus] = useState("");
  const [facecolor, setFacecolor] = useState("");
  const [workingstatus, setWorkingStatus] = useState("");
  const [education, setEducation] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [foodstyle, setFoodstyle] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [drinkinghabbit, setDrinkinghabbit] = useState('');
  const [familybackground, setFamilybackground] = useState("");
  const [gotra, setGotra] = useState("");
  const [occupation, setOccupation] = useState("");


  const [minageError, setMinageError] = useState("");
  const [maxageError, setMaxageError] = useState("");
  const [minheightError, setMinheightError] = useState("");
  const [maxheightError, setMaxheightError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [casteError, setCasteError] = useState("");
  const [subcasteError, setSubcasteError] = useState("");
  const [mothertongueError, setMothertongueError] = useState("");
  const [maritalstatusError, setMaritalstatusError] = useState("");
  const [facecolorError, setFacecolorError] = useState("");
  const [workingstatusError, setWorkingStatusError] = useState("");
  const [foodstyleError, setFoodstyleError] = useState("");
  const [minweightError, setMinweightError] = useState("");
  const [maxweightError, setMaxweightError] = useState("");
  const [minincomeError, setMinincomeError] = useState("");
  const [maxincomeError, setMaxincomeError] = useState("");
  const [educationError, setEducationError] = useState("");
  const [hobbiesError, setHobbiesError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [drinkinghabbitError, setDrinkinghabbitError] = useState('');
  const [familybackgroundError, setFamilybackgroundError] = useState("");
  const [gotraError, setGotraError] = useState("");
  const [occupationError, setOccupationError] = useState("");
  const [otherGotraError, setOtherGotraError] = useState("");
  const [otherGotra, setOtherGotra] = useState("");


  

  const userId = sessionStorage.getItem("userId");
  const religion = sessionStorage.getItem("religion");


 


  const hobbiesOptions = [
    { value: "Reading", label: "Reading" },
    { value: "Traveling", label: "Traveling" },
    { value: "Gardening", label: "Gardening" },
    { value: "Cooking", label: "Cooking" },
    { value: "Dancing", label: "Dancing" },
    { value: "Singing", label: "Singing" },
    { value: "Hiking", label: "Hiking" },
    { value: "Crafts", label: "Crafts" },
    { value: "Writing", label: "Writing" },
    { value: "Fitness", label: "fitness" },
    { value: "Cinema", label: "Cinema" },
    { value: "Gaming", label: "Gaming" },
    { value: "Yoga", label: "Yoga" },
    { value: "Painting", label: "Painting" },
    { value: "Photography", label: "Photography" }
    // ... (other options)
  ];
  
  const foodstyleOptions = [
    { value: "Veg", label: "Veg" },
    { value: "Pure Veg", label: "Pure Veg" },
    { value: "Non Veg", label: "Non Veg" },
    { value: "Egg", label: "Egg" },
    { value: "Jain", label: "Jain" },
    { value: "Vegan", label: "Vegan" },
  ]

  const countryOptions = [
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
    { value: "India", label: "India" },
    { value: "Germany", label: "Germany" },
    { value: "France", label: "France" },
    { value: "Spain", label: "Spain" },
    { value: "Itlay", label: "Itlay" },
    { value: "Japan", label: "Japan" },
    { value: "China", label: "China" },
    { value: "Brazil", label: "Brazil" },
    { value: "Russia", label: "Russia" },
    { value: "South Africa", label: "South Africa" }
  ]

  const casteOptions = [
    { value: "Brahmin", label: "Brahmin" },
    { value: "Kshatriya", label: "Kshatriya" },
    { value: "Vaishya", label: "Vaishya" },
    { value: "Shudra", label: "Shudra" },
    { value: "Scheduled Castes (SC)", label: "Scheduled Castes (SC)" },
    { value: "Scheduled Tribes (ST)", label: "Scheduled Tribes (ST)" }
  ]


  const subcasteOptions = [
    { value: "Not Applicable", label: "Not Applicable" },
    { value: "Agarwal", label: "Agarwal" },
    { value: "Dalit", label: "Dalit" },
    { value: "Adi Dravida", label: "Adi Dravida" },
    { value: "Chamar", label: "Chamar" },
    { value: "Tribal", label: "Tribal" },
    { value: "Gond", label: "Gond" },
    { value: "Munda", label: "Munda" },
    { value: "Yadav", label: "Yadav" },
    { value: "Kurmi", label: "Kurmi" },
    { value: "Maurya", label: "Maurya" },
    { value: "Nair", label: "Nair" },
    { value: "Nadar", label: "Nadar" },
    { value: "Vanniyar", label: "Vanniyar" },
    { value: "Brahmin", label: "Brahmin" },
    { value: "Iyer", label: "Iyer" },
    { value: "Iyengar", label: "Iyengar" },
    { value: "Smarta Brahmin", label: "Smarta Brahmin" },
    { value: "Rajput", label: "Rajput" },
    { value: "Thakur", label: "Thakur" },
    { value: "Kshatriya Raju", label: "Kshatriya Raju" }
  ]


  const mothertongueOptions = [
    { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Spanish", label: "Spanish" },
  { value: "French", label: "French" },
  { value: "German", label: "German" },
  { value: "Chinese", label: "Chinese" },
  { value: "Arabic", label: "Arabic" },
  { value: "Russian", label: "Russian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Bengali", label: "Bengali" },
  { value: "Telugu", label: "Telugu" },
  { value: "Tamil", label: "Tamil" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Marathi", label: "Marathi" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Kannada", label: "Kannada" },
  { value: "Odia", label: "Odia" },
  { value: "Malayalam", label: "Malayalam" }
  ]

  const maritalstatusOptions = [
    { value: "Single", label: "Single" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widow", label: "Widow" },
  { value: "Seperated", label: "Seperated" }
  ]

  const facecolorOptions = [
    { value: "Fair", label: "Fair" },
  { value: "Medium Skin", label: "Medium Skin" },
  { value: "Wheatish", label: "Wheatish" },
  { value: "Dark", label: "Dark" },
  { value: "Brown", label: "Brown" }
  ]

  const workingstatusOptions = [
    { value: "Working", label: "Working" },
  { value: "Non Working", label: "Non Working" },
  { value: "Student", label: "Student" }
  ]

  const educationOptions = [
    { value: "12th Pass", label: "12th Pass" },
    { value: "Diploma", label: "Diploma" },
    { value: "Certification", label: "Certification" },
    { value: "Associates Degree", label: "Associates Degree" },
    { value: "Bachelors Degree", label: "Bachelors Degree" },
    { value: "B.A (Bachelor of Arts)", label: "B.A (Bachelor of Arts)" },
    { value: "B.Sc (Bachelor of Science)", label: "B.Sc (Bachelor of Science)" },
    { value: "B.Com (Bachelor of Commerce)", label: "B.Com (Bachelor of Commerce)" },
    { value: "B.Tech (Bachelor of Technology)", label: "B.Tech (Bachelor of Technology)" },
    { value: "BBA (Bachelor of Business Administration)", label: "BBA (Bachelor of Business Administration)" },
    { value: "BCA (Bachelor of Computer Applications)", label: "BCA (Bachelor of Computer Applications)" },
    { value: "LLB (Bachelor of Laws)", label: "LLB (Bachelor of Laws)" },
    { value: "MBBS (Bachelor of Medicine, Bachelor of Surgery)", label: "MBBS (Bachelor of Medicine, Bachelor of Surgery)" },
    { value: "Pharm.D (Doctor of Pharmacy)", label: "Pharm.D (Doctor of Pharmacy)" },
    { value: "BDS (Bachelor of Dental Surgery)", label: "BDS (Bachelor of Dental Surgery)" },
    { value: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)", label: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)" },
    { value: "BVSc (Bachelor of Veterinary Science)", label: "BVSc (Bachelor of Veterinary Science)" },
    { value: "BE (Bachelor of Engineering)", label: "BE (Bachelor of Engineering)" },
    { value: "Masters Degree", label: "Masters Degree" },
    { value: "M.A (Master of Arts)", label: "M.A (Master of Arts)" },
    { value: "M.Sc (Master of Science)", label: "M.Sc (Master of Science)" },
    { value: "M.Com (Master of Commerce)", label: "M.Com (Master of Commerce)" },
    { value: "M.Tech (Master of Technology)", label: "M.Tech (Master of Technology)" },
    { value: "MBA (Master of Business Administration)", label: "MBA (Master of Business Administration)" },
    { value: "MCA (Master of Computer Applications)", label: "MCA (Master of Computer Applications)" },
    { value: "MFA (Master of Fine Arts)", label: "MFA (Master of Fine Arts)" },
    { value: "MSW (Master of Social Work)", label: "MSW (Master of Social Work)" },
    { value: "LL.M (Master of Laws)", label: "LL.M (Master of Laws)" },
    { value: "MD (Doctor of Medicine)", label: "MD (Doctor of Medicine)" },
    { value: "MPharm (Master of Pharmacy)", label: "MPharm (Master of Pharmacy)" },
    { value: "MDS (Master of Dental Surgery)", label: "MDS (Master of Dental Surgery)" },
    { value: "MS (Master of Surgery)", label: "MS (Master of Surgery)" },
    { value: "MBA (Master of Business Administration)", label: "MBA (Master of Business Administration)" },
    { value: "M.Phil (Master of Philosophy)", label: "M.Phil (Master of Philosophy)" },
    { value: "Ph.D. (Doctor of Philosophy)", label: "Ph.D. (Doctor of Philosophy)" },
    { value: "Other Masters Degree (Specify)", label: "Other Masters Degree (Specify)" },
    { value: "Doctorate (Post-Doctoral)", label: "Doctorate (Post-Doctoral)" },
  ];


  const stateOptions = districtData.states.map(state => ({
    value: state.state,
    label: state.state
  }));
  console.log(stateOptions)


  const CityOptions = [];
  cityData.states.forEach(state => {
    state.city.forEach(city => {
      CityOptions.push({ value: city, label: `${city}` });
    });
  });
  console.log(CityOptions)



  const handleFoodstyleChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setFoodstyle(selectedValues.join(', '));

  };

  const handlepartnerHobbiesChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setHobbies(selectedValues.join(', '));

  };


  const handlepartnerCountryChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setCountry(selectedValues.join(', '));

  };

  const handlepartnerCasteChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setCaste(selectedValues.join(', '));

  };

  const handlepartnerSubcasteChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setSubcaste(selectedValues.join(', '));

  };

  const handlepartnermothertongueChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setMothertongue(selectedValues.join(', '));

  };

  const handlepartnermaritalstatusChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setMaritalstatus(selectedValues.join(', '));

  };

  const handlepartnerfacecolorChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setFacecolor(selectedValues.join(', '));

  };

  const handlepartnerworkingstatusChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setWorkingStatus(selectedValues.join(', '));

  };

  const handlepartnereducationChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setEducation(selectedValues.join(', '));

  };

  const handlepartnerstateChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setState(selectedValues.join(', '));

  };


  const handlepartnercityChange = (selectedOptions) => {
    // Extract and store only the values in the selectedpartner foodstyle state
    const selectedValues = selectedOptions.map((option) => option.value);
  setCity(selectedValues.join(', '));

  };

  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };



  const handleSearch = async () => {
  
    if (
      minage.trim() !== "" &&
      maxage.trim() !== "" &&
      minheight.trim() !== "" &&
      maxheight.trim() !== "" &&
      country.length !== 0 &&
      caste.length !== 0 &&
      subcaste.length !== 0 &&
      mothertongue.length !== 0 &&
      maritalstatus.length !== 0 &&
      facecolor.length !== 0 &&
      workingstatus.length !== 0 &&
      foodstyle.length !== 0 &&
      state.length !== 0 &&
      city.length !== 0 &&
      minweight.trim() !== "" &&
      maxweight.trim() !== "" &&
      minincome.trim() !== "" &&
      maxincome.trim() !== "" &&
      drinkinghabbit.trim() !== "" &&
      gotra.trim() !== "" &&
      familybackground.trim() !== "" &&
      occupation.trim() !== "" &&
      education.trim() !== "" &&
      hobbies.trim() !== "" &&
      ((gotra === "Other (Specify)" && otherGotra.trim() !== "") ||
      gotra !== "Other (Specify)")

    ) {
  
      try {

        const response = await fetch(`${process.env.REACT_APP_MAINURL}/partnerdetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "user_id": userId,
            "country": country,
            "preferred_states": state,
            "food_style": foodstyle,
            "preferred_cities": city,
            "religion": religion,
            "caste": caste,
            "mother_tongue": mothertongue,
            "min_age": minage,
            "max_age": maxage,
            "min_height": minheight,
            "max_height":maxheight,
            "min_income": minincome,
            "max_income": maxincome,
            "min_weight": minweight,
            "max_weight": maxweight,
            "face_color": facecolor,
            "drinking_habbit": drinkinghabbit,
            "sub_caste": subcaste,
            "gotra": gotra === "Other (Specify)" ? capitalizeFirstLetter(otherGotra) : gotra,
            "working_status": workingstatus,
            "family_background": familybackground,
            "occupation": occupation,
            "education":education,
            "hobbies": hobbies,
            "marital_status": maritalstatus,
          }),
        });
  
        const data = await response.json();
        console.log(data);
  
        
        if (data !== null) {
          navigate('/myprofile');
        }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      
      setMinageError(
        minage.trim() === "" ? "Please enter Minimum age" : ""
      );

      setMaxageError(
        maxage.trim() === "" ? "Please enter Maximum age" : ""
      );

      setMinheightError(
        minheight.trim() === "" ? "Please enter Minimum height" : ""
      );

      setMaxheightError(
        maxheight.trim() === "" ? "Please enter Maximum height" : ""
      );

      setMinincomeError(
        minincome.trim() === "" ? "Please enter Minimum income" : ""
      );

      setMaxincomeError(
        maxincome.trim() === "" ? "Please enter Maximum income" : ""
      );

      setMinweightError(
        minweight.trim() === "" ? "Please enter Minimum weight" : ""
      );

      setMaxweightError(
        maxweight.trim() === "" ? "Please enter Maximum weight" : ""
      );

      setCountryError(country.length === 0  ? "Please fill in the country" : "");

      setCasteError(caste.length === 0  ? "Please fill in the caste" : "");

      setSubcasteError(subcaste.length === 0  ? "Please fill in the subcaste" : "");

      setMothertongueError(mothertongue.length === 0  ? "Please fill in the mother tongue" : "");

      setMaritalstatusError(maritalstatus.length === 0  ? "Please fill in the marital status" : "");

      setFacecolorError(facecolor.length === 0  ? "Please fill in the face color" : "");

      setWorkingStatusError(hobbies.length === 0  ? "Please fill in the working status" : "");

      setFoodstyleError(foodstyle.length === 0  ? "Please fill in the food style" : "");

      setStateError(state.length === 0  ? "Please fill in the state" : "");

      setCityError(city.length === 0  ? "Please fill in the city" : "");

      setHobbiesError(hobbies.length === 0  ? "Please fill in the hobbies" : "");

      setDrinkinghabbitError(
        drinkinghabbit.trim() === "" ? "Please fill drinking habbit" : ""
      );

      setOtherGotraError(
        gotra === "Other (Specify)" && otherGotra.trim() === ""
          ? "Please specify gotra"
          : ""
      );


      setFamilybackground(
        familybackground.trim() === "" ? "Please fill family background" : ""
      );

      setOccupationError(
        occupation.trim() === "" ? "Please fill ocupation" : ""
      );

      setEducationError(education.length === 0  ? "Please fill in the education" : "");

      
    }

  };
  




  return (
    <>
      
      <div style={{margin:"100px 20px", marginBottom:"50px", display:"flex", justifyContent:"center"}}>
      <span style={{fontSize:"20px", fontWeight:"500", fontFamily:"Poppins", border:"1px solid #dcd7d7", padding:"10px", borderRadius:"10px"}}>
      The criteria below influences your matches and interest</span>
      </div>

      
      <div className="advance-search">
        

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="minage">Min age *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",}}
                className="form-control"
                id="minage"
                value={minage}
                onChange={(e) => setMinage(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {minageError}
            </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label htmlFor="maxage">Max age *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="maxage"
                value={maxage}
                onChange={(e) => setMaxage(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {maxageError}
            </div>
          </div>
          
        </div>

        <div className="custom-row">

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="minheight">Min height (in ft) *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="minheight"
                value={minheight}
                onChange={(e) => setMinheight(e.target.value)}
              >
                <option value="">Select</option>
                <option value="48">4 feet (48 inches)</option>
                <option value="49">4 feet 1 inch (49 inches)</option>
                <option value="50">4 feet 2 inches (50 inches)</option>
                <option value="51">4 feet 3 inches (51 inches)</option>
                <option value="52">4 feet 4 inches (52 inches)</option>
                <option value="53">4 feet 5 inches (53 inches)</option>
                <option value="54">4 feet 6 inches (54 inches)</option>
                <option value="55">4 feet 7 inches (55 inches)</option>
                <option value="56">4 feet 8 inches (56 inches)</option>
                <option value="57">4 feet 9 inches (57 inches)</option>
                <option value="58">4 feet 10 inches (58 inches)</option>
                <option value="59">4 feet 11 inches (59 inches)</option>
                <option value="60">5 feet (60 inches)</option>
                <option value="61">5 feet 1 inch (61 inches)</option>
                <option value="62">5 feet 2 inches (62 inches)</option>
                <option value="63">5 feet 3 inches (63 inches)</option>
                <option value="64">5 feet 4 inches (64 inches)</option>
                <option value="65">5 feet 5 inches (65 inches)</option>
                <option value="66">5 feet 6 inches (66 inches)</option>
                <option value="67">5 feet 7 inches (67 inches)</option>
                <option value="68">5 feet 8 inches (68 inches)</option>
                <option value="69">5 feet 9 inches (69 inches)</option>
                <option value="70">5 feet 10 inches (70 inches)</option>
                <option value="71">5 feet 11 inches (71 inches)</option>
                <option value="72">6 feet (72 inches)</option>
                <option value="73">6 feet 1 inch (73 inches)</option>
                <option value="74">6 feet 2 inches (74 inches)</option>
                <option value="75">6 feet 3 inches (75 inches)</option>
                <option value="76">6 feet 4 inches (76 inches)</option>
                <option value="77">6 feet 5 inches (77 inches)</option>
                <option value="78">6 feet 6 inches (78 inches)</option>
                <option value="79">6 feet 7 inches (79 inches)</option>
                <option value="80">6 feet 8 inches (80 inches)</option>
                <option value="81">6 feet 9 inches (81 inches)</option>
                <option value="82">6 feet 10 inches (82 inches)</option>
                <option value="83">6 feet 11 inches (83 inches)</option>
                <option value="84">7 feet (84 inches)</option>
                <option value="85">7 feet 1 inch (85 inches)</option>
                <option value="86">7 feet 2 inches (86 inches)</option>
                <option value="87">7 feet 3 inches (87 inches)</option>
                <option value="88">7 feet 4 inches (88 inches)</option>
                <option value="89">7 feet 5 inches (89 inches)</option>
                <option value="above 89">Above 7.5 feet</option>
              </select>
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {minheightError}
            </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label htmlFor="maxheight">Max Height (in ft) *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="maxheight"
                value={maxheight}
                onChange={(e) => setMaxheight(e.target.value)}
              >
                <option value="">Select</option>
                <option value="48">4 feet (48 inches)</option>
                <option value="49">4 feet 1 inch (49 inches)</option>
                <option value="50">4 feet 2 inches (50 inches)</option>
                <option value="51">4 feet 3 inches (51 inches)</option>
                <option value="52">4 feet 4 inches (52 inches)</option>
                <option value="53">4 feet 5 inches (53 inches)</option>
                <option value="54">4 feet 6 inches (54 inches)</option>
                <option value="55">4 feet 7 inches (55 inches)</option>
                <option value="56">4 feet 8 inches (56 inches)</option>
                <option value="57">4 feet 9 inches (57 inches)</option>
                <option value="58">4 feet 10 inches (58 inches)</option>
                <option value="59">4 feet 11 inches (59 inches)</option>
                <option value="60">5 feet (60 inches)</option>
                <option value="61">5 feet 1 inch (61 inches)</option>
                <option value="62">5 feet 2 inches (62 inches)</option>
                <option value="63">5 feet 3 inches (63 inches)</option>
                <option value="64">5 feet 4 inches (64 inches)</option>
                <option value="65">5 feet 5 inches (65 inches)</option>
                <option value="66">5 feet 6 inches (66 inches)</option>
                <option value="67">5 feet 7 inches (67 inches)</option>
                <option value="68">5 feet 8 inches (68 inches)</option>
                <option value="69">5 feet 9 inches (69 inches)</option>
                <option value="70">5 feet 10 inches (70 inches)</option>
                <option value="71">5 feet 11 inches (71 inches)</option>
                <option value="72">6 feet (72 inches)</option>
                <option value="73">6 feet 1 inch (73 inches)</option>
                <option value="74">6 feet 2 inches (74 inches)</option>
                <option value="75">6 feet 3 inches (75 inches)</option>
                <option value="76">6 feet 4 inches (76 inches)</option>
                <option value="77">6 feet 5 inches (77 inches)</option>
                <option value="78">6 feet 6 inches (78 inches)</option>
                <option value="79">6 feet 7 inches (79 inches)</option>
                <option value="80">6 feet 8 inches (80 inches)</option>
                <option value="81">6 feet 9 inches (81 inches)</option>
                <option value="82">6 feet 10 inches (82 inches)</option>
                <option value="83">6 feet 11 inches (83 inches)</option>
                <option value="84">7 feet (84 inches)</option>
                <option value="85">7 feet 1 inch (85 inches)</option>
                <option value="86">7 feet 2 inches (86 inches)</option>
                <option value="87">7 feet 3 inches (87 inches)</option>
                <option value="88">7 feet 4 inches (88 inches)</option>
                <option value="89">7 feet 5 inches (89 inches)</option>
                <option value="above 89">Above 7.5 feet</option>
              </select>
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {maxheightError}
            </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="minweight">Min Weight (in kg) *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",}}
                className="form-control"
                id="minweight"
                value={minweight}
                onChange={(e) => setMinweight(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {minweightError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label htmlFor="maxweight">Max Weight (in kg) *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",}}
                className="form-control"
                id="maxweight"
                value={maxweight}
                onChange={(e) => setMaxweight(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {maxweightError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="minincome">Min Income *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="minincome"
                value={minincome}
                onChange={(e) => setMinincome(e.target.value)}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="100000">1,00,000</option>
                <option value="500000">5,00,000</option>
                <option value="1000000">10,00,000</option>
                <option value="1500000">15,00,000</option>
                <option value="2000000">20,00,000</option>
                <option value="2500000">25,00,000</option>
                <option value="3000000">30,00,000</option>
                <option value="3500000">35,00,000</option>
                <option value="4000000">40,00,000</option>
                <option value="45000000">45,00,000</option>
                <option value="5000000">50,00,000</option>
                <option value="5500000">55,00,000</option>
                <option value="6000000">60,00,000</option>
                <option value="6500000">65,00,000</option>
                <option value="7000000">70,00,000</option>
                <option value="7500000">75,00,000</option>
                <option value="8000000">80,00,000</option>
                <option value="8500000">85,00,000</option>
                <option value="9000000">90,00,000</option>
                <option value="9500000">95,00,000</option>
                <option value="10000000">100,00,000</option>
                <option value="100000000">10,00,00,000</option>
              </select>
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {minincomeError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label htmlFor="maxincome">Max Income *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="maxincome"
                value={maxincome}
                onChange={(e) => setMaxincome(e.target.value)}
              >
                <option value="">Select</option>
                <option value="0">0</option>
                <option value="100000">1,00,000</option>
                <option value="500000">5,00,000</option>
                <option value="1000000">10,00,000</option>
                <option value="1500000">15,00,000</option>
                <option value="2000000">20,00,000</option>
                <option value="2500000">25,00,000</option>
                <option value="3000000">30,00,000</option>
                <option value="3500000">35,00,000</option>
                <option value="4000000">40,00,000</option>
                <option value="45000000">45,00,000</option>
                <option value="5000000">50,00,000</option>
                <option value="5500000">55,00,000</option>
                <option value="6000000">60,00,000</option>
                <option value="6500000">65,00,000</option>
                <option value="7000000">70,00,000</option>
                <option value="7500000">75,00,000</option>
                <option value="8000000">80,00,000</option>
                <option value="8500000">85,00,000</option>
                <option value="9000000">90,00,000</option>
                <option value="9500000">95,00,000</option>
                <option value="10000000">100,00,000</option>
                <option value="100000000">10,00,00,000</option>
                <option value="1000000000">10,00,000,000</option>
              </select>
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {maxincomeError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="country">Country *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={countryOptions}
                  value={countryOptions.filter((option) => country.includes(option.value))}
                  onChange={handlepartnerCountryChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {countryError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="maxincome">Caste *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={casteOptions}
                  value={casteOptions.filter((option) => caste.includes(option.value))}
                  onChange={handlepartnerCasteChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {casteError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="subcaste">Sub Caste *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={subcasteOptions}
                  value={subcasteOptions.filter((option) => subcaste.includes(option.value))}
                  onChange={handlepartnerSubcasteChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {subcasteError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="mothertongue">Mother Tongue *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={mothertongueOptions}
                  value={mothertongueOptions.filter((option) => mothertongue.includes(option.value))}
                  onChange={handlepartnermothertongueChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {mothertongueError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="maritalstatus">Marital Status *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={maritalstatusOptions}
                  value={maritalstatusOptions.filter((option) => maritalstatus.includes(option.value))}
                  onChange={handlepartnermaritalstatusChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {maritalstatusError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="facecolor">Face Color *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={facecolorOptions}
                  value={facecolorOptions.filter((option) => facecolor.includes(option.value))}
                  onChange={handlepartnerfacecolorChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {facecolorError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="workingstatus">Working Status *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={workingstatusOptions}
                  value={workingstatusOptions.filter((option) => workingstatus.includes(option.value))}
                  onChange={handlepartnerworkingstatusChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {workingstatusError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="education">Education *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={educationOptions}
                  value={educationOptions.filter((option) => education.includes(option.value))}
                  onChange={handlepartnereducationChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {educationError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="hobbies">Hobbies *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={hobbiesOptions}
                  value={hobbiesOptions.filter((option) => hobbies.includes(option.value))}
                  onChange={handlepartnerHobbiesChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {hobbiesError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="foodstyle">Food style *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={foodstyleOptions}
                  value={foodstyleOptions.filter((option) => foodstyle.includes(option.value))}
                  onChange={handleFoodstyleChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {foodstyleError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label style={{width:"100%"}} htmlFor="states">Preffered States *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={stateOptions}
                  value={stateOptions.filter((option) => state.includes(option.value))}
                  onChange={handlepartnerstateChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {stateError}
              </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label style={{width:"100%"}} htmlFor="city">Preffered City *</label>
              <Select 
                  className="w-100" styles={{border: '1px solid rgba(126, 121, 121, 0.95)', }}
                  isMulti
                  options={CityOptions}
                  value={CityOptions.filter((option) => city.includes(option.value))}
                  onChange={handlepartnercityChange}
                />

              </div>
              <br></br>
              <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
                {cityError}
              </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="drinkinghabbit">Drinking Habbit *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  }}
                className="form-control"
                id="drinkinghabbit"
                value={drinkinghabbit}
                onChange={(e) => setDrinkinghabbit(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Non-Drinker">Non-Drinker</option>
              <option value="Social Drinker">Social Drinker</option>
              <option value="Regular Drinker">Regular Drinker</option>
              <option value="Occasional Drinker">Occasional Drinker</option>
              <option value="Heavy Drinker">Heavy Drinker</option>
              <option value="Never Tried">Never Tried</option>
              <option value="Quit Drinking">Quit Drinking</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {drinkinghabbitError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="familybackground">Family Background *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",}}
                className="form-control"
                id="familybackground"
                value={familybackground}
                onChange={(e) => setFamilybackground(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Rich">Rich</option>
                <option value="Middle Class">Middle Class</option>
                <option value="Poor">Poor</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {familybackgroundError}
            </div>
          </div>
        </div>


        <div className="custom-row">
        <div className="custom-col">
            <div className="form-group">
              <label htmlFor="gotra">Gotra</label>
              <select
                className="form-control"
                id="gotra"
                value={gotra}
                onChange={(e) => setGotra(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Bharadwaj">Bharadwaj</option>
                <option value="Kashyap">Kashyap</option>
                <option value="Vatsa">Vatsa</option>
                <option value="Gautam">Gautam</option>
                <option value="Shandilya">Shandilya</option>
                <option value="Atri">Atri</option>
                <option value="Vasistha">Vasistha</option>
                <option value="Kaushik">Kaushik</option>
                <option value="Garg">Garg</option>
                <option value="Parashara">Parashara</option>
                <option value="Sandilya">Sandilya</option>
                <option value="Vashishtha">Vashishtha</option>
                <option value="Jamadagni">Jamadagni</option>
                <option value="Angirasa">Angirasa</option>
                <option value="Kaundinya">Kaundinya</option>
                <option value="Suryavanshi">Suryavanshi</option>
                <option value="Chandravanshi">Chandravanshi</option>
                <option value="Agnivanshi">Agnivanshi</option>
                <option value="Nagvanshi">Nagvanshi</option>
                <option value="Kachwaha">Kachwaha</option>
                <option value="Rathore">Rathore</option>
                <option value="Tomar">Tomar</option>
                <option value="Solanki">Solanki</option>
                <option value="Chauhan">Chauhan</option>
                <option value="Parmar">Parmar</option>
                <option value="Gohil">Gohil</option>
                <option value="Pratihar">Pratihar</option>
                <option value="Parihar">Parihar</option>
                <option value="Rajput">Rajput</option>
                <option value="Rajawat">Rajawat</option>
                <option value="Agarwal">Agarwal</option>
                <option value="Garg">Garg</option>
                <option value="Bansal">Bansal</option>
                <option value="Mittal">Mittal</option>
                <option value="Singhal">Singhal</option>
                <option value="Kansal">Kansal</option>
                <option value="Mangal">Mangal</option>
                <option value="Madhukul">Madhukul</option>
                <option value="Goyal">Goyal</option>
                <option value="Jindal">Jindal</option>
                <option value="Tayal">Tayal</option>
                <option value="Kuchhal">Kuchhal</option>
                <option value="Goyal">Goyal</option>
                <option value="Mangal">Mangal</option>
                <option value="Khandelwal">Khandelwal</option>
                <option value="Gupta">Gupta</option>
                <option value="Maheshwari">Maheshwari</option>
                <option value="Oswal">Oswal</option>
                <option value="Porwal">Porwal</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {gotra === "Other (Specify)" && (
                <input
                  type="text" 
                  className="form-control"
                  placeholder="Specify"
                  value={otherGotra}
                  onChange={(e) => setOtherGotra(e.target.value)}
                  style={{
                    marginTop: '20px' 
                  }}
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {gotraError || otherGotraError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="occupation">Occupation *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",}}
                className="form-control"
                id="occupation"
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Accountant">Accountant</option>
                <option value="Artist">Artist</option>
                <option value="Engineer">Engineer</option>
                <option value="Doctor">Doctor</option>
                <option value="Teacher">Teacher</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Manager">Manager</option>
                <option value="Lawyer">Lawyer</option>
                <option value="Nurse">Nurse</option>
                <option value="Designer">Designer</option>
                <option value="Writer">Writer</option>
                <option value="Farmer">Farmer</option>
                <option value="Cook">Cook</option>
                <option value="Clerk">Clerk</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Police Officer">Police Officer</option>
                <option value="Chef">Chef</option>
                <option value="Salesperson">Salesperson</option>
                <option value="Student">Student</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Retired">Retired</option>
                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Manager">Manager</option>
                <option value="Senior Manager">Senior Manager</option>
                <option value="Consultant">Consultant</option>
                <option value="Lecturer">Lecturer</option>
                <option value="Director">Director</option>
                <option value="Technical lead">Technical lead</option>
                <option value="General Manager">General Manager</option>
                <option value="Government Officer">Government Officer</option>
                <option value="Minister">Minister</option>
                <option value="MLA">MLA</option>
                <option value="MP">MP</option>
                <option value="Speaker">Speaker</option>
                <option value="Managing Director">Managing Director</option>
                <option value="Architect">Architect</option>
                <option value="Interior Designer">Interior Designer</option>
                <option value="Human Resource">Human Resource</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Executive">Executive</option>
                <option value="Senior Executive">Senior Executive</option>
                <option value="Businessman">Businessman</option>
                <option value="Franchise owner">Franchise owner</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {occupationError}
            </div>
          </div>
        </div>

        


        
      </div>


      <button
        className="advance-search-btn"
        style={{ marginBottom: "50px" }}
        onClick={handleSearch}
      >
        Next
      </button>


      <Aboutdownload/>
      <Footer/>
    </>
    
  )
}

export default PartnerDetails;