import React from 'react';
import { Chart } from 'primereact/chart';

export const Piechartcomparison = ({partnerShare, reviewShare}) => {
  // Data for the pie chart
  const data = {
    labels: ['Partner Preference Share', 'Reviews Share'],
    datasets: [
      {
        data: [partnerShare, reviewShare],
        backgroundColor: ['#00E0B8', '#FF8A00'], // Yellow and gray colors
      },
    ],
  };

  // Options for the pie chart
  const options = {
    legend: {
      position: 'bottom',
    },
  };

  return (
    <div style={{width:"200px", height:"200px", margin:"auto"}}>
      <Chart type="pie" data={data} options={options} />
    </div>
  );
};


export const Piechart = ({personality_score}) => {
    // Data for the pie chart
    const data = {
        labels: ['Overall Personality Score'],
      datasets: [
        {
          data: [personality_score, 100-personality_score],
          backgroundColor: ['#FBC913', 'grey'], // Yellow color
        },
      ],
    };
  
    // Options for the pie chart
    const options = {
      legend: {
        position: 'bottom', // Hide legend for a single data point
      },
    };
  
    return (
      <div style={{width:"200px", height:"200px", margin:"auto"}}>
        <Chart type="pie" data={data} options={options}/>
      </div>
    );
  };
