import styled from "styled-components";

export default styled.div`
  ${'' /* display: flex; */}
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  height: 500px;
  width: 80%;
  background-color: #00008B;
  color: #fff;
  margin: 0 15px;
  font-size: 3em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
`;


