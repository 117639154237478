import React from "react";
import vs from "./vs-new.jpeg";
import { Link } from "react-router-dom";

export const AboutNavigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ background: "grey" }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              style={{ width: "190px", height: "35px" }}
              src={vs}
              alt="logo"
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/aboutus">About Us</Link>
            </li>

            <li>
              <a href="#leadership" className="page-scroll">
                Leadership Team
              </a>
            </li>
            <li>
              <a href="/magazine" className="page-scroll">
                Magazine
              </a>
            </li>
            <li>
              <a href="#footer" className="page-scroll">
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="#about-download"
                className="page-scroll"
                style={{
                  background: "#AC0A00",
                  color: "white",
                  padding: "10px 18px",
                  borderRadius: "10px",
                }}
              >
                Download Now
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
