import React, { useState } from "react";
import { Loginnavigation } from "./Loginnavigation";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import camera from './images/camera.png'
import plus from './images/plus.png'

const Kyc = () => {
  const [aadhar, setAadhar] = useState("");
  const [otp, setOtp] = useState("");
  const [aadharerror, setAadharerror] = useState("");
  const [otperror, setOtperror] = useState("");
  const [isAadharVerificationOpen, setIsAadharVerificationOpen] = useState(false);

  const [pan, setPan] = useState("");
  const [panerror, setPanerror] = useState("");
  const [isPanVerificationOpen, setIsPanVerificationOpen] = useState(false);

  const [isFaceVerificationOpen, setIsFaceVerificationOpen] = useState(false);

  // New state variable for the uploaded image
  const [uploadedImage, setUploadedImage] = useState(null);

  const [uploadedImage1, setUploadedImage1] = useState(null);

  const toggleAadharVerification = () => {
    setIsAadharVerificationOpen(!isAadharVerificationOpen);
  };

  const togglePanVerification = () => {
    setIsPanVerificationOpen(!isPanVerificationOpen);
  };

  const toggleFaceVerification = () => {
    setIsFaceVerificationOpen(!isFaceVerificationOpen);
  };

  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Update the state with the uploaded image
      setUploadedImage(URL.createObjectURL(file));
    }
  };

  const handleImageUpload1 = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Update the state with the uploaded image
      setUploadedImage1(URL.createObjectURL(file));
    }
  };

  // Function to remove uploaded image
  const removeUploadedImage = () => {
    setUploadedImage(null);
  };

  const removeUploadedImage1 = () => {
    setUploadedImage1(null);
  };

  // Function to capture image from camera
  const captureImageFromCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      const video = document.createElement("video");
      document.body.appendChild(video);
      video.srcObject = stream;

      video.onloadedmetadata = () => {
        video.play();

        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const capturedImage = canvas.toDataURL("image/png");

        // Update the state with the captured image
        setUploadedImage(capturedImage);

        // Stop the camera stream and remove the video element
        stream.getTracks().forEach((track) => track.stop());
        document.body.removeChild(video);
      };
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };


  // Function to handle image selection from user's device
const handleImageSelection = (e) => {
  const file = e.target.files[0];

  if (file) {
    // Update the state with the selected image
    setUploadedImage1(URL.createObjectURL(file));
  }
};

  return (
    <>
      <Loginnavigation />
      <div
        style={{
          marginTop: "100px",
          fontSize: "25px",
          fontWeight: "600",
          color: "#292929",
        }}
      >
        <p style={{ textAlign: "center", fontSize:"23px" }}>
          Please verify your Aadhar card or PAN card along with face match
          verification.
        </p>
      </div>

      <div className="kyc" >
        <h3 onClick={toggleAadharVerification} style={{background:"#FF8A00",
         padding:"10px 30px", color:"white", cursor:"pointer", borderRadius:"5px"}}>Aadhar Card Verification</h3>
        {isAadharVerificationOpen && (
        <div style={{background: "#FF8A000D"}} className="custom-row">
          <div className="custom-col">
            <div
              className="form-group"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div >
                <label htmlFor="aadhar" style={{fontSize:"20px"}}>Enter Aadhar Number</label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="form-control"
                  type="number"
                  id="aadhar"
                  value={aadhar}
                  onChange={(e) => setAadhar(e.target.value)}
                />
                <button
                  className="btn"
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    background: "#BE0000",
                  }}
                >
                  Get Otp
                </button>
              </div>
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {aadharerror}
            </div>
          </div>

          <div className="custom-col">
            <div
              className="form-group"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div>
                <label htmlFor="otp" style={{fontSize:"20px"}}>Enter Otp</label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="form-control"
                  type="number"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <button
                  className="btn"
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    background: "#BE0000",
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {otperror}
            </div>
          </div>
        </div>
        )}
      </div>

      <h3 style={{textAlign:"center"}}>OR</h3>

      <div className="pan">
        <h3 onClick={togglePanVerification} style={{background:"#FF8A00",
         padding:"10px 30px", color:"white", cursor:"pointer", borderRadius:"5px"}}>PAN Card Verification</h3>
        {isPanVerificationOpen && (
        <div style={{background: "#FF8A000D"}} className="custom-row">
          <div className="custom-col">
            <div
              className="form-group"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div >
                <label htmlFor="aadhar" style={{fontSize:"20px"}}>Enter PAN Number</label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="form-control"
                  type="number"
                  id="pan"
                  value={pan}
                  onChange={(e) => setPan(e.target.value)}
                />
                <button
                  className="btn"
                  style={{
                    marginLeft: "10px",
                    color: "white",
                    background: "#BE0000",
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {panerror}
            </div>
          </div>

          
        </div>
        )}
      </div>

      <div className="face-match">
        <h3
          onClick={toggleFaceVerification}
          style={{
            background: "#FF8A00",
            padding: "10px 30px",
            color: "white",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Face Match Verification
        </h3>

        {isFaceVerificationOpen && (
          <div style={{ background: "#FF8A000D", margin:'10px 50px' }} className="custom-row">
            <div className="custom-col">
              <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <label htmlFor="imageUpload" style={{ fontSize: "20px", marginLeft:'20px' }}>
                    Upload Your Selfie
                  </label>
                </div>
                <div style={{ position: 'relative' }}>
                  {uploadedImage ? (
                    <div className="custom-col">
                      <div>
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                          style={{ maxWidth: "100%", maxHeight: "200px", marginTop: "10px" }}
                        />
                        {/* Close icon for removing the uploaded image */}
                        <div
                          style={{
                            position: 'absolute',
                            top: 15,
                            right: 150,
                            cursor: 'pointer',
                            padding: '5px 8px',
                            background: '#fff',
                            borderRadius: '50%',
                          }}
                          onClick={removeUploadedImage}
                        >
                          &#10006;
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="uploaded-image"
                      style={{
                        height: '150px',
                        width: '150px',
                        background: '#D9D9D9',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => captureImageFromCamera()}
                    >
                      <img src={camera} alt="Camera" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="custom-col">
              <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <label htmlFor="imageUpload" style={{ fontSize: "20px", marginLeft:'20px' }}>
                    Upload Photo ID Card
                  </label>
                </div>
                <div style={{ position: 'relative' }}>
                  {uploadedImage1 ? (
                    <div className="custom-col">
                      <div>
                        <img
                          src={uploadedImage1}
                          alt="Uploaded"
                          style={{ maxWidth: "100%", maxHeight: "200px", marginTop: "10px" }}
                        />
                        {/* Close icon for removing the uploaded image */}
                        <div
                          style={{
                            position: 'absolute',
                            top: 15,
                            right: 150,
                            cursor: 'pointer',
                            padding: '5px 8px',
                            background: '#fff',
                            borderRadius: '50%',
                          }}
                          onClick={removeUploadedImage1}
                        >
                          &#10006;
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="uploaded-image"
                      style={{
                        height: '150px',
                        width: '150px',
                        background: '#D9D9D9',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      
                    >
                      {/* <img src={plus} alt="Camera" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
                      <input
                          type="file"
                          accept="image/*"
                          capture="environment"
                          onChange={handleImageSelection}
                          style={{ display: 'none' }}
                          id="imageInput"
                        />
                        {/* Label for the file input, styled as the plus icon */}
                        <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                          <img src={plus} alt="Camera" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                        </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Aboutdownload/>
      <Footer/>
    </>
  );
};

export default Kyc;




