import React, { useState } from "react";
import "./RegisterForm.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneRegex } from "../data/phoneregex";
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from './images/search_2.png'
import img8 from './images/image_1.png'

const PageOne = ({ onButtonClick }) => {
  const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profile, setProfile] = useState("");

  const [emailError, setEmailError] = useState("");
  // const [usernameError, setUsernameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [profileError, setProfileError] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);



  



  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = (e) => {
    e.preventDefault();
    setConfirmPasswordVisible((prev) => !prev);
  };

  const generateRandomPassword = (e) => {
    const randomPassword = generatePassword();
    e.preventDefault();
    setPassword(randomPassword);
    setConfirmPassword(randomPassword);
  };

  const generatePassword = () => {
    // Generate a random password that meets your criteria
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}[]:;<>,.?~\\-";
    const passwordLength = 10; // You can adjust the length as needed

    let password = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  };

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(password);
    setIsPasswordCopied(true);

    // Reset the copied state after a certain duration (e.g., 2 seconds)
    setTimeout(() => {
      setIsPasswordCopied(false);
    }, 2000);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    for (const regexObject of phoneRegex) {
      const regex = regexObject.validation;
      if (regex.test(phoneNumber)) {
        return true; // Found a match, the phone number is valid
      }
    }
    return false; // No match found, the phone number is not valid
  };

  const handleCreateWorkspace = () => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // phone number validation
    const isPhoneNumberValid = isValidPhoneNumber(phoneNumber);

    // Password must be at least 8 characters long
    const isPasswordValid =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{7,}$/.test(password);

    // Your validation logic here...

    if (
      email.trim() !== "" &&
      emailRegex.test(email) &&
      // username.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      isPhoneNumberValid &&
      isPasswordValid &&
      confirmPassword.trim() !== "" &&
      password === confirmPassword &&
      profile.trim() !== ""
    ) {
      onButtonClick("pagetwo", email, phoneNumber, password, profile);
      console.log(phoneNumber);
    } else {
      // Set error messages for each field
      setEmailError(
        email.trim() === ""
          ? "Please fill in the email address"
          : !emailRegex.test(email)
          ? "Enter a valid email address"
          : ""
      );
      // setUsernameError(username.trim() === "" ? "Please fill in the username" : "");
      setPhoneNumberError(
        phoneNumber.trim() === ""
          ? "Please fill in the phone number"
          : !isPhoneNumberValid
          ? "Enter a valid phone number"
          : ""
      );
      setPasswordError(
        password.trim() === ""
          ? "Please fill in the password"
          : !isPasswordValid
          ? "Password must be at least 7 characters long\n Contain at least one special character\n Contains one capital letter\n(e.g Perfect@xyz)"
          : ""
      );
      setConfirmPasswordError(
        confirmPassword.trim() === ""
          ? "Please fill in the confirm password"
          : password !== confirmPassword
          ? "Both password should be same"
          : ""
      );
      setProfileError(profile.trim() === "" ? "Please select a profile" : "");
    }
  };

  return (
    <main
      className="black-80 center form-main-register"
      style={{ maxHeight: "30%", margin: "auto",}}
    >
      <div className="desktop-step" style={{ display: "flex", flexDirection: "column", }}>
        <div id="#form-step">
          <i
            style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}
            className=" step fa"
          >
            <img src={img1} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Easy Registeration</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa"
          style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Chat With Partner</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Video Calls</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img7} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Search Profiles Conveniently</h3>
          </div>
        </div>
      </div>
      <div className="form-desktop-step">
        <form
          className="measure measure1"
          style={{
            margin: "auto",
            padding: "20px",
            background: "#FF4242",
            borderRadius: "20px",
          }}
        >
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div
              className="mv3"
            >
              <label
                className="db lh-copy f6 mb1"
                htmlFor="email"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: "white",
                  fontWeight: "575",
                }}
              >
                Email *
              </label>
              <input
                className="f6 br3 ph3 pv4 mb4 dib black w-100"
                type="email"
                name="email"
                id="email"
                placeholder="xyz25@gmail.com"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: emailError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                marginTop: "-25px",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {emailError}
            </div>

            <div className="mv3">
              <label
                className="db lh-copy f6 mb1"
                htmlFor="phone-number"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: "white",
                  fontWeight: "575",
                }}
              >
                Phone Number *
              </label>
             
              <PhoneInput
                style={{
                  // borderStyle: "solid",
                  // borderWidth: "1px",
                  borderColor: phoneNumberError
                    ? "red"
                    : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                international
                defaultCountry="IN" // Set the default country (you can change it based on your needs)
                className="f6 br3 ph3 pv3 mb4 dib black w-100"
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                placeholder={(country, metadata) => `e.g. ${metadata.example}`}
              />
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                marginTop: "-25px",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {phoneNumberError}
            </div>

            <div className="mv3 relative">
              <label
                className="db lh-copy f6 mb1"
                htmlFor="password"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: "white",
                  fontWeight: "575",
                }}
              >
                Password *
              </label>
              <div className="relative">
                <input
                  className="f6 br3 ph3 pv4 mb4 dib black w-100 pr4" // Add paddingRight to accommodate the button
                  name="password"
                  id="password"
                  placeholder="Please click on auto-generate password or type manually"
                  type={passwordVisible ? "text" : "password"}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: passwordError
                      ? "red"
                      : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  style={{ height: "58.5px", width: "50px" }}
                  className="absolute top-50 transform -translate-y-50 right-0 pr2" // Adjust position as needed
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
                <button
                  style={{
                    height: "58.5px",
                    width: "50px",
                    marginLeft: "-100px",
                  }}
                  className="absolute top-50 transform -translate-y-50  pr2 "
                  onClick={copyToClipboard}
                  title="Copy to Clipboard"
                >
                  <FaCopy />
                </button>

                
              </div>
              {isPasswordCopied && (
                <div style={{ color: "green", fontSize: "1rem" }}>
                  Password copied to clipboard!
                </div>
              )}
            </div>
            <div
              className="mv3 error-message"
              style={{
                color: "red",
                fontSize: "1.5rem",
                marginTop: "-25px",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {passwordError}
            </div>

            <div className="mv3 relative">
              <label
                className="db lh-copy f6 mb1"
                htmlFor="confirm-password"
                style={{
                  textAlign: "left",
                  fontSize: "2rem",
                  color: "white",
                  fontWeight: "575",
                }}
              >
                Confirm Password *
              </label>
              <div className="relative">
                <input
                  className="f6 br3 ph3 pv4 mb4 dib black w-100 pr-4"
                  type={confirmpasswordVisible ? "text" : "password"}
                  name="confirm-password"
                  id="confirm-password"
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: confirmPasswordError
                      ? "red"
                      : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    color: "rgb(126 121 121 / 95%)",
                  }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  style={{ height: "58.5px", width: "50px" }}
                  className="absolute top-50 transform -translate-y-50 right-0 pr2" // Adjust position as needed
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {confirmpasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <div
              className="mv3"
              style={{
                color: "red",
                fontSize: "1.5rem",
                marginTop: "-25px",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {confirmPasswordError}
            </div>

            <button
              className="absolute top-50 transform -translate-y-50 right-60 pr2"
              onClick={generateRandomPassword}
              title="It will automatically generate a unique password for you"
              style={{
                border: "none",
                borderRadius: "10px",
                fontSize: "1.8rem",
                cursor: "pointer",
                fontWeight: "575",
                padding: "7px 10px",
                display: "flex",
                marginTop: "5px",
              }}
            >
              Auto-generate password
            </button>

            <div className="mv3 relative" style={{ marginTop: "60px", display: "flex", flexDirection:"column" }}>
                <div>
                <label
              className="db lh-copy f6 mb1"
              htmlFor="profile"
              style={{
                textAlign: "left",
                fontSize: "2rem",
                color: "white",
                fontWeight: "575",
                display: "block", // Add this style to make the label a block element
              }}
            >
              Profile for *
            </label>
                </div>

            <div
              className="relative"
              style={{
                background: "#950A06",
                padding: "2px 5px",
                borderRadius:"5px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "10px", // Add margin-top to separate radio buttons from the label
              }}
            >
              {["Self", "Relative", "Son", "Daughter", "Client", "Friend", "Brother", "Sister"].map((option) => (
                <label key={option} style={{ marginRight: "20px", marginBottom: "10px", color:'white', fontSize:"13px" }}>
                  <input
                    type="radio"
                    name="profile"
                    value={option}
                    checked={profile === option}
                    onChange={(e) => setProfile(e.target.value)}
                    style={{ marginRight: "5px", color: "white" }}
                  />
                  {option}
                </label>
              ))}
              {/* Repeat the same pattern for other options */}
            </div>
          </div>
          <div
            className="mv3"
            style={{
              color: "red",
              fontSize: "1.5rem",
              marginTop: "-25px",
              textAlign: "left",
              marginBottom: "10px",
            }}
          >
            {profileError}
          </div>



          </fieldset>
          <div >
            <input
              className="f6 grow br3 ph3 pv2 mb4 dib white"
              style={{
                borderStyle: "none",
                marginTop: "10px",
                width:"100%",
                padding: "7px 30px",
                backgroundColor: "rgb(149, 10, 6)",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
              type="button"
              value="Next"
              onClick={handleCreateWorkspace}
            />
          </div>
        </form>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }} className="desktop-step">
        <div id="#form-step">
          <i className=" step fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img src={img4} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Verified Profiles</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "27px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img5} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Affordable Membership Plans</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img6} alt="img" />
          </i>
          <div className="service-desc">
            <h3>15+ available filters for Matchmaking</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img8} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Photo Based Additional Verification</h3>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageOne;


