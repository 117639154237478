import React, {useEffect} from "react";
import { Termsnavigation } from "./Termsnavigation";
import Footer from "./Footer";

const Termscondition = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Termsnavigation />

      <div
        style={{
          marginTop: "80px",
          padding: "20px 50px",
          background:
            "linear-gradient(180deg, hsla(45, 100%, 59%, 1) 0%, hsla(48, 63%, 80%, 1) 100%)",
          paddingBottom: "250px",
        }}
      >
        <h3
          style={{
            fontSize: "35px",
            fontWeight: "700",
            textAlign: "center",
            color: "white",
            paddingBottom: "30px",
            fontFamily: "Lemon",
          }}
        >
          Terms & Condition
        </h3>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          These Terms and Conditions, along with privacy policy or other terms
          (“Terms”) constitute a binding agreement by and between RADHA KRISHNA
          TECHNOLOGY SOLUTIONS, ( “Website Owner” or “we” or “us” or “our”) and
          you (“you” or “your”) and relate to your use of our website, goods (as
          applicable) or services (as applicable) (collectively, “Services”).
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          By using our website and availing the Services, you agree that you
          have read and accepted these Terms (including the Privacy Policy). We
          reserve the right to modify these Terms at any time and without
          assigning any reason. It is your responsibility to periodically review
          these Terms to stay informed of updates.
        </p>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          The use of this website or availing of our Services is subject to the
          following terms of use:
        </p>

        <ul
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          <li>
            1. To access and use the Services, you agree to provide true, accurate
            and complete information to us during and after registration, and
            you shall be responsible for all acts done through the use of your
            registered account.
          </li>
          <li>
            2. Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials offered on this website
            or through the Services, for any specific purpose. You acknowledge
            that such information and materials may contain inaccuracies or
            errors and we expressly exclude liability for any such inaccuracies
            or errors to the fullest extent permitted by law.
          </li>

          <li>
            3. Your use of our Services and the websiteis solely at your own risk
            and discretion.. You are required to independently assess and ensure
            that the Services meet your requirements.
          </li>

          <li>
            4. The contents of the Website and the Services are proprietary to Us
            and you will not have any authority to claim any intellectual
            property rights, title, or interest in its contents.
          </li>

          <li>
            5. You acknowledge that unauthorized use of the Website or the Services
            may lead to action against you as per these Terms or applicable
            laws.
          </li>
          <li>
            6. You agree to pay us the charges associated with availing the
            Services.
          </li>
          <li>
            7. You agree not to use the website and/ or Services for any purpose
            that is unlawful, illegal or forbidden by these Terms, or Indian or
            local laws that might apply to you.
          </li>

          <li>
            8. You agree and acknowledge that website and the Services may contain
            links to other third party websites. On accessing these links, you
            will be governed by the terms of use, privacy policy and such other
            policies of such third party websites.
          </li>
          <li>
            9. You understand that upon initiating a transaction for availing the
            Services you are entering into a legally binding and enforceable
            contract with the us for the Services.
          </li>
          <li>
            10. You shall be entitled to claim a refund of the payment made by you
            in case we are not able to provide the Service. The timelines for
            such return and refund will be according to the specific Service you
            have availed or within the time period provided in our policies (as
            applicable). In case you do not raise a refund claim within the
            stipulated time, than this would make you ineligible for a refund.
          </li>

          <li>
            11. Notwithstanding anything contained in these Terms, the parties shall
            not be liable for any failure to perform an obligation under these
            Terms if performance is prevented or delayed by a force majeure
            event.
          </li>

          <li>
            12. These Terms and any dispute or claim relating to it, or its
            enforceability, shall be governed by and construed in accordance
            with the laws of India.
          </li>
          <li>
            13. All disputes arising out of or in connection with these Terms shall
            be subject to the exclusive jurisdiction of the courts in AMROHA,
            Uttar Pradesh
          </li>
          <li>
            14. All concerns or communications relating to these Terms must be
            communicated to us using the contact information provided on this
            website.
          </li>
        </ul>

        <p
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          15. Limitation of Liability. In the absence of negligence, 
          bad faith or willful misconduct, none of the Sponsor, the Administrator, 
          nor the Authorized Participant shall be liable to each other or to any other person, 
          including any party claiming by, through or on behalf of the Authorized Participant, 
          for any losses, liabilities, damages, costs or expenses arising out of any error in data 
          or other information provided to any of them by each other or any other person or out of 
          any interruption or delay in the electronic means of communications used by them.
        </p>

        <hr></hr>
      </div>

      <Footer/>
    </div>
  );
};

export default Termscondition;
