import React, { useEffect,useRef,useState } from 'react';
import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';

import './biodataDownload.css'
import bg1 from './images/bg-1.png'
import bg2 from './images/bg-2.png'
import bg3 from './images/bg-3.png'
import bg4 from './images/bg-4.png'
import bg5 from './images/bg-5.png'
import bg6 from './images/bg-6.png'
import bg7 from './images/bg-7.png'
import bg8 from './images/bg-8.png'
import bg9 from './images/bg-9.png'
import bg10 from './images/bg-10.png'
import bg11 from './images/bg-11.png'
import bg12 from './images/bg-12.png'
import bg13 from './images/bg-13.png'
import bg14 from './images/bg-14.png'
import bg15 from './images/bg-15.png'
import bg16 from './images/bg-16.png'
import bg17 from './images/bg-17.png'
const BiodataDownloadForm = () => {
const pdfRef=useRef();
const [photo, setPhoto] = useState('');
const [aboutMe, setAboutMe] = useState('');
const [userInfo, setUserInfo] = useState({});
const [backgroundImage, setBackgroundImage] = useState(bg1);
const [personalInfo, setPersonalInfo] = useState({
  name:'',
  dob: '',
  education: '',
  collname: '',
  profession: '',
  religion: '',
  caste: '',
  gotra: ''
});
const [familyInfo, setFamilyInfo] = useState({
  fatherocc: '',
  motherocc: '',
  broc: '',
  sisc: '',
  famin: '',
  famdet: ''
});
const [expectations, setExpectations] = useState({
  partagemin: '',
  partagemax: '',
  partwemin: '',
  partwemax: '',
  partcout: '',
  partcaste: '',
  partgotra: '',
  partmarital: '',
  partworking: '',
  parted: '',
  partinmin: '',
  partinmax: ''
});
const [contactDetails, setContactDetails] = useState({
  ph: '',
  address: ''
});
const selectedId = localStorage.getItem('selectedid');
useEffect(() => {
    const fetchData = () => {
      const storedData = JSON.parse(localStorage.getItem('biodataForm'));
      if (storedData && storedData.uploadedPhoto) {
        setPhoto(storedData.uploadedPhoto);
      

      setAboutMe(storedData.aboutme);

     
     
    
      const backgroundImages = {
        1: bg1,
        2: bg2,
        3: bg3,
        4: bg4,
        5: bg5,
        6: bg6,
        7:bg7,
        8:bg8,
        9:bg9,
        10:bg10,
        11:bg11,
        12:bg12,
        13:bg13,
        14:bg14,
        15:bg15,
        16:bg16,
        17:bg17
      };
     
   

    setBackgroundImage(backgroundImages[selectedId] || bg1);

    setPersonalInfo({
      name:storedData.name,
      dob: storedData.dob,
      education: storedData.education,
      collname: storedData.collname,
      profession: storedData.profession,
      religion: storedData.religion,
      caste: storedData.caste,
      gotra: storedData.gotra
    });

    setFamilyInfo({
      fatherocc: storedData.fatherocc,
      motherocc: storedData.motherocc,
      broc: storedData.broc,
      sisc: storedData.sisc,
      famin: storedData.famin,
      famdet: storedData.famdet
    });

    setExpectations({
      partagemin: storedData.partagemin,
      partagemax: storedData.partagemax,
      partwemin: storedData.partwemin,
      partwemax: storedData.partwemax,
      partcout: storedData.partcout,
      partcaste: storedData.partcaste,
      partgotra: storedData.partgotra,
      partmarital: storedData.partmarital,
      partworking: storedData.partworking,
      parted: storedData.partedu,
      partinmin: storedData.partinmin,
      partinmax: storedData.partinmax
    });

    setContactDetails({
      ph: storedData.ph,
      address: storedData.address
    });
  };
    }
  fetchData();

  const timer = setTimeout(() => {
    downloadPDF();
  }, 500); // 3000 milliseconds = 3 seconds

  // Cleanup function to clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, []);

    const downloadImage = (imgData) => {
      // Create an anchor element
      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = 'biodata_image.png'; // Specify the file name for the download
  
      // Append the anchor to the body, click it, and then remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  };






const downloadPDF = () => {
   const input = pdfRef.current;
   html2canvas(input, {
       scale: 6, // Higher resolution
       useCORS: true, // For images from external origins
       logging: true, // For debugging purposes
     
       width: input.clientWidth,
       height: input.clientHeight
   }).then(canvas => {
       const imgData = canvas.toDataURL('image/png', 4.0); // Get the highest quality image
       downloadImage(imgData); // Call the function to download the image
      
       const imgWidth = canvas.width;
       const imgHeight = canvas.height;
   // Convert canvas dimensions from pixels to millimeters (mm)
   const widthInMm = imgWidth * 0.264583; // 1 px = 0.264583 mm
   const heightInMm = imgHeight * 0.264583;
      // Create a new jsPDF instance with page size exactly matching the image size
      const pdf = new jsPDF({
        orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
        unit: 'mm',
        format: [widthInMm, heightInMm]
    });
       // Fit the canvas image to the entire A4 page
    
        // Add the image to the PDF at full page size
        pdf.addImage(imgData, 'PNG', 0, 0, widthInMm, heightInMm);

       pdf.save('biodata.pdf');
   });
};


console.log(selectedId);

// const backgroundImage = backgroundImages[selectedId] || './images/bg-1.png';
// console.log(backgroundImage)
return (
   <>
  
   <div  className='main-div' >
 <div className="card-container"    style={{ backgroundImage: `url(${backgroundImage})` }} ref={pdfRef}>
      <div className="profile-header">
        <div className="profile-image">
         <img src={photo} alt="User Data"/>
        </div>
        <div style={{marginLeft:'2px'}}>
            <h1 style={{marginTop: '0', marginBottom: '4px',textAlign:'left', fontFamily: 'Redressed', fontWeight:'bold', fontSize:'30px', color:'#f6C105', lineHeight: '29.17px'}}>{personalInfo.name}</h1>
            <p className="formattedText">
    {aboutMe}
</p>
        </div>
    </div>

    <div style={{width:'100%', maxWidth:'900px',marginLeft:'60px'}}>
        
        <div style={{display: 'flex'}}>
      <div style={{padding:'10px',marginRight:'20px', marginLeft:'5px'}}>
        <h2 style={{fontSize:'1.4em',fontWeight:'bold' ,lineHeight:'28px', color:'#f6C105',textTransform:'none'}}>Personal Information:</h2>
      
  
        <ul style={{listStyle: 'none', paddingLeft:'0'}}>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            DOB :               {personalInfo.dob} 
          </li>
      
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
           Highest Eduction : {personalInfo.education}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
           College Name : {personalInfo.collname}
          </li>
       
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Profession : {personalInfo.profession}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Religion :   {personalInfo.religion}
          </li>
          <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Caste :   {personalInfo.caste} ,  Gotra :          {personalInfo.gotra}
          </li>
      
         
        </ul>
      </div>
      
        <div style={{padding:'10px',marginLeft:'8px'}}>
        <h2 style={{fontSize:'1.4em',fontWeight:'bold', lineHeight:'28px',color: '#f6C105',textTransform:'none'}}>Family Information:</h2>
       
        <ul style={{listStyle: 'none',paddingLeft:'0'}}>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Father Occupation :  {familyInfo.fatherocc}
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Mother Occupation :   {familyInfo.motherocc}
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Brother count: {familyInfo.broc}  
          </li>
        
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
             Sister count :  {familyInfo.sisc}    
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
          Family Income   :    {familyInfo.famin} 
          </li>
          <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
            <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
            Family Details  :   {familyInfo.famdet}
          </li>
        </ul>
        
      </div>
      </div>
      
        <div style={{marginLeft:'20px'}}>
          <h2 style={{fontSize:'1.4em', fontWeight:'bold',lineHeight:'28px' ,color:'#f6C105',textTransform:'none'}}>Expectations:</h2>
          
          <div style={{display:'flex',marginLeft:'2px'}}>
              <div style={{marginRight:'20px'}}>
                  <ul style={{listStyle: 'none',paddingLeft:'0'}}>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                         Age :      {expectations.partagemin} -  {expectations.partagemax}
                      </li>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                         Weight :    {expectations.partwemin} - {expectations.partwemax}
                      </li>
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                        Country :    {expectations.partcout}
                      </li>
                    
                      <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                        <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                        Caste :      {expectations.partcaste}, Gotra :   {expectations.partgotra}
                      </li>
                      </ul>
              </div>
             <div style={{marginLeft:'30px'}}>
              <ul style={{listStyle: 'none', paddingLeft: '0'}}>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Marital Status :     {expectations.partmarital}
                  </li>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Working Status :   {expectations.partworking}
                  </li>
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Education :    {expectations.parted}
                  </li>
                
                  <li style={{position:'relative',marginLeft:'20px', paddingLeft:'15px', marginBottom:'10px' ,color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                     Min Income :     {expectations.partinmin}  -  {expectations.partinmax}          
                          </li>
                  </ul>
             </div>
          </div>
             <div style={{padding:'2px', marginRight:'10px', marginLeft:'2px'}}>
                <h2 style={{fontSize:'1.4em', fontWeight:'bold', lineHeight:'28px', color:'#f6C105',textTransform:'none'}}>Contact Details</h2>
             
          
                <ul style={{listStyle: 'none', paddingLeft:'0'}}>
                  <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Phone Number:      {contactDetails.ph}
                    </li>
                  <li style={{position:'relative', marginLeft:'20px', paddingLeft: '15px', marginBottom:'10px', color:'white'}}>
                    <span style={{position:'absolute', left:'0', top: '50%', transform:'translateY(-50%)',width:'10px' ,height:'10px' ,backgroundColor:'yellow', borderRadius: '5px'}}></span>
                    Residental Address:     {contactDetails.address}
                  </li>
                  </ul>
             </div>

    </div>
    </div>
    </div>
    </div>
        <button className='btn' onClick={downloadPDF}>Download Pdf</button>
     </>

  );
}

export default BiodataDownloadForm;
