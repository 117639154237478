import React from "react";
import "./Footer.css";
import { useState } from "react";
import "./NewsletterModal.css";

const NewsletterPopupcontent = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");

  const handleSubscribe = async () => {
    try {
      // Perform basic email validation
      if (!email) {
        setError("Email cannot be empty");
        return;
      }

      // Check if the email is valid using a simple regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      // Perform basic mobile number validation
      if (!mobileNumber) {
        setMobileNumberError("Mobile number cannot be empty");
        return;
      }

      // Check if the mobile number is a 10-digit number
      const mobileNumberRegex = /^\d{10}$/;
      if (!mobileNumberRegex.test(mobileNumber)) {
        setMobileNumberError("Please enter a valid 10-digit mobile number");
        return;
      }

      // Clear any previous error
      setMobileNumberError("");
      setError("");

      // Send the email to the server with the subscribe parameter
      const response = await fetch(`${process.env.REACT_APP_MAINURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          mobile_number: mobileNumber,
          subscribe: true /* or false */,
        }),
      });

      const data = await response.json();

      if (data.error === "You have already Subscribed to the newsletter") {
        window.alert("You have already Subscribed to the newsletter");
      }

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        console.log("Request processed successfully!");
        // Optionally, you can reset the email input field or show a success message.
        setEmail("");

        // Show alert for successful subscription
        window.alert("You have successfully subscribed to the newsletter!");
      } else {
        console.error("Failed to process the request. Please try again.");
        // Handle error, show an error message, etc.
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle unexpected errors
    }
  };

  return (
    <div className="col-lg-5 col-sm-5">
      <form
        action="#"
        className="f_subscribe_two mailchimp newsletter-form"
        method="post"
        noValidate="true"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubscribe();
        }}
      >
        <div style={{ display: "block" }}>
          <div>
            <p
              className="mb-0 f_400"
              style={{ fontSize: "17px", fontWeight: "400", textAlign: "left" }}
            >
              Subscribe for Newsletter
            </p>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                name="EMAIL"
                className={`form-control memail ${error ? "is-invalid" : ""}`}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              
            </div>
            {error && <div className="invalid-feedback">{error}</div>}
            <p className="mchimp-errmessage" style={{ display: "none" }}></p>
            <p className="mchimp-sucmessage" style={{ display: "none" }}></p>
          </div>

          <div className="sms-div" style={{marginLeft: '1px', marginTop: '20px'}}>
            <p
              className="mb-0 f_400"
              style={{
                fontSize: "17px",
                fontWeight: "400",
                textAlign: "left",
              }}
            >
              Subscribe for SMS
            </p>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                name="MOBILE"
                className={`form-control mmobile ${
                  MobileNumberError ? "is-invalid" : ""
                }`}
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <button
                className="btn btn_get btn_get_two"
                style={{ margin: "0 15px" }}
                type="submit"
              >
                Subscribe
              </button>
            </div>
            {MobileNumberError && (
              <div className="invalid-feedback">{MobileNumberError}</div>
            )}
            <p className="mchimp-errmessage" style={{ display: "none" }}></p>
            <p className="mchimp-sucmessage" style={{ display: "none" }}></p>
          </div>
        </div>
      </form>
      {/* <p>Made with <i className="icon_heart"></i> in <a href="http://cakecounter.com" target="_blank">CakeCounter</a></p> */}
    </div>
  );
};

export default NewsletterPopupcontent;
