import React, {useState} from 'react'
import Aboutdownload from './Aboutdownload'
//import Footer from './Footer'
import phonepay from './images/phonepe.webp'
//import cashfree from './images/cashfree.jpg'
//import ccavenue from './images/ccavenue.png'
import { useNavigate } from "react-router-dom";
import { Paymentnavigation } from './Paymentnavigation'
import "./plan.css";



const PricingCard = () => {
    const subscribe_user_status = sessionStorage.getItem("subscribe_user_status");
    

    const navigate = useNavigate();

    

    const handleSubscribepaln = async () => {
        if (subscribe_user_status === 'null'){
  
          navigate('/subscriptionplans/checkout')
         // try{
          //    const response = await fetch("http://localhost:3000/subscriptionplans/ccavResponseHandler", {
           //   method: 'POST',
           //   headers: {
            //    'Content-Type': 'application/json',
            //  },
            //  body: JSON.stringify({
              //}),
           // });
  
            //const data = await response.json();
            //console.log(data)
            // if(data !== null && family_details_status === "empty"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/familydetails");
            // }
            // if(data !== null && family_details_status === "created" && partner_pref_status === "empty"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/partnerdetails");
            // }
  
            // if(data !== null && family_details_status === "created" && partner_pref_status === "created"){
            //   window.alert("You have subscribe the Plan the successfully")
            //   navigate("/myprofile");
            // }
  
           // }catch (err) {
           //   console.error(err);
           //   window.alert("Error occurred while processing the request.");
            //}
        }
  
        if (subscribe_user_status === 'free' || subscribe_user_status === 'created'){
  
          navigate('/subscriptionplans/checkout')
         
        }
      }
      return (
        <div>
           <Paymentnavigation/>
            <p style={{textAlign:'center', color:'#292929', fontWeight:'600', fontSize:'25px', margin:'0px 10px', marginTop:'100px'}}>
            Please choose one of these to proceed ahead with payment</p>

            <div className='payment-method' >
                <img
                onClick={handleSubscribepaln}
                src={phonepay} style={{height:'200px', border:'2px solid grey', borderRadius:'10px', padding:'20px'}}/>
              
            </div>

            <Aboutdownload/>
    </div>
      );
    
}

export default PricingCard
