import React, { useState, useEffect } from 'react';
import useFetchSubscription from './utils/useFetchSubscription';
import "./Myprofile.css"; 

const PlanSubscriptionDetails = () => {

    const userId = sessionStorage.getItem("userId");
    const subscriptionInfo = useFetchSubscription(userId);


    
    

    return (
        <div>
          {subscriptionInfo?.plan_name !== null ? (
            <div className='plansubscription'> 
              <h1>Current Plan <span style={{ color:"#F3CD02", marginLeft:"20px" }}>{subscriptionInfo?.plan_name}</span></h1>
              <h1 style={{ color:"#FF8A00" }}>Duration: <span style={{ color:"white", marginLeft:"20px", background:"#FF4479", padding:"5px 15px", borderRadius:"10px" }}>
                {subscriptionInfo?.plan_duration} months</span></h1>
              <h1>Your subscription will expire within {subscriptionInfo?.year_diff} years, {subscriptionInfo?.months_diff} months and {subscriptionInfo?.days_diff} days.</h1>
              <h1 style={{fontSize:"23px"}}>Upgrade your subscription by choosing below plans </h1>      
            </div>
          ) : (
            <div className='plansubscription'>
                <h1 style={{textAlign:"center"}}>You haven't any active Plan</h1>
            </div>
          )}
        </div>
      );
}

export default PlanSubscriptionDetails;

