import React, { useState, useEffect } from "react";
import vs from "./images/vs-new.png";
import { Link } from "react-router-dom";
import Modal from "./fullScreenmodal";
import MultiStepForm from "./MultiStepForm";
import img from "./google-play.jpeg";
export const Navigation = (props) => {
  const [modalShown, toggleModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [downloadModalShown, toggleDownloadModal] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount and unmount

  const navClass = scrolled ? "navbar-scrolled" : "";

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${navClass}`}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand page-scroll" to="/">
            <img
              style={{ width: "200px", height: "53px", marginTop: "-20px" }}
              src={vs}
              alt="logo"
            />
          </Link>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                className="page-scroll"
                onClick={() => {
                  toggleModal(!modalShown);
                }}
              >
                Register
              </Link>

              <Modal
                shown={modalShown}
                close={() => {
                  toggleModal(false);
                }}
              >
                {/* <h1>Look! I'm inside the modal!</h1> */}
                <MultiStepForm />
              </Modal>
            </li>
            <li className="dropdown hover-dropdown">
              <Link
                to="#"
                className="dropdown-toggle page-scroll"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Help <span className="caret"></span>
              </Link>
              <ul
                className="dropdown-menu"
                style={{
                  fontSize: "17px",
                  fontFamily: "Lemon",
                  borderRadius: "10px",
                  color: "#3e3e3ed1",
                }}
              >
                <li>
                  <a href="#footer" className="page-scroll">
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                {/* Add more dropdown items as needed */}
              </ul>
            </li>
            <li className="dropdown hover-dropdown">
              <Link
                to="/planpage"
                className="dropdown-toggle page-scroll"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Browse Plans <span className="caret"></span>
              </Link>
            </li>
            <li className="dropdown hover-dropdown">
              <Link
                to="/login"
                className="dropdown-toggle page-scroll"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color: "white",
                  padding: "10px 18px",
                  border: "1px solid white",
                }}
              >
                Login <span className="caret"></span>
              </Link>
              {/* <ul className="dropdown-menu" style={{fontSize: '17px', fontFamily: 'Lemon', borderRadius: '10px', color: '#3e3e3ed1'}}>
                <li><Link to="#">Login Option 1</Link></li>
                <li><Link to="#">Login Option 2</Link></li>
              </ul> */}
            </li>
            <li>
              <Link to="/magazine">Magazine</Link>
            </li>
            <li>
              {/* Special highlighting button similar to Register but after Login */}
              <Link
                className="page-scroll"
                onClick={() => toggleDownloadModal(!downloadModalShown)}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "30px",
                  textDecoration: "none",
                  display: "inline-block",
                }}
              >
                Download App
              </Link>

              <Modal
                shown={downloadModalShown}
                close={() => toggleDownloadModal(false)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: "5px solid",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "40px",
                  }}
                >
                  <h2>Download Our App From</h2>
                  {/* Link to redirect users to the Play Store */}

                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.vivahsahyog"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={img} alt="Play Store" />
                  </a>
                </div>
              </Modal>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
