
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import PageSix from "./PageSix";
import tachyons from "tachyons";
import React, { useState, useEffect } from "react";  
import MultiStepProgressBar from "./multistep-bar";
import Popup from "./NewsletterModal";

function MultiStepForm() {


  const [page, setPage] = useState("pageone");

  const [valuesSet1, setValuesSet1] = useState(false); // New state to track values setting
  const [valuesSet2, setValuesSet2] = useState(false); // New state to track values setting
  const [valuesSet3, setValuesSet3] = useState(false); // New state to track values setting
  const [valuesSet4, setValuesSet4] = useState(false); // New state to track values setting
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [profile, setProfile] = useState("");
  const [martialStatus, setMartialStatus] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [religion, setReligion] = useState("");
  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");
  const [gotra, setGotra] = useState("");
  const [manglik, setManglik] = useState("");
  const [fullname, setFullname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [facecolor, setFacecolor] = useState("");
  const [age, setAge] = useState("");
  const [birth_time, setBirth_time] = useState("");
  const [birth_location, setBirth_location] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [education, setEducation] = useState("");
  const [other_education, setOther_education] = useState("");
  const [college, setCollege] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [food_style, setFood_style] = useState("");
  const [drinking_habit, setDrinking_habit] = useState("");


  const nextPage1 = (page, email, phoneNumber,password, profile, ) => {
    setPage(page);

    // Set values only if they haven't been set before
    if (!valuesSet1 ) {
      setEmail(email);
      // setUsername(username);
      setPhoneNumber(phoneNumber);
      setPassword(password)
      setProfile(profile);
      setValuesSet1(true); // Update the state to indicate that values have been set
    }

    

    
  };

  const nextPage2 = (page,martialStatus, motherTongue, religion, caste, subCaste, gotra, manglik ) => {
    setPage(page);


    if (!valuesSet2){
      setMartialStatus(martialStatus);
      setMotherTongue(motherTongue);
      setReligion(religion);
      setCaste(caste);
      setSubCaste(subCaste);
      setGotra(gotra);
      setManglik(manglik)
      setValuesSet2(true);
    }

    
  };

  const nextPage3 = (page,fullname, gender, dob, height, weight, facecolor,age, birth_time, birth_location, address, district, city, state, country ) => {
    setPage(page);

    if (!valuesSet3){
      setFullname(fullname);
      setGender(gender);
      setDob(dob);
      setHeight(height);
      setWeight(weight);
      setFacecolor(facecolor);
      setAge(age);
      setBirth_time(birth_time);
      setBirth_location(birth_location);
      setAddress(address);
      setDistrict(district);
      setCity(city);
      setState(state);
      setCountry(country);
      setValuesSet3(true)
    
    }

    
  };

  const nextPage4 = (page,education,other_education, college,hobbies, food_style, drinking_habit ) => {
    setPage(page);
  
    if (page === "pagefive"){
      setEducation(education);
      setOther_education(other_education);
      setCollege(college);
      setHobbies(hobbies);
      setFood_style(food_style);
      setDrinking_habit(drinking_habit);
      setValuesSet4(true)
     
    }
  };

  const nextPage5 = (page, working) => {
    setPage(page);
    setPopupVisibility(true);
  };


  

  

  return (
    <div className="App" style={{textAlign: 'center', paddingBottom: '30px'}}>
      {/* <Logo /> */}
      {/* <p style={{fontSize: '28px', color: 'rgb(255, 159, 153)', fontWeight: 'bold' }}>Registeration Form</p> */}
      <MultiStepProgressBar page={page}  />
      {
        {
          pageone: <PageOne onButtonClick={nextPage1}  />,
          pagetwo: <PageTwo  
          onButtonClick={nextPage3} 
                      />,
          pagethree: <PageThree onButtonClick={nextPage2} 
                      email={email}
                      phoneNumber={phoneNumber}
                      password={password}
                      profile={profile} 
                      fullname={fullname}
                      gender={gender}
                      dob={dob}
                      height={height}
                      weight={weight}
                      facecolor={facecolor}
                      age={age}
                      birth_time={birth_time}
                      birth_location={birth_location}
                      address={address}
                      district={district}
                      city={city}
                      state={state}
                      country={country}
                      
                      
                       />,
                        
          pagefour: <PageFour onButtonClick={nextPage4} />,
          pagefive: <PageFive email={email}
                      phoneNumber={phoneNumber}
                      password={password}
                      profile={profile}
                      martialStatus={martialStatus}
                      motherTongue={motherTongue}
                      religion={religion}
                      caste={caste}
                      subCaste={subCaste}
                      gotra={gotra}
                      manglik={manglik}
                      fullname={fullname}
                      gender={gender}
                      dob={dob}
                      height={height}
                      weight={weight}
                      facecolor={facecolor}
                      age={age}
                      birth_time={birth_time}
                      birth_location={birth_location}
                      address={address}
                      district={district}
                      city={city}
                      state={state}
                      country={country}
                      education={education}
                      other_education={other_education}
                      college={college}
                      hobbies={hobbies}
                      food_style={food_style}
                      drinking_habit={drinking_habit}
                      onButtonClick={nextPage5} />,
          pagesix: <PageSix/>
        }[page]
      }
      {isPopupVisible && <Popup onClose={()=>setPopupVisibility(false)} />}
    </div>
  );
}

export default MultiStepForm;










