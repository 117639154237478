import React from "react";
import { AboutNavigation } from "./AboutNavigation";
import Aboutdownload from "./Aboutdownload";
import AboutTeam from "./AboutTeam";
import Footer from "./Footer";

const About = () => {
  return (
    <>
      <AboutNavigation />
      <div
        style={{
          marginTop: "80px",
          padding: "20px 50px",
          background:
            "linear-gradient(0deg, rgba(18,19,19,1) 0%, rgba(248,240,240,1) 0%, rgba(228,52,28,1) 100%)",
          paddingBottom: "250px",
        }}
      >
        <h3
          style={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            color: "white",
            paddingBottom: "30px",
            fontFamily: "Lemon",
          }}
        >
          About Us
        </h3>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "500",
            color: "white",
            textAlign: "justify",
          }}
        >
          VivahSahyog is a matchmaking startup providing affordable matchmaking
          to all masses. It aims to provide most premium services along with
          trustworthy profiles that too at a very small cost . We have range of
          membership plans based on the needs of the customers and looking to
          provide a fearless matchmaking journey that too with highly affordable
          cost. It also aims to provide provide easy interaction between the
          interested partners with features like video chat . It also promises a
          standout profile with extra rating features which will be disclosed
          soon. We have a team of around 10+ employees who belongs to different regions all over the country.
        </p>
        <hr></hr>
      </div>

      <AboutTeam />

      <Aboutdownload />

      <Footer/>
    </>
  );
};

export default About;
