import React, {useState} from 'react'
import "./search.css";
import { Loginnavigation } from './Loginnavigation'
import Aboutdownload from './Aboutdownload'
import Footer from './Footer'
import "react-input-range/lib/css/index.css";
import { useNavigate } from "react-router-dom";

const FamilyDetails = () => {
  const navigate = useNavigate();

  const [familytype, setFamilytype] = useState("");
  const [familyincome, setFamilyincome] = useState("");
  const [fatheroccupation, setFatheroccupation] = useState("");
  const [otherfatheroccupation, setOtherfatheroccupation] = useState("");
  const [motheroccupation, setMotheroccupation] = useState("");
  const [othermotheroccupation, setOthermotheroccupation] = useState("");
  const [brother, setBrother] = useState("");
  const [sister, setSister] = useState("");
  const [relativecontact, setRelativecontact] = useState("");


  const [familytypeError, setFamilytypeError] = useState("");
  const [familyincomeError, setFamilyincomeError] = useState("");
  const [fatheroccupationError, setFatheroccupationError] = useState("");
  const [otherfatheroccupationError, setOtherfatheroccupationError] = useState("");
  const [motheroccupationError, setMotheroccupationError] = useState("");
  const [othermotheroccupationError, setOthermotheroccupationError] = useState("");
  const [brotherError, setBrotherError] = useState("");
  const [sisterError, setSisterError] = useState("");
  const [relativecontactError, setRelativecontactError] = useState("");

  const userId = sessionStorage.getItem("userId");
  const partner_pref_status = sessionStorage.getItem("partner_pref_status");


  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };



  const handleSearch = async () => {
    if (
      familytype.trim() !== "" &&
      familyincome.trim() !== "" &&
      fatheroccupation.trim() !== "" &&
      motheroccupation.trim() !== "" &&
      brother.trim() !== "" &&
      sister.trim() !== "" &&
      ((relativecontact.trim() === "") || (relativecontact.trim().length === 10)) &&
      ((familyincome === "Other (Specify)" && otherfatheroccupation.trim() !== "") ||
        fatheroccupation !== "Other (Specify)") &&
      ((motheroccupation === "Other (Specify)" && othermotheroccupation.trim() !== "") ||
        motheroccupation !== "Other (Specify)")
    ) {
  
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}/familydetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "user_id": userId,
            "family_type": familytype,
            "family_income": familyincome,
            "father_occupation": fatheroccupation === "Other (Specify)" ? capitalizeFirstLetter(otherfatheroccupation) : fatheroccupation,
            "mother_occupation": motheroccupation === "Other (Specify)" ? capitalizeFirstLetter(othermotheroccupation) : motheroccupation,
            "brother_count": brother,
            "sister_count": sister,
            "relative_contact": relativecontact,
          }),
        });
  
        const data = await response.json();
        console.log(data);
  
        
        if (data !== null && partner_pref_status === "empty") {
          navigate('/partnerdetails');
        }else if (data !== null && partner_pref_status === "created"){
          navigate('/myprofile')
        }
  
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
    } else {
      setMotheroccupationError(motheroccupation.trim() === "" ? "Please select occupation" : "");
      setOthermotheroccupationError(
        motheroccupation === "Other (Specify)" && othermotheroccupation.trim() === ""
          ? "Please specify occupation"
          : ""
      );
  
      setFatheroccupationError(fatheroccupation.trim() === "" ? "Please select occupation" : "");
      setOtherfatheroccupationError(
        fatheroccupation === "Other (Specify)" && otherfatheroccupation.trim() === ""
          ? "Please specify occupation"
          : ""
      );
  
      setFamilytypeError(familytype.trim() === "" ? "Please select family type" : "");
  
      setFamilyincomeError(familyincome.trim() === "" ? "Please select family income" : "");
  
      setBrotherError(brother.trim() === "" ? "Please enter the number of brothers" : "");
  
      setSisterError(sister.trim() === "" ? "Please enter the number of sisters" : "");
  
      setRelativecontactError(
        (relativecontact.trim() !== "" && relativecontact.trim().length !== 10) ? "Relative contact must be 10 digits" : ""
      );
    }
  };
  




  return (
    <>
      
      <div style={{margin:"100px 20px", marginBottom:"50px", display:"flex", justifyContent:"center"}}>
      <span style={{fontSize:"20px", fontWeight:"500", fontFamily:"Poppins", border:"1px solid #dcd7d7", padding:"10px", borderRadius:"10px"}}>
      You have completed your registration successfully! 5% increase in matches found for profiles with Family Details</span>
      </div>

      
      <div className="advance-search">
        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="facecolor">Family Type *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: familytypeError ? "red" : "#FBC913"}}
                className="form-control"
                id="familytype"
                value={familytype}
                onChange={(e) => setFamilytype(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Rich">Rich</option>
                <option value="Upper Middle">Upper Middle</option>
                <option value="Middle Class">Middle Class</option>
                <option value="Poor">Poor</option>
                <option value="Highly Rich">Highly Rich</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {familytypeError}
            </div>
          </div>

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="facecolor">Family Income *</label>
              <select
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: familyincomeError ? "red" : "#FBC913"}}
                className="form-control"
                id="familyincome"
                value={familyincome}
                onChange={(e) => setFamilyincome(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Less than 1,00,000">Less than 1,00,000</option>
                <option value="1,00,000 - 2,00,000">1,00,000 - 2,00,000</option>
                <option value="2,00,000 - 3,00,000">2,00,000 - 3,00,000</option>
                <option value="3,00,000 - 4,00,000">3,00,000 - 4,00,000</option>
                <option value="4,00,000 - 5,00,000">4,00,000 - 5,00,000</option>
                <option value="5,00,000 - 6,00,000">5,00,000 - 6,00,000</option>
                <option value="6,00,000 - 7,00,000">6,00,000 - 7,00,000</option>
                <option value="7,00,000 - 8,00,000">7,00,000 - 8,00,000</option>
                <option value="8,00,000 - 9,00,000">8,00,000 - 9,00,000</option>
                <option value="9,00,000 - 10,00,000">9,00,000 - 10,00,000</option>
                <option value="10,00,000 - 12,50,000">10,00,000 - 12,50,000</option>
                <option value="12,50,000 - 15,00,000">12,50,000 - 15,00,000</option>
                <option value="15,00,000 - 20,00,000">15,00,000 - 20,00,000</option>
                <option value="20,00,000 - 30,00,000">20,00,000 - 30,00,000</option>
                <option value="30,00,000 - 40,00,000">30,00,000 - 40,00,000</option>
                <option value="40,00,000 - 50,00,000">40,00,000 - 50,00,000</option>
                <option value="50,00,000 - 60,00,000">50,00,000 - 60,00,000</option>
                <option value="60,00,000 - 70,00,000">60,00,000 - 70,00,000</option>
                <option value="70,00,000 - 80,00,000">70,00,000 - 80,00,000</option>
                <option value="80,00,000 - 90,00,000">80,00,000 - 90,00,000</option>
                <option value="90,00,000 - 1,00,00,000">90,00,000 - 1,00,00,000</option>
                <option value="1,00,00,000 - 1,25,00,000">1,00,00,000 - 1,25,00,000</option>
                <option value="1,25,00,000 - 1,50,00,000">1,25,00,000 - 1,50,00,000</option>
                <option value="1,50,00,000 - 2,00,00,000">1,50,00,000 - 2,00,00,000</option>
                <option value="More than 2,00,00,000">More than 2,00,00,000</option>
                <option value="More than 10,00,00,000">More than 10,00,00,000</option>
                <option value="Not Applicable">Not Applicable</option>
              </select>
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {familyincomeError}
            </div>
          </div>
          
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="fatheroccupation">Father's Occupation</label>
              <select
                className="form-control"
                id="fatheroccupation"
                value={fatheroccupation}
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: fatheroccupationError ? "red" : "#FBC913"}}
                onChange={(e) => {
                  setFatheroccupation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherfatheroccupation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Accountant">Accountant</option>
                <option value="Artist">Artist</option>
                <option value="Engineer">Engineer</option>
                <option value="Doctor">Doctor</option>
                <option value="Teacher">Teacher</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Manager">Manager</option>
                <option value="Lawyer">Lawyer</option>
                <option value="Nurse">Nurse</option>
                <option value="Designer">Designer</option>
                <option value="Writer">Writer</option>
                <option value="Farmer">Farmer</option>
                <option value="Cook">Cook</option>
                <option value="Clerk">Clerk</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Police Officer">Police Officer</option>
                <option value="Chef">Chef</option>
                <option value="Salesperson">Salesperson</option>
                <option value="Student">Student</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Retired">Retired</option>
                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Manager">Manager</option>
                <option value="Senior Manager">Senior Manager</option>
                <option value="Consultant">Consultant</option>
                <option value="Lecturer">Lecturer</option>
                <option value="Director">Director</option>
                <option value="Technical lead">Technical lead</option>
                <option value="General Manager">General Manager</option>
                <option value="Government Officer">Government Officer</option>
                <option value="Minister">Minister</option>
                <option value="MLA">MLA</option>
                <option value="MP">MP</option>
                <option value="Speaker">Speaker</option>
                <option value="Managing Director">Managing Director</option>
                <option value="Architect">Architect</option>
                <option value="Interior Designer">Interior Designer</option>
                <option value="Human Resource">Human Resource</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Executive">Executive</option>
                <option value="Senior Executive">Senior Executive</option>
                <option value="Businessman">Businessman</option>
                <option value="Franchise owner">Franchise owner</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {fatheroccupation === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={otherfatheroccupation}
                  onChange={(e) => setOtherfatheroccupation(e.target.value)}
                  style={{ marginTop: "20px",borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherfatheroccupationError ? "red" : "#FBC913" }}
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {fatheroccupationError || otherfatheroccupationError}
            </div>
          </div>
          

          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="motheroccupation">Mother's Occupation *</label>
              <select
                className="form-control"
                id="motheroccupation"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: motheroccupationError ? "red" : "#FBC913"}}
                value={motheroccupation}
                onChange={(e) => {
                  setMotheroccupation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOthermotheroccupation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Accountant">Accountant</option>
                <option value="Artist">Artist</option>
                <option value="Engineer">Engineer</option>
                <option value="Doctor">Doctor</option>
                <option value="Teacher">Teacher</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Manager">Manager</option>
                <option value="Lawyer">Lawyer</option>
                <option value="Nurse">Nurse</option>
                <option value="Designer">Designer</option>
                <option value="Writer">Writer</option>
                <option value="Farmer">Farmer</option>
                <option value="Cook">Cook</option>
                <option value="Clerk">Clerk</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Police Officer">Police Officer</option>
                <option value="Chef">Chef</option>
                <option value="Salesperson">Salesperson</option>
                <option value="Student">Student</option>
                <option value="Homemaker">Homemaker</option>
                <option value="Retired">Retired</option>
                <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
                <option value="Assistant Professor">Assistant Professor</option>
                <option value="Associate Professor">Associate Professor</option>
                <option value="Professor">Professor</option>
                <option value="Manager">Manager</option>
                <option value="Senior Manager">Senior Manager</option>
                <option value="Consultant">Consultant</option>
                <option value="Lecturer">Lecturer</option>
                <option value="Director">Director</option>
                <option value="Technical lead">Technical lead</option>
                <option value="General Manager">General Manager</option>
                <option value="Government Officer">Government Officer</option>
                <option value="Minister">Minister</option>
                <option value="MLA">MLA</option>
                <option value="MP">MP</option>
                <option value="Speaker">Speaker</option>
                <option value="Managing Director">Managing Director</option>
                <option value="Architect">Architect</option>
                <option value="Interior Designer">Interior Designer</option>
                <option value="Human Resource">Human Resource</option>
                <option value="HR Manager">HR Manager</option>
                <option value="Executive">Executive</option>
                <option value="Senior Executive">Senior Executive</option>
                <option value="Businessman">Businessman</option>
                <option value="Franchise owner">Franchise owner</option>
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {motheroccupation === "Other (Specify)" && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Specify"
                  value={othermotheroccupation}
                  onChange={(e) => setOthermotheroccupation(e.target.value)}
                  style={{
                    marginTop: "20px",
                    borderStyle: "solid",
                   borderWidth: "1px",
                    borderColor: othermotheroccupationError ? "red" : "#FBC913"}}
                  
                />
              )}
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {motheroccupationError || othermotheroccupationError}
            </div>
          </div>
        </div>

        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="brother">Brother(s) *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: brotherError ? "red" : "#FBC913"}}
                className="form-control"
                id="brother"
                value={brother}
                onChange={(e) => setBrother(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {brotherError}
            </div>
          </div>

          <div className="custom-col">
          <div className="form-group">
              <label htmlFor="sister">Sister(s) *</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: sisterError ? "red" : "#FBC913"}}
                className="form-control"
                id="sister"
                value={sister}
                onChange={(e) => setSister(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {sisterError}
            </div>
          </div>
          
        </div>


        <div className="custom-row">
          <div className="custom-col">
            <div className="form-group">
              <label htmlFor="relativecontact">Relative Contact(optional)</label>
              <input
                type="number"
                style={{borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: relativecontactError ? "red" : "#FBC913"}}
                className="form-control"
                id="relativeconatct"
                value={relativecontact}
                onChange={(e) => setRelativecontact(e.target.value)}
              />
              
            </div>
            <div className="mv3" style={{ color: "red", fontSize: "1.5rem", textAlign: 'left', marginBottom: '10px' }}>
            {relativecontactError}
            </div>
          </div>

          
          
        </div>
      </div>


      <button
        className="advance-search-btn"
        style={{ marginBottom: "50px" }}
        onClick={handleSearch}
      >
        Next
      </button>


      <Aboutdownload/>
      <Footer/>
    </>
    
  )
}

export default FamilyDetails

