import React from "react";
import "./Footer.css";
import vs from "./vs-new.jpeg";
import insta from "./images/insta-icon.jpeg";
import linkdin from "./images/linkdin.png";
import facebook from "./images/facebook.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");

  const handleSubscribe = async () => {
    try {
      // Perform basic email validation
      if (!email) {
        setError("Email cannot be empty");
        return;
      }

      // Check if the email is valid using a simple regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      // Perform basic mobile number validation
      if (!mobileNumber) {
        setMobileNumberError("Mobile number cannot be empty");
        return;
      }

      // Check if the mobile number is a 10-digit number
      const mobileNumberRegex = /^\d{10}$/;
      if (!mobileNumberRegex.test(mobileNumber)) {
        setMobileNumberError("Please enter a valid 10-digit mobile number");
        return;
      }

      // Clear any previous error
      setMobileNumberError("");
      setError("");

      // Send the email to the server with the subscribe parameter
      const response = await fetch(`${process.env.REACT_APP_MAINURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          mobile_number: mobileNumber,
          subscribe: true /* or false */,
        }),
      });

      const data = await response.json();

      if (data.error === "You have already Subscribed to the newsletter") {
        window.alert("You have already Subscribed to the newsletter");
      }

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        console.log("Request processed successfully!");
        // Optionally, you can reset the email input field or show a success message.
        setEmail("");

        // Show alert for successful subscription
        window.alert("You have successfully subscribed to the newsletter!");
      } else {
        console.error("Failed to process the request. Please try again.");
        // Handle error, show an error message, etc.
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle unexpected errors
    }
  };

  return (
    <div id="footer">
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          {/* <div className="col-lg-3 col-md-6"> */}
          <div
            className="f_widget social-widget pl_70 wow fadeInLeft"
            data-wow-delay="0.8s"
            style={{
              visibility: "visible",
              animationDelay: "0.8s",
              animationName: "fadeInLeft",
            }}
          >
            {/* <h3 className="f-title f_600 t_color f_size_18">Team Solutions</h3> */}
            <div
              className="f_social_icon"
              style={{ margin: "50px 0px", textAlign: "center" }}
            >
              <a
                href="https://www.facebook.com/VivahSahyog"
                className="fa"
                style={{ border: "none" }}
              >
                <img
                  style={{ height: "43px", width: "45px" }}
                  src={facebook}
                  alt="img"
                />
              </a>
              <a
                href="https://twitter.com/VivahSahyog"
                className="fa"
                style={{ border: "none" }}
              >
                <img
                  style={{ height: "38px", width: "40px" }}
                  src="https://about.twitter.com/content/dam/about-twitter/x/brand-toolkit/logo-black.png.twimg.1920.png"
                  alt="img"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/vivahsahyog/mycompany/"
                className="fa"
                style={{ border: "none" }}
              >
                <img
                  style={{ height: "43px", width: "45px" }}
                  src={linkdin}
                  alt="img"
                />
              </a>
              <a
                href="https://www.instagram.com/vivahsahyog/"
                className="fa"
                style={{ border: "none" }}
              >
                <img
                  style={{ height: "40px", width: "45px" }}
                  src={insta}
                  alt="img"
                />
              </a>
            </div>
            {/* </div> */}
          </div>
          <div
            className="container"
            style={{ textAlign: "left", padding: "0 1rem" }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <div
                  className="f_widget company_widget wow fadeInLeft"
                  data-wow-delay="0.2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <h3 className="f-title f_600 t_color f_size_18">
                    <img
                      style={{
                        width: "240px",
                        height: "60px",
                        marginBottom: "-20px",
                      }}
                      src={vs}
                      alt="logo"
                    />
                  </h3>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "300",
                      marginBottom: "-5px",
                    }}
                  >
                    Radha Krishna Technology Solutions
                  </p>
                  <p style={{ fontSize: "18px", fontWeight: "500" }}>
                    <a
                      href="#"
                      style={{
                        color: "rgb(125 120 120)",
                        textDecoration: "none",
                      }}
                    >
                      info@vivahsahyog.com 
                    </a>
                  </p>
                  <p style={{ fontSize: "18px", fontWeight: "300" }}>
                    Sri Krishna Kripa, Aarzoo Enclave, Joya Road, Amroha, Uttar Pradesh (244221)
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-10">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <h3 className="f-title f_600 t_color f_size_18">About Us</h3>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="#">Our competition</a>
                    </li>
                    <li>
                      <a href="#">Channels</a>
                    </li>
                    <li>
                      <a href="#">Scale</a>
                    </li>
                    <li>
                      <a href="#">Watch demo</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-10">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <h3 className="f-title f_600 t_color f_size_18">Products</h3>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="#">Features</a>
                    </li>
                    <li>
                      <a href="#">Integrations</a>
                    </li>
                    <li>
                      <a href="#">Enterprise</a>
                    </li>
                    <li>
                      <a href="#">Solutions</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-10">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <h3 className="f-title f_600 t_color f_size_18">Resources</h3>
                  <ul className="list-unstyled f_list">
                    <li>
                      <a href="#">Partners</a>
                    </li>
                    <li>
                      <a href="#">Developers</a>
                    </li>
                    <li>
                      <a href="#">Community</a>
                    </li>
                    <li>
                      <a href="#">Apps</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-10">
                <div
                  className="f_widget about-widget pl_70 wow fadeInLeft"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInLeft",
                  }}
                >
                  <h3 className="f-title f_600 t_color f_size_18">Company</h3>
                  <ul className="list-unstyled f_list">
                    <li>
                    <Link to="/terms">Terms & Condition</Link>
                    </li>
                    <li>
                    <Link to="/privacy">privacy Policy</Link>
                    </li>

                    <li>
                    <Link to="/refundpolicy">Cancellation & Refund Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom" style={{ marginTop: "50px" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-7">
                <p
                  className="mb-0 f_400"
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    textAlign: "left",
                  }}
                >
                  © 2023 Vivah Sahyog
                </p>
              </div>
              <div className="col-lg-5 col-sm-5">
                <form
                  action="#"
                  className="f_subscribe_two mailchimp"
                  method="post"
                  noValidate="true"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubscribe();
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <p
                        className="mb-0 f_400"
                        style={{
                          fontSize: "17px",
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        Subscribe for Newsletter
                      </p>
                      <div style={{ display: "flex" }}>
                        <input
                          type="text"
                          name="EMAIL"
                          className={`form-control memail ${
                            error ? "is-invalid" : ""
                          }`}
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {error && <div className="invalid-feedback">{error}</div>}
                      <p
                        className="mchimp-errmessage"
                        style={{ display: "none" }}
                      ></p>
                      <p
                        className="mchimp-sucmessage"
                        style={{ display: "none" }}
                      ></p>
                    </div>

                    <div className="sms-div">
                      <p
                        className="mb-0 f_400"
                        style={{
                          fontSize: "17px",
                          fontWeight: "400",
                          textAlign: "left",
                        }}
                      >
                        Subscribe for SMS
                      </p>
                      <div style={{ display: "flex" }}>
                        <input
                          type="text"
                          name="MOBILE"
                          className={`form-control mmobile ${
                            MobileNumberError ? "is-invalid" : ""
                          }`}
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                        <button
                          className="btn btn_get btn_get_two"
                          style={{ margin: "0 15px" }}
                          type="submit"
                        >
                          Subscribe
                        </button>
                      </div>
                      {MobileNumberError && (
                        <div className="invalid-feedback">
                          {MobileNumberError}
                        </div>
                      )}
                      <p
                        className="mchimp-errmessage"
                        style={{ display: "none" }}
                      ></p>
                      <p
                        className="mchimp-sucmessage"
                        style={{ display: "none" }}
                      ></p>
                    </div>
                  </div>
                </form>
                {/* <p>Made with <i className="icon_heart"></i> in <a href="http://cakecounter.com" target="_blank">CakeCounter</a></p> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

