import React, { useState, useEffect } from 'react';
import './search.css';
import Mymatches from './Mymatches';
import Nearby from './Nearby'
import Premium from './Premium'
import Popular from './Popular'
import { Loginnavigation } from './Loginnavigation';
import grid from './images/grid.png'
import list from './images/list.png'
import filter from './images/filter.png'
import MymatchesGrid from './MymatchesGrid';
import NearbyGrid from './NearbyGrid';
import PremiumGrid from './PremiumGrid';
import PopularGrid from './PopularGrid';
import loading1 from "./images/loading.gif"


const Mymatchesfilter = () => {
  const [activeMatches1, setActiveMatches1] = useState(
    sessionStorage.getItem('activeMatches1') || 'Mymatches'
  );
  const [displayGrid, setDisplayGrid] = useState(
    sessionStorage.getItem('displayGrid') === 'true' || false
  );

  const [loading, setLoading] = useState(false);
  const [servererror, setServererror] = useState(false);
  const [userinfo, setUserinfo] = useState("");
  const [userinfonearby, setUserinfonearby] = useState("");
  const [userinfopopular, setUserinfopopular] = useState("");
  const [userinfopremium, setUserinfopremium] = useState("");
  const userId = sessionStorage.getItem("userId");
  const gender = sessionStorage.getItem("gender");
  const religion = sessionStorage.getItem("religion");

  const dataReceives = async () => {
    try {
      setLoading(true);
      setServererror(false);
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/mymatchesfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": userId,
          "gender": gender,
          "religion": religion,
          mymatch: true,
        }),
      });

      const data = await response.json();
      console.log(data)
      setUserinfo(data?.other_users);
    } catch (err) {
      console.error(err);
      setServererror(true)
    } finally{
      setLoading(false);
    }
      
  };


  const dataReceivesnearby = async () => {
    try {
      setLoading(true);
      setServererror(false);
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/mymatchesfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": userId,
          "gender": gender,
          "religion": religion,
          isnearby: true,
        }),
      });

      const data = await response.json();
      setUserinfonearby(data?.other_users);
    } catch (err) {
      console.error(err);
      setServererror(true)
    } finally{
      setLoading(false);
    }
  };

  const dataReceivespopular = async () => {
    try {
      setLoading(true);
      setServererror(false);
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/mymatchesfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": userId,
          "gender": gender,
          "religion": religion,
          ispopular: true,
        }),
      });

      const data = await response.json();
      setUserinfopopular(data?.other_users);
    } catch (err) {
      console.error(err);
      setServererror(true)
    } finally{
      setLoading(false);
    }
  };

  const dataReceivespremium = async () => {
    try {
      setLoading(true);
      setServererror(false);
      const response = await fetch(`${process.env.REACT_APP_MAINURL}/mymatchesfilter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "user_id": userId,
          "gender": gender,
          "religion": religion,
          ispremium: true,
        }),
      });

      const data = await response.json();
      setUserinfopremium(data?.other_users);
    } catch (err) {
      console.error(err);
      setServererror(true)
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    // Save activeMatches and displayGrid to sessionStorage
    sessionStorage.setItem('activeMatches1', activeMatches1);
    sessionStorage.setItem('displayGrid', displayGrid.toString());

    dataReceives();
    dataReceivesnearby();
    dataReceivespopular();
    dataReceivespremium();
  }, [activeMatches1, displayGrid]);


  



  const handleMatchesButtonClick = (searchType) => {
    setActiveMatches1(searchType);
  };

  const toggleGridView = () => {
    const newDisplayGrid = !displayGrid;
    setDisplayGrid(newDisplayGrid);
    sessionStorage.setItem('displayGrid', newDisplayGrid.toString());
  };


  

  return (
    <div>
      <Loginnavigation />
      <div className='search-filter' >
        <button style={{padding: '1px 20px', background: 'none', color: '#222222', border: 'none',}}
          onClick={() => handleMatchesButtonClick('Mymatches')}
          className={`mymatches-btn ${activeMatches1 === 'Mymatches' ? 'active' : ''}`}
        >
          My Matches ({userinfo?.length})
        </button>
        <button style={{padding: '1px 30px'}}
          onClick={() => handleMatchesButtonClick('Nearby')}
          className={`mymatches-btn ${activeMatches1 === 'Nearby' ? 'active' : ''}`}
        >
          Nearby ({userinfonearby?.length})
        </button>
        <button style={{padding: '1px 30px', }}
          onClick={() => handleMatchesButtonClick('Premium')}
          className={`mymatches-btn ${activeMatches1 === 'Premium' ? 'active' : ''}`}
        >
          New ({userinfopremium?.length})
        </button>
        <button style={{padding: '1px 30px'}}
          onClick={() => handleMatchesButtonClick('Popular')}
          className={`mymatches-btn ${activeMatches1 === 'Popular' ? 'active' : ''}`}
        >
          Verified ({userinfopopular?.length})
        </button>

        <div>
        <img style={{height: '50px', cursor: 'pointer'}} src={grid} alt="grid" onClick={toggleGridView}/>
        <img style={{height: '50px'}} src={list} alt="list"/>
        <img style={{height: '50px'}} src={filter} alt="filter"/>
        </div>

      </div>


      {loading ? (
          <div style={{ width: "100vw", textAlign: "center" }}>
          <img style={{height:"300px", width:"400px", margin:"auto"}} src={loading1}  alt="loading..."/>
          </div>
        ) : (
          <>
            {servererror ? (
              <div style={{ width: "100vw", textAlign: "center" }}>
                <h3>Server Error. Please try again later.</h3>
              </div>
            ) : (
            <>
            {activeMatches1 === 'Mymatches' && !displayGrid && <Mymatches userinfo={userinfo} />}
            {activeMatches1 === 'Mymatches' && displayGrid && <MymatchesGrid userinfo={userinfo} />}
            {activeMatches1 === 'Nearby' && !displayGrid && <Nearby userinfo={userinfonearby} />}
            {activeMatches1 === 'Nearby' && displayGrid && <NearbyGrid userinfo={userinfonearby} />}
            {activeMatches1 === 'Premium' && !displayGrid && <Premium userinfo={userinfopremium} />}
            {activeMatches1 === 'Premium' && displayGrid && <PremiumGrid userinfo={userinfopremium} />}
            {activeMatches1 === 'Popular' && !displayGrid && <Popular userinfo={userinfopopular} />}
            {activeMatches1 === 'Popular' && displayGrid && <PopularGrid userinfo={userinfopopular} />}
            </>
        )}
          </>
        )}
    </div>
  )
}

export default Mymatchesfilter;

