import React, { useState,useEffect } from 'react';
import { FaUser, FaUsers, FaPhone, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { FaFileDownload } from "react-icons/fa";
import { AiOutlineLike } from "react-icons/ai";
import { CiShare2 } from "react-icons/ci";
import Modal from "./fullScreenmodal";
import { SiSpringsecurity } from "react-icons/si";
import { CiFileOn } from "react-icons/ci";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { LuCrown } from "react-icons/lu";
import { LuHeartHandshake } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';

import {
  Collapse,
  Button,
  CardBody,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap';

import Footer from './Footer';
import './BiodataForm.css';
import theme1 from "./images/theme1.png";
import theme2 from "./images/theme2.png";
import theme3 from "./images/theme3.png";
import theme4 from "./images/theme4.png";
import theme5 from "./images/theme5.png";
import Premium from './Premium';
import { Navigation } from './navigation';

const themes = [
  { id: 1, title: "Theme 1", image: theme1 },
  { id: 2, title: "Theme 2", image: theme2 },
  { id: 3, title: "Theme 3", image: theme3 },
  { id: 4, title: "Theme 4", image: theme4 },
  { id: 5, title: "Theme 5", image: theme5 }
];

const BiodataForm = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [downloadModalShown, toggleDownloadModal] = useState(false);
  const [aboutMe, setAboutMe] = useState('');
  const [uploadedPhoto, setUploadedPhoto] = useState('');
 
  const navigate = useNavigate();
    sessionStorage.setItem("cost", 49)
    const [formData, setFormData] = useState({
      name: '',
      dob: '',
     collname:'',
       religion: '',
      caste: '',
      gotra: '',
      education: '',
      profession: '',
      uploadedPhoto: '',
      aboutme:'',
      fatherocc:'',
      motherocc:'',
      broc:'',
      sisc:'',
      famin:'',
      famdet:'',
      ph:'',
      address:'',
      partagemin:'',
      partagemax:'',
      partwemin:'',
      partwemax:'',
      partcout:'',
      partcaste:'',
      partgotra:'',
      partmarital:'',
      partworking:'',
      partedu:'',
      partinmin:'',
      partinmax:''
    });
  const handleClick = (id) => {
    setSelectedId(id);
    toggleDownloadModal(true);
    localStorage.setItem("selectedid",id);
  };




  const handleChange = (event) => {
    const { name, value } = event.target;
    const newFormData = { ...formData, [name]: value };

    setFormData(newFormData);
    if (name === "aboutme") {
      setAboutMe(value);
    }
    // Save updated form data to localStorage
    localStorage.setItem('formData', JSON.stringify(newFormData));
  };
  useEffect(() => {
    // Load stored data from localStorage
    const storedData = JSON.parse(localStorage.getItem('formData'));
    if (storedData) {
      setFormData(storedData);
      if (storedData.aboutme) {
        setAboutMe(storedData.aboutme);
      }
    }
  }, []);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setUploadedPhoto(base64String);
      const newFormData = { ...formData, uploadedPhoto: reader.result };
      setFormData(newFormData);
      localStorage.setItem('biodataForm', JSON.stringify(newFormData));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const [isOpen, setIsOpen] = useState({
    personal: false,
    family: false,
    contact: false,
    partner:false,
    photos: false,
  });

  const toggle = (section) => {
    setIsOpen((prevState) => ({ ...prevState, [section]: !prevState[section] }));
  };





  return (
    <div>
    <Navigation/>
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <h1 style={{ color: '#912524', fontWeight: 'bold', marginBottom: '40px' }}>
          Start Making Your Biodata
        </h1>
      </div>

      <Container>
        <Card className="mb-3">
          <Button
            color="primary"
            block
            onClick={() => toggle('personal')}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#912524',
            }}
          >
            <span>
              <FaUser /> Personal Details
            </span>
            {isOpen.personal ? <FaCaretUp /> : <FaCaretDown />}
          </Button>
          <Collapse isOpen={isOpen.personal}>
            <Container>
              <CardBody>
                <Form className="form-layout">
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" placeholder="Enter Name"  value={formData.name} onChange={handleChange} />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="dob">Date of Birth</Label>
                    <Input type="date" name="dob" id="dob"   value={formData.dob} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="tob">Time of Birth</Label>
                    <Input type="time" name="tob" id="tob" />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="pob">Place of Birth</Label>
                    <Input type="text" name="pob" id="pob" placeholder="Enter Place of Birth" />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="collname">College Name</Label>
                    <Input type="text" name="collname" id="collname" placeholder="Enter College Name" value={formData.collname} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="nakshatra">Nakshatra</Label>
                    <Input type="text" name="nakshatra" id="nakshatra" placeholder="Enter Nakshatra" />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="height">Height</Label>
                    <Input type="text" name="height" id="height" placeholder="Enter Height" />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="religion">Religion</Label>
                    <Input type="text" name="religion" id="religion" placeholder="Enter Religion"  value={formData.religion} onChange={handleChange} />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="caste">Caste</Label>
                    <Input type="text" name="caste" id="caste" placeholder="Enter Caste"  value={formData.caste} onChange={handleChange} />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="gotra">Gotra</Label>
                    <Input type="text" name="gotra" id="gotra" placeholder="Enter Gotra" value={formData.gotra} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="education">Education</Label>
                    <Input type="text" name="education" id="education" placeholder="Enter Education"  value={formData.education} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="profession">Profession</Label>
                    <Input type="select" name="profession" id="profession"  value={formData.profession} onChange={handleChange}>
                      <option>Select Profession</option>
                      <option>Engineer</option>
                      <option>Doctor</option>
                      <option>Teacher</option>
                      <option>Software Developer</option>
                      <option>Artist</option>
                      <option>Accountant</option>
                      <option>Other</option>
                    </Input>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
  <Label htmlFor="photoUpload">Upload Photo</Label>
  <Input type="file" name="photoUpload" id="photoUpload"   onChange={handlePhotoUpload} />
</FormGroup>
                  <FormGroup className="form-group-bio">
      <Label htmlFor="aboutme">About me (Limit: 100 characters)</Label>
     <div>
      <textarea 
        name="aboutme" 
        id="aboutme" 
        placeholder="Describe yourself to your partner" 
        value={formData.aboutme} 
        onChange={handleChange} 
        maxLength={100}
        style={{ width: '100%' }} 
        rows={5} // Adjust the number of rows as needed
        // Adjust the width of the textarea
      />

<p style={{ marginTop: '5px' }}>Remaining characters: {100 - aboutMe.length}</p>
</div>
    </FormGroup>
                </Form>
              </CardBody>
            </Container>
            <Button
              color="primary"
              onClick={() => toggle('family')}
              style={{
                marginLeft: '40px',
                backgroundColor: '#FF8A00',
                border: 'none',
                borderRadius: '15px',
                color: 'black',
                marginBottom: '20px'
              }}
            >
              Add Other Details
            </Button>
          </Collapse>
        </Card>

        <Card className="mb-3">
          <Button
            color="primary"
            block
            onClick={() => toggle('family')}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#912524',
            }}
          >
            <span>
              <FaUsers /> Family Details
            </span>
            {isOpen.family ? <FaCaretUp /> : <FaCaretDown />}
          </Button>
          <Collapse isOpen={isOpen.family}>
            <Container>
              <CardBody>
                <Form className="form-layout">
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="fatherocc">Father's Occupation</Label>
                    <Input type="text" name="fatherocc" id="fatherocc" placeholder="Enter Father's Occupation" value={formData.fatherocc} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="motherocc">Mother's Occupation</Label>
                    <Input type="text" name="motherocc" id="motherocc" placeholder="Enter Mother's Occupation" value={formData.motherocc} onChange={handleChange} />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="broc">Brother's count</Label>
                    <Input type="text" name="broc" id="broc" placeholder="Enter Brother's count" value={formData.broc} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="sisc">Sister's Count</Label>
                    <Input type="text" name="sisc" id="sisc" placeholder="Enter Sister's Count" value={formData.sisc} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="famin">Family Income</Label>
                    <Input type="number" name="famin" id="famin" placeholder="Family income Approx." value={formData.famin} onChange={handleChange}/>
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="famdet">Family Details</Label>
                    <Input type="text" name="famdet" id="famdet" placeholder="Family Details" value={formData.famdet} onChange={handleChange}/>
                  </FormGroup>
                </Form>
              </CardBody>
            </Container>
          </Collapse>
        </Card>

        <Card className="mb-3">
          <Button
            color="primary"
            block
            onClick={() => toggle('contact')}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#912524',
            }}
          >
            <span>
              <FaPhone /> Contact Details
            </span>
            {isOpen.contact ? <FaCaretUp /> : <FaCaretDown />}
          </Button>
          <Collapse isOpen={isOpen.contact}>
            <Container>
              <CardBody>
                <Form className="form-layout">
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="ph">Phone Number</Label>
                    <Input type="number" name="ph" id="ph" placeholder="Enter Phone Number" value={formData.ph} onChange={handleChange} />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="emailId">Email ID</Label>
                    <Input type="email" name="emailId" id="emailId" placeholder="Enter Email ID" />
                  </FormGroup>
                  <FormGroup className="form-group-bio">
                    <Label htmlFor="address">Address 1</Label>
                    <Input type="text" name="address" id="address" placeholder="Enter Address 1" value={formData.address} onChange={handleChange} />
                  </FormGroup>
            
                </Form>
              </CardBody>
            </Container>
          </Collapse>
        </Card>
        <Card className="mb-3">
          <Button
            color="primary"
            block
            onClick={() => toggle('partner')}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#912524',
            }}
          >
            <span>
            <LuHeartHandshake /> Partner Prefernces
            </span>
            {isOpen.partner ? <FaCaretUp /> : <FaCaretDown />}
          </Button>
          <Collapse isOpen={isOpen.partner}>
            <Container>
            <CardBody>
  <Form className="form-layout">
    <FormGroup className="form-group-bio">
      <Label htmlFor="partagemin"> Min Age</Label>
      <Input type="text" name="partagemin" id="partagemin" placeholder="Enter Min Age" value={formData.partagemin} onChange={handleChange} />
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partagemax"> Max Age</Label>
      <Input type="text" name="partagemax" id="partagemax" placeholder="Enter Max Age" value={formData.partagemax} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partwemin"> Min Weight</Label>
      <Input type="text" name="partwemin" id="partwemin" placeholder="Enter Min Weight" value={formData.partwemin} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partwemax"> Max Weight</Label>
      <Input type="text" name="partwemax" id="partwemax" placeholder="Enter Max Weight" value={formData.partwemax} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partcout"> Country</Label>
      <Input type="text" name="partcout" id="partcout" placeholder="Enter Country" value={formData.partcout} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partcaste"> Caste</Label>
      <Input type="text" name="partcaste" id="partcaste" placeholder="Enter Caste" value={formData.partcaste} onChange={handleChange} />
    </FormGroup>
  
    <FormGroup className="form-group-bio">
      <Label htmlFor="partgotra"> Gotra</Label>
      <Input type="text" name="partgotra" id="partgotra" placeholder="Enter Gotra" value={formData.partgotra} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partmarital"> Marital Status</Label>
      <Input type="text" name="partmarital" id="partmarital" placeholder="Enter Marital Status" value={formData.partmarital} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partworking"> Working Status</Label>
      <Input type="text" name="partworking" id="partworking" placeholder="Enter Working Status" value={formData.partworking} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partedu"> Education</Label>
      <Input type="text" name="partedu" id="partedu" placeholder="Enter Education" value={formData.partedu} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partinmin"> Min Income</Label>
      <Input type="text" name="partinmin" id="partinmin" placeholder="Enter Min Income" value={formData.partinmin} onChange={handleChange}/>
    </FormGroup>
    <FormGroup className="form-group-bio">
      <Label htmlFor="partinmax"> Max Income</Label>
      <Input type="text" name="partinmax" id="partinmax" placeholder="Enter Max Income" value={formData.partinmax} onChange={handleChange}/>
    </FormGroup>
  </Form>
</CardBody>

            </Container>
          </Collapse>
        </Card>

        <Card className="mb-3">
          <Button
            color="primary"
            block
            onClick={() => toggle('photos')}
            style={{
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#912524',
            }}
          >
            <span>
            <FaFileDownload  style={{ marginRight: '3px' }} />Download Your Biodata
            </span>
            {isOpen.photos ? <FaCaretUp /> : <FaCaretDown />}
          </Button>
          <Collapse isOpen={isOpen.photos}>
            <Container>
              <CardBody>
                <div style={{ display: 'flex', marginBottom: '22px' }}>
                  <h5 style={{ marginLeft: '40px' }}>Select Our Top Templates</h5>
                  <button className="download-button">Download</button>
                </div>
                <div className="photo-grid">
                  {themes.map((theme) => (
                    <div
                      key={theme.id}
                      className={`theme-item ${selectedId === theme.id ? "selected" : ""}`}
                      onClick={() => handleClick(theme.id)}
                    >
                      <img src={theme.image} alt={theme.title} />
                    </div>
                  ))}
                </div>
                {selectedId !== null && (
                  <Modal
                    shown={downloadModalShown}
                    close={() => toggleDownloadModal(false)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <div className="modal-content2">
                    
                      <div className="modal-image-section">
                        <img src={themes.find(theme => theme.id === selectedId).image} alt="Selected Template" className="modal-image" />
                        <div className="modal-buttons">
                          <button className="like-button"> <AiOutlineLike /> Like</button>
                          <button className="share-button"> <CiShare2/> Share</button>
                        </div>
                      </div>
                      <div className="modal-info-section">
                        <div className="modal-info-content">
                          <p style={{fontWeight:'bold',fontSize:'20px'}}> <LuCrown />  <span style={{color:'#FF8A00'}}>Premium</span> Image</p>
                          <p>Unlock this image by downloading this for just</p>
                         
                            <div style={{background:'yellow',marginBottom:'20px',border:'none',width:'70px',height:'30px',borderRadius:'8px',padding:'3px',marginLeft:'2px'}}>Rs 49/-</div>
                            <button style={{background:'#FF8A00',width:'120px',height:'40px',color:'black',border:'none',borderRadius:'10px',marginBottom:'5px',fontWeight:'bold'}} onClick={() => navigate('/biodata/payment')}>Download Now</button>
                        
                      
                         <p>Are You Only A Premium Member ? <a href='/login' style={{fontWeight:'bold'}}>Log In</a></p>

                        </div>
                        <div className="modal-extra-content">
                       <p><SiSpringsecurity /> <span>Premium License:</span>  More info</p>
                       <p><CiFileOn /> <span>File type:</span> PSD, JPG How to edit?</p>
                       <p><AiOutlineExclamationCircle /> <span>Any issue found?</span> Report content</p>
                       <button style={{background:'ghostwhite',width:'200px',height:'50px',padding:'10px',fontSize:'17px',fontWeight:'bold',border:'none',borderRadius:'10px'}}><MdOutlineBookmarkAdd /> Add to Collection</button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
              </CardBody>
            </Container>
          </Collapse>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default BiodataForm;