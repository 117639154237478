import React from "react";

export default function Subscribe() {
  const handleSubscribe = () => {
    window.open("https://forms.gle/dgjtbwAJruCvd3bh6", "_blank");
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Subscription Plans</h1>

      <div style={styles.row}>
        <div style={styles.card}>
          <div style={styles.cardBody}>
            <h3 style={styles.cardTitle}>Monthly</h3>
            <div style={styles.cardSubtitle}>
              <span style={styles.price}>299</span>
              <span style={styles.priceDetails}>/ month</span>
            </div>
            <ul style={styles.list}>
              <li>1 Monthly Edition</li>
              <li>Free Shipping</li>
            </ul>
            <button style={styles.button} onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>

        <div style={styles.card}>
          <div style={styles.cardBody}>
            <h3 style={styles.cardTitle}>Half Yearly</h3>
            <div style={styles.cardSubtitle}>
              <span style={styles.price}>1499</span>
              <span style={styles.priceDetails}>/ half yearly</span>
            </div>
            <ul style={styles.list}>
              <li>6 Monthly Edition</li>
              <li>Free Shipping</li>
            </ul>
            <button style={styles.button} onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>

        <div style={styles.card}>
          <div style={styles.cardBody}>
            <h3 style={styles.cardTitle}>Yearly</h3>
            <div style={styles.cardSubtitle}>
              <span style={styles.price}>2499</span>
              <span style={styles.priceDetails}>/ yearly</span>
            </div>
            <ul style={styles.list}>
              <li>12 Monthly Edition</li>
              <li>Free Shipping</li>
            </ul>
            <button style={styles.button} onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
    textAlign: "center",
  },
  heading: {
    marginBottom: "40px",
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  row: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    flex: "1 1 calc(33.333% - 40px)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px",
    overflow: "hidden",
  },
  cardBody: {
    padding: "20px",
  },
  cardTitle: {
    fontSize: "1.8rem",
    marginBottom: "10px",
  },
  cardSubtitle: {
    marginBottom: "20px",
    fontSize: "1.5rem",
  },
  price: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#333",
  },
  priceDetails: {
    fontSize: "1rem",
    color: "#777",
    marginLeft: "10px",
  },
  list: {
    listStyle: "none",
    padding: 0,
    marginBottom: "20px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#ff4c4c",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
  },
};
