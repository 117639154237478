import React, { useState } from "react";
import './RegisterForm.css'
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from './images/search_2.png'
import img8 from './images/image_1.png'
import axios from "axios";



const PageFive = ({
  onButtonClick,
  email,
  // username,
  phoneNumber,
  password,
  profile,
  martialStatus,
  motherTongue,
  religion,
  caste,
  subCaste,
  gotra,
  manglik,
  fullname,
  gender,
  dob,
  height,
  weight,
  facecolor,
  age,
  birth_time,
  birth_location,
  address,
  district,
  city,
  state,
  country,
  education,
  other_education,
  college,
  hobbies,
  food_style,
  drinking_habit,
}) => {
  const [working, setWorking] = useState("");
  const [employeed, setEmployeed] = useState("");
  const [otherEmployeed, setOtherEmployeed] = useState("");
  const [profession, setProfession] = useState("");
  const [otherProfession, setOtherProfession] = useState("");
  const [salary, setSalary] = useState("");
  const [company, setCompany] = useState(""); // Initialize with an empty string
  const [otherCompany, setOtherCompany] = useState("");
  const [worklocation, setWorklocation] = useState("");
  const [otherWorklocation, setOtherWorklocation] = useState("");


  const [workingError, setWorkingError] = useState("");
  const [employeedError, setEmployeedError] = useState("");
  const [otherEmployeedError, setOtherEmployeedError] = useState("");
  const [professionError, setProfessionError] = useState("");
  const [otherProfessionError, setOtherProfessionError] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [otherCompanyError, setOtherCompanyError] = useState("");
  const [worklocationError, setWorklocationError] = useState("");
  const [otherWorklocationError, setOtherWorklocationError] = useState("");


  // const [registrationError, setRegistrationError] = useState("");


  const capitalizeFirstLetter = (value) => {
    // Capitalize the first letter of the input value
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const handleCreateWorkspace = async () => {

    if (working.trim() === "Non-working" || working.trim() === "Student") {
      // If the user is Non-working or Student, set other fields to empty string
      setEmployeed("-");
      setOtherEmployeed("-");
      setProfession("-");
      setOtherProfession("-");
      setSalary("-");
      setCompany("-");
      setOtherCompany("-");
      setWorklocation("-");
      setOtherWorklocation("-");
    }

    
    if (
      working.trim() !== "" &&
      employeed.trim() !== "" &&
      profession.trim() !== "" &&
      salary.trim() !== "" &&
      company.trim() !== "" &&
      worklocation.trim() !== "" &&
    ((employeed === "Other (Specify)" && capitalizeFirstLetter(otherEmployeed).trim() !== "") ||
    employeed !== "Other (Specify)") &&
    ((profession === "Other (Specify)" && capitalizeFirstLetter(otherProfession).trim() !== "") ||
    profession !== "Other (Specify)")  &&
    ((company === "Other (Specify)" && capitalizeFirstLetter(otherCompany).trim() !== "") ||
    company !== "Other (Specify)") &&
    ((worklocation === "Other (Specify)" && capitalizeFirstLetter(otherWorklocation).trim() !== "") ||
    worklocation !== "Other (Specify)")  
    )
    
     {
      
      try {
        const response = await fetch(`${process.env.REACT_APP_MAINURL}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            username: email,
            phonenumber: phoneNumber,
            password: password,
            createfor: profile,
            marital_status: martialStatus,
            mother_tongue: motherTongue,
            religion: religion,
            caste: caste,
            sub_caste: subCaste,
            gotra: gotra,
            manglik: manglik,
            full_name: fullname,
            gender: gender,
            date_of_birth: dob,
            birth_date: dob,
            height: height,
            weight: weight,
            face_color: facecolor,
            age: age,
            birth_time: birth_time,
            birth_location: birth_location,
            address: address,
            district: district,
            city: city,
            state: state,
            country: country,
            highest_education: education,
            other_education : other_education,
            college_name: college,
            hobbies: hobbies,
            food_style: food_style,
            drinking_habit: drinking_habit,
            working_status: working,
            employed_in: employeed === "Other (Specify)" ? capitalizeFirstLetter(otherEmployeed) : employeed,
            occupation: profession === "Other (Specify)" ? capitalizeFirstLetter(otherProfession) : profession,
            income: salary,
            current_company: company === "Other (Specify)" ? capitalizeFirstLetter(otherCompany) : company,
            company_location: worklocation === "Other (Specify)" ? capitalizeFirstLetter(otherWorklocation) : worklocation,
            register: true,
          }),
        });
  
        const data = await response.json();
  
        if (data.error === "Email already registered") {
          window.alert("Email already registered. Please use a different email.");
        } else {
          onButtonClick("pagesix", working, data);
        }
      } catch (err) {
        console.error(err);
        window.alert("Error occurred while processing the request.");
      }
      
    } else {
      setWorkingError(
        working.trim() === ""
      ? "Please fill in the working status"
      : "");

      

      setEmployeedError(employeed.trim() === "" ? "Please fill in the employeed in" : "");
      setOtherEmployeedError(
        employeed === "Other (Specify)" && otherEmployeed.trim() === ""
          ? "Please specify employeed in"
          : ""
      );

      setProfessionError(
        profession.trim() === "" ? "Please select the profession" : ""
      );
      setOtherProfessionError(
        profession === "Other (Specify)" && otherProfession.trim() === ""
          ? "Please specify profession"
          : ""
      );

      setSalaryError(salary.trim() === "" ? "Please select the salary" : ""
      );
      

      setCompanyError(
        company.trim() === "" ? "Please fill in the company" : ""
      );
      setOtherCompanyError(
        company === "Other (Specify)" && otherCompany.trim() === ""
          ? "Please specify your current company"
          : ""
      );

      setWorklocationError(worklocation.trim() === "" ? "Please fill in the work location" : "");
      setOtherWorklocationError(
        worklocation === "Other (Specify)" && otherWorklocation.trim() === ""
          ? "Please specify work location"
          : ""
      );

    }
  };

  return (
    <main
      className="black-80 center form-main-register"
      style={{ maxHeight: "30%", margin: "auto" }}
    >
      <div className="desktop-step" style={{ display: "flex", flexDirection: "column", }}>
                <div id="#form-step">
                  <i
                    style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                    className=" step fa"
                  >
                    <img src={img1} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Easy Registeration</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa"
                  style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Chat With Partner</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Video Calls</h3>
                  </div>
                </div>
                <div id="#form-step">
                  <i className="fa" style={{
                      fontSize: "42px",
                      width: "120px",
                      height: "120px",
                      padding: "38px 40px",
                      background: "#FBC913",
                      borderRadius: "50%",
                      color: "#fff",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}>
                    <img style={{ paddingTop: "10px" }} src={img7} alt="img" />
                  </i>
                  <div className="service-desc">
                    <h3>Search Profiles Conveniently</h3>
                  </div>
                </div>
              </div>

              <div className="form-desktop-step">
      <form className="measure measure1" style={{margin: "auto", padding: "20px",
                            background: "#FF4242",
                            borderRadius: "20px" }}>
                        <h4 style={{color: 'white'}}>Employment</h4>
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
        <div className="mv3">
            <label
              className="db lh-copy f6 mb1"
              htmlFor="working"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Working Status *
            </label>
            <select
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              name="working"
              id="working"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: workingError ? "red" : "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={working}
              onChange={(e) => setWorking(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Working">Working</option>
              <option value="Non-working">Non-working</option>
              <option value="Student">Student</option>
            </select>
          </div>
          <div className="mv3" style={{ color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {workingError}
          </div>
          


          
          <div className="mv3" style={{ display: working === "Non-working" || working === "Student" ? "none" : "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="employeed"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Employeed In *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="employeed"
                id="employeed"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: employeedError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={employeed}
                onChange={(e) => {
                  setEmployeed(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherEmployeed("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Private Sector">Private Sector</option>
              <option value="Government Sector">Government Sector</option>
              <option value="Public Sector">Public Sector</option>
              <option value="Self-Employed">Self-Employed</option>
              <option value="Not Employed">Not Employed</option>
              <option value="Education">Education</option>
              <option value="Healthcare">Healthcare</option>
              <option value="IT/Technology">IT/Technology</option>
              <option value="Finance">Finance</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Research">Research</option>
              <option value="Retail">Retail</option>
              <option value="Media/Entertainment">Media/Entertainment</option>
              <option value="Non-Profit">Non-Profit</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {employeed === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherEmployeed}
                  onChange={(e) => setOtherEmployeed(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherEmployeedError? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{display: working === "Non-working" || working === "Student" ? "none" : "", color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {employeedError || otherEmployeedError}
          </div>


          
          <div className="mv3" style={{ display: working === "Non-working" || working === "Student" ? "none" : "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="profession"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
            >
              Profession *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="profession"
                id="profession"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: professionError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={profession}
                onChange={(e) => {
                  setProfession(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherProfession("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Accountant">Accountant</option>
              <option value="Artist">Artist</option>
              <option value="Engineer">Engineer</option>
              <option value="Doctor">Doctor</option>
              <option value="Teacher">Teacher</option>
              <option value="Software Developer">Software Developer</option>
              <option value="Manager">Manager</option>
              <option value="Lawyer">Lawyer</option>
              <option value="Nurse">Nurse</option>
              <option value="Designer">Designer</option>
              <option value="Writer">Writer</option>
              <option value="Farmer">Farmer</option>
              <option value="Cook">Cook</option>
              <option value="Clerk">Clerk</option>
              <option value="Entrepreneur">Entrepreneur</option>
              <option value="Police Officer">Police Officer</option>
              <option value="Chef">Chef</option>
              <option value="Salesperson">Salesperson</option>
              <option value="Student">Student</option>
              <option value="Homemaker">Homemaker</option>
              <option value="Retired">Retired</option>
              <option value="CEO/Founder/CTO/CXO">CEO/Founder/CTO/CXO</option>
              <option value="Assistant Professor">Assistant Professor</option>
              <option value="Associate Professor">Associate Professor</option>
              <option value="Professor">Professor</option>
              <option value="Manager">Manager</option>
              <option value="Senior Manager">Senior Manager</option>
              <option value="Consultant">Consultant</option>
              <option value="Lecturer">Lecturer</option>
              <option value="Director">Director</option>
              <option value="Technical lead">Technical lead</option>
              <option value="General Manager">General Manager</option>
              <option value="Government Officer">Government Officer</option>
              <option value="Minister">Minister</option>
              <option value="MLA">MLA</option>
              <option value="MP">MP</option>
              <option value="Speaker">Speaker</option>
              <option value="Managing Director">Managing Director</option>
              <option value="Architect">Architect</option>
              <option value="Interior Designer">Interior Designer</option>
              <option value="Human Resource">Human Resource</option>
              <option value="HR Manager">HR Manager</option>
              <option value="Executive">Executive</option>
              <option value="Senior Executive">Senior Executive</option>
              <option value="Businessman">Businessman</option>
              <option value="Franchise owner">Franchise owner</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {profession === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherProfession}
                  onChange={(e) => setOtherProfession(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherProfessionError? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{display: working === "Non-working" || working === "Student" ? "none" : "", color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {professionError || otherProfessionError}
          </div>


          <div className="mv3" style={{display: working === "Non-working" || working === "Student" ? "none" : ""}}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="salary"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Salary *
            </label>
            <select
              className="f6 br3 ph3 pv4 mb4 dib black w-100"
              name="salary"
              id="salary"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: salaryError ? "red" : "rgb(126 121 121 / 95%)",
                fontSize: "1.5rem",
                color: "rgb(126 121 121 / 95%)",
              }}
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Less than 1,00,000">Less than 1,00,000</option>
              <option value="1,00,000 - 2,00,000">1,00,000 - 2,00,000</option>
              <option value="2,00,000 - 3,00,000">2,00,000 - 3,00,000</option>
              <option value="3,00,000 - 4,00,000">3,00,000 - 4,00,000</option>
              <option value="4,00,000 - 5,00,000">4,00,000 - 5,00,000</option>
              <option value="5,00,000 - 6,00,000">5,00,000 - 6,00,000</option>
              <option value="6,00,000 - 7,00,000">6,00,000 - 7,00,000</option>
              <option value="7,00,000 - 8,00,000">7,00,000 - 8,00,000</option>
              <option value="8,00,000 - 9,00,000">8,00,000 - 9,00,000</option>
              <option value="9,00,000 - 10,00,000">9,00,000 - 10,00,000</option>
              <option value="10,00,000 - 12,50,000">10,00,000 - 12,50,000</option>
              <option value="12,50,000 - 15,00,000">12,50,000 - 15,00,000</option>
              <option value="15,00,000 - 20,00,000">15,00,000 - 20,00,000</option>
              <option value="20,00,000 - 30,00,000">20,00,000 - 30,00,000</option>
              <option value="30,00,000 - 40,00,000">30,00,000 - 40,00,000</option>
              <option value="40,00,000 - 50,00,000">40,00,000 - 50,00,000</option>
              <option value="50,00,000 - 60,00,000">50,00,000 - 60,00,000</option>
              <option value="60,00,000 - 70,00,000">60,00,000 - 70,00,000</option>
              <option value="70,00,000 - 80,00,000">70,00,000 - 80,00,000</option>
              <option value="80,00,000 - 90,00,000">80,00,000 - 90,00,000</option>
              <option value="90,00,000 - 1,00,00,000">90,00,000 - 1,00,00,000</option>
              <option value="1,00,00,000 - 1,25,00,000">1,00,00,000 - 1,25,00,000</option>
              <option value="1,25,00,000 - 1,50,00,000">1,25,00,000 - 1,50,00,000</option>
              <option value="1,50,00,000 - 2,00,00,000">1,50,00,000 - 2,00,00,000</option>
              <option value="More than 2,00,00,000">More than 2,00,00,000</option>
              <option value="More than 10,00,00,000">More than 10,00,00,000</option>
              <option value="Not Applicable">Not Applicable</option>
            </select>
          </div>
          <div className="mv3" style={{display: working === "Non-working" || working === "Student" ? "none" : "", color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left',marginBottom: '10px'}}>
            {salaryError}
          </div>

          
          <div className="mv3" style={{ display: working === "Non-working" || working === "Student" ? "none" : "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="company"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575' }}
            >
              Current Company *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="company"
                id="company"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: companyError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherCompany("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="TCS">TCS</option>
              <option value="ACCENTURE">ACCENTURE</option>
              <option value="COGNIZANT">COGNIZANT</option>
              <option value="Microsoft">Microsoft</option>
              <option value="Google">Google</option>
              <option value="NUTANIX">NUTANIX</option>
              <option value="FLIPKART">FLIPKART</option>
              <option value="AMAZON">AMAZON</option>
              <option value="GOLDMAN SACHS">GOLDMAN SACHS</option>
              <option value="WALMART">WALMART</option>
              <option value="VIVAHSAHYOG.COM">VIVAHSAHYOG.COM</option>
              <option value="WIPRO">WIPRO</option>
              <option value="ICICI BANK">ICICI BANK</option>
              <option value="BANK OF BARODA">BANK OF BARODA</option>
              <option value="HDFC BANK">HDFC BANK</option>
              <option value="SBI BANK">SBI BANK</option>
              <option value="ZOMATO">ZOMATO</option>
              <option value="HP">HP</option>
              <option value="DELL">DELL</option>
              <option value="SWIGGY">SWIGGY</option>
              <option value="PAYTM">PAYTM</option>
              <option value="PAYPAL">PAYPAL</option>
              
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {company === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherCompany}
                  onChange={(e) => setOtherCompany(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherCompanyError? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{display: working === "Non-working" || working === "Student" ? "none" : "", color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {companyError || otherCompanyError}
          </div>


          
          <div className="mv3" style={{ display: working === "Non-working" || working === "Student" ? "none" : "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <label
              className="db lh-copy f6 mb1"
              htmlFor="worklocation"
              style={{ textAlign: "left", fontSize: "2rem", color: 'white', fontWeight: '575'}}
            >
              Working Location *
            </label>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
              <select
                className="f6 br3 ph6 pv4 mb4 dib black w-100"
                name="worklocation"
                id="worklocation"
                style={{
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: worklocationError ? "red" : "rgb(126 121 121 / 95%)",
                  fontSize: "1.5rem",
                  color: "rgb(126 121 121 / 95%)",
                }}
                value={worklocation}
                onChange={(e) => {
                  setWorklocation(e.target.value);
                  // Reset otherMotherTongue when a different option is selected
                  if (e.target.value !== "Other (Specify)") {
                    setOtherWorklocation("");
                  }
                }}
              >
                <option value="">Select</option>
                <option value="Delhi">Delhi</option>
              <option value="Gurugram">Gurugram</option>
              <option value="Faridabad">Faridabad</option>
              <option value="Noida">Noida</option>
              <option value="Greater Noida">Greater Noida</option>
              <option value="Ghaziabad">Ghaziabad</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Kolkata">Kolkata</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Chennai">Chennai</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Ahmedabad">Ahmedabad</option>
              <option value="Pune">Pune</option>
              <option value="Surat">Surat</option>
              <option value="Lucknow">Lucknow</option>
              <option value="Jaipur">Jaipur</option>
              <option value="Kanpur">Kanpur</option>
              <option value="Indore">Indore</option>
              <option value="Nagpur">Nagpur</option>
              <option value="Patna">Patna</option>
              <option value="Amroha">Amroha</option>
              <option value="Remote">Remote</option>
                {/* Add other language options */}
                <option value="Other (Specify)">Other (Specify)</option>
              </select>

              {worklocation === "Other (Specify)" && (
                <input
                  type="text" 
                  className="f6 br3 ph5 pv4 mb4 dib black w-100"
                  placeholder="Specify"
                  value={otherWorklocation}
                  onChange={(e) => setOtherWorklocation(e.target.value)}
                  style={{
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: otherWorklocationError? "red" : "rgb(126 121 121 / 95%)",
                    fontSize: "1.5rem",
                    marginLeft: "15px",
                    paddingBottom: "15px",
                    
                  }}
                />
              )}
            </div>
          </div>
          <div className="mv3" style={{ display: working === "Non-working" || working === "Student" ? "none" : "",color: "red", fontSize: "1.5rem", marginTop: '-25px', textAlign: 'left', marginBottom: '10px' }}>
            {worklocationError || otherWorklocationError}
          </div>

        </fieldset>

        
        <div >
          <input
            className="f6 grow br3 ph3 pv2 mb4 dib white"
            style={{
              borderStyle: "none",
              // width: "100%",
              padding: "7px 30px",
              backgroundColor: "rgb(204, 0, 0)",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
            type="button"
            value="Submit"
            onClick={handleCreateWorkspace}
          />
        </div>
      </form>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }} className="desktop-step">
        <div id="#form-step">
          <i className=" step fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img src={img4} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Verified Profiles</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "27px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img5} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Affordable Membership Plans</h3>
            

          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img6} alt="img" />
          </i>
          <div className="service-desc">
            <h3>15+ available filters for Matchmaking</h3>
          </div>
        </div>
        <div id="#form-step">
          <i className="fa" style={{
              fontSize: "42px",
              width: "120px",
              height: "120px",
              padding: "38px 40px",
              background: "#FBC913",
              borderRadius: "50%",
              color: "#fff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            }}>
            <img style={{ paddingTop: "10px" }} src={img8} alt="img" />
          </i>
          <div className="service-desc">
            <h3>Photo Based Additional Verification</h3>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PageFive;