import React from "react";
import PlanCardApi from "./PlanCardApi";
import Aboutdownload from "./Aboutdownload";
import Footer from "./Footer";
import PlanPagecardsSubscribe from "./PlanPagecardsSubscribe";
import PlanSubscriptionDetails from "./PlanSubscriptionDetails";
import { useNavigate } from "react-router-dom";

const PlanSubscribe = () => {
  const navigate = useNavigate();
  const subscribe_user_status = sessionStorage.getItem("subscribe_user_status");
  const family_details_status = sessionStorage.getItem("family_details_status");
    const partner_pref_status = sessionStorage.getItem("partner_pref_status");

  const handleclick = () => {
    if (family_details_status === "empty"){
      navigate('/familydetails')
    }

    if(family_details_status === "created" && partner_pref_status === "empty"){
      navigate('/partnerdetails')
    }

    if (family_details_status === "created" && partner_pref_status === "created"){
      navigate('/myprofile')
    }
  }

  return (
    <div>
      {/* <Loginnavigation/> */}
      {subscribe_user_status !== 'null' ? (
        <button style={{background:"none", border:"none", display:"flex", margin:"auto"}}>
        <button
          style={{
            marginTop: "30px",
            padding: "10px 20px",
            background: "rgb(214, 14, 8)",
            borderRadius: "10px",
            fontSize:"20px",
            fontWeight:"575",
            color: "white",
            border:"none",

          }}
          onClick={handleclick}
        >
          Skip
        </button>
      </button>
      ):(
        <button style={{background:"rgb(214, 14, 8)", border:"none", display:"flex", margin:"auto", padding: "5px 10px",borderRadius: "10px",
            fontSize:"20px",
            fontWeight:"575",
            color: "white", marginTop:"30px"}}>Please Select a plan to continue</button>
      )}
      <PlanSubscriptionDetails />
      <PlanCardApi subscribe_user_status={subscribe_user_status} />
      <PlanPagecardsSubscribe />
      <Aboutdownload />
      <Footer />
    </div>
  );
};

export default PlanSubscribe;
