import React from "react";
import tachyons from "tachyons";
import img1 from "./images/Vector.png";
import img2 from "./images/Group-6.png";
import img3 from "./images/Vector-1.png";
import img4 from "./images/Vector-2.png";
import img5 from "./images/rs.png";
import img6 from "./images/sliders.png";
import img7 from "./images/search_2.png";
import img8 from "./images/image_1.png";

export const Services = (props) => {
  return (
    <div id="services" style={{ background: "rgb(245 233 191 / 69%)" }}>
      <div className="container">
        <div className="section-title">
          <h2
            className="text-center"
            style={{
              color: "#FFB800",
              fontSize: "38px",
              fontWeight: "575",
              textTransform: "none",
              fontFamily: "Lemon",
            }}
          >
            Enjoy matchmaking with Vivah Sahyog...
          </h2>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
          <div
            style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 65%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img1} alt="img" />
            </i>
            <div className="service-desc" style={{ marginBottom: "0" }}>
            <h3></h3>
              <h3
                style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}
              >
                Easy Registeration
              </h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 65%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img style={{ paddingTop: "10px" }} src={img2} alt="img" />
            </i>
            <div className="service-desc" style={{ marginBottom: "0" }}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Chat with Partner</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 65%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img style={{ paddingTop: "10px" }} src={img3} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Video Calls</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img4} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
              <h3 ></h3>
              
                <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Verified Profiles</h3>
                <h3></h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img
                style={{ width: "30px", height: "40px" }}
                src={img5}
                alt="img"
              />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3
              style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Affordable Membership Plans</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img6} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>15+ available filters for Matchmaking</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img7} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Search Profiles as per your Convinience</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img8} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Photo Based Additional Verification</h3>
            </div>
          </div>
          </div>

          <div className="col-md-4">
          <div 
          style={{
              margin: '20px 0',
              paddingTop: '40px',
              paddingBottom: '40px',
              border: "1px solid #FF8A00",
              borderRadius: "20px",
              background:
                "linear-gradient(180deg, hsla(0, 0%, 78%, 1) 60%, hsla(0, 0%, 100%, 1) 58%)",
              boxShadow: '#FF8A00 0px 2px 4px, rgba(0, 0, 0, 0.4) 0px 7px 13px -3px, #FF8A00 0px -3px 0px inset'
            }}
          >
            {" "}
            <i className="fa">
              <img src={img8} alt="img" />
            </i>
            <div className="service-desc" style={{marginBottom: '0'}}>
            <h3></h3>
              <h3 style={{
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#3E3E3E",
                }}>Horoscope Matching</h3>
                <h3></h3>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
