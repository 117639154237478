import React, { useState } from "react";
import "./Form.css";
import plan_header1 from "./images/plan-header1.jpeg";
import { Navigation } from "./navigation";

export const Planheader = (props) => {
  return (
    <div>
      <Navigation />
      <header id="header">
        <div className="intro" style={{ background: "#FBC913" }}>
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="container">
                      <div
                        className="col-md-6 intro-text new-col new-plan-height"
                      >
                        <h1 className="plan-heading" >
                        We offer affordable Membership Plans covering all range of needs
                        </h1>
                        
                      </div>
                   <div className="col-md-6 intro-text new-col new-plan-img-height">
                    
                      

                      <div
                        class="container mt-3 col-sm-6  "
                        style={{
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <img className="plan-header-img"  src={plan_header1} />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
