import React, { useState, useEffect } from "react";
import { AboutNavigation } from "./AboutNavigation";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";

const Magazine = () => {
  const [magazines, setMagazines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/pdfs.json")
      .then((response) => response.json())
      .then((data) => setMagazines(data))
      .catch((error) => console.error("Error fetching magazines:", error));
  }, []);

  const handleSubscribeClick = () => {
    navigate("/subscribe");
  };

  return (
    <>
      <AboutNavigation />
      <div
        style={{
          marginTop: "80px",
          padding: "20px 50px",
          background:
            "linear-gradient(0deg, rgba(18,19,19,1) 0%, rgba(248,240,240,1) 0%, rgba(228,52,28,1) 100%)",
          paddingBottom: "20px",
          textAlign: "center",
        }}
      >
        <h3
          style={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            color: "white",
            paddingBottom: "5px",
            fontFamily: "Lemon",
          }}
        >
          Magazine
        </h3>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdmg9pRecHVv9ztlHT7JFVP0FTEhbmyNk1fY3C5f33Zibzb_Q/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              style={{
                marginRight: "10px",
                padding: "10px 20px",
                backgroundColor: "#ffc107",
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
                fontSize: "16px",
              }}
            >
              Online Nomination Form
            </button>
          </a>
          <button
            onClick={handleSubscribeClick}
            style={{
              padding: "10px 20px",
              backgroundColor: "#ffc107",
              border: "none",
              cursor: "pointer",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          >
            Subscribe
          </button>
        </div>
        <hr />
      </div>

      <div
        style={{
          padding: "20px",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {magazines.map((magazine, index) => (
            <div
              key={index}
              style={{
                flex: "1 1 calc(25% - 20px)", // Make each card take up 25% of the width with gap considered
                maxWidth: "calc(25% - 20px)", // Ensure maximum width to maintain uniformity
                minWidth: "300px", // Set a minimum width for each card to ensure responsiveness
                margin: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={() =>
                window.location.href ===
                `/view/${encodeURIComponent(magazine.path)}`
              }
            >
              <img
                src={magazine.image}
                alt={`${magazine.name} cover`}
                style={{
                  width: "100%",
                  height: "400px", // Set a fixed height for uniformity
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <h4
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontSize: "1.5rem",
                    marginBottom: "10px",
                  }}
                >
                  {magazine.name}
                </h4>
                <Link
                  to={`/view/${encodeURIComponent(magazine.path)}`}
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontSize: "1.2rem",
                    marginTop: "0px",
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  View PDF
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Magazine;
